import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import Menu from "../../components/Menu";
import { getMessage } from "../../lib/translator";
import ErrorBoundary, { notifyBugsnag } from "../../components/ErrorBoundary";
import {
  isExtensionEnabled,
  getStores,
  setStores,
  getSession,
} from "../../lib/auth";
import { set, get } from "../../lib/storage";
import API from "../../lib/api";
import NavBar from "../../components/NavBar";
import Coachmarks from "../../components/Coachmarks";
import ZoppingIcon from "../../components/Menu/images/logo_dark.webp";
import { GOOGLE_MAP_DEFAULT_KEY } from "../../config/app";
import AccountDisabled from "../../components/AccountDisabled";
import FreeTrailEnd from "../../components/FreeTrialEnd";
import CountTrialDays from "../../utils/countTrailDays";


const httpStatusMessages = {
  403: getMessage("error.server.403"),
};

const ICoachList = [
  {
    id: 1,
    title: getMessage("coachmarks.home.1.title"),
    description: getMessage("coachmarks.home.1.description"),
    buttonText: getMessage("got.it"),
    toolTipPosition: "bottom",
  },
  {
    id: 2,
    title: getMessage("menu.item.my-account"),
    description: getMessage("coachmarks.home.3.description"),
    buttonText: getMessage("got.it"),
    toolTipPosition: "left",
  },
  {
    id: 3,
    title: getMessage("pending.actions"),
    description: getMessage("coachmarks.home.4.description"),
    buttonText: getMessage("got.it"),
    toolTipPosition: "bottom",
  },
  {
    id: 4,
    title: getMessage("quick.links.text"),
    description: getMessage("coachmarks.home.5.description"),
    buttonText: getMessage("got.it"),
    toolTipPosition: "bottom",
  },
  {
    id: 5,
    title: getMessage("coachmarks.home.6.title"),
    description: getMessage("coachmarks.home.6.description"),
    buttonText: getMessage("got.it"),
    toolTipPosition: window.screen.width <= 640 ? "bottom" : "right",
  },
];

const updateCoachList = (coachList, props, accountBalanceRef, myAccountRef) => {
  const updatedCoachList = coachList.map((coachListItem, index) => {
    switch (index) {
      case 0:
        coachListItem.reference = { current: props.visitStoreRef };
        break;
      case 1:
        coachListItem.reference = { current: myAccountRef };
        break;
      case 2:
        coachListItem.reference = { current: props.pendingActionsRef };
        break;
      case 3:
        coachListItem.reference = { current: props.quickLinksRef };
        break;
      case 4:
        coachListItem.reference = { current: props.storeAnalyticsRef };
        break;
      default:
        break;
    }
    return coachListItem;
  });
  return updatedCoachList;
};

export default class AuthenticatedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      httpStatus: 200,
      clickMenuIcon: false,
      balance: null,
      coachList: ICoachList,
    };
    this.title = props.title;
    this.showGuidedTour = Boolean(
      window.location.search?.includes("showGuidedTour")
    );
    this.handleError = this.handleError.bind(this);
  }
  componentWillMount() {
    window.addEventListener("unhandledrejection", this.handleError);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleError);
  }

  getCurrentBalance() {
    let permissions = JSON.parse(window.localStorage.getItem("permissions"));
    if (permissions.hasOwnProperty("billing-service/balance")) {
      if (get("account-balance")) {
        this.setState({
          balance: JSON.parse(get("account-balance")),
        });
      } else {
        let api = new API({ url: "/billing-service/balance" });
        api
          .get()
          .then((response) => {
            this.setState({ balance: response.data }, () => {
              set("account-balance", JSON.stringify(response.data));
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  getWebsiteConfig() {
    if (!get("googleMapAPIKey")) {
      const websiteConfigApi = new API({
        url: `/config-service/config/website`,
      });
      websiteConfigApi
        .get()
        .then((websiteConfigResponse) => {
          const key =
            websiteConfigResponse.data &&
            websiteConfigResponse.data.website &&
            websiteConfigResponse.data.website.googleApisKey;
          set("googleMapAPIKey", key || GOOGLE_MAP_DEFAULT_KEY);
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  setAccountBalanceRef = (reference) => {
    this.accountBalanceRef = reference;
  };

  setMyAccountRef = (reference) => {
    this.myAccountRef = reference;
  };

  componentDidMount() {
    if (isExtensionEnabled("MultiStoreSupport")) {
      const organization = JSON.parse(
        window.localStorage.getItem("organization")
      );
      if (
        (!getStores() || getStores()[0].name === "Default") &&
        organization.status === "ENABLED"
      ) {
        const api = new API({ url: "/account-service/store" });
        api
          .get({ paginate: "false" })
          .then((response) => {
            let stores = response.data.store.reverse();
            let user = getSession() && getSession().user;
            if (user && user.stores && user.stores.length > 0) {
              stores = user.stores.reverse();
              setStores(stores);
              set("store", stores[0].id);
            }
            setStores(stores);
            set("store", stores[0].id);
            this.setState({
              stores,
              storeId: stores[0].id,
            });
            this.props.setApiParam && this.props.setApiParam(stores[0].id);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({
          stores: getStores(),
          storeId: get("store"),
        });
      }
    } else {
      if (!get("store")) {
        const organizationData = JSON.parse(
          window.localStorage.getItem("organization")
        );
        const stores = [organizationData.defaultStore];
        set("store", organizationData.defaultStore?.id);
        setStores(stores);
        this.setState({
          storeId: organizationData.defaultStore.id,
          stores,
        });
      } else {
        this.setState({
          stores: getStores(),
          storeId: get("store"),
        });
      }
    }
    this.getCurrentBalance();
    this.getWebsiteConfig();
    window.scroll(0, 0);
    if (this.showGuidedTour) {
      const updatedCoachList = updateCoachList(
        this.state.coachList,
        this.props,
        this.accountBalanceRef,
        this.myAccountRef
      );
      this.setState({ coachList: updatedCoachList });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.showGuidedTour &&
      (prevProps.visitStoreRef !== this.props.visitStoreRef ||
        prevProps.pendingActionsRef !== this.props.pendingActionsRef ||
        prevProps.quickLinksRef !== this.props.quickLinksRef ||
        prevProps.storeAnalyticsRef !== this.props.storeAnalyticsRef)
    ) {
      const updatedCoachList = updateCoachList(
        this.state.coachList,
        this.props,
        this.accountBalanceRef,
        this.myAccountRef
      );
      this.setState({ coachList: updatedCoachList });
    }
  }

  handleError(e) {
    e.preventDefault();
    const newState = Object.assign({}, this.state);
    if (e?.reason?.code === 401) {
      newState.redirectToLogin = true;
    } else if (e?.reason?.message === "cancelled") {
      // do nothing
    } else {
      newState.httpStatus = e?.reason?.code;
      newState.error = e?.reason?.message;
      if (e?.reason?.code !== 401 && e?.reason?.code !== 403) {
        notifyBugsnag(e?.reason?.message || e, e?.reason);
      }
    }
    console.error(e?.reason?.message);
    this.setState(newState);
  }
  updateStore(storeId) {
    this.setState(
      {
        storeId: storeId,
      },
      () => this.props.onChange && this.props.onChange()
    );
  }

  handleMenuIconClick = (clickIcon) => {
    this.setState({ clickMenuIcon: clickIcon });
  };
  handleMenuClose = (e) => {
    if (
      !e.target.classList.length ||
      e.target.classList.contains("mobile-menu")
    ) {
      this.setState({ clickMenuIcon: false });
    }
  };
  render() {
    const { props } = this;
    const { clickMenuIcon, balance, coachList } = this.state;
    const userData = JSON.parse(get("user")) || {};
    let numberOfDays = 0;

    let menuProps = {
      items: props.menu,
    };
    if (props.storeDependent && isExtensionEnabled("MultiStoreSupport")) {
      menuProps.stores = this.state.stores;
      menuProps.storeId = this.state.storeId;
    }
    if (props.showLanguageDropDown) {
      menuProps.showLanguageDropDown = true;
    }
    if (menuProps?.items?.user && !userData.isOwner) {
      menuProps.items.user = menuProps.items.user.filter(
        (userMenu) => userMenu !== "delete-account"
      );
    }
    // const isUserVerified = userData?.isOwner && userData?.emails?.[0]?.verified;
    // const showVerifyEmailWarning = Boolean(
    //   userData?.id &&
    //     !isUserVerified &&
    //     userData?.emails?.length &&
    //     !window?.location?.pathname?.includes("onboarding")
    // );

    if (getSession()?.organization?.evaluationPeriodDetails) {
      numberOfDays = CountTrialDays(
        getSession()?.organization?.evaluationPeriodDetails
      );
    }
    const showFreeTrailEnd =
      getSession()?.organization?.evaluationPeriodDetails?.underEvaluation &&
      numberOfDays <= 0 &&
      !window?.location?.pathname?.includes("/admin/user/account-balance");
    const showNavbar = !window?.location?.pathname?.includes("onboarding");
    const showAccountDisable =
      getSession()?.organization?.status === "DISABLED" &&
      !window?.location?.pathname?.includes("/admin/user/account-balance");
    return this.state.redirectToLogin ? (
      <Redirect to="/user/logout" />
    ) : (
      <div id="app">
        {props.notShowHelmet && <Helmet title="Zopping Platform Console" />}
        <div className="top-navbar">
          {showNavbar && !get("redirectedFromApp") && (
            <NavBar
              {...menuProps}
              handleClickMenu={(clickIcon) =>
                this.handleMenuIconClick(clickIcon)
              }
              balance={balance}
              // showVerifyEmailWarning={showVerifyEmailWarning}
              setAccountBalanceRef={this.setAccountBalanceRef}
              setMyAccountRef={this.setMyAccountRef}
            />
          )}
        </div>
        <div
          className={[
            // showVerifyEmailWarning ? "verify-warning" : "",
            "app-main-wrp",
          ].join(" ")}
        >
          {window.innerWidth > 1023 ? (
            <div className="desktop-menu">
              {props.menu ? (
                <Menu {...menuProps} onChange={(e) => this.updateStore(e)} />
              ) : null}
            </div>
          ) : (
            <div
              className={[
                "mobile-menu",
                clickMenuIcon ? "show-mobile-menu" : "",
              ].join(" ")}
              onClick={this.handleMenuClose}
            >
              <div className="recharge-mobile-wrp">
                <div className="recharge-home-wrp dashboard">
                  <Link to="/home/dashboard">
                    <img
                      className="home-icon"
                      src={ZoppingIcon}
                      alt="home-icon"
                    />
                  </Link>
                </div>
              </div>
              {props.menu ? (
                <Menu {...menuProps} onChange={(e) => this.updateStore(e)} />
              ) : null}
            </div>
          )}
          <main className={props.className} id="main-dash-content">
            <ErrorBoundary title={this.title} showHeader={false}>
              {showAccountDisable || showFreeTrailEnd ? (
                <>
                  {showFreeTrailEnd ? (
                    <FreeTrailEnd />
                  ) : (
                    !props.isLoading &&
                    showAccountDisable && <AccountDisabled />
                  )}
                </>
              ) : (
                <>
                  {this.state.error ? (
                    <div>
                      <h1>{this.title}</h1>
                      {httpStatusMessages[this.state.httpStatus] ||
                        getMessage("error.server")}
                    </div>
                  ) : (
                    props.children
                  )}
                </>
              )}
            </ErrorBoundary>
          </main>
        </div>
        {this.showGuidedTour && <Coachmarks coachList={coachList} />}
      </div>
    );
  }
}
