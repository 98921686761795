import React from "react";

const OrderIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g
      id="Dispatch-Web"
      stroke="#B8B8B5"
      strokeWidth="0"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-23">
        <g id="master">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        </g>
        <g
          id="Group-22"
          transform="translate(2.000000, 2.000000)"
          strokeWidth="0.8"
        >
          <line
            x1="2.23977159"
            y1="4.20889809"
            x2="14.8045225"
            y2="4.20889809"
            id="Path-2"
          ></line>
          <line
            x1="2.23977159"
            y1="8.20889809"
            x2="14.8045225"
            y2="8.20889809"
            id="Path-2-Copy"
          ></line>
          <line
            x1="2.23977159"
            y1="12.2088981"
            x2="8.27133407"
            y2="12.1758769"
            id="Path-2-Copy-2"
          ></line>
          <polyline
            id="Path-5"
            points="16.6161614 13.8488529 12.5376769 13.8488529 13.95 18.2630442 18.95 18.2630442 20.0181818 13.8488529 17 13.8488529 17 0 0 0 0 16 13.2 16 12.0002651 12.1923875 10.2027475 12.2088981"
          ></polyline>
          <circle id="Oval" cx="13.95" cy="19.4258769" r="1.15"></circle>
          <circle id="Oval-Copy-2" cx="18.95" cy="19.4258769" r="1.15"></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default OrderIcon;
