import React from "react";

const CatalogueIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 25 25">
    <g fill="none" fillRule="evenodd" stroke="#b8b8b5">
        <path d="M.5 12.5h23v4H.5zM4.866 9.217V2.59h6.169v2.137M16.34 6.968l3.045-5.895 4.496 2.338-4.134 8.658M2.18 16.91l2.307 6.983h14.925l2.126-7.262M9.93 9.644V5.646h6.054v3.909"/>
    </g>
</svg>
);

export default CatalogueIcon;