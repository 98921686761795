import React from "react";

const MarketingIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Coupons-Copy-3">
        <g
          id="Group-49"
          transform="translate(3.000000, 2.000000)"
          stroke="#B8B8B5"
          strokeWidth="0.8"
        >
          <rect
            id="Rectangle-5-Copy"
            x="8.8172043"
            y="2.86475971"
            width="4.30107527"
            height="4.30107527"
          ></rect>
          <polyline
            id="Path-34-Copy"
            points="4.71758446 11.1827957 0 11.1827957 0 0 15.483871 0 15.483871 6.46799027"
          ></polyline>
          <line
            x1="7.08866491"
            y1="5.06869031"
            x2="1.92737459"
            y2="5.06869031"
            id="Path-35-Copy"
          ></line>
          <line
            x1="7.09677419"
            y1="7.31182796"
            x2="1.93548387"
            y2="7.31182796"
            id="Path-35-Copy-2"
          ></line>
          <polyline
            id="Path-37-Copy"
            points="9.53763441 15.8048511 9.53763441 20 12.1182796 20 12.1182796 15.6989247"
          ></polyline>
          <line
            x1="7.09677419"
            y1="2.79569892"
            x2="1.93548387"
            y2="2.79569892"
            id="Path-35-Copy-3"
          ></line>
          <path
            d="M12.9784946,11.1827957 L9.29868578,11.1827957 L9.29868578,11.1827957 C8.0054025,11.1827957 6.95698925,12.1456242 6.95698925,13.3333333 L6.95698925,13.3333333 L6.95698925,13.3333333 C6.95698925,14.5210425 8.0054025,15.483871 9.29868578,15.483871 L12.9784946,15.483871 L12.9784946,11.1827957 Z"
            id="Path-36-Copy"
          ></path>
          <polyline
            id="Path-38-Copy"
            points="12.678982 11.3533209 17.8402723 7.31182796 17.8402723 19.3548387 12.678982 15.7208091"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export default MarketingIcon;
