import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import ZoppingIcon from "../Menu/images/logo_dark.webp";
// import Searchable from "../Form/Inputs/Searchable";
import Notification from "../Menu/Notification";
import LanguageSelector from "../Menu/LanguageSelector";
import UserActions from "../Menu/UserActions";
import { withRouter } from "react-router";
import MenuIcon from "../Menu/hamburger.svg";
import { Link } from "react-router-dom";
import { getSession } from "../../lib/auth";
import { get } from "../../lib/storage";
import ViewStoreIcon from "./ViewStoreIcon";
import { getMessage } from "../../lib/translator";
// import VerifyEmailWarning from "../VerifyEmailWarning";

const NavBar = React.memo((props) => {
  const { handleClickMenu, setAccountBalanceRef, setMyAccountRef } = props;
  // const { showVerifyEmailWarning } = props
  const [showMenu, handleShowMenu] = useState(false);
  const toggleEle = useRef(null);
  const organization = getSession().organization;
  let siteUrl = organization?.domain;
  if (siteUrl) {
    let isHttpsEnabled = JSON.parse(get("organization")).httpsEnabled;
    let protocol = isHttpsEnabled ? "https://" : "http://";
    const isPrefixInSiteUrl = siteUrl?.includes("http");
    siteUrl = isPrefixInSiteUrl ? siteUrl : `${protocol}${siteUrl}`;
  }

  const handleMenuToggle = () => {
    handleShowMenu(!showMenu);
    handleClickMenu(!showMenu);
  };
  const handleClickOutside = (event) => {
    if (toggleEle.current && !toggleEle.current.contains(event.target)) {
      handleShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* {showVerifyEmailWarning && <VerifyEmailWarning />} */}
      <div className="navbar-wrp">
        <div className="icon-wrp">
          <div
            onClick={handleMenuToggle}
            ref={toggleEle}
            className="mobile-menu-icon"
          >
            <img
              src={MenuIcon}
              alt="menu-icon"
              ref={window.screen.width <= 640 ? setAccountBalanceRef : null}
            />
          </div>
          <Link to="/home/dashboard">
            <img className="home-icon" src={ZoppingIcon} alt="home-icon" />
          </Link>
        </div>
        {/* <Searchable navBarSearch /> */}
        <div className="recharge-account-wrp">
          <div className="store-wrp">
            <a
              href={siteUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="store-widget"
            >
              <ViewStoreIcon />
              <h3>{getMessage("edit.website.text")}</h3>
            </a>
          </div>
          <div className="accountInfo">
            <div className="store-mobile-wrp">
              <a
                href={siteUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="store-mobile-widget"
              >
                <ViewStoreIcon />
              </a>
            </div>
            <Notification />
            <LanguageSelector />
            <UserActions
              active={props.location.pathname}
              setMyAccountRef={setMyAccountRef}
            />
          </div>
        </div>
      </div>
    </>
  );
});
const NavBarWithRouter = withRouter(NavBar); // Using Higher-order component to add router properties like location.

export default NavBarWithRouter;
