import React, { Component } from "react";
import "./style.css";
import { ZOPPING_URL } from "../../config/app";
import { getMessage } from "../../lib/translator";
import API from "../../lib/api";
import FreeTrialImg from "./FreeTrialImg";

class FreeTrailEnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentAmount: 0,
      gatewayDetails: null,
      currency: "",
    };
  }
  componentDidMount() {
    let organizationData = JSON.parse(
      window.localStorage.getItem("organization")
    );
    let billingSettings =
      JSON.parse(window.localStorage.getItem("billingSettings")) || {};
    let cost = billingSettings?.pricingRule?.COST;
    if (organizationData.countryCode !== "IN") {
      this.setState({
        paymentAmount: cost?.USD || 69,
        currency: "$",
      });
    } else {
      this.setState({
        paymentAmount: cost?.INR || 4999,
        currency: "₹",
      });
    }
  }
  render() {
    const handleOnClick = (e) => {
      let billingSettings =
        JSON.parse(window.localStorage.getItem("billingSettings")) || {};
      let serviceName = billingSettings?.name;

      e.preventDefault();
      let api = new API({ url: "/billing-service/online-payment" });

      api
        .post({
          amount: Number(this.state.paymentAmount),
          service: serviceName,
        })
        .then(
          ({ data: { onlinepayment } }) => {
            this.setState(
              {
                gatewayDetails: onlinepayment,
              },
              () => {
                this.paymentGatewayForm.submit();
              }
            );
          },
          (error) => {
            throw error;
          }
        );
    };
    return (
      <div className="popup-container">
        <div className="popup">
          <FreeTrialImg />
          <h2 className="title">{getMessage("free.trial.has.ended")}</h2>
          <div className="description">
            <span>{getMessage("pay.one.time.activation.fee")}</span>

            <span>
              <a
                href={ZOPPING_URL + "/pricing"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {getMessage("know.more")}
              </a>
            </span>
          </div>
          <div className="active-now-button" onClick={handleOnClick}>
            {`Pay  ${this.state.currency} ${this.state.paymentAmount}`}
          </div>
        </div>
        {this.state.gatewayDetails ? (
          <form
            className="payment-gateway-redirect-form"
            method="POST"
            action={this.state.gatewayDetails.redirectUrl}
            ref={(node) => {
              this.paymentGatewayForm = node;
            }}
          >
            {Object.keys(this.state.gatewayDetails)
              .filter((key) => key !== "redirectUrl")
              .map((key) => (
                <input
                  key={key}
                  type="hidden"
                  name={key.replace(/([A-Z])/g, "_$1").toLowerCase()}
                  value={this.state.gatewayDetails[key]}
                />
              ))}
          </form>
        ) : null}
      </div>
    );
  }
}

export default FreeTrailEnd;
