import React from "react";

const AnalyticsIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="All-illustartion"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group">
        <rect
          id="Rectangle"
          stroke="#b8b8b5"
          fill="#181912"
          opacity="0"
          x="0.5"
          y="0.5"
          width="23"
          height="23"
        ></rect>
        <g
          id="icon-analytics"
          transform="translate(2.000000, 4.000000)"
          stroke="#b8b8b5"
        >
          <ellipse
            id="Oval"
            cx="5.78461538"
            cy="11.2091987"
            rx="1.15384615"
            ry="1.15893287"
          ></ellipse>
          <ellipse
            id="Oval"
            cx="9.63076923"
            cy="6.57346725"
            rx="1.15384615"
            ry="1.15893287"
          ></ellipse>
          <ellipse
            id="Oval"
            cx="14.2461538"
            cy="9.66395492"
            rx="1.15384615"
            ry="1.15893287"
          ></ellipse>
          <ellipse
            id="Oval"
            cx="18.0923077"
            cy="5.02822342"
            rx="1.15384615"
            ry="1.15893287"
          ></ellipse>
          <path
            d="M1.94615385,0 L1.94615385,17 M0,15.0723083 L20,15.0723083 M10.48,7.15756942 L13.3015385,9.06903604 M6.27692308,10.6621824 L8.96,7.42721447 M15.0884615,8.94000818 L17.59,6.06121892"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default AnalyticsIcon;
