import React, { Component } from "react";
import previousIcon from "./previous.svg";
import nextIcon from "./next.svg";
import debounce from "lodash/debounce";
import { SortableList } from "./SortableImageSlider";
class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images || [],
      currentIndex: 0,
      scrollWidth: 0,
    };
    this.goToImage = this.goToImage.bind(this);
    this.setWidth = this.setWidth.bind(this);
    this.resizeWidth = debounce(this.resizeWidth.bind(this), 350);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
  }
  handleTouchEnd() {
    let { start, end } = this.state;
    if (start && end) {
      // to avoid nextslide call on single touch
      if (start - end < 0) {
        this.goToImage("previous");
      } else {
        this.goToImage("next");
      }
    }
    this.setState({
      start: 0,
      end: 0,
    });
  }

  handleTouchStart(event) {
    this.setState({ start: event.touches[0].clientX });
  }

  handleTouchMove(event) {
    this.setState({ end: event.touches[event.touches.length - 1].clientX });
  }
  goToImage(button) {
    const sliderContainer = this.refs.slider;
    const containerOffsetWidth = sliderContainer.offsetWidth;
    const containerScrollWidth = sliderContainer.scrollWidth;
    if (containerScrollWidth < 254) {
      return;
    }
    if (button === "next") {
      if (
        containerOffsetWidth + Math.abs(this.state.scrollWidth) <=
        containerScrollWidth
      ) {
        this.setState(
          {
            scrollWidth: this.state.scrollWidth - 254,
            currentIndex: this.state.currentIndex + 1,
          },
          () => {
            sliderContainer.style.transform = `translateX(${this.state.scrollWidth}px)`;
          }
        );
      }
    } else {
      if (this.state.scrollWidth < 0) {
        this.setState(
          {
            scrollWidth: this.state.scrollWidth + 254,
            currentIndex: this.state.currentIndex - 1,
          },
          () => {
            sliderContainer.style.transform = `translateX(${this.state.scrollWidth}px)`;
          }
        );
      }
    }
  }

  setWidth() {
    const sliderContainer = this.refs.slider;
    const containerOffsetWidth = sliderContainer.offsetWidth;
    const containerScrollWidth = sliderContainer.scrollWidth;
    this.setState({
      containerOffsetWidth: containerOffsetWidth,
      containerScrollWidth: containerScrollWidth,
    });
  }

  resizeWidth() {
    this.setWidth();
  }

  componentDidMount() {
    this.setWidth();
    window.addEventListener("resize", this.resizeWidth, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWidth, false);
  }

  componentWillReceiveProps({ images }) {
    this.setWidth();
    this.setState({ images: images });
  }

  handleDelete = (arr, index) => {
    if (arr.length - 1 === index || arr.length - 2 === index) {
      this.setState(
        { scrollWidth: this.state.scrollWidth + 254 },
        this.goToImage()
      );
    }
    this.props.removeItem(index);
  };

  render() {
    const {
      containerOffsetWidth,
      containerScrollWidth,
      scrollWidth,
      currentIndex,
    } = this.state;
    return (
      <div className="product-image-container">
        {containerOffsetWidth < containerScrollWidth ||
        this.state.images.length > 2 ? (
          <button
            type="button"
            onClick={() => this.goToImage("previous")}
            disabled={scrollWidth === 0}
          >
            <img src={previousIcon} alt="previous" />
          </button>
        ) : (
          <span />
        )}
        <div className="preview-slider-container">
          <div
            className="preview-area-container"
            ref="slider"
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
          >
            <SortableList
              images={this.state.images}
              onSortEnd={this.props.onSortEnd}
              lockAxis="x"
              axis="x"
              handleDelete={this.handleDelete}
              pressDelay={200}
              helperClass="draggable-item"
              variantIndex={this.props.variantIndex}
            />
          </div>
        </div>
        {containerOffsetWidth < containerScrollWidth ||
        this.state.images.length > 2 ? (
          <button
            type="button"
            onClick={() => this.goToImage("next")}
            disabled={
              (containerOffsetWidth + Math.abs(scrollWidth) >=
                containerScrollWidth &&
                this.state.images.length < 2) ||
              (currentIndex === this.state.images.length - 2 &&
                (containerOffsetWidth === 492 || containerOffsetWidth === 0))
            }
          >
            <img src={nextIcon} alt="next" />
          </button>
        ) : (
          <span />
        )}
      </div>
    );
  }
}

export default ImageSlider;
