import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { getSession, saveSession } from "../../../lib/auth";
import API from "../../../lib/api";
import userImage from "../images/customer-image-placeholder.svg";
import { Upload } from "../../Form";

import "./style.css";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.saveUser = this.saveUser.bind(this);
  }
  saveUser(value, error) {
    if (error) {
      console.error(error);
    }
    const userAPI = new API({ url: "/account-service/me" });
    userAPI
      .put({
        imageUrl: value,
      })
      .then(
        (response) => {
          saveSession({
            user: response.data.user,
          });
          this.setState({
            showModal: false,
          });
          window.location.reload();
        },
        (error) => {
          console.error(error);
        }
      );
  }
  render() {
    const user = getSession().user;
    if (!user) {
      return <Redirect to="/user/logout" />;
    }
    const email =
      user && user.emails && !!user.emails.length && user.emails[0].email;
    const phone =
      user && user.phones && !!user.phones.length && user.phones[0].phone;
    return (
      <div className="submenu-info userinfo">
        <div className="userinfo-image">
          <img
            src={user.imageUrl || userImage}
            width="110"
            height="110"
            alt="Avatar"
          />
          <label
            htmlFor="profile-image-input"
            className="userinfo-image-edit"
          />
          <Upload name="profile-image-input" onChange={this.saveUser} />
        </div>
        <div className="userinfo-name">{user.name}</div>
        {email && <div className="userinfo-email">{email}</div>}
        {phone && <div className="userinfo-email">{phone}</div>}
      </div>
    );
  }
}

export default UserInfo;
