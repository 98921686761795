import React from "react";
import { Link,withRouter } from "react-router-dom";
import "./style.css";
import PopupModal from "../Modal";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
    this.handleOk = this.handleOk.bind(this);
  }
  handleOk() {
    const { onOk } = this.props;
    this.setState({ submitting: true }, onOk);
  }
  componentWillReceiveProps() {
    // Enable okay button for the second time
    if (!this.props.show && this.state.submitting === true) {
      this.setState({ submitting: false });
    }
  }
  handleAddAddress = () => {
    const { history, storeId } = this.props;
    history.push(`/settings/multi-store/stores/edit/${storeId}`, {
      from: "all courier orders",
    });
  };
  handleGetBtn = () => {
    const { showAddAddressButton, custom, closeText, navigateLink, close } =
      this.props;

    if (showAddAddressButton) {
      return (
        <button
          type="button"
          className="primary button"
          onClick={this.handleAddAddress}
        >
          {closeText}
        </button>
      );
    }

    if (custom) {
      return (
        <Link to={navigateLink ?? "#"}>
          <button type="button" className="button">
            {closeText}
          </button>
        </Link>
      );
    }

    return (
      <button type="button" className="button" onClick={close}>
        {closeText}
      </button>
    );
  };
  render() {
    const {
      show,
      title,
      message,
      information,
      close,
      className,
      onOk,
      okText,
      configureText,
      configure,
      custom,
      img,
    } = this.props;
    return (
      <PopupModal show={show} close={close}>
        <div className="dialogPopup">
          <div className={className || "warn"}>
          {custom ? <img src={img} alt="" /> : <div className="image" />}
          <div>{title}</div>
            <div className="description">{message}</div>
            {information && <p>{information}</p>}
            {this.handleGetBtn()}
            {onOk && (
              <button
                type="button"
                className="primary button"
                onClick={this.handleOk}
                disabled={this.state.submitting || false}
              >
                {okText}
              </button>
            )}
            {configureText && (
              <button
                type="button"
                className="primary button"
                onClick={configure}
                disabled={this.state.submitting || false}
              >
                {configureText}
              </button>
            )}
          </div>
        </div>
      </PopupModal>
    );
  }
}

export default withRouter(Dialog);
