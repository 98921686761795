import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../../../lib/api";
import { getStores } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";
import DoubleLineCharts from "../../../analytics/Charts/LineChart/DoubleLineChart";
import Calendar from "./Calendar";
import SelectDateRange from "./SelectDateRange";
import Select from "../../../../components/Form/Inputs/Select";

import "./style.css";

const dateToNum = (d) => {
  // Convert date "2016-06-26" to 20160626
  return Number(d.split("-").join(""));
};

const HeaderTag = ({ selectedTime }) => {
  return <div className="header-tag">{selectedTime}</div>;
};

export const getTimeRange = (id) => {
  const now = moment();
  const todayEndTime = now.endOf("day").format("YYYY-MM-DD");
  switch (id) {
    case "Today":
      const todayStartTime = now.startOf("day").format("YYYY-MM-DD");
      return [todayStartTime, todayEndTime];
    case "prevToday":
      const prevTodayStartTime = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const prevTodayEndTime = moment()
        .subtract(1, "days")
        .endOf("day")
        .format("YYYY-MM-DD");
      return [prevTodayStartTime, prevTodayEndTime];
    case "Yesterday":
      const yesterDayDate = now.subtract(1, "days");
      const yesterDayStartTime = yesterDayDate
        .startOf("day")
        .format("YYYY-MM-DD");
      const yesterDayEndTime = yesterDayDate.endOf("day").format("YYYY-MM-DD");
      return [yesterDayStartTime, yesterDayEndTime];
    case "prevYesterday":
      const prevYesterDayStartTime = moment()
        .subtract(2, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const prevYesterDayEndTime = moment()
        .subtract(2, "days")
        .endOf("day")
        .format("YYYY-MM-DD");
      return [prevYesterDayStartTime, prevYesterDayEndTime];

    case "ThisWeek":
      const weekStartTime = now.startOf("isoWeek").format("YYYY-MM-DD");
      const weekEndTime = todayEndTime;
      return [weekStartTime, weekEndTime];
    case "prevThisWeek":
      const prevWeekEndTime = moment().subtract(1, "week").format("YYYY-MM-DD");
      const prevWeekStartTime = moment(prevWeekEndTime)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      return [prevWeekStartTime, prevWeekEndTime];
    case "ThisMonth":
      const monthStartTime = now.startOf("month").format("YYYY-MM-DD");
      const monthEndTime = todayEndTime;
      return [monthStartTime, monthEndTime];

    case "prevThisMonth":
      const prevMonthEndTime = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      const prevMonthStartTime = moment(prevMonthEndTime)
        .startOf("month")
        .format("YYYY-MM-DD");
      return [prevMonthStartTime, prevMonthEndTime];
    case "ThisYear":
      const yearStartTime = now.startOf("year").format("YYYY-MM-DD");
      const yearEndTime = todayEndTime;
      return [yearStartTime, yearEndTime];
    case "prevThisYear":
      const prevYearEndTime = moment().subtract(1, "year").format("YYYY-MM-DD");
      const prevYearStartTime = moment(prevYearEndTime)
        .startOf("year")
        .format("YYYY-MM-DD");
      return [prevYearStartTime, prevYearEndTime];
    case "Last7Days":
      const daysPast7StartTime = moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      const daysPast7EndTime = todayEndTime;
      return [daysPast7StartTime, daysPast7EndTime];
    case "prevLast7Days":
      const prevDaysPast7EndTime = moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD");
      const prevDaysPast7StartTime = moment(prevDaysPast7EndTime)
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      return [prevDaysPast7StartTime, prevDaysPast7EndTime];
    case "Last30Days":
      const daysPast30StartTime = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD");
      const daysPast30EndTime = todayEndTime;
      return [daysPast30StartTime, daysPast30EndTime];
    case "prevLast30Days":
      const prevDaysPast30EndTime = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      const prevDaysPast30StartTime = moment(prevDaysPast30EndTime)
        .subtract(29, "days")
        .format("YYYY-MM-DD");
      return [prevDaysPast30StartTime, prevDaysPast30EndTime];
    case "Last12Months":
      const monthsPast12StartTime = moment()
        .subtract(12, "months")
        .add(1, "days")
        .format("YYYY-MM-DD");
      const monthsPast12EndTime = todayEndTime;
      return [monthsPast12StartTime, monthsPast12EndTime];
    case "prevLast12Months":
      const prevMonthsPast12EndTime = moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      const prevMonthsPast12StartTime = moment(prevMonthsPast12EndTime)
        .subtract(12, "months")
        .add(1, "days")
        .format("YYYY-MM-DD");
      return [prevMonthsPast12StartTime, prevMonthsPast12EndTime];
    default:
      const defaultPast7StartTime = moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      const defaultPast7EndTime = todayEndTime;
      return [defaultPast7StartTime, defaultPast7EndTime];
  }
};

const DataCharts = (props) => {
  const defualtDateId = "Last7Days";
  const defaultDatePrevId = "prevLast7Days";
  const defaultDateName = "Last 7 days";
  const [start7DayTime, End7DayTime] = getTimeRange(defualtDateId);

  const [currentTime, setCurrentTime] = useState({
    start: start7DayTime,
    end: End7DayTime,
    id: defualtDateId,
    prevID: defaultDatePrevId,
    name: defaultDateName,
  });
  const [selectedStore, setSelectedStore] = useState(
    props.organizationData.defaultStoreId
  );
  const [prevTime, setPrevTime] = useState({
    start: "",
    end: "",
  });
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [isComparator, setIsComparator] = useState(false);
  const [chartsInputData, setChartsInputData] = useState({
    arr: [],
    totalCurrent: {},
    totalPrev: {},
  });
  const [headerActive, setHeaderActive] = useState("count");
  const checkActiveHeader = (header) => {
    const { isMobileView } = props;
    return !isMobileView || headerActive === header;
  };

  const generateSortedDateArray = (data) => {
    return Object.keys(data)
      .filter((item) => item !== "total")
      .sort((a, b) => {
        return dateToNum(a) - dateToNum(b);
      });
  };
  const transformDataForChart = (data, pastData, _isComparator) => {
    const datesArr = generateSortedDateArray(data);
    let finalData = datesArr.map((date) => {
      return {
        date: moment(date).format("DD/MM/YY"),
        count: data?.[date]?.count,
        amount: data?.[date]?.amount,
        prevCount: 0,
        prevAmount: 0,
        prevDate: "",
      };
    });

    if (_isComparator) {
      const pastDatesArr = generateSortedDateArray(pastData);
      finalData = pastDatesArr.map((date, index) => {
        return {
          ...finalData[index],
          prevCount: pastData?.[date]?.count,
          prevAmount: pastData?.[date]?.amount,
          prevDate: moment(date).format("DD/MM/YY"),
        };
      });
    }
    return finalData;
  };

  const handleChange = (e) => {
    setSelectedStore(e);
  };
  const handleGetData = (_currentTime, _prevTime, _isComparator) => {
    const orderCountApiCurrent = new API({
      url: `/order-service/stats`,
    });
    const orderCountApiPast = _isComparator
      ? new API({
          url: `/order-service/stats`,
        })
      : null;

    Promise.all([
      orderCountApiCurrent.get({
        from: `${_currentTime.start}`,
        to: `${_currentTime.end}`,
        storeId: `${selectedStore}`,
      }),
      orderCountApiPast &&
        orderCountApiPast.get({
          from: `${_prevTime.start}`,
          to: `${_prevTime.end}`,
        }),
    ])
      .then(([orderCountCurrentResponse, orderCountPastResponse]) => {
        let orderCountPastData = null;
        if (orderCountPastResponse) {
          orderCountPastData = orderCountPastResponse?.data?.stats?.sales;
          setChartsInputData((prevState) => ({
            ...prevState,
            totalPrev: orderCountPastData?.total || {},
          }));
        }

        const orderCountCurrentData = transformDataForChart(
          orderCountCurrentResponse?.data?.stats?.sales,
          orderCountPastData,
          _isComparator
        );
        setChartsInputData((prevState) => ({
          ...prevState,
          arr: orderCountCurrentData || [],
          totalCurrent:
            orderCountCurrentResponse?.data?.stats?.sales?.total || {},
        }));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };
  useEffect(() => {
    handleGetData(currentTime, prevTime, isComparator);
    // eslint-disable-next-line
  }, [selectedStore]);
  const xAxisTicks = [
    chartsInputData?.arr?.[0]?.date,
    chartsInputData?.arr?.[chartsInputData?.arr?.length - 1]?.date,
  ];
  const selectedTime = currentTime?.name;
  const renderOrderTooltip = (data) => {
    const { active, payload } = data;
    if (active && payload) {
      const {
        payload: { date = "", count, prevCount, prevDate },
      } = payload[0];
      return (
        <div className="line-chart-tooltip">
          <div>
            {date}: {count}
          </div>
          {prevDate && (
            <div>
              {prevDate}: {prevCount}
            </div>
          )}
        </div>
      );
    }
    return null;
  };
  const renderRevenueTooltip = (data) => {
    const { active, payload } = data;
    if (active && payload) {
      const {
        payload: { date = "", amount, prevAmount, prevDate },
      } = payload[0];
      return (
        <div className="line-chart-tooltip">
          <div>
            {date}: {amount}
          </div>
          {prevDate && (
            <div>
              {prevDate}: {prevAmount}
            </div>
          )}
        </div>
      );
    }
    return null;
  };
  const legendCurrentCount = `${currentTime?.name}: ${
    chartsInputData?.totalCurrent?.totalOrders ?? ""
  }`;
  const legendPrevCount = `Previous Period: ${
    chartsInputData?.totalPrev?.totalOrders ?? ""
  }`;
  const legendCurrentAmount = `${currentTime?.name}: ${
    chartsInputData?.totalCurrent?.totalAmount?.toFixed(1) ?? ""
  }`;
  const legendPrevAmount = `Previous Period: ${
    chartsInputData?.totalPrev?.totalAmount?.toFixed(1) ?? ""
  }`;
  const { isMobileView, organizationData } = props;
  const currencySymbol = organizationData?.currency?.symbol || "₹";
  const stores = getStores();
  const storesList = stores?.map((store) => ({
    value: store.id,
    text: store.name,
  }));
  return (
    <div className="home-page-charts">
      <div className="header">
        <div className="left">
          {!isMobileView &&
            getMessage("customer.details.pages.basicinfo.totalorders")}
          {isMobileView && (
            <>
              <div
                className={`head ${checkActiveHeader("count") ? "active" : ""}`}
                onClick={() => setHeaderActive("count")}
              >
                {getMessage("customer.details.pages.basicinfo.totalorders")}
              </div>
              <div
                className={`head ${
                  checkActiveHeader("amount") ? "active" : ""
                }`}
                onClick={() => setHeaderActive("amount")}
              >
                {getMessage("home.charts.heading.total.revenue", {
                  symbol: currencySymbol,
                })}
              </div>
            </>
          )}
        </div>
        <div className="right">
          {!isMobileView && storesList?.length > 1 && (
            <Select
              className="select-store"
              name={`select-store`}
              placeholder={"select store"}
              options={storesList}
              value={selectedStore}
              onChange={handleChange}
            />
          )}
          <Calendar
            className="calendar"
            onClick={() => {
              setShowDateFilter(!showDateFilter);
            }}
          />
          {showDateFilter && (
            <SelectDateRange
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              prevTime={prevTime}
              setPrevTime={setPrevTime}
              handleGetData={handleGetData}
              setShowDateFilter={setShowDateFilter}
              isComparator={isComparator}
              setIsComparator={setIsComparator}
            />
          )}
        </div>
      </div>
      {isMobileView && storesList?.length > 1 && (
        <Select
          className="select-store"
          name={`select-store`}
          placeholder={"select store"}
          options={storesList}
          value={selectedStore}
          onChange={handleChange}
        />
      )}

      {checkActiveHeader("count") && (
        <DoubleLineCharts
          data={chartsInputData?.arr}
          yKey="count"
          yKey2="prevCount"
          heading=""
          labelName="current"
          xaxis={{
            xKey: "date",
            xAxisTicks: { xAxisTicks },
            tickLine: false,
            tickMargin: 6,
            axisLine: { stroke: "#eaefef" },
            tick: { color: "#787872", strokeWidth: 0.3 },
          }}
          yaxis={{
            tickLine: false,
            tickMargin: 6,
            tick: { color: "#787872", strokeWidth: 0.3 },
            axisLine: { stroke: "#eaefef" },
          }}
          cartesianGrid={{
            vertical: false,
            stroke: "#eaefef",
          }}
          legendStyles={{
            verticalAlign: "top",
            align: "left",
            height: 36,
          }}
          legendsName={{
            primary: legendCurrentCount,
            secondary: legendPrevCount,
          }}
          isComparator={isComparator}
          headerTag={<HeaderTag selectedTime={selectedTime} />}
          toolTipContent={renderOrderTooltip}
          isMobileView={isMobileView}
        />
      )}

      {!isMobileView && (
        <div className="header">
          <div className="left">
            {getMessage("home.charts.heading.total.revenue", {
              symbol: currencySymbol,
            })}
          </div>
          <div className="right"></div>
        </div>
      )}

      {checkActiveHeader("amount") && (
        <DoubleLineCharts
          data={chartsInputData?.arr}
          yKey="amount"
          yKey2="prevAmount"
          heading=""
          labelName="current"
          xaxis={{
            xKey: "date",
            xAxisTicks: { xAxisTicks },
            tickLine: false,
            tickMargin: 6,
            axisLine: { stroke: "#eaefef" },
            tick: { color: "#787872", strokeWidth: 0.3 },
          }}
          yaxis={{
            tickLine: false,
            tickMargin: 6,
            tick: { color: "#787872", strokeWidth: 0.3 },
            axisLine: { stroke: "#eaefef" },
          }}
          cartesianGrid={{
            vertical: false,
            stroke: "#eaefef",
          }}
          legendStyles={{
            verticalAlign: "top",
            align: "left",
            height: 36,
          }}
          legendsName={{
            primary: legendCurrentAmount,
            secondary: legendPrevAmount,
          }}
          isComparator={isComparator}
          headerTag={<HeaderTag selectedTime={selectedTime} />}
          toolTipContent={renderRevenueTooltip}
          isMobileView={isMobileView}
        />
      )}

      {isMobileView && (
        <div className="sliding-indicator">
          <div
            className={checkActiveHeader("count") ? `active` : ""}
            onClick={() => setHeaderActive("count")}
          ></div>
          <div
            className={checkActiveHeader("amount") ? "active" : ""}
            onClick={() => setHeaderActive("amount")}
          ></div>
        </div>
      )}
    </div>
  );
};

export default DataCharts;
