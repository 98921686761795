import React, { useEffect, useState } from "react";
import { Toggle } from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import { getTimeRange } from "../index";
import "./style.css";

export const DATE_BUTTONS = {
  row1: {
    col1: {
      name: "Today",
      id: "Today",
      prevID: "prevToday",
    },
    col2: {
      name: "Yesterday",
      id: "Yesterday",
      prevID: "prevYesterday",
    },
  },
  row2: {
    col1: {
      name: "This week",
      id: "ThisWeek",
      prevID: "prevThisWeek",
    },
    col2: {
      name: "This month",
      id: "ThisMonth",
      prevID: "prevThisMonth",
    },
    col3: {
      name: "This year",
      id: "ThisYear",
      prevID: "prevThisYear",
    },
  },
  row3: {
    col1: {
      name: "Last 7 days",
      id: "Last7Days",
      prevID: "prevLast7Days",
    },
    col2: {
      name: "Last 30 days",
      id: "Last30Days",
      prevID: "prevLast30Days",
    },
    col3: {
      name: "Last 12 months",
      id: "Last12Months",
      prevID: "prevLast12Months",
    },
  },
};

const SelectDateRange = (props) => {
  const {
    currentTime,
    setCurrentTime,
    setPrevTime,
    handleGetData,
    setShowDateFilter,
    isComparator,
    setIsComparator,
    prevTime,
  } = props;
  const [tempCurrentTime, setTempCurrentTime] = useState({ ...currentTime });
  const [tempPrevTime, setTempPrevTime] = useState({ ...prevTime });
  const [tempIsComparator, setTempIsComparator] = useState(isComparator);

  const handleToggle = () => {
    setTempIsComparator(!tempIsComparator);
  };

  useEffect(() => {
    if (tempIsComparator) {
      const [prevStartTime, prevEndTime] = getTimeRange(tempCurrentTime.prevID);
      setTempPrevTime({
        start: prevStartTime,
        end: prevEndTime,
      });
    }
  }, [tempIsComparator, tempCurrentTime]);

  return (
    <div className='home-select-date'>
      <div className='heading'>{getMessage("home.select.date.heading")}</div>
      <div className='btn-container'>
        {Object.entries(DATE_BUTTONS).map(([rowKey, row]) => {
          return (
            <div className='row' key={rowKey}>
              {Object.entries(row).map(([colKey, item]) => {
                const { name, id } = item;
                const isActive = id === tempCurrentTime?.id ? "active" : "";
                return (
                  <div
                    className={`col ${isActive}`}
                    onClick={() => {
                      const [currentStartTime, currentEndTime] =
                        getTimeRange(id);
                      setTempCurrentTime({
                        start: currentStartTime,
                        end: currentEndTime,
                        ...item,
                      });
                    }}
                    key={colKey}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className='sub-heading'>
        <div className='left'>{getMessage("home.select.date.subheading")}</div>
        <div className='right'>
          <Toggle
            name='dateRangeComparator'
            className='date-range-toggle'
            value={tempIsComparator}
            icons={false}
            onChange={() => handleToggle()}
          />
        </div>
      </div>
      <div
        className='apply-btn'
        onClick={() => {
          setCurrentTime({ ...tempCurrentTime });
          setPrevTime({ ...tempPrevTime });
          setIsComparator(tempIsComparator);
          handleGetData(tempCurrentTime, tempPrevTime, tempIsComparator);
          setShowDateFilter(false);
        }}
      >
        {getMessage("leaves.apply")}
      </div>
    </div>
  );
};

export default SelectDateRange;
