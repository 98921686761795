import React from "react";
import { Link } from "react-router-dom";
import { getMessage } from "../../lib/translator";
import AccountDisabledImg from "./AccountDisabledImg";
import "./style.css";

const AccountDisabled = () => {
  return (
    <div className="account-disabled-wrp">
      <div className="container">
        <div className="img">
          <AccountDisabledImg />
        </div>
        <div className="title">
          {getMessage("account.disable.notification.dialog.title")}
        </div>
        <p className="information">
          {getMessage("account.disable.notification.dialog.information")}
        </p>
        <Link to={"/user/account-balance"}>
          <button type="button" className="button">
            {getMessage("account.disable.notification.button.text")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AccountDisabled;
