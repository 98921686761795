import React from "react";

const ContactSupport = (props) => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g
      id="Admin-Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Zopping-Admin-Home-Final---hanging-button-Click-view"
        transform="translate(-1301.000000, -926.000000)"
      >
        <g id="Group-44" transform="translate(1301.000000, 926.000000)">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
          <path
            d="M8.34193152,3.68002435 C9.13372624,3.67688672 9.92670314,3.97743416 10.5312683,4.58236508 L10.5312683,4.58236508 L13.4263305,7.47910244 C14.027729,8.08089596 14.3284013,8.86959208 14.3284013,9.65826658 C14.3284013,10.44413 14.0298679,11.2300009 13.4327417,11.8309401 L13.4327417,11.8309401 L11.0204217,14.1130636 C12.1158561,16.2714713 14.6418276,20.0112278 17.3202598,22.6920297 C20.0043738,25.3777673 23.7618077,27.9046679 25.3271372,28.6975719 L25.3271372,28.6975719 L28.234627,26.7421594 C28.8411199,26.1107362 29.6484375,25.7949857 30.4557668,25.7949742 C31.2437377,25.7949629 32.0316861,26.0957304 32.6328556,26.6971701 L32.6328556,26.6971701 L35.4179302,29.4839294 C36.0229082,30.0892422 36.3233012,30.8832841 36.3199726,31.6760273 C36.3166441,32.4687533 36.0096059,33.2602213 35.3995704,33.8604041 L35.3995704,33.8604041 L34.6428166,34.6049159 C34.0600969,35.0583205 33.3765305,35.4483172 32.6621107,35.7276668 C31.9779438,35.9924701 31.258115,36.2182916 30.5615771,36.3005303 C27.8859446,36.3007551 21.0313868,35.4894027 12.775829,27.2313648 C5.77387351,20.2222991 3.09866309,15.0675763 3.7852892,9.38756561 C3.85921969,8.71573549 4.03145021,8.0414212 4.29567157,7.3436038 C4.57853672,6.6207409 4.96270475,5.9418274 5.4158657,5.35584902 L5.4158657,5.35584902 L6.15976563,4.599691 C6.75953695,3.98999185 7.55012631,3.68316202 8.34193152,3.68002435 Z"
            id="Path"
            stroke="#fff"
            strokeWidth="1.36"
            fill="#4AB819"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ContactSupport;
