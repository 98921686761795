import React from "react";

const AccountDisabledImg = () => {
  return (
    <svg
      width="232px"
      height="203px"
      viewBox="0 0 232 203"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Admin-home-new"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Zopping-admin---ACCOUNT-DISABLED"
          transform="translate(-691.000000, -306.000000)"
        >
          <g id="Group-17" transform="translate(602.000000, 291.000000)">
            <g id="Group-108-Copy" transform="translate(90.000000, 16.000000)">
              <g id="Group-106" transform="translate(0.000000, 9.064459)">
                <path
                  d="M165.183022,64.2891726 L216.471044,36.7852674 C215.68235,34.8166374 215.561615,33.0470427 216.108838,31.4764833 C216.656061,29.9059238 217.540483,28.6354058 218.762103,27.6649292 L210.015408,11.4165389"
                  id="Path-365"
                  stroke="#00BE00"
                  strokeWidth="0.97542144"
                  strokeLinecap="round"
                  fillRule="nonzero"
                ></path>
                <line
                  x1="201.574092"
                  y1="8.68006103"
                  x2="164.106659"
                  y2="27.4132386"
                  id="Path-366"
                  stroke="#00BE00"
                  strokeWidth="0.97542144"
                  strokeLinecap="round"
                  fillRule="nonzero"
                ></line>
                <path
                  d="M164.106659,23.5740506 L207.591117,0.658627594 C208.280619,0.295273791 209.134128,0.559669614 209.497482,1.24917219 C209.497721,1.24962569 209.49796,1.25007931 209.498198,1.25053306 L227.437157,35.3817479 L227.437157,35.3817479"
                  id="Path-363"
                  stroke="#005A75"
                  strokeWidth="0.97542144"
                  strokeLinecap="round"
                  fillRule="nonzero"
                ></path>
                <g id="Group-103" transform="translate(0.000000, 6.208384)">
                  <g
                    id="Group-102"
                    transform="translate(189.567636, 81.330144) rotate(17.000000) translate(-189.567636, -81.330144) translate(157.902387, 46.632748)"
                  >
                    <polyline
                      id="Path-363-Copy"
                      stroke="#005A75"
                      strokeWidth="0.97542144"
                      strokeLinecap="round"
                      fillRule="nonzero"
                      points="9.10976385e-13 23.5740506 44.7342743 -2.27605016e-13 63.3304984 35.3817479"
                    ></polyline>
                    <polygon
                      id="Path-369"
                      fill="#FAFAFA"
                      transform="translate(6.721932, 20.726634) rotate(-20.000000) translate(-6.721932, -20.726634) "
                      points="11.7614716 17.7154326 1.68239146 23.737835 1.68527199 19.8882917"
                    ></polygon>
                    <line
                      x1="1.07636341"
                      y1="69.3947922"
                      x2="58.5964141"
                      y2="38.3962322"
                      id="Path-364-Copy"
                      stroke="#005A75"
                      strokeWidth="0.97542144"
                      strokeLinecap="round"
                      fillRule="nonzero"
                    ></line>
                    <path
                      d="M1.07636341,64.2891726 L52.3643851,36.7852674 C51.5756915,34.8166374 51.4549563,33.0470427 52.0021796,31.4764833 C52.5494029,29.9059238 53.4338245,28.6354058 54.6554445,27.6649292 L45.9087498,11.4165389"
                      id="Path-365-Copy"
                      stroke="#00BE00"
                      strokeWidth="0.97542144"
                      strokeLinecap="round"
                      fillRule="nonzero"
                    ></path>
                    <line
                      x1="37.4674332"
                      y1="8.68006103"
                      x2="9.10976385e-13"
                      y2="27.4132386"
                      id="Path-366-Copy"
                      stroke="#005A75"
                      strokeWidth="0.97542144"
                      fillRule="nonzero"
                    ></line>
                    <ellipse
                      id="Oval-Copy-108"
                      stroke="#00BE00"
                      strokeWidth="0.97542144"
                      strokeLinecap="round"
                      fillRule="nonzero"
                      cx="18.9407144"
                      cy="36.4358148"
                      rx="14.103435"
                      ry="14.1363387"
                    ></ellipse>
                  </g>
                  <rect
                    id="Rectangle"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    fill="#FAFAFA"
                    fillRule="nonzero"
                    x="75.8612668"
                    y="0.0699080746"
                    width="89.3217551"
                    height="150.787613"
                    rx="9.408"
                  ></rect>
                  <path
                    d="M124.753175,0.0699080746 C137.600576,7.44529491 144.024277,16.6714518 144.024277,27.7483789 C144.024277,44.3637695 149.165083,40.6978624 149.165083,58.0807885 C149.165083,75.4637147 144.085963,90.7106234 151.683679,101.201499 C156.748822,108.195417 159.363779,124.347651 159.528549,149.658203 C163.099295,148.173931 164.884668,146.150403 164.884668,143.587621 C164.884668,141.024839 164.98412,138.618573 165.183022,136.368825 L165.183022,76.5207565 L165.183022,32.1878481 L165.183022,9.10963881 C163.787914,4.28853113 161.903089,1.52509323 159.528549,0.819325114 C157.154009,0.113556999 153.69952,-0.136248681 149.165083,0.0699080746 L124.753175,0.0699080746 Z"
                    id="Path-371"
                    fill="#EAFCF1"
                  ></path>
                  <path
                    d="M75.8612668,51.5454773 C80.4955109,62.1908729 82.8126329,71.4302568 82.8126329,79.263629 C82.8126329,91.0136873 84.4008365,117.221614 91.597784,127.014759 C96.3957489,133.543522 100.882215,141.49111 105.057182,150.857521 L82.8126329,150.857521 C79.2700672,149.23574 77.2986989,147.527717 76.898528,145.733452 C76.4983571,143.939187 76.1526034,142.45271 75.8612668,141.274019 L75.8612668,117.521745 L75.8612668,51.5454773 Z"
                    id="Path-370"
                    fill="#E6F6FF"
                  ></path>
                  <path
                    d="M101.050762,5.20815479 L86.6701489,5.20815479 C83.2928172,5.20815479 80.5549489,7.94602309 80.5549489,11.3233548 L80.5549489,137.472421 C80.5549489,140.849753 83.2928172,143.587621 86.6701489,143.587621 L132.512666,143.587621 L132.512666,143.587621"
                    id="Path-356"
                    stroke="#00BE00"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M147.59009,143.587621 L153.413349,143.587621 C156.790681,143.587621 159.528549,140.849753 159.528549,137.472421 L159.528549,11.3233548 C159.528549,7.94602309 156.790681,5.20815479 153.413349,5.20815479 L139.988624,5.20815479 L139.988624,5.20815479"
                    id="Path-357"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M103.752351,15.0078999 L134.501682,15.0078999 C137.532036,15.0078999 139.988624,12.5513121 139.988624,9.52095755 L139.988624,9.52095755 L139.988624,9.52095755"
                    id="Path-358"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                  ></path>
                  <line
                    x1="103.752351"
                    y1="9.52095755"
                    x2="124.753175"
                    y2="9.52095755"
                    id="Path-359"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                  ></line>
                  <line
                    x1="132.512666"
                    y1="9.52095755"
                    x2="136.632873"
                    y2="9.52095755"
                    id="Path-360"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                  ></line>
                  <path
                    d="M22.4820386,76.1082288 L20.2810331,72.8554516 C19.1898966,71.2429049 19.612583,69.0511366 21.2251297,67.9600001 C21.9837305,67.4466896 22.9123309,67.2487789 23.8143043,67.4081746 L24.3170022,67.4970108 C28.9294522,68.3121177 33.5545515,66.1298998 35.8582054,62.0516315 L35.8603181,62.0478912 C37.0575502,59.9283742 39.7463065,59.1807146 41.8658234,60.3779466 C42.5652827,60.7730438 43.1434629,61.3517876 43.5378783,62.0516315 L43.622663,62.2020719 C45.862808,66.1769465 50.3941038,68.2736337 54.8739317,67.4081746 C56.6831722,67.058647 58.4331995,68.241978 58.7827271,70.0512185 C58.9422205,70.8767953 58.7841193,71.7321514 58.3400524,72.4461682 L55.912656,76.3491873 C54.188296,79.1217917 53.2744057,82.3216931 53.2744057,85.5867738 C53.2744057,88.8820055 53.9511591,92.1421276 55.2627247,95.1650976 L65.6957091,119.211625 C70.2405655,129.686864 65.4330399,141.863058 54.9578001,146.407915 C52.4167956,147.510372 49.6801747,148.091017 46.9104234,148.115374 L31.2745709,148.252877 C20.1810623,148.350434 11.108911,139.436454 11.0113538,128.342946 C10.985999,125.459779 11.5816216,122.604941 12.7577433,119.972446 L23.3075149,96.3590533 C24.7631344,93.1009623 25.5154866,89.5725825 25.5154866,86.0041115 C25.5154866,82.4765364 24.4589401,79.0298117 22.4820386,76.1082288 Z"
                    id="Path-361"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M34.5314361,64.4673703 C31.2265965,67.608468 30.9023747,71.2739162 33.5587709,75.4637147 C37.543365,81.7484125 34.5314361,89.5731979 34.5314361,94.5475909 C34.5314361,96.5932002 41.884451,116.23388 41.1743691,129.448642 C40.880319,134.920972 37.7329026,141.280727 31.7321198,148.527908 L24.0603872,147.116603 L17.3108179,143.587621 L13.029778,136.807904 L11.6403643,131.760351 L10.8450541,126.14219 L12.6032426,120.753496 L17.3108179,109.211462 L22.5239023,98.1975426 L24.9594108,91.3588474 L24.3748061,79.6394761 L21.5204383,74.5001019 L19.7425645,72.261339 L20.4698268,68.6602693 L23.0151841,67.1915113 L25.6887388,67.7175554 L31.183974,66.3514728 L34.5314361,64.4673703 Z"
                    id="Path-372"
                    fill="#EAFCF1"
                  ></path>
                  <polygon
                    id="Path-350"
                    fill="#005A75"
                    points="24.4609448 80.1758276 54.3674096 80.1758276 55.2228403 77.9016212 58.5256483 72.4398908 58.8732338 69.7083723 56.703525 67.3654311 52.2805638 67.5427358 47.3965768 66.3177471 43.3555686 61.8365115 40.8161077 59.8044134 37.7580626 60.1586494 36.1080557 61.8365115 31.294573 66.3177471 27.8154576 67.5427358 23.2178654 67.3654311 20.4698268 68.6602693 19.8774303 70.790864 20.6445028 73.3668209 23.2178654 76.8091445"
                  ></polygon>
                  <path
                    d="M22.4820386,76.1082288 L20.2810331,72.8554516 C19.1898966,71.2429049 19.612583,69.0511366 21.2251297,67.9600001 C21.9837305,67.4466896 22.9123309,67.2487789 23.8143043,67.4081746 L24.3170022,67.4970108 C28.9294522,68.3121177 33.5545515,66.1298998 35.8582054,62.0516315 L35.8603181,62.0478912 C37.0575502,59.9283742 39.7463065,59.1807146 41.8658234,60.3779466 C42.5652827,60.7730438 43.1434629,61.3517876 43.5378783,62.0516315 L43.622663,62.2020719 C45.862808,66.1769465 50.3941038,68.2736337 54.8739317,67.4081746 C56.6831722,67.058647 58.4331995,68.241978 58.7827271,70.0512185 C58.9422205,70.8767953 58.7841193,71.7321514 58.3400524,72.4461682 L55.912656,76.3491873 C54.188296,79.1217917 53.2744057,82.3216931 53.2744057,85.5867738 C53.2744057,88.8820055 53.9511591,92.1421276 55.2627247,95.1650976 L65.6957091,119.211625 C70.2405655,129.686864 65.4330399,141.863058 54.9578001,146.407915 C52.4167956,147.510372 49.6801747,148.091017 46.9104234,148.115374 L31.2745709,148.252877 C20.1810623,148.350434 11.108911,139.436454 11.0113538,128.342946 C10.985999,125.459779 11.5816216,122.604941 12.7577433,119.972446 L23.3075149,96.3590533 C24.7631344,93.1009623 25.5154866,89.5725825 25.5154866,86.0041115 C25.5154866,82.4765364 24.4589401,79.0298117 22.4820386,76.1082288 Z"
                    id="Path-361"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    fillRule="nonzero"
                  ></path>
                  <rect
                    id="Rectangle"
                    stroke="#00BE00"
                    strokeWidth="0.97542144"
                    fill="#FAFAFA"
                    fillRule="nonzero"
                    strokeLinecap="round"
                    x="19.4475267"
                    y="80.1758276"
                    width="39.9597326"
                    height="10.8378597"
                    rx="5.41892985"
                  ></rect>
                  <polygon
                    id="Path-362"
                    fill="#005A75"
                    points="123.894827 98.235586 110.86883 122.484734 117.381828 122.484734 117.381828 136.807904 130.24997 114.414369 123.894827 114.414369"
                  ></polygon>
                  <g
                    id="Group-104"
                    transform="translate(67.869320, 162.637804)"
                    fillRule="nonzero"
                    strokeWidth="0.97542144"
                  >
                    <g
                      id="Group-105"
                      transform="translate(71.927519, 10.837860)"
                    >
                      <line
                        x1="6.47945707"
                        y1="5.6545355"
                        x2="6.47945707"
                        y2="11.2273465"
                        id="Path-368"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="13.5311746"
                        y1="6.12574679"
                        x2="13.5311746"
                        y2="11.6985578"
                        id="Path-368-Copy"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="19.1725486"
                        y1="6.12574679"
                        x2="19.1725486"
                        y2="11.6985578"
                        id="Path-368-Copy-2"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="24.8139226"
                        y1="6.12574679"
                        x2="24.8139226"
                        y2="11.6985578"
                        id="Path-368-Copy-3"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="30.9254111"
                        y1="4.71211291"
                        x2="30.9254111"
                        y2="10.284924"
                        id="Path-368-Copy-4"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <path
                        d="M33.8482441,3.29847904 L33.8482441,8.95301453 C33.8482441,10.7747142 26.2710565,12.2514936 16.924122,12.2514936 C7.57718753,12.2514936 0,10.7747142 0,8.95301453 C0,8.78395529 0.0652577883,8.61786663 0.191131727,8.45565321 L0.191784921,3.29847904 L33.8482441,3.29847904 Z"
                        id="Combined-Shape"
                        stroke="#005A75"
                      ></path>
                      <ellipse
                        id="Oval"
                        stroke="#005A75"
                        fill="#FAFAFA"
                        cx="16.924122"
                        cy="3.29847904"
                        rx="16.924122"
                        ry="3.29847904"
                      ></ellipse>
                    </g>
                    <g id="Group-105-Copy">
                      <line
                        x1="6.47945707"
                        y1="5.6545355"
                        x2="6.47945707"
                        y2="11.2273465"
                        id="Path-368"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="13.5311746"
                        y1="6.12574679"
                        x2="13.5311746"
                        y2="11.6985578"
                        id="Path-368-Copy"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="19.1725486"
                        y1="6.12574679"
                        x2="19.1725486"
                        y2="11.6985578"
                        id="Path-368-Copy-2"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="24.8139226"
                        y1="6.12574679"
                        x2="24.8139226"
                        y2="11.6985578"
                        id="Path-368-Copy-3"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <line
                        x1="30.9254111"
                        y1="4.71211291"
                        x2="30.9254111"
                        y2="10.284924"
                        id="Path-368-Copy-4"
                        stroke="#00BE00"
                        strokeLinecap="round"
                      ></line>
                      <path
                        d="M33.8482441,3.29847904 L33.8482441,8.95301453 C33.8482441,10.7747142 26.2710565,12.2514936 16.924122,12.2514936 C7.57718753,12.2514936 0,10.7747142 0,8.95301453 C0,8.78395529 0.0652577883,8.61786663 0.191131727,8.45565321 L0.191784921,3.29847904 L33.8482441,3.29847904 Z"
                        id="Combined-Shape"
                        stroke="#005A75"
                      ></path>
                      <ellipse
                        id="Oval"
                        stroke="#005A75"
                        fill="#FAFAFA"
                        cx="16.924122"
                        cy="3.29847904"
                        rx="16.924122"
                        ry="3.29847904"
                      ></ellipse>
                    </g>
                  </g>
                  <line
                    x1="165.183022"
                    y1="63.1864081"
                    x2="222.703073"
                    y2="32.1878481"
                    id="Path-364"
                    stroke="#005A75"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                  ></line>
                  <ellipse
                    id="Oval"
                    stroke="#00BE00"
                    strokeWidth="0.97542144"
                    strokeLinecap="round"
                    fillRule="nonzero"
                    cx="183.047373"
                    cy="30.2274307"
                    rx="14.103435"
                    ry="14.1363387"
                  ></ellipse>
                </g>
                <g
                  id="₹"
                  transform="translate(103.127908, 37.849449)"
                  fill="#E6F6FF"
                >
                  <path
                    d="M1.81248964,12.7900208 L0.151040803,17.7555582 L19.182182,17.7555582 C18.7290596,19.6364436 17.671774,21.141152 16.0858456,22.2696832 C13.7447131,23.9248624 10.1952542,24.7524519 5.43746892,24.7524519 L0.755204017,24.7524519 L0.755204017,30.6208144 C2.41665286,30.6208144 4.07810169,31.1474623 5.58850973,32.2007582 C6.94787696,33.1035832 8.23172379,34.3073498 9.44005022,35.962529 L22.9582021,53.7180872 L34.965946,53.7180872 L18.6535392,33.254054 C16.9920904,31.1474623 15.8592844,29.7932248 15.2551211,29.3418123 C19.2577024,28.4389873 22.4295593,26.7838082 24.8462122,24.3762749 C26.507661,22.5706249 27.640467,20.3135624 28.0935894,17.7555582 L35.9477112,17.7555582 L37.6091601,12.7900208 L28.2446302,12.7900208 C27.9425486,9.7053687 26.9607834,7.07212913 25.374855,4.96553747 L35.8721908,4.96553747 L37.5336397,0 L1.66144884,0 L0,4.96553747 L8.08068298,4.96553747 C11.7811827,4.96553747 14.5754375,5.71789164 16.538968,7.37307079 C18.049376,8.65207287 18.9556208,10.4577229 19.3332228,12.7900208 L1.81248964,12.7900208 Z"
                    id="Path"
                  ></path>
                </g>
                <g
                  id="₹-copy"
                  transform="translate(181.166915, 35.493392) rotate(-25.000000) translate(-181.166915, -35.493392) translate(175.995655, 27.954012)"
                  fill="#EAFCF1"
                >
                  <path
                    d="M0.498434651,3.59018127 L0.0415362209,4.98401635 L5.27510006,4.98401635 C5.1504914,5.51198418 4.85973785,5.93435845 4.42360753,6.25113915 C3.77979611,6.71575084 2.80369491,6.94805669 1.49530395,6.94805669 L0.207681105,6.94805669 L0.207681105,8.59531633 C0.664579535,8.59531633 1.12147797,8.74314732 1.53684018,9.03880931 C1.91066616,9.29223387 2.26372404,9.63013328 2.59601381,10.094745 L6.31350558,15.0787613 L9.61563515,15.0787613 L5.12972329,9.33447129 C4.67282486,8.74314732 4.3613032,8.36301048 4.19515832,8.2362982 C5.29586817,7.98287364 6.16812881,7.51826195 6.83270835,6.84246312 C7.28960678,6.335614 7.60112843,5.7020526 7.7257371,4.98401635 L9.88562059,4.98401635 L10.342519,3.59018127 L7.76727332,3.59018127 C7.68420088,2.72431402 7.41421544,1.98515905 6.97808512,1.39383508 L9.86485248,1.39383508 L10.3217509,0 L0.45689843,0 L0,1.39383508 L2.22218782,1.39383508 C3.23982523,1.39383508 4.00824532,1.60502221 4.54821619,2.06963391 C4.9635784,2.42865203 5.21279573,2.93550115 5.31663628,3.59018127 L0.498434651,3.59018127 Z"
                    id="Path"
                  ></path>
                </g>
                <g
                  id="₹-copy-2"
                  transform="translate(176.465770, 84.970578) rotate(-9.000000) translate(-176.465770, -84.970578) translate(171.294510, 77.431197)"
                  fill="#EAFCF1"
                >
                  <path
                    d="M0.498434651,3.59018127 L0.041536221,4.98401635 L5.27510006,4.98401635 C5.1504914,5.51198418 4.85973785,5.93435845 4.42360753,6.25113915 C3.77979611,6.71575084 2.80369491,6.94805669 1.49530395,6.94805669 L0.207681105,6.94805669 L0.207681105,8.59531633 C0.664579535,8.59531633 1.12147797,8.74314732 1.53684018,9.03880931 C1.91066616,9.29223387 2.26372404,9.63013328 2.59601381,10.094745 L6.31350558,15.0787613 L9.61563515,15.0787613 L5.12972329,9.33447129 C4.67282486,8.74314732 4.3613032,8.36301048 4.19515832,8.2362982 C5.29586817,7.98287364 6.16812881,7.51826195 6.83270835,6.84246312 C7.28960678,6.335614 7.60112843,5.7020526 7.7257371,4.98401635 L9.88562059,4.98401635 L10.342519,3.59018127 L7.76727332,3.59018127 C7.68420088,2.72431402 7.41421544,1.98515905 6.97808512,1.39383508 L9.86485248,1.39383508 L10.3217509,0 L0.45689843,0 L9.10976385e-13,1.39383508 L2.22218782,1.39383508 C3.23982523,1.39383508 4.00824532,1.60502221 4.54821619,2.06963391 C4.9635784,2.42865203 5.21279573,2.93550115 5.31663628,3.59018127 L0.498434651,3.59018127 Z"
                    id="Path"
                  ></path>
                </g>
                <rect
                  id="Rectangle"
                  stroke="#005A75"
                  strokeWidth="0.97542144"
                  fillRule="nonzero"
                  x="75.8612668"
                  y="6.27829219"
                  width="89.3217551"
                  height="150.787613"
                  rx="9.408"
                ></rect>
              </g>
              <g
                id="Group-107"
                fillRule="nonzero"
                strokeLinecap="round"
                strokeWidth="0.9216"
              >
                <ellipse
                  id="Oval"
                  stroke="#005A75"
                  cx="63.5529567"
                  cy="20.4197906"
                  rx="1.72938185"
                  ry="1.73450243"
                ></ellipse>
                <ellipse
                  id="Oval-Copy-57"
                  stroke="#005A75"
                  cx="207.104285"
                  cy="61.7893558"
                  rx="1.72938185"
                  ry="1.73450243"
                ></ellipse>
                <ellipse
                  id="Oval-Copy-5"
                  stroke="#00BE00"
                  cx="178.528461"
                  cy="134.763116"
                  rx="1.72938185"
                  ry="1.73450243"
                ></ellipse>
                <ellipse
                  id="Oval-Copy-2"
                  stroke="#00BE00"
                  cx="57.4557481"
                  cy="64.5180616"
                  rx="1.15292124"
                  ry="1.15633495"
                ></ellipse>
                <path
                  d="M0,112.488372 C1.22614618,112.488372 0.603324403,110.654602 1.7205454,110.654602 C2.8377664,110.654602 2.70616137,109.837965 2.70616137,109.414291"
                  id="Path-18"
                  stroke="#00BE00"
                ></path>
                <path
                  d="M125.642631,180.22161 C126.87166,180.220142 126.249564,178.391416 127.369411,178.390078 C128.489258,178.38874 128.358319,177.574174 128.358825,177.151492"
                  id="Path-18-Copy"
                  stroke="#00BE00"
                  transform="translate(127.002962, 178.686551) rotate(-63.000000) translate(-127.002962, -178.686551) "
                ></path>
                <path
                  d="M125.328115,3.53983494 C126.556013,3.54056211 125.931218,1.70903791 127.050035,1.70970068 C128.168851,1.71036345 128.036576,0.894812385 128.036325,0.471742369"
                  id="Path-18-Copy-2"
                  stroke="#00BE00"
                  transform="translate(126.684698, 2.005789) rotate(-330.000000) translate(-126.684698, -2.005789) "
                ></path>
                <path
                  d="M210.306661,130.053738 C211.534559,130.054465 210.909763,128.222941 212.02858,128.223604 C213.147397,128.224266 213.015121,127.408715 213.014871,126.985645"
                  id="Path-18-Copy-3"
                  stroke="#00BE00"
                  transform="translate(211.663244, 128.519692) rotate(-330.000000) translate(-211.663244, -128.519692) "
                ></path>
                <ellipse
                  id="Oval-Copy-4"
                  stroke="#005A75"
                  cx="168.900336"
                  cy="4.68767039"
                  rx="1.15292124"
                  ry="1.15633495"
                ></ellipse>
                <ellipse
                  id="Oval-Copy-58"
                  stroke="#005A75"
                  cx="58.4762368"
                  cy="174.014263"
                  rx="1.15292124"
                  ry="1.15633495"
                ></ellipse>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AccountDisabledImg;
