import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../containers/PrivateRoute";
import Support from "./supportComponent";
import IndividualSupportComponent from "./IndividualSupportComponent";
export function SupportRoutes(props) {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/support/support"
        component={Support}
        {...props}
      />
      <PrivateRoute
        path="/support/ticket/:id"
        component={IndividualSupportComponent}
        exact
        {...props}
      />
    </Switch>
  );
}
