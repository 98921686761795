import React from "react";

const ArrowRight = (props) => (
  <svg
    width='6px'
    height='13px'
    viewBox='0 0 6 13'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <defs></defs>
    <g
      id='campaign-and-pages'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='Arrow-left' transform='scale(-1, 1))' stroke='#80959D'>
        <polyline id='Shape' points='0 0 6 5.377 0.286 11'></polyline>
      </g>
    </g>
  </svg>
);

export default ArrowRight;
