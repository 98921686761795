import React from "react";
import { BaseForm, Input } from "../../../../../components/Form";
import Loader from "../../../../../components/Loader";

import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";

import "./style.css";

class CompleteOrderWithPickupPending extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      pickupPending: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const api = new API({
      url: `/order-service/order/${this.props.referenceNumber}`,
    });
    api.get().then((response) => {
      let order = response.data.order;
      if (order.pickupItems) {
        order.pickupItems.forEach((item) => {
          item.quantity = item.orderDetails.disputeQuantity;
        });
        this.setState({
          pickupPending: true,
          values: {
            pickupItems: order.pickupItems,
          },
        });
      }
      this.setState({
        showLoader: false,
      });
    });
  }

  onSubmit(formData) {
    const data = Object.assign({}, formData);
    let params = {};
    params.status = "COMPLETED";
    let obj = {};
    data.pickupItems.forEach((item) => {
      obj[item.orderDetails.orderItemId] = item.quantity;
    });
    params.pickupPendingItems = obj;
    this.props.onPickupPendingComplete(params);
  }

  render() {
    const { productReturned, redirectUrl } = this.props;
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const pickupItems =
      this.state.pickupPending && this.state.values.pickupItems;
    return this.state.showLoader ? (
      <Loader />
    ) : this.state.pickupPending ? (
      <Form>
        <div className="pickup-pending-form">
          {pickupItems.map((item, index) => {
            let entityType = item.entityType.toLowerCase();
            let name = item[entityType].name;
            let step = item.product.isSoldByWeight ? "0.01" : "1";
            let orderDetails = item.orderDetails;
            return (
              <div
                key={`pickup-item-container-${index}`}
                className="pickup-pending"
              >
                <p>{name}</p>
                <Input
                  type="number"
                  key={`pickup-item-${index}`}
                  name={`pickup-item-${index}`}
                  step={step}
                  max={orderDetails.disputeQuantity}
                  required
                  min={0}
                  siblings={{
                    after: [
                      <div key={`dispute-item-${index}`} className="return-qty">
                        {" "}
                        /{Number(orderDetails.disputeQuantity).toFixed(2)}
                      </div>,
                    ],
                  }}
                  {...this.generateStateMappers({
                    stateKeys: ["pickupItems", index, "quantity"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            );
          })}
          <div className="form-actions">
            <SubmitButton>
              {getMessage("product.form.delete.confirmText")}
            </SubmitButton>
            <button type="button" className="button" onClick={this.props.close}>
              {getMessage("cancel.text")}
            </button>
          </div>
        </div>
      </Form>
    ) : (
      <div className="return-complete">
        {!productReturned && (
          <div className="text-center">{getMessage("are.you.sure")}</div>
        )}
        <div className="text-center text-muted">
          {productReturned
            ? getMessage("order.details.packed.item.return.message")
            : getMessage("order.details.completion.message")}
        </div>
        <div className="form-actions">
          {productReturned ? (
            <a href={redirectUrl} className="button primary">
              {getMessage("order.details.completion.confirmReturnItem")}
            </a>
          ) : (
            <button
              type="button"
              className="button primary"
              onClick={this.props.onComplete}
            >
              {getMessage("product.form.delete.confirmText")}
            </button>
          )}
          <button type="button" className="button" onClick={this.props.close}>
            {getMessage("cancel.text")}
          </button>
        </div>
      </div>
    );
  }
}
export default CompleteOrderWithPickupPending;
