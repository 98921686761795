import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import coverTag from "./cover-tag.svg";
import { getMessage } from "../../../../lib/translator";

const SortableItem = SortableElement((props) => {
  const { index, data, arr, handleDelete, image_index } = props;
  return (
    <div key={index} className="image-preview-container">
      {data.uploading ? (
        <div className="loading-icon">
          {getMessage("product.form.imageUpload.uploading")}
        </div>
      ) : null}
      {data.error ? (
        <div className="loading-icon">
          {getMessage("product.form.imageUpload.error")}
        </div>
      ) : null}
      <div
        className="delete-icon"
        onClick={() => {
          handleDelete(arr, image_index);
        }}
      >
        &times;
      </div>
      {image_index === 0 ? (
        <div className="cover-tag">
          <img src={coverTag} alt="cover-tag" />
        </div>
      ) : (
        ""
      )}
      <img src={data.url || data.blob} alt="" />
    </div>
  );
});

export const SortableList = SortableContainer((props) => {
  const { images, variantIndex } = props;
  return (
    <div>
      {images &&
        images.map((data, index, arr) => {
          return (
            <SortableItem
              data={data}
              index={index}
              image_index={index}
              arr={arr}
              {...props}
              collection={variantIndex}
            />
          );
        })}
    </div>
  );
});
