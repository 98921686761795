import React from "react";

const AnalyticsIcon = (props) => (
  <svg
    width="6px"
    height="13px"
    viewBox="0 0 6 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Arrow-left</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="campaign-and-pages"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Arrow-right"
        transform="translate(3.000000, 6.500000) scale(-1, 1) translate(-3.000000, -6.500000) translate(0.000000, 1.000000)"
        stroke="#80959D"
      >
        <polyline id="Shape" points="0 0 6 5.377 0.286 11"></polyline>
      </g>
    </g>
  </svg>
);

export default AnalyticsIcon;
