import React from "react";

const SettingsIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 26">
    <g fill="none" fillRule="nonzero" stroke="#b8b8b5">
        <path d="M13 8.167c-2.651 0-4.833 2.182-4.833 4.833 0 2.651 2.182 4.833 4.833 4.833 2.651 0 4.833-2.182 4.833-4.833 0-2.651-2.182-4.833-4.833-4.833z"/>
        <path d="M23.9 10.8l-1.4-.6c-.333-.133-.6-.4-.767-.733-.133-.434-.133-.834 0-1.167l.567-1.4c.3-.467.2-1.067-.167-1.433L20.567 3.9a1.16 1.16 0 0 0-1.4-.167l-1.434.567c-.766.3-1.6-.067-1.9-.8l-.6-1.367c-.1-.533-.566-.9-1.1-.9H11.9c-.533 0-1 .367-1.1.867l-.6 1.4a1.517 1.517 0 0 1-1.9.8l-1.4-.567c-.433-.3-1.033-.233-1.433.167L3.9 5.467a1.16 1.16 0 0 0-.167 1.4L4.3 8.3c.3.767-.067 1.6-.8 1.9l-1.367.6c-.533.1-.9.567-.9 1.1v2.233c0 .534.367 1 .867 1.1l1.4.6a1.517 1.517 0 0 1 .8 1.9l-.567 1.4c-.3.467-.233 1.034.167 1.434l1.567 1.566a1.16 1.16 0 0 0 1.4.167l1.433-.567c.767-.3 1.6.067 1.9.8l.6 1.367c.1.533.567.9 1.1.9h2.233c.534 0 1-.367 1.1-.867l.6-1.4a1.517 1.517 0 0 1 1.9-.8l1.4.567c.434.3 1.034.233 1.434-.167l1.566-1.566a1.16 1.16 0 0 0 .167-1.4l-.567-1.434c-.3-.766.067-1.6.8-1.9l1.367-.6c.533-.1.9-.566.9-1.1V11.9c0-.533-.367-1-.9-1.1z"/>
    </g>
</svg>
);

export default SettingsIcon;