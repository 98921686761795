import React, { Component } from "react";
import ListingPage from "../../containers/ListingPage";
import API from "../../lib/api";
import { getMessage } from "../../lib/translator";
import emptyIcon from "../operations/Orders/Table/index";
import { Row, Cell } from "../../components/Table";
import { Link } from "react-router-dom";
import { getPrintableTime } from "../../lib/datetime";
import { hasPermissions } from "../../lib/auth";
import TicketFiltersForm from "./Filters";
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
} from "../../containers/CustomerWidgets";
import "./style.css";
const emptyState = (props) => {
  return {
    icon: emptyIcon,
    message: getMessage("order.emptyList.message"),
  };
};

const API_HEADER = {
  realmID: 1,
};

const getMobileView = () => {
  return window.screen.width <= 840;
};

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ticketData: {},
      customerCommunicationConfig: {},
      isMobileView: getMobileView(),
    };
  }

  mobileView = () => {
    if (
      window.innerWidth <= 840 ||
      getMobileView() ||
      window.screen.width <= 840
    ) {
      return this.setState({ isMobileView: true });
    }
    return this.setState({ isMobileView: false });
  };
  componentDidMount() {
    this.mobileView();
    window.addEventListener("resize", this.mobileView, false);
    this.comminucationApi = new API({
      url: "/account-service/config/communication",
    });
    this.comminucationApi
      .get()
      .then((response) => {
        this.setState({
          customerCommunicationConfig: response?.data?.config?.communication,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  tableProperties = (isMobileView) => {
    return {
      headers: [
        "TicketID",
        "Customer",
        "Buyer App",
        "Order ID/Date",
        "Short Description",
        "Ticket Status",
      ],
      row: ({
        id,
        rating,
        complainant,
        review,
        customer,
        createdAt,
        status,
        title,
        metaData,
        description,
      }) => {
        const buyerApp = metaData?.BuyerApp;
        const orderId = "ORD#" + metaData?.OrderDetails?.id;
        let allowCall =
          this.state.customerCommunicationConfig &&
          this.state.customerCommunicationConfig.allowCall !== false &&
          hasPermissions("communication", "call", "post");
        let allowEmail =
          this.state.customerCommunicationConfig &&
          this.state.customerCommunicationConfig.allowEmail !== false &&
          hasPermissions("communication", "email", "post");

        return (
          <Row>
            <Cell className="ticketId">
              <div className="ticket-wrapper">
                <div className="order-mobile-header">
                  <Link to={`/support/ticket/${id}`}>{id}</Link>
                </div>
                <div>{getPrintableTime(createdAt).split(", ")[0]}</div>
              </div>
            </Cell>
            {isMobileView && (
              <Cell className="mobile-status-display-wrapper">
                <div className="order-status-wrapper">
                  {orderId && (
                    <div className="order-wrapper">
                      <div className="mobile-order">Order ID</div>
                      <div className="order-id-display">
                        {orderId ?? <h3>{orderId}</h3>}
                      </div>
                    </div>
                  )}
                  {status && (
                    <div className="mobile-status-wrapper">
                      <div className="mobile-status-title">Ticket Status</div>
                      <div className="mobile-status-display">
                        {status ?? <h4>{status}</h4>}
                      </div>
                    </div>
                  )}
                </div>
              </Cell>
            )}
            <Cell className="customerDetails">
              {complainant &&
                (isMobileView ? (
                  <div className="cutomer-wrapper">
                    <div className="customerName">
                      <Link
                        className="customer-name"
                        to={`/customers/customers/view/${complainant?.name}`}
                      >
                        {complainant?.name ?? complainant?.name}
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="cutomer-wrapper">
                    <div className="customerName">
                      <Link
                        className="customer-name"
                        to={`/customers/customers/view/${complainant?.name}`}
                      >
                        {complainant?.name ?? complainant?.name}
                      </Link>
                    </div>
                    <div className="customer-actions">
                      {/* {address && (
                      <CustomerMapWidget
                        address={
                          type.name === "DELIVERY"
                            ? address.address +
                              ", " +
                              address.city +
                              ", " +
                              address.pincode
                            : pickupLocation
                            ? pickupLocation.address
                            : ""
                        }
                      />
                    )} */}
                      {allowEmail && complainant && complainant.email && (
                        <CustomerEmailWidget
                          emails={complainant.email}
                          showOptions={false}
                        />
                      )}
                      {allowCall && complainant && complainant.phone && (
                        <CustomerCallWidget
                          phones={complainant.phone}
                          showOptions={false}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </Cell>
            <Cell className="buyerApp">
              {buyerApp && (
                <div className="buyerAppDisplay">
                  {buyerApp ?? <h3>{buyerApp}</h3>}
                </div>
              )}
            </Cell>
            <Cell className="orderId">
              {orderId &&
                (!isMobileView ? (
                  <div className="order-wrapper">
                    <div className="orderIdDisplay">
                      <Link
                        className="order-number"
                        to={`/orders/orders/${orderId.split("#")[1]}`}
                      >
                        {orderId}
                      </Link>
                    </div>
                    <div>
                      {metaData?.OrderDetails?.createdAt
                        ? getPrintableTime(
                            metaData?.OrderDetails?.createdAt
                          ).split(", ")[0]
                        : null}
                    </div>
                  </div>
                ) : (
                  <div className="order-wrapper">
                    <div>
                      {metaData?.OrderDetails?.createdAt
                        ? getPrintableTime(
                            metaData?.OrderDetails?.createdAt
                          ).split(", ")[0]
                        : null}
                    </div>
                  </div>
                ))}
            </Cell>
            <Cell className="ticketDescription">
              {title &&
                (!isMobileView ? (
                  <>
                    <div className="ticketDescriptionDisplay">
                      {title ?? <h3>{title}</h3>}
                    </div>
                    <div className="description">
                      {description ?? <p>{description}</p>}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="border-ticket-description" />
                    <div className="ticketDescriptionDisplay">
                      {title ?? <h3>{title}</h3>}
                    </div>
                    <div className="description">
                      {description ?? <p>{description}</p>}
                    </div>
                  </>
                ))}
            </Cell>
            <Cell className="status">
              {status && (
                <div className="statusDisplay">
                  {status ?? <h3>{status}</h3>}
                </div>
              )}
            </Cell>
          </Row>
        );
      },
    };
  };

  componentWillUnmount() {
    this.storeApi && this.storeApi.cancel();
    window.removeEventListener("resize", this.mobileView, false);
  }
  render() {
    const { props } = this;
    return (
      <ListingPage
        helmetTitle="Zopping-Support-Tickets"
        menu={props?.menu}
        className="ticket-support-page"
        title={getMessage("tickets.heading")}
        api={{
          url: "/ticket-service/ticket",
          headers: API_HEADER,
          transform: (response) => {
            return response.data.ticket;
          },
        }}
        emptyState={props.emptyState ? props.emptyState() : emptyState(props)}
        filters={{
          component: TicketFiltersForm,
          transformFilter: () => {},
          transformSubmit: (formData) => {
            return formData;
          },
        }}
        tableProperties={this.tableProperties(this.state?.isMobileView)}
        tableDynamic
        storeDependent={this.props.match.url === "/support"}
      />
    );
  }
}

export default Support;
