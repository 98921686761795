import React, { useState } from "react";
import { CoachMark } from "react-coach-mark";
import "./coachmarks.css";

export const CoachCard = (props) => {
  const {
    handleButtonClick,
    currentIndex,
    activeIndex,
    coachListLength,
    title,
    description,
    buttonText,
  } = props;

  const indicators = Array(coachListLength)
    .fill(0)
    .reduce((acc, cv) => {
      acc.push((acc[acc.length - 1] || cv) + 1);
      return acc;
    }, []);

  return (
    <div className="coach-card-wrp">
      <div
        className="close-button"
        onClick={() => handleButtonClick(coachListLength)}
      >
        &times;
      </div>
      <div className="coach-card-title">{title}</div>
      <div className="coach-card-desc">{description}</div>
      <div className="coach-card-indicator-btn-wrp">
        <div className="coach-card-indicators-wrp">
          {indicators.map((i) => (
            <div
              className={[
                "coach-card-indicator",
                activeIndex === i - 1 ? "coach-card-selected-indicator" : "",
              ].join(" ")}
              key={i}
              onClick={() => handleButtonClick(i - 1)}
            />
          ))}
        </div>
        <button
          className="coach-card-btn"
          onClick={() => handleButtonClick(currentIndex + 1)}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

const Coachmarks = (props) => {
  const { coachList } = props;
  const [active, setActive] = useState(0);

  const handleClick = (activeIndex) => {
    setActive(activeIndex);
  };

  const ICoachList = coachList.reduce((acc, cv, index) => {
    const { title, description, reference, buttonText, toolTipPosition, id } =
      cv;
    acc.push({
      activate: active === index,
      component: (
        <CoachCard
          handleButtonClick={handleClick}
          currentIndex={index}
          activeIndex={active}
          coachListLength={coachList.length}
          title={title}
          description={description}
          buttonText={buttonText}
          key={id}
        />
      ),
      reference,
      tooltip: { position: toolTipPosition },
    });
    return acc;
  }, []);

  const coach = ICoachList[active];

  return <CoachMark {...coach} />;
};

export default Coachmarks;
