import React from "react";
import { withRouter } from "react-router-dom";
import Menu from "../Menu";
import "../Menu/menu.css";
import Navbar from "../../components/NavBar";
import { isLoggedIn } from "../../lib/auth";
import Loader from "../Loader";
import { get } from "../../lib/storage";

const FallbackLoader = (props) => {
  const { location } = props;
  return (
    <div id="app">
      {!!isLoggedIn() &&
        location.pathname !== "/onboarding" &&
        !get("redirectedFromApp") && <Navbar balance={null} />}
      <div className="app-main-wrp">
        <div className="desktop-menu">
          {props.menu && <Menu items={props.menu} />}
        </div>
        <Loader />
      </div>
    </div>
  );
};

export default withRouter(FallbackLoader);
