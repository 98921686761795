import React, { Component } from "react";
import { SingleDatePicker, Select } from "../../Inputs";
import { getMessage } from "../../../../lib/translator";
import { getTimes } from "../../../../lib/datetime";
import "./style.css";

function getValidationObj(props, value) {
  let valueMissing = props.required && !value;
  if (props.required && props.multiple) {
    valueMissing = valueMissing || !value || !value.length;
  }
  let result = {
    valueMissing,
    valid: !valueMissing,
  };
  return result;
}

class DateTime extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.runValidation = this.runValidation.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    const value = this.props.value || "";
    this.state = {
      date: value.split(" ")[0],
      time: value.split(" ")[1],
    };
  }

  handleDateChange(e) {
    this.setState({
      date: e,
    });
    const time = this.state.time;
    if (time) {
      this.props.onChange(`${e} ${time}:00`);
    } else {
      this.props.onChange(`${e}`);
    }
  }

  runValidation(date) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, date));
  }

  handleTimeChange(e) {
    this.setState({
      time: e,
    });
    const date = this.state.date;
    if (date) {
      this.props.onChange(`${date} ${e}`);
    } else {
      this.props.onChange(` ${e}`);
    }
  }

  render() {
    const {
      name,
      dateplaceholder,
      timePlaceholder,
      required,
      value,
      ...props
    } = this.props;
    return (
      <div className="Date-time">
        <SingleDatePicker
          placeholder={
            dateplaceholder || getMessage("trips.filter.byDates.heading")
          }
          required={required}
          {...props}
          name={`date-time-${name}-date`}
          value={value && value.split(" ")[0]}
          onChange={this.handleDateChange}
        />
        <Select
          name={`date-time-${name}-time`}
          required={required}
          options={getTimes()}
          placeholder={
            timePlaceholder || getMessage("campaign.form.time.heading")
          }
          value={value && value.split(" ")[1]}
          onChange={this.handleTimeChange}
        />
      </div>
    );
  }
}

export default DateTime;
