import React, { Component } from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  Polygon,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { GOOGLE_MAPS_URL, GOOGLE_MAP_DEFAULT_KEY } from "../../config/app.js";
import { get } from "../../lib/storage/index.js";

class BaseMap extends Component {
  render() {
    const { props } = this;
    const defaultOptions = props.defaultOptions || null;
    return (
      <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={props.zoom || 10}
        onClick={props.onMapClick || null}
        center={props.center || (props.getCenter && props.getCenter())}
        onBoundsChanged={props.onBoundsChanged}
        defaultOptions={defaultOptions}
      >
        {props.children}
      </GoogleMap>
    );
  }
}

const Map = withScriptjs(withGoogleMap(BaseMap));

const GoogleMaps = (props) => {
  let googleMapURL =
    GOOGLE_MAPS_URL + (get("googleMapAPIKey") || GOOGLE_MAP_DEFAULT_KEY);
  return (
    <Map
      googleMapURL={googleMapURL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div className={props.containerClassName} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    >
      {props.children}
    </Map>
  );
};
export default GoogleMaps;
export {
  Marker,
  SearchBox,
  Polygon,
  InfoWindow,
  MarkerWithLabel,
  DrawingManager,
  Polyline,
};
