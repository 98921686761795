import React from "react";
import { DropDown, DropDownItem } from "../../DropDown";
import { Link, Redirect, withRouter } from "react-router-dom";
import { getMessage } from "../../../lib/translator";
import { getSession } from "../../../lib/auth";
import { set } from "../../../lib/storage";
import ProfileImageIcon from "./ProfileImageIcon";

import "./style.css";

const MENU_KEY = "zopsmart-menu-collapse";

function UserActions(props) {
  const { setMyAccountRef } = props;
  const className = props.active.split("/")[1] === "user" ? "active" : "";
  const user = getSession().user;
  const profileImage = user?.imageUrl ? "profile-img" : "";
  if (!user) {
    return <Redirect to="/user/logout" />;
  }
  let styles = {};
  if (user.imageUrl) {
    styles = {
      backgroundImage: `url(${user.imageUrl})`,
      backgroundSize: "cover",
      borderRadius: "50%",
    };
  }

  const handleAccountClick = (e) => {
    e.stopPropagation();
    set(MENU_KEY, "collapse-in");
    props.history.push("/user/personal-information");
  };

  const handleChangePasswordClick = (e) => {
    e.stopPropagation();
    set(MENU_KEY, "collapse-in");
    props.history.push("/user/change-password");
  };

  return (
    <div
      style={styles}
      className={`UserActions icon-profile-image menu-toggle-container ${className} ${profileImage} `}
      ref={setMyAccountRef}
    >
      {!user.imageUrl && <ProfileImageIcon />}
      <DropDown>
        <DropDownItem>
          <span onClick={handleAccountClick}>
            {getMessage("menu.item.my-account")}
          </span>
        </DropDownItem>
        <DropDownItem>
          <span onClick={handleChangePasswordClick}>
            {getMessage("menu.item.change-password")}
          </span>
        </DropDownItem>
        <DropDownItem>
          <Link to="/user/logout">{getMessage("menu.item.logout")}</Link>
        </DropDownItem>
      </DropDown>
    </div>
  );
}

export default withRouter(UserActions);
