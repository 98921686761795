import React, { Component } from "react";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import unorderedListIcon from "./unordered-list-icon.svg";
import orderedListIcon from "./ordered-list-icon.svg";
import "draft-js/dist/Draft.css";
import "./style.css";

const defaultMethods = [
  "BOLD",
  "ITALIC",
  "UNDERLINE",
  "unordered-list-item",
  "ordered-list-item",
  "LINK",
  "STRIKETHROUGH",
];
export default class RichEditor extends Component {
  constructor(props) {
    super(props);
    this.interceptProps(props);
    this.onChange = props.onChange || (() => {});
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    // Toolbar click handlers
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.toggleBlockType = this.toggleBlockType.bind(this);
  }

  toggleBlockType(style) {
    this.onChange(RichUtils.toggleBlockType(this.props.value, style));
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.props.onChange(newState);
      return "handled";
    }
    return "not-handled";
  }
  toggleInlineStyle(style, e) {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, style));
  }
  interceptProps(props) {
    if (!(props.value instanceof EditorState)) {
      props.onChange(importText(props.value));
    }
  }
  componentWillReceiveProps(newProps) {
    this.interceptProps(newProps);
  }

  render() {
    let { props } = this;
    if (!(props.value instanceof EditorState)) {
      return null;
    }

    const allowedMethods = props.allowedMethods || defaultMethods;

    let className = "RichEditor-editor";
    var contentState = this.props.value.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }

    return (
      <div className="RichEditor">
        <div className="editor-actions">
          {allowedMethods.includes("BOLD") && (
            <button
              tabIndex="-1"
              type="button"
              className="editor-action"
              onMouseDown={(e) => {
                this.toggleInlineStyle("BOLD", e);
              }}
            >
              <strong>B</strong>
            </button>
          )}

          {allowedMethods.includes("ITALIC") && (
            <button
              tabIndex="-1"
              type="button"
              className="editor-action"
              onMouseDown={(e) => {
                this.toggleInlineStyle("ITALIC", e);
              }}
            >
              <em>I</em>
            </button>
          )}

          {allowedMethods.includes("UNDERLINE") && (
            <button
              tabIndex="-1"
              type="button"
              className="editor-action"
              onMouseDown={(e) => {
                this.toggleInlineStyle("UNDERLINE", e);
              }}
            >
              <u>U</u>
            </button>
          )}

          {allowedMethods.includes("unordered-list-item") && (
            <button
              tabIndex="-1"
              type="button"
              className="editor-action-unordered-list icon"
              onMouseDown={(e) => {
                this.toggleBlockType("unordered-list-item");
              }}
            >
              <img
                src={unorderedListIcon}
                alt="unordered list icon"
                height={22}
              />
            </button>
          )}

          {allowedMethods.includes("ordered-list-item") && (
            <button
              tabIndex="-1"
              type="button"
              className="editor-action-ordered-list icon"
              onMouseDown={(e) => {
                this.toggleBlockType("ordered-list-item");
              }}
            >
              {
                <img
                  src={orderedListIcon}
                  alt="Ordered list icon"
                  height={27}
                />
              }
            </button>
          )}

          {allowedMethods.includes("STRIKETHROUGH") && (
            <button
              tabIndex="-1"
              type="button"
              className="editor-action strike"
              onMouseDown={(e) => {
                this.toggleInlineStyle("STRIKETHROUGH", e);
              }}
            >
              <strike>Z</strike>
            </button>
          )}
        </div>

        <div className={className} onClick={this.focus}>
          <Editor
            placeholder={props.placeholder}
            editorState={props.value}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            customStyleMap={{
              STRIKETHROUGH: {
                textDecoration: "line-through",
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export function importHTML(value) {
  return stateFromHTML(value);
}

export function exportHTML(editorState) {
  return stateToHTML(editorState.getCurrentContent());
}

export function importText(value, formatting) {
  if (!value) {
    return EditorState.createEmpty();
  } else if (typeof value === "string") {
    return EditorState.createWithContent(importHTML(value));
  }
  return value;
}

export function exportText(editorState) {
  const content = editorState?.getCurrentContent();
  if (!content.hasText()) {
    return "";
  } else {
    // assuming editorState instanceof EditorState
    return stateToHTML(content);
  }
}
