import React from "react";

function Review(props) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="product-detail-page"
        stroke="none"
        strokeWidth="0"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-17">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
          <g
            id="Group-16"
            transform="translate(2.000000, 3.000000)"
            stroke="#B8B8B5"
            strokeWidth="0.8"
          >
            <path
              d="M16.8258065,0.4 C17.1019488,0.4 17.3519488,0.511928813 17.5329132,0.692893219 C17.7138776,0.873857625 17.8258065,1.12385763 17.8258065,1.4 L17.8258065,14.2393227 C17.8258065,14.5154651 17.7138776,14.7654651 17.5329132,14.9464295 C17.3519488,15.1273939 17.1019488,15.2393227 16.8258065,15.2393227 L7.65157353,15.2393228 C7.17510794,15.2395403 6.71838842,15.4285938 6.38781371,15.7644919 C5.39960234,16.743561 4.72962649,17.3835594 4.36309497,17.5540286 C3.7965207,16.76633 3.70292518,16.2857775 3.54630429,15.557719 L1.42747079,15.2389542 C1.14046237,15.2466477 0.879840053,15.1333764 0.692893219,14.9464295 C0.511928813,14.7654651 0.4,14.5154651 0.4,14.2393227 L0.4,1.4 C0.4,1.12385763 0.511928813,0.873857625 0.692893219,0.692893219 C0.873857625,0.511928813 1.12385763,0.4 1.4,0.4 Z"
              id="Rectangle"
            ></path>
            <path
              d="M18.1364557,3.37827083 L19.0764516,3.37827083 C19.5865133,3.37827083 20,4.04601307 20,4.86971486 L20,16.2336605 C20,17.0573623 19.5865133,17.7251046 19.0764516,17.7251046 L11.929784,17.7251046 C11.3923784,17.7251046 10.8549727,17.7251046 10.3175671,17.7251046 C9.90859958,17.7251046 9.49963206,17.7251046 9.09066454,17.7251046 L7.72999998,17.7251046 C7.2199383,17.7251046 6.80645161,17.0573623 6.80645161,16.2336605 L6.80645161,15.592956"
              id="Path"
            ></path>
            <path
              d="M8.66809908,3.37068628 L10.3979077,5.7033354 L13.2432905,6.23174452 L11.5242432,8.52018028 L11.8822689,11.3154081 L9.06790716,10.427464 L6.41908377,11.6347944 L6.43482487,8.78583563 L4.46477512,6.74398197 L7.25265611,5.86014041 L8.66809908,3.37068628 Z"
              id="Star"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Review;
