import React, { Component } from "react";
import { getSession } from "../../../lib/auth";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import { ZOPPING_URL } from "../../../config/app";

class FreeTrailAnnouncementBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentAmount: 0,
      currency: "",
      gatewayDetails: null,
    };
  }
  componentDidMount() {
    const organization = getSession()?.organization;
    let billingSettings =
      JSON.parse(window.localStorage.getItem("billingSettings")) || {};
    let cost = billingSettings?.pricingRule?.COST;
    if (organization.countryCode !== "IN") {
      this.setState({
        paymentAmount: cost?.USD || 69,
        currency: "$",
      });
    } else {
      this.setState({
        paymentAmount: cost?.INR || 4999,
        currency: "₹",
      });
    }
  }
  render() {
    let { trailDay } = this.props;
    let billingSettings =
      JSON.parse(window.localStorage.getItem("billingSettings")) || {};
    let serviceName = billingSettings?.name;

    const handleOnClick = (e) => {
      e.preventDefault();
      let api = new API({ url: "/billing-service/online-payment" });

      api
        .post({
          amount: Number(this.state.paymentAmount),
          service: serviceName,
        })
        .then(
          ({ data: { onlinepayment } }) => {
            this.setState(
              {
                gatewayDetails: onlinepayment,
              },
              () => {
                this.paymentGatewayForm.submit();
              }
            );
          },
          (error) => {
            throw error;
          }
        );
    };
    return (
      <>
        <div className="trial-announcement-bar">
          <div className="trail-content">
            <div className="trail-days">
              <span>{trailDay}</span>
              <span>{getMessage("days.left.for.free.trial")}</span>
            </div>
            <div className="activation-fee">
              <span>{getMessage("pay.one.time.activation.fee")}</span>

              <span>
                <a
                  href={ZOPPING_URL + "/pricing"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getMessage("know.more")}
                </a>
              </span>
            </div>
          </div>
          <div className="trail-button">
            <div className="initial-fee" onClick={handleOnClick}>
              {`Pay  ${this.state.currency} ${this.state.paymentAmount}`}
            </div>
          </div>
        </div>

        {this.state.gatewayDetails ? (
          <form
            className="payment-gateway-redirect-form"
            method="POST"
            action={this.state.gatewayDetails.redirectUrl}
            ref={(node) => {
              this.paymentGatewayForm = node;
            }}
          >
            {Object.keys(this.state.gatewayDetails)
              .filter((key) => key !== "redirectUrl")
              .map((key) => (
                <input
                  key={key}
                  type="hidden"
                  name={key.replace(/([A-Z])/g, "_$1").toLowerCase()}
                  value={this.state.gatewayDetails[key]}
                />
              ))}
          </form>
        ) : null}
      </>
    );
  }
}

export default FreeTrailAnnouncementBar;
