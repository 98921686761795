import React from "react";
import {
  BaseForm,
  Select,
  Input,
  SingleDatePicker,
  VALIDATION_TYPES,
} from "../../../components/Form";
import { getMessage } from "../../../lib/translator";
const STATUS_OPTIONS = [
  {
    text: "OPEN",
    value: "OPEN",
  },
  {
    text: "PENDING",
    value: "PENDING",
  },
  {
    text: "RESOLVED",
    value: "RESOLVED",
  },
  {
    text: "CLOSED",
    value: "CLOSED",
  },
];

export default class TicketFiltersForm extends BaseForm {
  handleOnChange = (date, stateKey) => {
    this.setState({
      values: {
        ...this.state.values,
        [stateKey]: date,
      },
    });
  };
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("ticket.filter.order.id")}
            placeholder={getMessage("ticket.filter.order.id.placeholder")}
            name="orderId"
            className="order-id-filter"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["orderID"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            label={getMessage("ticket.filter.ticket.id")}
            placeholder={getMessage("ticket.filter.ticket.id.placeholder")}
            name="ticketId"
            className="ticket-id-filter"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["ticketID"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <SingleDatePicker
            label={getMessage("coupon.form.startDate.placeholder")}
            placeholder={getMessage("trips.filter.byDates.heading")}
            name="start-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["startDate"],
              loseEmphasisOnFill: true,
            })}
            value={this?.state?.values?.startDate}
            onChange={(e) => {
              this.handleOnChange(e, "startDate");
            }}
          />
          <SingleDatePicker
            label={getMessage("offer.delivery-date.end")}
            placeholder={getMessage("trips.filter.byDates.heading")}
            name="end-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["endDate"],
              loseEmphasisOnFill: true,
            })}
            value={this?.state?.values?.endDate}
            onChange={(e) => {
              this.handleOnChange(e, "endDate");
            }}
          />
          <Select
            name="status"
            label={getMessage("ticket.filter.ticket.status")}
            options={STATUS_OPTIONS}
            placeholder={getMessage("ticket.filter.ticket.status.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              validationType: "ONBLUR",
            })}
          />
        </div>
        <SubmitButton>{getMessage("search.label.text")}</SubmitButton>
        <ClearButton>
          {getMessage("ticket.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
