import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute, { submenuRedirect } from "../../containers/PrivateRoute";
import Dashboard from "./Dashboard";
import { getSession } from "../../lib/auth";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.org = getSession().organization;
  }
  render() {
    const { props } = this;
    return (
      <Switch>
        {!this.org.industry && (
          <Redirect from="/home/dashboard" to="/onboarding" />
        )}
        <PrivateRoute path="/home/dashboard" component={Dashboard} {...props} />
        <Redirect
          from="/"
          to={submenuRedirect("home", "dashboard", props.menu)}
          {...props}
        />
      </Switch>
    );
  }
}

export default Home;
