import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AuthenticatedPage from "../../../containers/AuthenticatedPage/index";
// import StatsTable from "./statsTable";
// import TopProductsTable from "./topProductsTable";
// import CategoriesStats from "./categoriesStats";
// import SlotOrderTable from "./slotOrdertable";
import Loader from "../../../components/Loader";
import { faro } from "@grafana/faro-web-sdk";
import StoreSelector, {
  makestoreDependentComponent,
} from "../../../containers/StoreSelector";
import getDefaultStore from "../../../containers/StoreSelector/getDefaultStore";
import API from "../../../lib/api";
// import { getCurrency } from "../../../lib/userDetails";
import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled, getSession } from "../../../lib/auth";
import { get, set } from "../../../lib/storage";
import "./style.css";
import { sortOrderStatus } from "../../../lib/commonlyused";
import NewUniqueOnlineStore from "./NewUniqueOnlineStore.svg";
import { Link } from "react-router-dom";
import { SingleDatePicker } from "../../../components/Form";
import SlideShow from "./SlideShow";
import EditWebsite from "./Edit-website.svg";
import { ZOPPING_URL } from "../../../config/app.js";
import ContactSupportIcon from "./ContactSupportIcon";
import ZoppingLogo from "./zoppinglogo.png";
import CloseIcon from "./closeicon.png";
import ProductAlert from "./ProductAlert";
import DataCharts from "./DataCharts";
import { getStandradDateTime, localTimeToUtc } from "../../../lib/datetime";
import FreeTrailAnnouncementBar from "./FreeTrialAnnouncementBar";
import CountTrialDays from "../../../utils/countTrailDays";
// import { getStores } from "../../../lib/auth";
// import Select from "../../../components/Form/Inputs/Select";

const BlogSetUpOnlineStore =
  "https://storage.googleapis.com/zopping-staging-uploads/originals%2F20230324%2Fblogsetuponlinestore-20230324-084601.png";
const BlogCustomDomain =
  "https://storage.googleapis.com/zopping-staging-uploads/originals%2F20230324%2FBlogCustomDomain-20230324-072543.png";
const BlogAddProducts =
  "https://storage.googleapis.com/zopping-staging-uploads/originals%2F20230324%2FBlogAddProducts-20230324-071546.png";
const BlogCustomizeFooter =
  "https://storage.googleapis.com/zopping-staging-uploads/originals%2F20230324%2FBlogCustomizeFooter-20230324-065423.png";

let actionHeading = {
  PRODUCT_ADDITION: "Add Products",
  LOGO_ADDITION: "Add store logo",
  CONTACT_DETAILS: "Add your contact deails",
  PRIMARY_PHONE_DETAILS: "Verify Phone Number",
  PRIMARY_EMAIL_DETAILS: "Verify Email Address",
};
let actionPara = {
  PRODUCT_ADDITION: "Add the products that you wish to sell",
  LOGO_ADDITION: "Brand your website with your logo",
  CONTACT_DETAILS: "Show how your customers can reach you",
  PRIMARY_PHONE_DETAILS:
    "Customer, order and store related communications will be sent here",
  PRIMARY_EMAIL_DETAILS:
    "Customer, order and store related communications will be mailed here",
};
let blogUrlDesc = {
  PRODUCT_ADDITION: "Learn how to add products?",
  LOGO_ADDITION: "Learn how to upload store logo",
  CONTACT_DETAILS: "Learn how to add contact details",
  PRIMARY_PHONE_DETAILS: "",
  PRIMARY_EMAIL_DETAILS: "",
};
let actionButton = {
  PRODUCT_ADDITION: "Add Products",
  LOGO_ADDITION: "Upload Logo",
  CONTACT_DETAILS: "Add Contact Details",
  PRIMARY_PHONE_DETAILS: "Verify Phone Number",
  PRIMARY_EMAIL_DETAILS: "Verify Email Address",
};
let countryContactSupport = {
  IN: "+91 9916814808",
  GB: "+44 74 1831 0190",
};
// Formats Date object in YYYY-MM-DD format
function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; // January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
}
function getMobileView() {
  return window.screen.width <= 640;
}
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      showSmallLoader: true,
      showSlotLoader: true,
      storeId: get("store") || getDefaultStore(this.props.stores).storeId,
      salesStatsData: {},
      customerStatsData: {},
      topProductsData: [],
      categoriesSalesData: [],
      showStore: false,
      selectedDate: formatDate(new Date()),
      showOrderStats: true,
      showRevenueData: false,
      isMobileView: getMobileView(),
      showTopProducts: false,
      showBlogContent: true,
      showQuickLinks: true,
      showOrdersMobile: true,
      showProductsMobile: false,
      showRevenueMobile: false,
      isActionsLoading: false,
      siteUrl: "",
      showContactSupport: false,
      showDownloadUrl: false,
      checkUnderEvaluation: null,
      selectedStore: JSON.parse(window.localStorage.getItem("organization"))
        .defaultStoreId,
    };
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.changeStore = this.changeStore.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  hideLoader() {
    this.setState({
      showLoader: false,
    });
  }

  handleDateChange(date) {
    this.setState({ selectedDate: date }, () => this.updateContent());
  }
  handleChange = (e) => {
    this.setState(
      {
        selectedStore: e,
      },
      () => this.updateContent()
    );
  };

  showLoader() {
    this.setState({
      showLoader: true,
    });
  }

  changeStore(storeId) {
    this.setState(
      {
        storeId: storeId,
      },
      this.updateContent
    );
  }

  getSiteUrl = () => {
    const organization = getSession().organization;
    let url = organization?.domain;
    if (url) {
      let isHttpsEnabled = JSON.parse(get("organization")).httpsEnabled;
      let protocol = isHttpsEnabled ? "https://" : "http://";
      const isPrefixInSiteUrl = url?.includes("http");
      this.setState({
        siteUrl: isPrefixInSiteUrl ? url : `${protocol}${url}`,
      });
    }
  };

  mobileView = () => {
    this.setState({ isMobileView: getMobileView() });
  };
  showPendingActionsData = (countryCode) => {
    this.setState({ isActionsLoading: true });
    const api = new API({ url: "/account-service/pending-action" });
    let data = {};
    const groupDescription = {
      "My Account": {
        url: "/user/account-balance",
        desc: getMessage("action.my.account.desc"),
      },
      "Online Store": {
        url: "/online-store/basic-information",
        desc: getMessage("action.online.store.desc"),
      },
      Catalogue: {
        url: "/catalogue/dashboard",
        desc: getMessage("action.catalogue.desc"),
      },
      Payments: {
        url: "/",
        desc: getMessage("action.payments.desc"),
      },
      Delivery: {
        url: "/delivery/dashboard",
        desc: getMessage("action.delivery.desc"),
      },
      Marketing: {
        url: "/marketing/campaigns",
        desc: getMessage("action.marketing.desc"),
      },
      Settings: {
        url: "/settings",
        desc: "",
      },
    };
    let pendingActions;
    api
      .get()
      .then((response) => {
        pendingActions = response?.data?.pendingAction;
        const PendingActionsId = [
          "PRODUCT_ADDITION",
          "LOGO_ADDITION",
          "CONTACT_DETAILS",
          "PRIMARY_PHONE_DETAILS",
          "PRIMARY_EMAIL_DETAILS",
        ];
        const excludedCountries = ["IN", "GB"];
        const countryNotSupportedPhone =
          !excludedCountries.includes(countryCode);
        if (countryNotSupportedPhone) {
          const index = PendingActionsId.indexOf("PRIMARY_PHONE_DETAILS");

          if (index !== -1) {
            PendingActionsId.splice(index, 1);
          }
        }
        let pendingArr = [];
        pendingActions.forEach((step) => {
          if (PendingActionsId.includes(step.id)) {
            pendingArr.push(step);
          }
        });
        pendingArr.reverse();
        pendingArr &&
          pendingArr.forEach((action) => {
            if (action.url !== "") {
              if (!data[action.id]) {
                data[action.id] = {
                  name: action.group,
                  id: action.id,
                  desc: groupDescription[action.group]?.desc,
                  url: groupDescription[action.group]?.url,
                  blogURL: action.blogURL,
                  actions: [
                    {
                      id: action.id,
                      desc: action.description,
                      dismissible: action.dismissible,
                      url: action.url,
                      blogURL: action.blogURL,
                    },
                  ],
                };
              } else {
                data[action.id].actions.push({
                  id: action.id,
                  desc: action.description,
                  dismissible: action.dismissible,
                  url: action.url,
                  blogURL: action.blogURL,
                });
              }
            }
          });
        const totalActions = response.data.totalAction + 3;
        let numPendingActions = [];
        pendingActions &&
          pendingActions.forEach((action) => {
            if (action.id === "PRODUCT_ADDITION")
              set("AddProductPendingAction", true);
            if (action.url !== "") {
              numPendingActions.push(action);
            }
          });
        let percentageComplete = 0;
        const completeActions = totalActions - numPendingActions.length;
        percentageComplete = Math.round((completeActions / totalActions) * 100);
        this.setState({ isActionsLoading: false });
        this.setState({ data });
        this.setProgress(percentageComplete);
      })
      .catch((error) => {
        this.setState({ isActionsLoading: false });
        if (!error.code === 404) throw error;
        if (faro?.api?.pushError) {
          faro.api.pushError(error);
        }
      });
  };

  setProgress(progress) {
    if (document.getElementById("progress-spinner") !== null) {
      document.getElementById("progress-spinner").style.background =
        "conic-gradient(rgb(74, 185, 24) " +
        progress +
        "%,rgb(43, 50, 56) " +
        progress +
        "%)";
    }
    if (document.getElementById("progress-spinner-mobile") !== null) {
      document.getElementById("progress-spinner-mobile").style.background =
        "conic-gradient(rgb(74, 185, 24) " +
        progress +
        "%,rgb(43, 50, 56) " +
        progress +
        "%)";
    }
    if (document.getElementById("middle-circle") !== null) {
      document.getElementById("middle-circle").innerHTML =
        progress.toString() + "%";
    }
    if (document.getElementById("middle-circle-mobile") !== null) {
      document.getElementById("middle-circle-mobile").innerHTML =
        progress.toString() + "%";
    }
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }

    return "unknown";
  };

  componentDidMount() {
    const organizationId = getSession()?.organization?.id;
    const countryCode = getSession().organization.countryCode;
    const org = new API({
      url: `/account-service/organization/${organizationId}?include=evaluationPeriodDetails`,
    });
    org
      .get()
      .then((response) => {
        this.setState({
          checkUnderEvaluation:
            response.data.organization?.evaluationPeriodDetails,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    window.addEventListener("resize", this.mobileView, false);
    this.updateContent();
    this.showPendingActionsData(countryCode);
    this.getSiteUrl();

    if (!get("shownDownloadApk"))
      this.setState({ showDownloadUrl: true }, () => {
        set("shownDownloadApk", true);
      });
  }

  handleShowOrderMobile = () => {
    this.setState({ showOrdersMobile: !this.state.showOrdersMobile });
  };

  handleShowProductsMobile = () => {
    this.setState({ showProductsMobile: !this.state.showProductsMobile });
  };

  handleShowRevenueMobile = () => {
    this.setState({ showRevenueMobile: !this.state.showRevenueMobile });
  };
  updateContent() {
    this.setState({
      showLoader: true,
      showSlotLoader: true,
      showSmallLoader: true,
      showStore: true,
    });
    let storeId =
      this.state.storeId ||
      get("store") ||
      getDefaultStore(this.props.stores).storeId;
    let org = JSON.parse(get("organization"));
    storeId = storeId || org.defaultStoreId;
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const lastWeekDate = formatDate(oneWeekAgo);
    const today = formatDate(new Date());
    this.orderStatsApi = new API({ url: "/order-service/stats" });
    this.customerStatsApi = new API({ url: "/customer-service/stats" });
    this.catalogueSalesApi = new API({ url: "/catalogue-service/sales" });
    this.slotOrdersApi = new API({ url: "/order-service/order-count" });

    storeId &&
      this.catalogueSalesApi
        .get({ date: today, storeId: storeId })
        .then((catalogueResponse) => {
          const catalogueSales = catalogueResponse.data.sales;
          this.setState({
            showSmallLoader: false,
            topProductsData: catalogueSales.topProducts,
            categoriesSalesData: catalogueSales.categorySales,
            showSlotLoader: false,
          });
        });

    let apis = storeId
      ? [
          this.orderStatsApi.get({ from: lastWeekDate, storeId: storeId }),
          this.customerStatsApi.get({
            startDate: lastWeekDate,
            storeId: storeId,
          }),
        ]
      : [];
    if (isExtensionEnabled("DeliverySlots")) {
      storeId &&
        apis.push(
          this.slotOrdersApi.get({
            preferredDate: getStandradDateTime(
              localTimeToUtc,
              `${this.state.selectedDate} 00:00:00`
            ),
            storeId: this.state.selectedStore,
          })
        );
    } else {
      storeId &&
        apis.push(
          this.slotOrdersApi.get({
            createdAt: getStandradDateTime(
              localTimeToUtc,
              `${this.state.selectedDate} 00:00:00`
            ),
            storeId: this.state.selectedStore,
          })
        );
    }

    Promise.all(apis).then(
      ([ordersResponse, customersResponse, slotOrdersResponse]) => {
        const salesStats = ordersResponse
          ? ordersResponse.data.stats.sales
          : {};
        const customerStats = customersResponse
          ? customersResponse.data.stats
          : {};
        const slotOrderStats =
          (slotOrdersResponse && slotOrdersResponse.data) || [];
        let statuses = [];
        let totalOrders = 0;
        let progressOrders = 0;
        let deliveredOrders = 0;
        let bookedOrders = 0;
        slotOrderStats.forEach((slot) => {
          let keys = (slot.count && Object.keys(slot.count)) || [];
          keys.forEach((key) => {
            if (statuses.indexOf(key) === -1) {
              statuses.push(key);
            }
            totalOrders += slot.count[key];
            if (key === "PENDING") bookedOrders += slot.count[key];
            if (key !== "COMPLETED" && key !== "PENDING" && key !== "CANCELLED")
              progressOrders += slot.count[key];
            if (key === "COMPLETED") deliveredOrders += slot.count[key];
          });
        });
        let fixedSlots = null;
        let asapSlots = null;
        let noSlots = null;
        let count = null;
        if (isExtensionEnabled("DeliverySlots")) {
          fixedSlots =
            slotOrderStats &&
            slotOrderStats
              .filter(
                (slot) =>
                  slot.slot && slot.slot && slot.slot.type === "STANDARD"
              )
              .sort((slot1, slot2) => {
                if (slot1.slot.startTime < slot2.slot.startTime) {
                  return -1;
                } else {
                  return 1;
                }
              });
          asapSlots =
            slotOrderStats &&
            slotOrderStats
              .filter((slot) => slot.slot && slot.slot.type === "ASAP")
              .sort((slot1, slot2) => {
                if (slot1.slot.endTime < slot2.slot.endTime) {
                  return -1;
                } else {
                  return 1;
                }
              });
        } else {
          count = {};
          slotOrderStats &&
            slotOrderStats.forEach((slot) => {
              let keys = Object.keys(slot.count) || [];
              keys.forEach((key) => {
                if (count[key]) {
                  count[key] += slot.count[key];
                } else {
                  count[key] = slot.count[key];
                }
              });
            });
          noSlots = { count: count };
        }

        this.setState({
          showLoader: false,
          customerStatsData: customerStats,
          salesStatsData: salesStats,
          statuses: sortOrderStatus(statuses),
          totalOrders,
          asapSlots,
          fixedSlots,
          noSlots,
          showSlotLoader: false,
          storeId: storeId,
          deliveredOrders,
          progressOrders,
          bookedOrders,
        });
      }
    );
  }

  handleShowOrderStats = () => {
    this.setState({
      showRevenueData: false,
      showOrderStats: true,
      showTopProducts: false,
    });
  };

  componentWillUnmount() {
    this.catalogueSalesApi.cancel();
    this.customerStatsApi.cancel();
    this.slotOrdersApi && this.slotOrdersApi.cancel();
    this.orderStatsApi.cancel();
    window.removeEventListener("resize", this.mobileView, false);
  }

  handleShowRevenue = () => {
    this.setState({
      showRevenueData: true,
      showOrderStats: false,
      showTopProducts: false,
    });
  };
  handleShowTopProducts = () => {
    this.setState({
      showRevenueData: false,
      showOrderStats: false,
      showTopProducts: true,
    });
  };

  handleBlogContent = () => {
    this.setState({ showBlogContent: !this.state.showBlogContent });
  };
  handleQuickLinks = () => {
    this.setState({ showQuickLinks: !this.state.showQuickLinks });
  };
  pendingActionsHeader = (setPendingActionsRef) => {
    return (
      <div className="heading-wrp">
        <div className="img-desc-wrp" ref={setPendingActionsRef}>
          <div className="header-desc">
            <h3>{getMessage("steps.to.finish.your.online.store")}</h3>
          </div>
        </div>
      </div>
    );
  };
  pendingActionsHeaderMobile = (setPendingActionsRef) => {
    return (
      <div className="heading-wrp-mob">
        <div
          className="img-desc-wrp-mob"
          ref={this.state.isMobileView ? setPendingActionsRef : null}
        >
          <div className="header-desc-mob">
            <h3>{getMessage("steps.to.finish.your.online.store")}</h3>
          </div>
        </div>
      </div>
    );
  };
  showPendingActionsSection = (data, setPendingActionsRef) => {
    return (
      data &&
      Object.keys(data).length !== 0 && (
        <div className="pending-actions-wrp">
          {this.pendingActionsHeader(setPendingActionsRef)}
          {data &&
            Object.values(data).map((action) => {
              return (
                <React.Fragment key={action.name}>
                  <div className="actions-card-wrp">
                    <div className="pending-name-wrp">
                      <div className="action-name">
                        {actionHeading[action.id]}
                      </div>
                      <p className="action-desc">{actionPara[action.id]}</p>
                      {action?.blogURL ? (
                        <p className="action-desc">
                          <a
                            href={ZOPPING_URL + action.blogURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>{blogUrlDesc[action.id]}</u>
                          </a>
                        </p>
                      ) : null}
                    </div>
                    <div className="actions-name-wrp">
                      {action &&
                        action.actions.map((name) => {
                          return (
                            <div
                              key={name.id}
                              className={`${
                                name.dismissible ? "show-green" : ""
                              } pending-action-name`}
                              onClick={() => this.handleActionClick(name)}
                            >
                              {actionButton[name.id]}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      )
    );
  };
  handleActionClick = (name) => {
    let getVisitedActions = JSON.parse(
      window.localStorage.getItem("visitedPendingActions")
    );
    let visitedPendingActions = {};
    if (name.dismissible) {
      visitedPendingActions = { ...getVisitedActions, [name.id]: true };
      window.localStorage.setItem(
        "visitedPendingActions",
        JSON.stringify(visitedPendingActions)
      );
    }
    const { history } = this.props;
    history.push(name.url);
  };

  handleCallButtonClick = () => {
    this.setState((prevState) => {
      if (prevState.showContactSupport === false && window.gtag) {
        window.gtag("event", "button_click", {
          category: "Button",
          page: "Home",
          label: "Call",
        });
      }
      return { showContactSupport: !prevState.showContactSupport };
    });
  };

  handleGaEvent = () => {
    if (window.gtag) {
      window.gtag("event", "button_click", {
        category: "Button",
        page: "Home",
        label: "Visit Store",
      });
    }
  };

  handleEditStoreGaEvent = () => {
    if (window.gtag) {
      window.gtag("event", "button_click", {
        category: "Button",
        page: "Home",
        label: "Edit",
      });
    }
  };

  render() {
    const { props } = this;
    const {
      showLoader,
      // customerStatsData,
      // salesStatsData,
      // showSlotLoader,
      // statuses,
      // asapSlots,
      // totalOrders,
      // fixedSlots,
      // noSlots,
      showStore,
      // showSmallLoader,
      // topProductsData,
      // categoriesSalesData,
      showOrderStats,
      showRevenueData,
      showTopProducts,
      showBlogContent,
      isMobileView,
      showQuickLinks,
      showRevenueMobile,
      showProductsMobile,
      // showOrdersMobile,
      data,
      isActionsLoading,
      siteUrl,
      // deliveredOrders,
      // progressOrders,
      // bookedOrders,
      showContactSupport,
      showDownloadUrl,
    } = this.state;
    let numberOfDays = 0;
    let organizationData = JSON.parse(
      window.localStorage.getItem("organization")
    );
    const organizationName = organizationData?.name;
    const evaluationPeriodDetails =
      this.state.checkUnderEvaluation ||
      organizationData?.evaluationPeriodDetails;
    if (evaluationPeriodDetails) {
      numberOfDays = CountTrialDays(evaluationPeriodDetails);
    }
    const {
      setVisitStoreRef,
      setPendingActionsRef,
      setQuickLinksRef,
      // setStoreAnalyticsRef,
    } = props;
    // const stores = getStores();
    // const storesList = stores?.map((store) => ({
    //   value: store.id,
    //   text: store.name,
    // }));
    return (
      <>
        <div className="home-page-main">
          <div className="home-page-left">
            {numberOfDays > 0 && evaluationPeriodDetails.underEvaluation && (
              <FreeTrailAnnouncementBar trailDay={numberOfDays} />
            )}
            <div className="dashboard-card-wrp">
              {!isMobileView ? (
                <div className="edit-website-wrp">
                  <div className="img-wrp">
                    <img src={NewUniqueOnlineStore} alt="online-store" />
                  </div>
                  <div className="website-detail-wrp">
                    <div className="org-name-wrp">
                      <div className="website-name">{organizationName}</div>
                    </div>
                    <a href={siteUrl} target="_blank" rel="noopener noreferrer">
                      <div className="website-url">
                        {organizationData?.domain}
                      </div>
                    </a>
                    <div className="website-edit-button">
                      <a
                        href={siteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.handleGaEvent}
                      >
                        <div className="edit-website" ref={setVisitStoreRef}>
                          {getMessage("visit-website-text")}
                        </div>
                      </a>
                      <Link
                       to="/online-store/themes"
                        className="edit-button-wrp"
                        data-tooltip="Edit Store"
                        onClick={this.handleEditStoreGaEvent}
                      >
                        <img src={EditWebsite} alt="edit-website" />
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="edit-website-wrp">
                  <div className="website-detail-wrp">
                    <div className="org-name-wrp">
                      <div className="website-name">{organizationName}</div>
                    </div>
                  </div>
                  <div className="img-wrp">
                    <img src={NewUniqueOnlineStore} alt="online-store" />
                  </div>
                  <div className="website-detail-wrp">
                    <a href={siteUrl} target="_blank" rel="noopener noreferrer">
                      <div className="website-url">
                        {organizationData?.domain}
                      </div>
                    </a>
                    <div className="website-edit-button">
                      <a
                        href={siteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.handleGaEvent}
                      >
                        <div className="edit-website" ref={setVisitStoreRef}>
                          {getMessage("visit-website-text")}
                        </div>
                      </a>
                      <Link
                        to="/online-store/themes"
                        className="edit-button-wrp"
                        data-tooltip="Edit Store"
                        onClick={this.handleEditStoreGaEvent}
                      >
                        <img src={EditWebsite} alt="edit-website" />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="actions-wrp">
              {this.showPendingActionsSection(data, setPendingActionsRef)}
              <div className="actions-menu">
                <div
                  onClick={this.handleShowOrderStats}
                  className={`${showOrderStats ? "selected" : ""} orders-menu`}
                >
                  {getMessage("menu.item.orders")}
                </div>
                <div
                  onClick={this.handleShowRevenue}
                  className={`${showRevenueData ? "selected" : ""} orders-menu`}
                >
                  {getMessage("revenue.from.stores")}
                </div>
                <div
                  onClick={this.handleShowTopProducts}
                  className={`${showTopProducts ? "selected" : ""} orders-menu`}
                >
                  {getMessage("top.selling.products")}
                </div>
              </div>
              {showLoader ? <Loader /> : <></>}
              {/* <div className='table-chart-container'>
            {(showSmallLoader) ? <Loader /> : (Object.keys(topProductsData).length > 0) && <TopProductsTable currency={currency} data={topProductsData} />}
            {(showSmallLoader) ? <Loader /> : (Object.keys(categoriesSalesData).length > 0) && <CategoriesStats data={categoriesSalesData} />}
          </div> */}
              <div className="product-alert-desktop">
                {!isMobileView && <ProductAlert />}
              </div>
              {/* {!showLoader ? (
                showSlotLoader ? (
                  <Loader />
                ) : (
                  showOrderStats && (
                    <div className="order-stats-desktop">
                      {storesList?.length > 1 && (
                        <Select
                          className="select-store"
                          name={`select-store`}
                          placeholder={"select store"}
                          options={storesList}
                          value={this.state.selectedStore}
                          onChange={this.handleChange}
                        />
                      )}
                      <SlotOrderTable
                        data={{
                          totalOrders,
                          asapSlots,
                          fixedSlots,
                          noSlots,
                          statuses,
                          deliveredOrders,
                          progressOrders,
                          bookedOrders,
                        }}
                        handleDateChange={this.handleDateChange}
                        selectedDate={this.state.selectedDate}
                        areOrdersScheduled={isExtensionEnabled("DeliverySlots")}
                        setStoreAnalyticsRef={setStoreAnalyticsRef}
                      />
                    </div>
                  )
                )
              ) : null} */}
            </div>
            <div className="home-total-order-desktop">
              {!isMobileView && (
                <DataCharts organizationData={organizationData} />
              )}
            </div>
          </div>
          <div className="home-page-right">
            <div className="operations-dashboard">
              {showStore && isExtensionEnabled("MultiStoreSupport") && (
                <StoreSelector
                  value={this.state.storeId}
                  onChange={this.changeStore}
                  stores={props.stores}
                />
              )}
              <div className="dashboard-wrp">
                <div className="sidebar-wrp">
                  {data && Object.keys(data).length !== 0 && (
                    <div className="pending-actions-mobile">
                      {isActionsLoading ? (
                        <Loader />
                      ) : (
                        <div className="pending-actions-wrp">
                          <div className="pending-header-wrp">
                            {this.pendingActionsHeaderMobile(
                              setPendingActionsRef
                            )}
                          </div>
                          <SlideShow allowAutoScroll={false}>
                            {data &&
                              Object.values(data).map((action) => (
                                <React.Fragment key={action.name}>
                                  <div className="actions-card-wrp">
                                    <div className="pending-name-wrp">
                                      <div className="action-name">
                                        {actionHeading[action.id]}
                                      </div>
                                      <p className="action-desc">
                                        {actionPara[action.id]}
                                      </p>
                                    </div>
                                    <div className="actions-name-wrp">
                                      {action &&
                                        action.actions.map((name) => {
                                          return (
                                            <div
                                              key={name.id}
                                              className={`${
                                                name.dismissible
                                                  ? "show-green"
                                                  : ""
                                              } pending-action-name`}
                                              onClick={() =>
                                                this.handleActionClick(name)
                                              }
                                            >
                                              {actionButton[name.id]}
                                            </div>
                                          );
                                        })}
                                    </div>
                                    <div className="blog-url-mob">
                                      <p className="action-desc">
                                        <a href={ZOPPING_URL + action.blogURL}>
                                          <u>{blogUrlDesc[action.id]}</u>
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}
                          </SlideShow>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="dashboard-video">
                    <div className="know-dashboard-wrp">
                      <h3>{getMessage("get.know.dashboard")}</h3>
                    </div>
                    <div className="corner-wrapper">
                      <iframe
                        width="100%"
                        height="155"
                        src="https://www.youtube.com/embed/5m5Qc1vrKok"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <p className="explore-desc">
                      {getMessage("explore.features.minutes")}
                    </p>
                    <div className="know-dashboard-mob">
                      <h3>{getMessage("demo.video")}</h3>
                      <p className="explore-desc-mobile">
                        {getMessage("explore.features.minutes")}
                      </p>
                    </div>
                  </div>
                  <div className="mobile-date-picker">
                    <SingleDatePicker
                      allowAllDates={
                        isExtensionEnabled("DeliverySlots") ? true : undefined
                      }
                      isOutsideRange={
                        !isExtensionEnabled("DeliverySlots") ? true : undefined
                      }
                      displayFormat="YYYY-MM-DD"
                      value={this.state.selectedDate}
                      openDirection="down"
                      onChange={this.handleDateChange}
                      // showHomeDashboard
                    />
                  </div>
                  <div className="product-alert-mobile">
                    {isMobileView && <ProductAlert />}
                  </div>
                  {/* <div
                    onClick={this.handleShowOrderMobile}
                    className={`${
                      showOrdersMobile && isMobileView ? "show-content" : ""
                    } order-mobile`}
                  >
                    <h3
                      className="order-header"
                      ref={isMobileView ? setStoreAnalyticsRef : null}
                    >
                      {getMessage("menu.item.orders")}
                    </h3>
                    {!showOrdersMobile && (
                      <span>{`Booked orders: ${bookedOrders}, Pending: ${progressOrders}, Delivered: ${deliveredOrders}`}</span>
                    )}
                    {showOrdersMobile && (
                      <SlotOrderTable
                        data={{
                          totalOrders,
                          asapSlots,
                          fixedSlots,
                          noSlots,
                          statuses,
                          bookedOrders,
                          progressOrders,
                          deliveredOrders,
                        }}
                        handleDateChange={this.handleDateChange}
                        selectedDate={this.state.selectedDate}
                        areOrdersScheduled={isExtensionEnabled("DeliverySlots")}
                      />
                    )}
                  </div> */}
                  <div className="home-total-order-mobile">
                    {isMobileView && (
                      <DataCharts
                        isMobileView={isMobileView}
                        organizationData={organizationData}
                      />
                    )}
                  </div>
                  <div
                    onClick={this.handleShowRevenueMobile}
                    className={`${
                      showRevenueMobile && isMobileView ? "show-content" : ""
                    } revenue-mobile`}
                  >
                    <h3>{getMessage("revenue.from.stores")}</h3>
                    {!showRevenueMobile && <span>{`Total revenue: `}</span>}
                  </div>
                  <div
                    onClick={this.handleShowProductsMobile}
                    className={`${
                      showProductsMobile && isMobileView ? "show-content" : ""
                    } products-mobile`}
                  >
                    <h3>{getMessage("top.selling.products")}</h3>
                  </div>
                  <div
                    onClick={this.handleQuickLinks}
                    className={`${
                      showQuickLinks && isMobileView ? "show-content" : ""
                    } quick-links`}
                  >
                    <div className="header-wrp">
                      <h3 className="quick-link-header" ref={setQuickLinksRef}>
                        {getMessage("quick.links.text")}
                      </h3>
                    </div>
                    <ul>
                      <Link to="/orders/orders/add">
                        <li>{getMessage("create.order.text")}</li>
                      </Link>
                      <Link to="/catalogue/products/add">
                        <li>{getMessage("add.product.text")}</li>
                      </Link>
                      <Link to="/marketing/campaigns/add">
                        <li>{getMessage("create.campaign.text")}</li>
                      </Link>
                    </ul>
                  </div>
                  <div
                    onClick={this.handleBlogContent}
                    className={`${
                      showBlogContent && isMobileView ? "show-content" : ""
                    } blog-links`}
                  >
                    <div className="header-wrp">
                      <h3 className="quick-link-header">
                        {getMessage("menu.item.blog")}
                      </h3>
                    </div>
                    <ul>
                      <a
                        href={ZOPPING_URL + "/blog/how-to-setup"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <img
                            src={BlogSetUpOnlineStore}
                            alt="blog-setup-online-store"
                          />
                        </div>
                        <li>{getMessage("how.setup.store")}</li>
                      </a>
                      <a
                        href={ZOPPING_URL + "/blog/custom-domain-setup"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <img
                            src={BlogCustomDomain}
                            alt="blog-setup-custom-domain"
                          />
                        </div>
                        <li>{getMessage("how.setup.customDomain")}</li>
                      </a>
                      <a
                        href={
                          ZOPPING_URL +
                          "/blog/how-to-add-products-to-your-online-store"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <img
                            src={BlogAddProducts}
                            alt="blog-add-products-online-store"
                          />
                        </div>
                        <li>{getMessage("how.add.products")}</li>
                      </a>
                      <a
                        href={ZOPPING_URL + "/blog/how-to-customize-footer"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <img
                            src={BlogCustomizeFooter}
                            alt="blog-customize-footer"
                          />
                        </div>
                        <li>{getMessage("how.customize.footer")}</li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
              {showDownloadUrl && (
                <div className="download-app">
                  <div className="container">
                    <button
                      className="close-btn"
                      onClick={() => {
                        this.setState({ showDownloadUrl: false });
                      }}
                    >
                      <img src={CloseIcon} alt="closeIcon" />
                    </button>
                    <img src={ZoppingLogo} alt="zoppingIcon" />
                    <p className="zopping-experience-text">
                      <span>{`${getMessage("use.mobile.app")} `}</span>
                      <span>{`${getMessage("use.zopping")} `}</span>
                      <span>{getMessage("download.mobile.app")}</span>
                    </p>
                    {this.getMobileOperatingSystem() === "IOS" ? (
                      <a
                        href="https://apps.apple.com/in/app/zopping/id1586937529"
                        target="_blank"
                        className="download-app-btn"
                        rel="noopener noreferrer"
                      >
                        {getMessage("product.downloadOptionsTitle")}
                      </a>
                    ) : (
                      <a
                        href="https://play.google.com/store/apps/details?id=com.zopping.admin"
                        target="_blank"
                        className="download-app-btn"
                        rel="noopener noreferrer"
                      >
                        {getMessage("product.downloadOptionsTitle")}
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="contact-support">
          {showContactSupport && (
            <div className="setup-support">
              <span>{getMessage("contact.support")}</span>
              <span className="phone-number">
                {getMessage("customer.details.call")}
                <span className="country-contact-number">
                  {countryContactSupport[organizationData.countryCode]}
                </span>
              </span>
            </div>
          )}
          <div
            className={`circle-container ${showContactSupport && "selected"}`}
            onClick={() => {
              this.handleCallButtonClick();
            }}
          >
            <ContactSupportIcon />
          </div>
        </div>
      </>
    );
  }
}

const DashboardWithStores = makestoreDependentComponent(Dashboard);

export default class OperationsDashboard extends Component {
  setVisitStoreRef = (reference) => {
    this.visitStoreRef = reference;
  };

  setPendingActionsRef = (reference) => {
    this.pendingActionsRef = reference;
  };

  setQuickLinksRef = (reference) => {
    this.quickLinksRef = reference;
  };

  setStoreAnalyticsRef = (reference) => {
    this.storeAnalyticsRef = reference;
  };

  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        homeDashboard
        className="home-dashboard"
        visitStoreRef={this.visitStoreRef}
        pendingActionsRef={this.pendingActionsRef}
        quickLinksRef={this.quickLinksRef}
        storeAnalyticsRef={this.storeAnalyticsRef}
        notShowHelmet={false}
      >
        <Helmet title="Zopping - Home" />
        <DashboardWithStores
          {...this.props}
          setVisitStoreRef={this.setVisitStoreRef}
          setPendingActionsRef={this.setPendingActionsRef}
          setQuickLinksRef={this.setQuickLinksRef}
          setStoreAnalyticsRef={this.setStoreAnalyticsRef}
        />
      </AuthenticatedPage>
    );
  }
}
