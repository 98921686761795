const lang = {
  kn: {
    language: "Kannada",
    strings: {
      "attendance.summary.table.na": "(ಆಂತರಿಕ ಉಲ್ಲೇಖಕ್ಕಾಗಿ ಮಾತ್ರ)",
      "order.table.clientId.prefix":
        "* ಎಲ್ಲಾ ಬೆಲೆಗಳು ತೆರಿಗೆಯನ್ನು ಒಳಗೊಂಡಿರುತ್ತವೆ",
      "order.details.invoice.taxDisclaimer":
        "* ಎಲ್ಲಾ ಬೆಲೆಗಳು ತೆರಿಗೆಯನ್ನು ಒಳಗೊಂಡಿರುತ್ತವೆ",
      "slots.asap.placeholder": "0 ನಿಮಿಷ",
      "mobileApps.appIcon.placeholder": "2048 x 2048 px png ಪಾರದರ್ಶಕ ಹಿನ್ನೆಲೆ",
      "customer.details.call.call": "ಶೀಘ್ರದಲ್ಲೇ ಗ್ರಾಹಕರಿಗೆ ಕರೆ ಮಾಡಲಾಗುವುದು",
      "onboarding.basicInfo.newOne.text": "ಒಂದು ಹೊಸದು",
      "substitutionGroup.helpItems.message":
        "ಬದಲಿ ಗುಂಪು ಪರಸ್ಪರ ಹೋಲುವ ಉತ್ಪನ್ನಗಳನ್ನು ಒಳಗೊಂಡಿದೆ ಮತ್ತು ಅಗತ್ಯವಿದ್ದಾಗ ಬದಲಿಯಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಬಹುದು.",
      "substitutionGroup.form.product.requiredMessage":
        "ಬದಲಿ ಗುಂಪು ಕನಿಷ್ಠ ಒಂದು ಉತ್ಪನ್ನವನ್ನು ಹೊಂದಿರಬೇಕು",
      "attendance.summary.table.ABSENT": "ಗೈರು",
      "offer.absoluteoff": "ಸಂಪೂರ್ಣ ಆಫ್",
      "menu.item.account-balance": "ಖಾತೆ ಬಾಕಿ",
      "settings.online.payment.accountholdername": "ಖಾತೆದಾರರ ಹೆಸರು",
      "socialMedia.google.placeholder": "ಖಾತೆ ID",
      "role.action": "ಕ್ರಿಯೆ",
      "actions.text": "ಕ್ರಿಯೆಗಳು",
      "pickers.today.active.pickers": "ಸಕ್ರಿಯ",
      "banners.radio.option.active": "ಸಕ್ರಿಯ ಬ್ಯಾನರ್‌ಗಳು",
      "coupon.analytics.activeFor.heading": "ಸಕ್ರಿಯವಾಗಿದೆ (ದಿನಗಳು)",
      "extensions.activeInstallations": "ಸಕ್ರಿಯ ಅನುಸ್ಥಾಪನೆಗಳು",
      "pickers.active.header": "ಸಕ್ರಿಯ ಪಿಕ್ಕರ್ಸ್",
      "add.label.text": "ಸೇರಿಸಿ",
      "rackManagement.rack.zones.type.new": "ಹೊಸ ವಲಯವನ್ನು ಸೇರಿಸಿ",
      "operations.store.form.add.heading": "ಅಂಗಡಿಯನ್ನು ಸೇರಿಸಿ",
      "order.form.addAddress.heading": "ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ",
      "campaign.form.pushImage.placeholder": "ಚಿತ್ರವನ್ನು ಸೇರಿಸಿ",
      "store.accounts.label": "ಅಪ್ಲಿಕೇಶನ್ ಲಿಂಕ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
      "banners.view.add.button": "ಬ್ಯಾನರ್ ಸೇರಿಸಿ",
      "product.form.variant.barcodes.placeholder": "ಬಾರ್‌ಕೋಡ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
      "marketing.form.add.heading": "ಬ್ಲಾಗ್ ಪೋಸ್ಟ್ ಸೇರಿಸಿ",
      "brand.add.text": "ಬ್ರ್ಯಾಂಡ್ ಸೇರಿಸಿ",
      "campaign.add.text": "ಪ್ರಚಾರವನ್ನು ಸೇರಿಸಿ",
      "category.form.add.heading": "ವರ್ಗವನ್ನು ಸೇರಿಸಿ",
      "store.configuraion.add.text": "ಸಂರಚನೆಯನ್ನು ಸೇರಿಸಿ",
      "coupon.add.text": "ಕೂಪನ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
      "designations.add": "ಪದನಾಮವನ್ನು ಸೇರಿಸಿ",
      "notifications.add.email": "ಇಮೇಲ್ ಸೇರಿಸಿ",
      "notifications.email.mail": "ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ",
      "customer.details.pages.phonemail.addEmailId": "ಇಮೇಲ್ ಐಡಿ ಸೇರಿಸಿ",
      "employee.add": "ಉದ್ಯೋಗಿಯನ್ನು ಸೇರಿಸಿ",
      "substitutionGroup.add.text": "ಗುಂಪನ್ನು ಸೇರಿಸಿ",
      "entityMetadata.add": "ಕೀಲಿಯನ್ನು ಸೇರಿಸಿ",
      "websitepage.form.addLayout": "ಲೇಔಟ್ ಸೇರಿಸಿ",
      "navigation.dropdown.item.addLink": "ಲಿಂಕ್ ಸೇರಿಸಿ",
      "product.form.brand.addButton": "ಹೊಸ ಬ್ರಾಂಡ್ ಸೇರಿಸಿ",
      "product.form.tag.addButton": "ಹೊಸ ಟ್ಯಾಗ್ ಸೇರಿಸಿ",
      "product.form.category.addButton": "ಹೊಸ ವರ್ಗವನ್ನು ಸೇರಿಸಿ",
      "vehicles.form.vendor.addButton": "ಹೊಸ ಮಾರಾಟಗಾರರನ್ನು ಸೇರಿಸಿ",
      "rackManagement.rack.add.zone": "ಹೊಸ ವಲಯವನ್ನು ಸೇರಿಸಿ",
      "campaign.form.pushImage.heading": "ಅಧಿಸೂಚನೆ ಚಿತ್ರವನ್ನು ಸೇರಿಸಿ",
      "customer.details.pages.phonemail.addPhoneNumber": "ಸಂಖ್ಯೆಯನ್ನು ಸೇರಿಸಿ",
      "offer.add.text": "ಆಫರ್ ಸೇರಿಸಿ",
      "websitePage.add.text": "ಪುಟ ಸೇರಿಸಿ",
      "notifications.add.phone": "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಸೇರಿಸಿ",
      "rackManagement.popup.heading": "ಆಯ್ಕೆ ಮಾಡುವ ಅನುಕ್ರಮವನ್ನು ಸೇರಿಸಿ",
      "orderDetails.uploadPrescription.addPrescription":
        "ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್ ಸೇರಿಸಿ",
      "productUpload.addProduct": "ಉತ್ಪನ್ನ ಸೇರಿಸಿ",
      "order.form.addProduct.heading": "ಉತ್ಪನ್ನಗಳನ್ನು ಸೇರಿಸಿ",
      "onboarding.products.heading": "ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಉತ್ಪನ್ನಗಳನ್ನು ಸೇರಿಸಿ",
      "calls.action.form.heading": "ಕಾರಣ ಮತ್ತು ಸಾರಾಂಶವನ್ನು ಸೇರಿಸಿ",
      "role.add": "ಪಾತ್ರವನ್ನು ಸೇರಿಸಿ",
      "cp.add": "ನಿಯಮ ಸೇರಿಸಿ",
      "search-config.searchurls": "ಹುಡುಕಾಟ ಕೀವರ್ಡ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
      "vehiclePlanner.addShift.heading": "ಶಿಫ್ಟ್ ಸೇರಿಸಿ",
      "imageSlideShow.addSlide": "ಸ್ಲೈಡ್ ಸೇರಿಸಿ",
      "slots.addHeading": "ಸ್ಲಾಟ್ ಸೇರಿಸಿ",
      "slots.store.addHeading": "ಸ್ಲಾಟ್ ನಿಯಮವನ್ನು ಸೇರಿಸಿ",
      "socialMedia.accounts.label": "ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮವನ್ನು ಸೇರಿಸಿ",
      "websitePage.form.add.heading": "ಸ್ಥಿರ ಪುಟವನ್ನು ಸೇರಿಸಿ",
      "operations.store.add.text": "ಅಂಗಡಿ ಸೇರಿಸಿ",
      "navigation.dropdown.item.addTag": "ಟ್ಯಾಗ್ ಸೇರಿಸಿ",
      "rackManagement.rack.zones.type.existing":
        "ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ವಲಯಕ್ಕೆ ಸೇರಿಸಿ",
      "product.form.variant.addButtonText": "ವೇರಿಯಂಟ್ ಸೇರಿಸಿ",
      "vehicles.header.addText": "ವಾಹನ ಸೇರಿಸಿ",
      "vehicles.vendor.addButton": "ಮಾರಾಟಗಾರರನ್ನು ಸೇರಿಸಿ",
      "extensions.addedOn": "ಸೇರಿಸಲಾಗಿದೆ",
      "calls.action.success.info": "ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
      "order.form.customer.address.heading": "ವಿಳಾಸ",
      "customer.details.address.added": "ವಿಳಾಸವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
      "order.log.address.changed": "ವಿಳಾಸ ಬದಲಾಗಿದೆ",
      "customer.callWidget.form.reason.addressClarifiation": "ವಿಳಾಸ ಸ್ಪಷ್ಟೀಕರಣ",
      "customer.details.call.option.addressClarification": "ವಿಳಾಸ ಸ್ಪಷ್ಟೀಕರಣ",
      "customer.details.address.deleted": "ವಿಳಾಸವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ",
      "customer.details.page.address.notServiceable.error":
        "ವಿಳಾಸವು ಸೇವೆಗೆ ಅರ್ಹವಲ್ಲ",
      "googleMaps.zeroResults.error": "ವಿಳಾಸ ಪತ್ತೆಯಾಗಿಲ್ಲ",
      "order.form.addressnotserved":
        "ವಿಳಾಸ ನೀಡಲಾಗಿಲ್ಲ. ದಯವಿಟ್ಟು ಇನ್ನೊಂದು ವಿಳಾಸ ಅಥವಾ ಅಂಗಡಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "customer.address.saved": "ವಿಳಾಸವನ್ನು ನವೀಕರಿಸಲಾಗಿದೆ",
      "vehiclePlanner.addShift.form.adhoc.label": "ಅಧೋಕ್ ವಾಹನ ಎಣಿಕೆ",
      "product.form.variant.aisle.heading": "ಹಜಾರ",
      "offer.alert.message": "ಎಚ್ಚರಿಕೆಯ ಸಂದೇಶ",
      "all.label.text": "ಎಲ್ಲಾ",
      "offer.all.except.selected.stores": "ಎಲ್ಲಾ ಆಯ್ದ ಅಂಗಡಿಗಳನ್ನು ಹೊರತುಪಡಿಸಿ",
      "Friday.all": "ಎಲ್ಲಾ ಶುಕ್ರವಾರಗಳು",
      "order.details.itemsTable.clearingMessage":
        "ಎಲ್ಲಾ ಐಟಂಗಳನ್ನು ತೆಗೆದುಹಾಕಲಾಗಿದೆ. ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಲಾಗುತ್ತದೆ.",
      "logistics.config.allOrders": "ಎಲ್ಲಾ ಆದೇಶಗಳು ಒಂದು ಸಮಯದಲ್ಲಿ",
      "Saturday.all": "ಎಲ್ಲಾ ಶನಿವಾರಗಳು",
      "offer.all.stores": "ಎಲ್ಲಾ ಅಂಗಡಿಗಳು",
      "Sunday.all": "ಎಲ್ಲಾ ಭಾನುವಾರಗಳು",
      "Thursday.all": "ಎಲ್ಲಾ ಗುರುವಾರಗಳು",
      "Tuesday.all": "ಎಲ್ಲಾ ಮಂಗಳವಾರಗಳು",
      "Wednesday.all": "ಎಲ್ಲಾ ಬುಧವಾರಗಳು",
      "shifts.form.toolAccess":
        "ಉದ್ಯೋಗಿಗೆ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಪೋರ್ಟಲ್ ಪ್ರವೇಶಿಸಲು ಅನುಮತಿಸಿ",
      "socialMedia.facebook.allowlogin": "ಫೇಸ್ಬುಕ್ ಲಾಗಿನ್ ಅನುಮತಿಸಿ",
      "socialMedia.google.allowlogin": "ಗೂಗಲ್ ಲಾಗಿನ್ ಅನುಮತಿಸಿ",
      "picking.config.multiplePickingAllowed": "ಬಹು ಆಯ್ಕೆಗಳನ್ನು ಅನುಮತಿಸಿ",
      "picking.config.partialPickingAllowed": "ಭಾಗಶಃ ಆಯ್ಕೆಯನ್ನು ಅನುಮತಿಸಿ",
      "socialMedia.twitter.allowlogin": "ಟ್ವಿಟರ್ ಲಾಗಿನ್ ಅನುಮತಿಸಿ",
      "resetPassword.oldUserText": "ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ?",
      "operations.store.form.businessHours.alwaysOpen": "ಯಾವಾಗಲೂ ತೆರೆದಿರುತ್ತದೆ",
      "campaign.form.time.am": "AM",
      "trips.details.orderList.amount": "ಮೊತ್ತ",
      "customer.details.page.amount": "ಮೊತ್ತ",
      "order.log.amount.changed": "ಮೊತ್ತ ಬದಲಾಗಿದೆ",
      "trips.paymentsForm.amount.placeholder": "ಗ್ರಾಹಕರು ಪಾವತಿಸಿದ ಮೊತ್ತ",
      "customer.details.email.send":
        "ಶೀಘ್ರದಲ್ಲೇ ಗ್ರಾಹಕರಿಗೆ ಇಮೇಲ್ ಕಳುಹಿಸಲಾಗುವುದು.",
      "customer.details.sms.send":
        "ಶೀಘ್ರದಲ್ಲೇ ಗ್ರಾಹಕರಿಗೆ ಎಸ್‌ಎಂಎಸ್‌ ಕಳುಹಿಸಲಾಗುವುದು.",
      "menu.item.analytics": "ವಿಶ್ಲೇಷಣೆಗಳು",
      "extensions.analytics.heading": "ಅನಾಲಿಟಿಕ್ಸ್ ವಿಸ್ತರಣೆ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
      "coupon.analytics.analyticsFor": "ಫಾರ್ ಅನಾಲಿಟಿಕ್ಸ್",
      "coupon.analytics.pieChart.MOBILE_ANDROID_WEB": "ಆಂಡ್ರಾಯ್ಡ್",
      "mobileApps.appIcon.heading": "ಅಪ್ಲಿಕೇಶನ್ ಐಕಾನ್",
      "offer.applicable.to.stores": "ಸಂಗ್ರಹಿಸಲು ಅನ್ವಯಿಸುತ್ತದೆ",
      "leaves.team.appliedon": "ಅನ್ವಯಿಸಲಾಗಿದೆ",
      "coupon.form.stores.heading": "ಅನ್ವಯಿಸುತ್ತದೆ",
      "leaves.apply": "ಅನ್ವಯಿಸು",
      "leaves.apply.new": "ರಜೆಗಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",
      "dispute.approve": "ಅನುಮೋದಿಸಿ",
      "dispute.approveReject": "ಅನುಮೋದಿಸಿ/ ತಿರಸ್ಕರಿಸಿ",
      "dispute.approved": "ಅನುಮೋದಿಸಲಾಗಿದೆ",
      "leaves.team.cancel.title": "ರದ್ದುಗೊಳಿಸಬಹುದಾದ ಅನುಮೋದಿತ ಎಲೆಗಳು",
      "notification.email.delete.title": "ನಿಮಗೆ ಖಚಿತವಾಗಿದೆಯೇ?",
      "coupon.dialog.title": "ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ",
      "customer.reset.password.title": "ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ ?",
      "product.form.delete.message":
        "ನೀವು ಈ ಉತ್ಪನ್ನವನ್ನು ಅಳಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
      "product.form.all.variants.delete.message":
        "ನೀವು ಎಲ್ಲಾ ರೂಪಾಂತರಗಳನ್ನು ತೆಗೆದುಹಾಕಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
      "product.form.variant.delete.message":
        "ನೀವು ಈ ರೂಪಾಂತರವನ್ನು ತೆಗೆದುಹಾಕಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
      "are.you.sure": "ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?",
      "cp.area": "ಪ್ರದೇಶ",
      "polygon.table.subdescription": "ನಕ್ಷೆಯಲ್ಲಿನ ಪ್ರದೇಶ",
      "mobileApps.assets.heading": "ಸ್ವತ್ತುಗಳು",
      "tripPlanner.assign": "ನಿಯೋಜಿಸು",
      "changePassword.list.lowercase": "ಕನಿಷ್ಠ 1 ಸಣ್ಣ ಅಕ್ಷರ (a..z)",
      "changePassword.list.number": "ಕನಿಷ್ಠ 1 ಸಂಖ್ಯೆ (0..9)",
      "changePassword.list.uppercase": "ಕನಿಷ್ಠ 1 ದೊಡ್ಡ ಅಕ್ಷರ (A..Z)",
      "changePassword.list.characterLimit": "ಕನಿಷ್ಠ 6 ಅಕ್ಷರಗಳು",
      "orderprocessing.orderLabelPrinting.startofpicking":
        "ತೆಗೆದುಕೊಳ್ಳುವ ಪ್ರಾರಂಭದಲ್ಲಿ",
      "shifts.header.attendance": "ಹಾಜರಾತಿ",
      "menu.item.attendance-summary": "ಹಾಜರಾತಿ ಸಾರಾಂಶ",
      "logistics.config.tripGeneration.automated": "ಸ್ವಯಂಚಾಲಿತ",
      "product.form.variant.availability.heading": "ಲಭ್ಯತೆ",
      "product.form.variant.availability.label": "ಲಭ್ಯವಿದೆ",
      "user.accountBalance.availableBalance.label": "ಲಭ್ಯವಿರುವ ಬ್ಯಾಲೆನ್ಸ್",
      "order.details.location.b2b.pending.title": "ಬಿ 2 ಬಿ ವಿವರಗಳು",
      "back.text": "ಹಿಂದೆ",
      "order.form.balanceAmount": "ಸಮತೋಲನ",
      "settings.online.payment.bank.account.details": "ಬ್ಯಾಂಕ್ ಖಾತೆ ವಿವರಗಳು",
      "settings.online.payment.accountnumber": "ಬ್ಯಾಂಕ್ ಖಾತೆ ಸಂಖ್ಯೆ",
      "settings.online.payment.bank.name": "ಬ್ಯಾಂಕ್ ಹೆಸರು",
      "menu.item.web-banners": "ಬ್ಯಾನರ್‌ಗಳು",
      "product.form.variant.barcodes.heading": "ಬಾರ್‌ಕೋಡ್‌ಗಳು",
      "order.details.summary.price": "ಮೂಲ ಬೆಲೆ",
      "coupon.analytics.smsTab.basedOnDelivered": "ವಿತರಿಸಿದ ಆಧಾರದ ಮೇಲೆ",
      "coupon.analytics.emailTab.basedOnEmailsDelivered":
        "ವಿತರಿಸಿದ ಇಮೇಲ್‌ಗಳ ಆಧಾರದ ಮೇಲೆ",
      "coupon.analytics.emailTab.basedOnEmailsOpened":
        "ತೆರೆಯಲಾದ ಇಮೇಲ್‌ಗಳ ಆಧಾರದ ಮೇಲೆ",
      "logistics.config.tripGeneration.basic": "ಮೂಲ",
      "customer.details.pages.basicinfo": "ಮೂಲ ಮಾಹಿತಿ",
      "menu.item.basic-information": "ಮೂಲ ಮಾಹಿತಿ",
      "settings.online.payment.beneficiaryname": "ಫಲಾನುಭವಿ ಹೆಸರು",
      "user.transactions.header.billingInfo": "ಬಿಲ್ಲಿಂಗ್ ಮಾಹಿತಿ",
      "menu.item.blog": "ಬ್ಲಾಗ್",
      "campaign.form.emailBody.heading": "ದೇಹ",
      "settings.online.payment.branchifsccode": "ಶಾಖೆ IFSC ಕೋಡ್",
      "brand.header.brand": "ಬ್ರಾಂಡ್",
      "product.form.brand.placeholder": "ಬ್ರಾಂಡ್ ಹೆಸರು",
      "menu.item.brands": "ಬ್ರಾಂಡ್‌ಗಳು",
      "themes.brand.show": "ತೋರಿಸಲು ಬ್ರಾಂಡ್‌ಗಳು",
      "offer.ordertype.options.bulk": "ಬೃಹತ್",
      "menu.item.bulk-coupons": "ಬೃಹತ್ ಕೂಪನ್‌ಗಳು",
      "product.form.bulkThreshold.label": "ಬೃಹತ್ ಆದೇಶದ ಮಿತಿ",
      "product.add.bulk.text": "ಬೃಹತ್ ಅಪ್ಲೋಡ್",
      "operations.store.form.businessHours.title": "ವ್ಯಾಪಾರ ಸಮಯ",
      "offer.buy": "ಖರೀದಿ",
      "order.log.user": "ಮೂಲಕ",
      "customer.details.call": "ಕರೆ",
      "customer.details.call.option.callToInternalEmployees":
        "ಆಂತರಿಕ ಉದ್ಯೋಗಿಗಳಿಗೆ ಕರೆ ಮಾಡಿ",
      "menu.item.calls": "ಕರೆಗಳು",
      "campaign.header.campaign": "ಅಭಿಯಾನ",
      "campaign.analytics.heading": "ಕ್ಯಾಂಪೇನ್ ಅನಾಲಿಟಿಕ್ಸ್",
      "menu.item.campaigns": "ಪ್ರಚಾರಗಳು",
      "errorDialog.delete.error.title": "ಅಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
      "logistics.config.leaveAtDoorstep":
        "ಆರ್ ಆರ್ ಆರ್ಡರ್ ಅನ್ನು ಮನೆ ಬಾಗಿಲಿಗೆ ಬಿಡಬಹುದೇ?",
      "cancel.text": "ರದ್ದುಗೊಳಿಸಿ",
      "order.action.cancel": "ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಿ",
      "leaves.filter.status.approvedcancelleble": "ರದ್ದುಗೊಳಿಸಬಹುದಾಗಿದೆ",
      "order.status.cancelled": "ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
      "menu.item.capacity-planning": "ಸಾಮರ್ಥ್ಯ ಯೋಜನೆ",
      "cp.today": "ಇಂದು ಸಾಮರ್ಥ್ಯ ಯೋಜನೆ",
      "order.details.payment.card": "ಕಾರ್ಡ್",
      "offer.cartprice": "ಕಾರ್ಟ್ ಬೆಲೆ",
      "order.form.paymentMode.cash": "ನಗದು",
      "order.form.cash.tendered": "ನಗದು ಟೆಂಡರ್",
      "coupon.form.cashback": "ಕ್ಯಾಶ್ ಬ್ಯಾಕ್",
      "wallet.config.cashbackPercentage": "ಕ್ಯಾಶ್‌ಬ್ಯಾಕ್ ಶೇಕಡಾ",
      "menu.item.catalogue": "ಕ್ಯಾಟಲಾಗ್",
      "menu.item.categories": "ವರ್ಗಗಳು",
      "themes.category.show": "ತೋರಿಸಬೇಕಾದ ವರ್ಗಗಳು",
      "product.filters.category.heading": "ವರ್ಗ",
      "category.form.name.placeholder": "ವರ್ಗದ ಹೆಸರು",
      "category.tree.title": "ವರ್ಗ ಮರ",
      "themes.change.text": "ಬದಲಾವಣೆ",
      "menu.item.change-password": "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
      "themes.change.heading": "ಥೀಮ್ ಬದಲಾಯಿಸಿ",
      "notification.success.save": "ಬದಲಾವಣೆಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
      "shifts.checkin": "ಚೆಕ್ ಇನ್ ಮಾಡಿ",
      "vehiclePlanner.checkout.button": "ಪರಿಶೀಲಿಸಿ",
      "shifts.checkout": "ಪರಿಶೀಲಿಸಿ",
      "order.status.checked": "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
      "employee.checkedInAt": "ನಲ್ಲಿ ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
      "order.status.checking": "ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ",
      "dispute.choose": "ಆಯ್ಕೆ ಮಾಡಿ",
      "themes.selection.heading": "ವಿನ್ಯಾಸವನ್ನು ಆರಿಸಿ",
      "banners.popup.banner.selection.heading": "ಬ್ಯಾನರ್ ಆಯ್ಕೆಮಾಡಿ",
      "order.filters.status.placeholder": "ಆದೇಶದ ಸ್ಥಿತಿಯನ್ನು ಆರಿಸಿ",
      "leaves.filter.status.placeholder": "ಸ್ಥಿತಿಯನ್ನು ಆರಿಸಿ",
      "leaves.form.type.placeholder": "ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ",
      "onboarding.theme.heading": "ನಿಮ್ಮ ಥೀಮ್ ಆಯ್ಕೆ ಮಾಡಿ",
      "order.form.customer.city.heading": "ನಗರ",
      "vehiclePlanner.filters.clearFiltersText": "ಸ್ಪಷ್ಟ",
      "category.filters.clearFiltersText": "ಎಲ್ಲವನ್ನೂ ತೆಗೆ",
      "rackManagement.clear.sequence": "ಸ್ಪಷ್ಟ ಅನುಕ್ರಮ",
      "themes.add.text": "ಲೇಔಟ್ ಸೇರಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "orderDetails.uploadPrescription.uploadImagePlaceholderText":
        "ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಅಥವಾ ಎಳೆಯಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "basicInfo.uploadImage.placeholder":
        "ನಿಮ್ಮ ಲೋಗೋವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಅಥವಾ ಎಳೆಯಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "productUpload.uploadFileText": "ಫೈಲ್ ಅಪ್ಲೋಡ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "brand.form.image.placeholder":
        "ಅಪ್ಲೋಡ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ, ಅಥವಾ ನಿಮ್ಮ ಚಿತ್ರವನ್ನು ಎಳೆಯಿರಿ",
      "user.payment.redirect.link": "ಖಾತೆ ಬ್ಯಾಲೆನ್ಸ್ ನೋಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "polygon.form.map.description": "ಅಳಿಸಲು ಪ್ರದೇಶದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "polygon.delete.text": "ಅಳಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
      "product.form.variant.image.placeholder":
        "ಅಪ್ಲೋಡ್ ಮಾಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ, ಅಥವಾ ಇಲ್ಲಿ ಚಿತ್ರಗಳನ್ನು ಎಳೆಯಿರಿ",
      "coupon.analytics.smsTab.clicked": "ಕ್ಲಿಕ್ ಮಾಡಲಾಗಿದೆ",
      "coupon.analytics.emailTab.clickedThrough": "ಮೂಲಕ ಕ್ಲಿಕ್ ಮಾಡಲಾಗಿದೆ",
      "campaign.analytics.smsTab.table.header.clicks": "ಕ್ಲಿಕ್‌ಗಳು (ಅನನ್ಯ)",
      "product.filters.clientId.heading": "ಕ್ಲೈಂಟ್ ಐಡಿ",
      "product.form.clientItemId": "ಕ್ಲೈಂಟ್ ಐಟಂ ಐಡಿ",
      "offer.dialog.notification.close": "ಮುಚ್ಚಿ",
      "trips.filter.byStatus.close": "ಮುಚ್ಚಲಾಗಿದೆ",
      "extensions.slot.businessClosingTime": "ನಲ್ಲಿ ಮುಚ್ಚುತ್ತದೆ",
      "operations.store.form.businessHours.closingTime": "ಮುಚ್ಚುವ ಸಮಯ",
      "logistics.config.orderBreakingMethod.clustering": "ಕ್ಲಸ್ಟರಿಂಗ್",
      "coupon.form.codPayments": "ಸಿಒಡಿ",
      "order.details.summary.collectionCharge": "ಸಂಗ್ರಹ ಶುಲ್ಕ",
      "order.details.summary.collectionDiscount": "ಸಂಗ್ರಹ ರಿಯಾಯಿತಿ",
      "themes.cc.name": "ಸಂಗ್ರಹ ಹೆಸರು",
      "offer.combo.discount": "ಕಾಂಬೊ",
      "coupon.form.comment.heading": "ಕಾಮೆಂಟ್",
      "menu.item.communication-configuration": "ಸಂವಹನ ಸಂರಚನೆ",
      "order.action.complete": "ಸಂಪೂರ್ಣ ಆದೇಶ",
      "order.status.completed": "ಪೂರ್ಣಗೊಂಡಿದೆ",
      "trips.details.orderList.completedTime": "ಪೂರ್ಣಗೊಂಡ ಸಮಯ",
      "tripPlanner.complete": "ಪೂರ್ಣಗೊಳಿಸುವಿಕೆ",
      "order.table.header.completionTime": "ಪೂರ್ಣಗೊಳಿಸುವ ಸಮಯ",
      "coupon.form.conditions.heading": "ಷರತ್ತುಗಳು",
      "themes.cc.config": "ಸಂರಚನೆಗಳು",
      "extensions.configure": "ಕಾನ್ಫಿಗರ್ ಮಾಡಿ",
      "deliveryArea.slotConfigure": "ಸ್ಲಾಟ್‌ಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿ",
      "product.form.delete.confirmText": "ದೃ .ೀಕರಿಸಿ",
      "order.details.completion.confirmReturnItem": "ಐಟಂಗಳನ್ನು ದೃೀಕರಿಸಿ",
      "changePassword.confirmPassword.placeholder":
        "ಹೊಸ ಗುಪ್ತಪದವನ್ನು ಖಚಿತಪಡಿಸಿ",
      "changePassword.different.confirmPassword":
        "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್‌ನಂತೆಯೇ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ದೃೀಕರಿಸಿ",
      "customer.table.header.contact": "ಸಂಪರ್ಕಿಸಿ",
      "menu.item.contact-details": "ಸಂಪರ್ಕ ವಿವರಗಳು",
      "category.helpItems.seo.description":
        "ಕಂಟೆಂಟ್ ಮಾರ್ಕೆಟಿಂಗ್ ಎನ್ನುವುದು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಉದ್ದೇಶಿತ ಪ್ರೇಕ್ಷಕರಿಗೆ ವಿಷಯವನ್ನು ರಚಿಸಲು, ಪ್ರಕಟಿಸಲು ಮತ್ತು ವಿತರಿಸಲು ಕೇಂದ್ರೀಕರಿಸಿದ ಮಾರ್ಕೆಟಿಂಗ್‌ನ ಒಂದು ರೂಪವಾಗಿದೆ.",
      "offer.dialog.clone.proceed": "ಮುಂದುವರಿಸಿ",
      "analytics.conversionPixel.heading": "ಕನ್ವರ್ಷನ್ ಪಿಕ್ಸೆಲ್",
      "coupon.download.form.error":
        "ಕೊಟ್ಟಿರುವ ಪೂರ್ವಪ್ರತ್ಯಯ ಮತ್ತು ಪ್ರತ್ಯಯಕ್ಕಾಗಿ ಕೂಪನ್‌ಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲಾಗಲಿಲ್ಲ",
      "operations.dashboard.counts": "ಎಣಿಕೆ",
      "entityMetaData.country": "ದೇಶಗಳು",
      "coupon.header.coupon": "ಕೂಪನ್",
      "coupon.analytics.heading": "ಕೂಪನ್ ಅನಾಲಿಟಿಕ್ಸ್",
      "coupon.form.name.heading": "ಕೂಪನ್ ಕೋಡ್",
      "order.details.summary.coupondiscount": "ಕೂಪನ್ ರಿಯಾಯಿತಿ",
      "coupon.form.discount.heading": "ಕೂಪನ್ ನೀಡುತ್ತದೆ",
      "menu.item.coupons": "ಕೂಪನ್ಗಳು",
      "order.packing.crates": "ಕ್ರೇಟ್ಸ್",
      "login.newUser.signupText": "ಖಾತೆ ತೆರೆ",
      "order.add.text": "ಹೊಸ ಆದೇಶವನ್ನು ರಚಿಸಿ",
      "offer.clone.copy": "ಇದೇ ರೀತಿ ರಚಿಸಿ",
      "signup.heading": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಚಿಸಿ",
      "onboarding.createWebsite.text": "ಈಗ ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ರಚಿಸಿ",
      "tripPlanner.orderPlacedDate": "ನಲ್ಲಿ ರಚಿಸಲಾಗಿದೆ",
      "calls.filters.tag.option.cs": "ಸಿಎಸ್",
      "customer.import.successText":
        "CSV ಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ! ಗ್ರಾಹಕರನ್ನು ಸೇರಿಸಲಾಗುತ್ತದೆ ಮತ್ತು 15 ನಿಮಿಷಗಳ ನಂತರ ಕಾಣಿಸಿಕೊಳ್ಳುತ್ತದೆ",
      "fileUpload.uploadedCsv.heading":
        "CSV ಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ! ಉತ್ಪನ್ನಗಳು 15 ನಿಮಿಷಗಳ ನಂತರ ಕಾಣಿಸಿಕೊಳ್ಳುತ್ತವೆ",
      "customer.tags.import.successText":
        "CSV ಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ! ಟ್ಯಾಗ್‌ಗಳನ್ನು ಸೇರಿಸಲಾಗುತ್ತದೆ ಮತ್ತು 15 ನಿಮಿಷಗಳ ನಂತರ ಕಾಣಿಸಿಕೊಳ್ಳುತ್ತದೆ",
      "picker.status": "ಪ್ರಸ್ತುತ ಸ್ಥಿತಿ",
      "dispute.emptyMessage": "ಪ್ರಸ್ತುತ ಯಾವುದೇ ವಿವಾದಗಳಿಲ್ಲ",
      "banners.empty.banner": "ಪ್ರಸ್ತುತ ಯಾವುದೇ ಮುಖಪುಟ ಬ್ಯಾನರ್‌ಗಳು ಲಭ್ಯವಿಲ್ಲ",
      "customer.details.purchase.emptyText":
        "ಪ್ರಸ್ತುತ ಈ ಗ್ರಾಹಕರಿಗೆ ಯಾವುದೇ ಆದೇಶಗಳಿಲ್ಲ",
      "pickers.emptyMessage": "ಪ್ರಸ್ತುತ ಯಾವುದೇ ಪಿಕರ್ಸ್ ಇಲ್ಲ",
      "pickers.empty": "ಪ್ರಸ್ತುತ ಯಾವುದೇ ಪಿಕರ್ಸ್ ಲಭ್ಯವಿಲ್ಲ",
      "menu.item.metadata": "ಕಸ್ಟಮ್ ಜಾಗ",
      "calls.filters.customer.label": "ಗ್ರಾಹಕ",
      "order.form.customer.email.heading": "ಗ್ರಾಹಕರ ಇಮೇಲ್ ವಿಳಾಸ",
      "order.form.customer.name.heading": "ಗ್ರಾಹಕ ಹೆಸರು",
      "order.log.customerNA": "ಗ್ರಾಹಕರು ಲಭ್ಯವಿಲ್ಲ",
      "notifications.customer.title": "ಗ್ರಾಹಕ ಅಧಿಸೂಚನೆಗಳು",
      "order.form.customer.phone.heading": "ಗ್ರಾಹಕರ ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
      "offer.customer.redemption": "ಗ್ರಾಹಕ ವಿಮೋಚನೆ",
      "trips.details.customerImage": "ಗ್ರಾಹಕರ ಸಹಿ",
      "menu.item.customer-management": "",
      "menu.item.customer-tags": "ಗ್ರಾಹಕ ಟ್ಯಾಗ್‌ಗಳು",
      "menu.item.customers": "ಗ್ರಾಹಕರು",
      "operations.dashboard.customerAcquired": "ಖರೀದಿಸಿದ ಗ್ರಾಹಕರು",
      "operations.dashboard.customersTransacted": "ಗ್ರಾಹಕರು ವಹಿವಾಟು ನಡೆಸಿದರು",
      "themes.customize.text": "ಕಸ್ಟಮೈಸ್ ಮಾಡಿ",
      "slots.cutoff.mins": "ನಿಮಿಷಗಳಲ್ಲಿ ಕಡಿತ",
      "cutoff.time": "ಕಟ್ಆಫ್ ಸಮಯ",
      "cp.today.cutofftime": "ಕಟ್ಆಫ್ ಸಮಯ 1",
      "coupon.analytics.dailyDistribution.heading": "ದೈನಂದಿನ ವಿತರಣೆ",
      "menu.item.dashboard": "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
      "trips.filter.byDates.heading": "ದಿನಾಂಕ",
      "campaign.analytics.emailTab.table.header.dateTime": "ದಿನಾಂಕ ಸಮಯ",
      "order.log.date.changed": "ದಿನಾಂಕ ಬದಲಾಗಿದೆ",
      "campaign.form.invalidDateTime":
        "ದಿನಾಂಕದ ಸಮಯವು ಪ್ರಸ್ತುತ ದಿನಾಂಕಕ್ಕಿಂತ ಹೆಚ್ಚಿರಬೇಕು",
      "operations.store.form.businessHours.day": "ದಿನ",
      "coupon.form.days.heading": "ದಿನಗಳು",
      "customer.contact.details.default": "ಡೀಫಾಲ್ಟ್",
      "orderprocessing.config.allStores": "ಡೀಫಾಲ್ಟ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
      "rackManagement.rack.add.zone.default": "ಡೀಫಾಲ್ಟ್ ವಲಯ",
      "catalogue.dashboard.helpItems.cm.title":
        "ವಿಷಯ ಮಾರ್ಕೆಟಿಂಗ್ ಸ್ಥಿತಿಯನ್ನು ವ್ಯಾಖ್ಯಾನಿಸುವುದು",
      "delete.text": "ಅಳಿಸು",
      "order.details.location.delivery.completed.title": "ಗೆ ತಲುಪಿಸಲಾಗಿದೆ",
      "order.details.location.delivery.pending.title": "ಗೆ ತಲುಪಿಸಲಾಗುತ್ತಿದೆ",
      "extensions.deliveryArea.zones.heading": "ವಲಯಗಳನ್ನು ತಲುಪಿಸುವುದು",
      "menu.item.delivery": "ವಿತರಣೆ",
      "trips.header.deliveryAgent": "ವಿತರಣಾ ಏಜೆಂಟ್",
      "menu.item.delivery-area": "ವಿತರಣಾ ಪ್ರದೇಶ",
      "deliveryArea.editSlot.heading": "ವಿತರಣಾ ಪ್ರದೇಶದ ಸ್ಲಾಟ್ ನಿಯಮಗಳು",
      "deliveryCharge.heading.text": "ವಿತರಣಾ ಶುಲ್ಕ",
      "offer.heading.deliveyDate": "ವಿತರಣಾ ದಿನಾಂಕ",
      "extensions.deliveryArea.deliveryFee": "ವಿತರಣಾ ಶುಲ್ಕ",
      "logistics.config.deliveryFee": "ವಿತರಣಾ ಶುಲ್ಕ",
      "description.heading.text": "ವಿವರಣೆ",
      "shifts.designation": "ಹುದ್ದೆ",
      "menu.item.designations": "ಪದನಾಮಗಳು",
      "coupon.analytics.pieChart.DESKTOP_NA_WEB": "ಡೆಸ್ಕ್‌ಟಾಪ್",
      "order.details.payment.details": "ವಿವರಗಳು",
      "coupon.analytics.deviceDistribution.heading": "ಸಾಧನ ವಿತರಣೆ",
      "substitutionGroup.action.disable": "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ",
      "offer.clone.disableNClone": "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ ಮತ್ತು ಹೊಸದನ್ನು ರಚಿಸಿ",
      "disabled.text": "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
      "product.form.variant.discount.heading": "ರಿಯಾಯಿತಿ",
      "product.form.discount.error": "ರಿಯಾಯಿತಿ ಬೆಲೆಗಿಂತ ಹೆಚ್ಚಿರಬಾರದು",
      "offer.discountvalue": "ರಿಯಾಯಿತಿ ಮೌಲ್ಯ",
      "order.status.dispatched": "ರವಾನಿಸಲಾಗಿದೆ",
      "product.config.checkoutAllowed":
        "ಡಿಸ್‌ಪ್ಲೇ ಆಡ್ ಟು ಕಾರ್ಟ್ ಬಟನ್ ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ",
      "product.config.showPriceToggle": "ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಬೆಲೆಯನ್ನು ಪ್ರದರ್ಶಿಸಿ",
      "order.dispute.heading": "ಆದೇಶಕ್ಕಾಗಿ ವಿವಾದ",
      "dispute.heading": "ವಿವಾದಿತ ಆದೇಶಗಳು",
      "menu.item.disputes": "ವಿವಾದಗಳು",
      "dispute.view.heading": "ಆದೇಶಕ್ಕಾಗಿ ವಿವಾದಗಳು",
      "trips.header.distance": "ದೂರ",
      "extensions.deliveryArea.radial.title": "ಅಂಗಡಿಯಿಂದ ದೂರ",
      "tripPlanner.order.distance": "ದೂರ (ಕಿಮೀ)",
      "orderDetails.uploadPrescription.doctorName": "ವೈದ್ಯರ ಹೆಸರು",
      "login.newUser.message": 'ಖಾತೆ ಇಲ್ಲವೇ? "" "',
      "order.details.itemsTable.actions.confirmText": "ಮುಗಿದಿದೆ",
      "orderprocessing.qualityChecking.donebychecker":
        "ಪರೀಕ್ಷಕರಿಂದ ಮಾಡಲಾಗುತ್ತದೆ",
      "orderprocessing.packingDetails.donebypacker":
        "ಪ್ಯಾಕರ್ ಮೂಲಕ ಮಾಡಲಾಗುತ್ತದೆ",
      "orderprocessing.packingDetails.donebypicker":
        "ಪಿಕ್ಕರ್ ಮೂಲಕ ಮಾಡಲಾಗುತ್ತದೆ",
      "product.downloadOptionsTitle": "ಡೌನ್ಲೋಡ್ ಮಾಡಿ",
      "product.downloadSuccessTitle": "{Value} ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
      "tripPlanner.download.summary.text": "CSV ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
      "analytics.report.modal.title": "ವರದಿಯನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿ",
      "productUpload.csvDownloadText": "ಮಾದರಿ CSV ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
      "orderDetails.uploadPrescription.dr": "ಡಾ.",
      "marketing.blog.table.status.draft": "ಕರಡು",
      "radial.form.map.description":
        "ಸ್ಥಳವನ್ನು ಗುರುತಿಸಲು ಡ್ರ್ಯಾಗ್ ಮಾಡಿ ಮತ್ತು ಕ್ಲಿಕ್ ಮಾಡಿ",
      "banners.view.drag.text": "ಆದೇಶವನ್ನು ಬದಲಾಯಿಸಲು ಎಳೆಯಿರಿ",
      "polygon.selectLocation.message": "ನಕ್ಷೆಯಲ್ಲಿ ಪ್ರದೇಶವನ್ನು ಎಳೆಯಿರಿ",
      "extensions.deliveryArea.polygon.title": "ನಕ್ಷೆಯಲ್ಲಿ ಚಿತ್ರಿಸಿ",
      "tripPlanner.unassignedOrders.placeholder":
        "ಪ್ರವಾಸಕ್ಕೆ ಸಿದ್ಧವಿಲ್ಲದ ಆದೇಶಗಳನ್ನು ಇಲ್ಲಿ ಬಿಡಿ",
      "leaves.form.duration": "ಅವಧಿ",
      "user.leave-summary.status.Earned": "ಗಳಿಸಿದೆ",
      "action.edit.text": "ತಿದ್ದು",
      "banners.editForm.header": "ಬ್ಯಾನರ್ ಸಂಪಾದಿಸಿ",
      "marketing.form.edit.heading": "ಬ್ಲಾಗ್ ಪೋಸ್ಟ್ ಸಂಪಾದಿಸಿ",
      "brand.edit.text": "ಬ್ರಾಂಡ್ ಎಡಿಟ್ ಮಾಡಿ",
      "campaign.form.edit.heading": "ಪ್ರಚಾರವನ್ನು ಸಂಪಾದಿಸಿ",
      "category.form.edit.heading": "ವರ್ಗವನ್ನು ಸಂಪಾದಿಸಿ",
      "sc.edit.heading": "ಸಂರಚನೆಯನ್ನು ಎಡಿಟ್ ಮಾಡಿ",
      "coupon.form.edit.heading": "ಕೂಪನ್‌ಗಳನ್ನು ಸಂಪಾದಿಸಿ",
      "designations.editheader": "ಪದನಾಮವನ್ನು ಸಂಪಾದಿಸಿ",
      "radial.form.edit.heading": "ಅಂಗಡಿಯಿಂದ ದೂರವನ್ನು ಸಂಪಾದಿಸಿ",
      "websitePage.popup.heading.image": "ಚಿತ್ರವನ್ನು ಸಂಪಾದಿಸಿ",
      "websitePage.popup.imagewithtext.heading":
        "ಪಠ್ಯದೊಂದಿಗೆ ಚಿತ್ರವನ್ನು ಸಂಪಾದಿಸಿ",
      "offer.edit.heading": "ಕೊಡುಗೆಯನ್ನು ಸಂಪಾದಿಸಿ",
      "order.action.editPackedOrder": "ಪ್ಯಾಕ್ ಮಾಡಿದ ಆದೇಶವನ್ನು ಸಂಪಾದಿಸಿ",
      "settings.users.action.editPermissions": "ಅನುಮತಿಗಳನ್ನು ಎಡಿಟ್ ಮಾಡಿ",
      "picker.editHeading": "ಪಿಕ್ಕರ್ ಸಂಪಾದಿಸಿ",
      "product.form.edit.heading": "ಉತ್ಪನ್ನವನ್ನು ಸಂಪಾದಿಸಿ",
      "role.edit.heading": "ಪಾತ್ರವನ್ನು ಸಂಪಾದಿಸಿ",
      "cp.edit": "ನಿಯಮವನ್ನು ಸಂಪಾದಿಸಿ",
      "vehiclePlanner.editShift.heading": "ಶಿಫ್ಟ್ ಸಂಪಾದಿಸಿ",
      "slots.store.editHeading": "ಸ್ಲಾಟ್ ನಿಯಮವನ್ನು ಸಂಪಾದಿಸಿ",
      "websitePage.form.edit.heading": "ಸ್ಥಿರ ಪುಟವನ್ನು ಸಂಪಾದಿಸಿ",
      "operations.store.form.edit.heading": "ಅಂಗಡಿಯನ್ನು ಸಂಪಾದಿಸಿ",
      "tag.edit.text": "ಟ್ಯಾಗ್ ಸಂಪಾದಿಸಿ",
      "vehicles.header.editText": "ವಾಹನವನ್ನು ಸಂಪಾದಿಸಿ",
      "pincode.form.edit.heading": "ಜಿಪ್ ಕೋಡ್ / ಪೋಸ್ಟಲ್ ಕೋಡ್ ಅನ್ನು ಎಡಿಟ್ ಮಾಡಿ",
      "email.title.text": "ಇಮೇಲ್",
      "customer.details.email.added": "ಇಮೇಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
      "notification.email.error": "ಇಮೇಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
      "customer.details.pages.phonemail.mailId": "ಇಮೇಲ್ ಐಡಿ",
      "customer.details.email.deleted": "ಇಮೇಲ್ ಐಡಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ",
      "calls.filters.employee.label": "ಉದ್ಯೋಗಿ",
      "attendance.summary.table.employee.name": "ನೌಕರನ ಹೆಸರು",
      "menu.item.employees": "ನೌಕರರು",
      "category.form.enableText": "ಸಕ್ರಿಯಗೊಳಿಸಿ",
      "socialMedia.whatsappSupportEnabled": "WhatsApp ಬೆಂಬಲವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
      "status.label.enabled": "ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
      "offer.delivery-date.end": "ಅಂತಿಮ ದಿನಾಂಕ",
      "banners.message.invalidDate":
        "ಅಂತಿಮ ದಿನಾಂಕವು ಪ್ರಾರಂಭದ ದಿನಾಂಕದ ನಂತರ ಇರಬೇಕು",
      "trips.header.endTime": "ಅಂತ್ಯ ಸಮಯ",
      "trips.action.close": "ಪ್ರವಾಸ ಮುಗಿಸಿ",
      "trips.endTrip.label": "ಟ್ರಿಪ್ KM",
      "trip.details.endedAt": "ನಲ್ಲಿ ಕೊನೆಗೊಂಡಿತು",
      "slots.endsAt": "ನಲ್ಲಿ ಕೊನೆಗೊಳ್ಳುತ್ತದೆ",
      "leaves.team.endson": "ಕೊನೆಗೊಳ್ಳುತ್ತದೆ",
      "product.form.enter": "ನಮೂದಿಸಿ",
      "input.invalidNumber": "ಧನಾತ್ಮಕ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
      "tag.linkToProduct.product.placeholder": "ಉತ್ಪನ್ನದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "basicInfo.invalidDomain": "ಮಾನ್ಯವಾದ ಡೊಮೇನ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "analytics.invalidTrackingId": "ಮಾನ್ಯವಾದ ಟ್ರ್ಯಾಕಿಂಗ್ ಐಡಿ ನಮೂದಿಸಿ",
      "substitutionGroup.form.percentageDeviation.placeholder":
        "0 ಮತ್ತು 100 ರ ನಡುವೆ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
      "order.form.customer.address.placeholder": "ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
      "offer.alert.message.placeholder": "ಎಚ್ಚರಿಕೆಯ ಸಂದೇಶವನ್ನು ನಮೂದಿಸಿ",
      "attendance.summary.filter.form.employee.placeholder":
        "ಉದ್ಯೋಗಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "order.filters.referenceNumber.placeholder":
        "ಆರ್ಡರ್ ಸಂಖ್ಯೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "brand.form.name.placeholder": "ಬ್ರಾಂಡ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "themes.bc.categories.placeholder": "ತೋರಿಸಲು ಬ್ರ್ಯಾಂಡ್‌ಗಳನ್ನು ನಮೂದಿಸಿ",
      "product.form.bulkThreshold.placeholder": "ಬೃಹತ್ ಆದೇಶದ ಮಿತಿಯನ್ನು ನಮೂದಿಸಿ",
      "calls.form.reason.placeholder": "ಕರೆ ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
      "calls.form.summary.placeholder": "ಕರೆ ಸಾರಾಂಶವನ್ನು ನಮೂದಿಸಿ",
      "capacity.placeholder": "ಸಾಮರ್ಥ್ಯವನ್ನು ನಮೂದಿಸಿ",
      "offer.cartprice.placeholder": "ಕಾರ್ಟ್ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
      "themes.cc.categories.placeholder": "ತೋರಿಸಲು ವರ್ಗಗಳನ್ನು ನಮೂದಿಸಿ",
      "sc.form.cat.placeholder": "ವರ್ಗವನ್ನು ನಮೂದಿಸಿ",
      "product.form.category.placeholder": "ವರ್ಗದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "order.form.customer.city.placeholder": "ನಗರವನ್ನು ಪ್ರವೇಶಿಸಿ",
      "order.filters.clientId.placeholder": "ಕ್ಲೈಂಟ್ ಐಡಿ ನಮೂದಿಸಿ",
      "product.form.clientItemId.placeholder": "ಕ್ಲೈಂಟ್ ಐಟಂ ಐಡಿ ನಮೂದಿಸಿ",
      "themes.cc.name.placeholder": "ಸಂಗ್ರಹಣೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "order.form.customerDetails.heading": "ಗ್ರಾಹಕರ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ",
      "order.form.customer.email.placeholder": "ಗ್ರಾಹಕ ಇಮೇಲ್ ನಮೂದಿಸಿ",
      "order.form.customer.name.placeholder": "ಗ್ರಾಹಕರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "offer.customer.redemption.placeholder": "ಗ್ರಾಹಕರ ವಿಮೋಚನೆಯನ್ನು ನಮೂದಿಸಿ",
      "slots.cutoff.placeholder": "ಕಟ್ಆಫ್ ಸಮಯವನ್ನು ನಿಮಿಷಗಳಲ್ಲಿ ನಮೂದಿಸಿ",
      "logistics.config.deliveryFee.placeholder": "ವಿತರಣಾ ಶುಲ್ಕವನ್ನು ನಮೂದಿಸಿ",
      "offer.description.placeholder": "ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ",
      "shifts.designation.placeholder": "ಪದನಾಮವನ್ನು ನಮೂದಿಸಿ",
      "orderDetails.uploadPrescription.enterDoctorName":
        "ವೈದ್ಯರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "shifts.form.email.placeholder": "ಇಮೇಲ್ ಐಡಿ ನಮೂದಿಸಿ",
      "videoCarousel.videoUrl.placeholder": "ಎಂಬೆಡ್ ವೀಡಿಯೊ URL ಅನ್ನು ನಮೂದಿಸಿ",
      "banners.form.endTime.placeholder": "ಅಂತಿಮ ಸಮಯವನ್ನು ನಮೂದಿಸಿ",
      "offer.entityid.placeholder": "ಎಂಟಿಟಿ ಐಡಿ ನಮೂದಿಸಿ",
      "extensions.enterExtensionName": "ವಿಸ್ತರಣೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "entityMetaData.text.placeholder": "ಕೀಲಿಯನ್ನು ನಮೂದಿಸಿ",
      "order.form.customer.landmark.placeholder": "ಹೆಗ್ಗುರುತನ್ನು ನಮೂದಿಸಿ",
      "designations.form.manager.placeholder": "ಮ್ಯಾನೇಜರ್ ಹುದ್ದೆ ನಮೂದಿಸಿ",
      "offer.min.amount.placeholder": "ಕನಿಷ್ಠ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
      "offer.min.quantity.placeholder": "ಕನಿಷ್ಠ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
      "substitutionGroup.form.product.placeholder": "ಉತ್ಪನ್ನದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "entityMetaData.number.placeholder": "ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "changePassword.oldPassword.placeholder": "ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
      "offer.max.redemption.placeholder": "ಆದೇಶ ಮಟ್ಟದ ವಿಮೋಚನೆಯನ್ನು ನಮೂದಿಸಿ",
      "return.filter.referenceNumber.placeholder":
        "ಆದೇಶ ಉಲ್ಲೇಖ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "order.details.packages.multi-text.placeholder": "ಪ್ಯಾಕೇಜ್ ಲೇಬಲ್ ನಮೂದಿಸಿ",
      "orderDetails.uploadPrescription.enterPatientName":
        "ರೋಗಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "offer.payment.type.placeholder": "ಪಾವತಿ ಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ",
      "order.form.customer.pincode.placeholder": "ಪಿನ್‌ಕೋಡ್ ನಮೂದಿಸಿ",
      "product.form.handlingDays.placeholder":
        "ಪೂರ್ವ-ಆದೇಶ ನಿರ್ವಹಣಾ ದಿನಗಳನ್ನು ನಮೂದಿಸಿ",
      "product.form.description.placeholder": "ಉತ್ಪನ್ನ ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ",
      "product.form.name.placeholder": "ಉತ್ಪನ್ನದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "coupon.promocode.placeholder": "ಪ್ರೋಮೋಕೋಡ್ ನಮೂದಿಸಿ",
      "offer.quantity.placeholder": "ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
      "leaves.form.reason.placeholder": "ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
      "designations.form.roles.placeholder": "ಪಾತ್ರಗಳನ್ನು ನಮೂದಿಸಿ",
      "search-config.searchurls.placeholder": "ಹುಡುಕಾಟ ಕೀವರ್ಡ್‌ಗಳನ್ನು ನಮೂದಿಸಿ",
      "banners.select.search.term.placeholder": "ಹುಡುಕಾಟ ಪದವನ್ನು ನಮೂದಿಸಿ",
      "product.form.secondarycategory.placeholder":
        "ದ್ವಿತೀಯ ವರ್ಗಗಳನ್ನು ನಮೂದಿಸಿ",
      "banners.form.startTime.placeholder": "ಆರಂಭದ ಸಮಯವನ್ನು ನಮೂದಿಸಿ",
      "settings.online.payment.enterstate": "ರಾಜ್ಯವನ್ನು ನಮೂದಿಸಿ",
      "customer-service-tags.table.name.placeholder": "ಟೇಬಲ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "category.form.tagline.placeholder": "ಟ್ಯಾಗ್ ಲೈನ್ ನಮೂದಿಸಿ",
      "product.form.tag.placeholder": "ಟ್ಯಾಗ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "campaign.form.pushText.placeholder": "ಪಠ್ಯವನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "videoCarousel.description.placeholder": "ವಿವರಣೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "trips.endTrip.placeholder":
        "ಓಡೋಮೀಟರ್‌ನಲ್ಲಿ ಪ್ರದರ್ಶಿಸಲಾದ KM ಅನ್ನು ನಮೂದಿಸಿ",
      "shifts.confirmpassword.placeholder": "ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಮತ್ತೆ ನಮೂದಿಸಿ",
      "banners.form.search.term.placeholder": "ಹುಡುಕಾಟ ಪದವನ್ನು ನಮೂದಿಸಿ",
      "banners.form.sequence.placeholder": "ಅನುಕ್ರಮವನ್ನು ನಮೂದಿಸಿ",
      "imagewithtext.placeholder": "ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
      "campaign.form.pushSubject.placeholder": "ಶೀರ್ಷಿಕೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "offer.total.redemption.placeholder": "ಒಟ್ಟು ವಿಮೋಚನೆಯನ್ನು ನಮೂದಿಸಿ",
      "mobileApps.shortDescription.placeholder": "30 ಅಕ್ಷರಗಳವರೆಗೆ ನಮೂದಿಸಿ",
      "campaign.form.pushUrl.placeholder": "Url ಅನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "settings.users.inviteForm.email.placeholder": "ಬಳಕೆದಾರರ ಇಮೇಲ್ ನಮೂದಿಸಿ",
      "settings.users.inviteForm.name.placeholder": "ಬಳಕೆದಾರರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "settings.users.inviteForm.phone.placeholder":
        "ಬಳಕೆದಾರರ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "enitityMetaData.values.placeholder": "ಮೌಲ್ಯಗಳನ್ನು ನಮೂದಿಸಿ",
      "vehiclePlanner.addShift.form.regular.count": "ವಾಹನದ ಎಣಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
      "vehicles.form.number.placeholder": "ವಾಹನದ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "vehicles.form.vendor.placeholder": "ಮಾರಾಟಗಾರರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "user.accountBalance.input.placeholder": "ನಿಮ್ಮ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
      "mobileApps.name.placeholder": "ನಿಮ್ಮ ಆಪ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "login.subheading": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಕೆಳಗೆ ನಮೂದಿಸಿ",
      "login.email.placeholder": "ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
      "integrationSettings.gtm.placeholder":
        "ನಿಮ್ಮ Google ಟ್ಯಾಗ್ ಮ್ಯಾನೇಜರ್ ಐಡಿ ನಮೂದಿಸಿ",
      "signup.name.placeholder": "ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "label.enter.phone.number": "ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "extensions.tawk.site.id.placeholder": "ನಿಮ್ಮ ಸೈಟ್ ಐಡಿ ನಮೂದಿಸಿ",
      "basicInfo.storeurl.placeholder": "ನಿಮ್ಮ ಅಂಗಡಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "operations.store.form.address.placeholder":
        "ನಿಮ್ಮ ಅಂಗಡಿಯ ಸಂಪೂರ್ಣ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
      "marketing.blog.form.title.placeholder":
        "ನಿಮ್ಮ ಶೀರ್ಷಿಕೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "extensions.tawk.widget.id.placeholder": "ನಿಮ್ಮ ವಿಜೆಟ್ ಐಡಿ ನಮೂದಿಸಿ",
      "rackManagement.rack.add.zone.placeholder": "ವಲಯವನ್ನು ನಮೂದಿಸಿ",
      "offer.entity.id": "ಘಟಕ ID",
      "offer.entity": "ಅಸ್ತಿತ್ವದ ಪ್ರಕಾರ",
      "error.text": "ದೋಷ",
      "product.form.imageUploade.error": "ಅಪ್‌ಲೋಡ್ ಮಾಡುವಲ್ಲಿ ದೋಷ",
      "offer.dialog.disable.errorText":
        "ಕೊಡುಗೆಯನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ. ದಯವಿಟ್ಟು ಪ್ರಯತ್ನಿಸಿ.",
      "banners.radio.option.expired": "ಅವಧಿ ಮೀರಿದ ಬ್ಯಾನರ್‌ಗಳು",
      "coupon.form.endDate.placeholder": "ಗಡುವು ದಿನಾಂಕ",
      "menu.item.extensions": "ವಿಸ್ತರಣೆಗಳು",
      "media.select.facebook": "ಫೇಸ್ಬುಕ್",
      "order.status.failed": "ವಿಫಲವಾಗಿದೆ",
      "product.form.stores.fetchError":
        "ಕೆಲವು ಅಂಗಡಿಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ವಿಫಲವಾಗಿದೆ. ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.",
      "user.accountBalance.loader.text":
        "ನಿಮ್ಮ ಸಮತೋಲನವನ್ನು ಪಡೆಯಲಾಗುತ್ತಿದೆ. ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ...",
      "input.fileSizeExeeded": "ಫೈಲ್ ಗಾತ್ರವನ್ನು ಹೊರಹಾಕಲಾಗಿದೆ",
      "input.invalidFileType": "ಫೈಲ್ ಪ್ರಕಾರವು ಅಮಾನ್ಯವಾಗಿದೆ.",
      "dispute.filingTime": "ಫೈಲಿಂಗ್ ಸಮಯ",
      "entityMetaData.checkboxLabel": "ಫಿಲ್ಟರ್",
      "themes.tag.filter.label": "ಫಿಲ್ಟರ್ ಕಾಣಿಸಿಕೊಳ್ಳಬೇಕು",
      "order.details.itemsTable.header.picked": "ಅಂತಿಮ ಪ್ರಮಾಣ",
      "trips.filter.byStatus.completed": "ಆರ್ಥಿಕವಾಗಿ ಮುಚ್ಚಲಾಗಿದೆ",
      "designations.form.timingtype.fixed": "ಸ್ಥಿರ",
      "designations.form.timingtype.flexible": "ಹೊಂದಿಕೊಳ್ಳುವ",
      "videoCarousel.fontSize.label": "ಫಾಂಟ್ ಗಾತ್ರ (px ನಲ್ಲಿ)",
      "videoCarousel.fontSize.placeholder": "px ನಲ್ಲಿ ವಿವರಣೆಗಾಗಿ ಫಾಂಟ್ ಗಾತ್ರ",
      "login.forgotPassword": "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ",
      "resetPassword.heading": "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?",
      Friday: "ಶುಕ್ರವಾರ",
      "calls.table.heading.from": "ನಿಂದ",
      "category.helpItems.title": "ಬ್ಲಾಗ್ ನಿಂದ",
      "radial.table.description": "ಅಂಗಡಿ ಸ್ಥಳದಿಂದ",
      "order.packing.frozenBoxes": "ಹೆಪ್ಪುಗಟ್ಟಿದ ಪೆಟ್ಟಿಗೆಗಳು",
      "extensions.analytics.gaAccount.heading": "ಜಿಎ ಖಾತೆ",
      "offer.get": "ಪಡೆಯಿರಿ",
      "banners.editForm.radio.option.global": "ಜಾಗತಿಕ",
      "resetPassword.loginLinkText": "ಲಾಗಿನ್ ಗೆ ಹೋಗಿ",
      "catalogue.dashboard.helpItems.gp.title":
        "ಉತ್ತಮ ಉತ್ಪನ್ನ ವಿವರಣೆಯು ಹಣವನ್ನು ಉಳಿಸುತ್ತದೆ",
      "analytics.tracking.heading": "ಗೂಗಲ್ ಅನಾಲಿಟಿಕ್ಸ್ ಟ್ರ್ಯಾಕಿಂಗ್ ಐಡಿ",
      "googleMaps.addressMap.error":
        "ಸ್ಥಿತಿಯೊಂದಿಗೆ ವಿಳಾಸ ನಕ್ಷೆಯಲ್ಲಿ Google ನಕ್ಷೆ API ವಿಫಲವಾಗಿದೆ",
      "integrationSettings.googleMapsKey.label": "Google ನಕ್ಷೆಗಳು Api ಕೀ",
      "integrationSettings.gtm.label": "ಗೂಗಲ್ ಟ್ಯಾಗ್ ಮ್ಯಾನೇಜರ್ ಐಡಿ",
      "themes.grid": "ಗ್ರಿಡ್",
      "attendance.summary.table.HALFDAY": "ಅರ್ಧ ದಿನ",
      "handle.text": "ಹ್ಯಾಂಡಲ್",
      "operations.store.form.hasDeliveryHub": "ವಿತರಣಾ ಕೇಂದ್ರವನ್ನು ಹೊಂದಿದೆ",
      "operations.store.table.header.deliveyHub": "ವಿತರಣಾ ಕೇಂದ್ರವನ್ನು ಹೊಂದಿದೆ",
      "operations.store.table.header.picking": "ಪಿಕಿಂಗ್ ಹೊಂದಿದೆ",
      "offer.field.hasUniquePromocode.label": "ವಿಶಿಷ್ಟ ಪ್ರೋಮೋಕೋಡ್ ಹೊಂದಿದೆ",
      "product.helpItems.submessage":
        "ಇಲ್ಲಿ ನೀವು ಬೆಲೆ ಸೇರಿದಂತೆ ಉತ್ಪನ್ನಗಳ ಬಗ್ಗೆ ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಬಹುದು ಮತ್ತು ಕೆಲವು ಉತ್ತಮ ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಬಹುದು.",
      "vehicles.emptyState.helperText":
        "ನಿಮಗೆ ಲಭ್ಯವಿರುವ ವಿತರಣಾ ವಾಹನಗಳ ಪಟ್ಟಿಯನ್ನು ಇಲ್ಲಿ ನೀವು ನೋಡುತ್ತೀರಿ.",
      "brand.status.hidden": "ಮರೆಮಾಡಲಾಗಿದೆ",
      "category.form.hideText": "ಅಡಗಿಸು",
      "banners.default.page": "ಮನೆ",
      "logistics.config.reseesOrders": "ಆರ್‌ಇ ಆದೇಶಗಳನ್ನು ಹೇಗೆ ನೋಡುತ್ತದೆ?",
      "extensions.slot.numOfDays": "ಕ್ಲೈಂಟ್ ತೋರಿಸಲು ಎಷ್ಟು ದಿನಗಳು?",
      "customer.helpItems.title": "ಗ್ರಾಹಕರನ್ನು ಹೇಗೆ ಪಡೆಯುವುದು?",
      "order.helpItems.title": "ಆದೇಶಗಳನ್ನು ಪಡೆಯುವುದು ಹೇಗೆ?",
      "menu.item.hr": "ಮಾನವ ಸಂಪನ್ಮೂಲ",
      "settings.online.payment.ihaveagateway": "ನನ್ನ ಬಳಿ ಪೇಮೆಂಟ್ ಗೇಟ್ ವೇ ಇದೆ",
      "extensions.policy": "ನಾನು ಗೌಪ್ಯತೆ ನೀತಿಯನ್ನು ಓದಿ ಒಪ್ಪಿಕೊಂಡಿದ್ದೇನೆ",
      "extensions.icon": "ಐಕಾನ್",
      "trips.header.id": "ID",
      "pickers.today.idle.pickers": "ಐಡಲ್",
      "pickers.idle.header": "ಐಡಲ್ ಪಿಕ್ಕರ್ಸ್",
      "category.header.image": "ಚಿತ್ರ",
      "dualImageBanner.image1": "ಚಿತ್ರ 1",
      "dualImageBanner.image2": "ಚಿತ್ರ 2",
      "themes.images": "ಚಿತ್ರಗಳು",
      "category.helpItems.poc.description":
        "ಚಿತ್ರಗಳು ನಿಮ್ಮ ಲ್ಯಾಂಡಿಂಗ್ ಪುಟದ ಮೇಲ್ಮನವಿಯನ್ನು ಸುಧಾರಿಸುತ್ತದೆ ಅದು ಉತ್ತಮ ಪರಿವರ್ತನೆಯೊಂದಿಗೆ ನೇರವಾಗಿ ಸಂಬಂಧ ಹೊಂದಿದೆ.",
      "fileUpload.importCsv.heading": "CSV ಫೈಲ್ ಆಮದು ಮಾಡಿ",
      "customer.import.text": "ಆಮದು ಗ್ರಾಹಕರು",
      "fileUpload.importKML.heading": "KML ಫೈಲ್ ಅನ್ನು ಆಮದು ಮಾಡಿ",
      "tag.import": "ಟ್ಯಾಗ್‌ಗಳನ್ನು ಆಮದು ಮಾಡಿ",
      "customer.helpItems.iec.title":
        "ನಿಮ್ಮ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕರನ್ನು ಆಮದು ಮಾಡಿ",
      "calls.filters.type.option.inbound": "ಒಳಬರುವ",
      "offer.includes": "ಒಳಗೊಂಡಿದೆ",
      "product.form.soldByWeight.pattern": "ತಪ್ಪಾದ ಹೆಸರಿನ ಮಾದರಿ",
      "offer.individual.discount": "ವೈಯಕ್ತಿಕ",
      "themes.infiniteScroll": "ಅನಂತ ಸ್ಕ್ರೋಲರ್",
      "extensions.install": "ಸ್ಥಾಪಿಸಿ",
      "extensions.installExtension": "ವಿಸ್ತರಣೆಯನ್ನು ಸ್ಥಾಪಿಸಿ",
      "extensions.installedSuccessfully": "ಯಶಸ್ವಿಯಾಗಿ ಸ್ಥಾಪಿಸಲಾಗಿದೆ",
      "menu.item.instore": "ಇನ್ಸ್ಟೋರ್ ಪ್ರೊಸೆಸಿಂಗ್",
      "menu.item.integration-settings": "ಏಕೀಕರಣ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
      "marketing.table.heading.interaction": "ಪರಸ್ಪರ ಕ್ರಿಯೆ",
      "trips.details.invalid-id": "ಅಮಾನ್ಯ ಟ್ರಿಪ್ ಐಡಿ",
      "product.type.inventory": "ದಾಸ್ತಾನು ಹಾಳೆ",
      "settings.users.inviteForm.invite.buttonText": "ಆಹ್ವಾನಿಸಿ",
      "settings.users.invite.text": "ಬಳಕೆದಾರರನ್ನು ಆಹ್ವಾನಿಸಿ",
      "order.details.invoice.title": "ಸರಕುಪಟ್ಟಿ",
      "coupon.analytics.pieChart.MOBILE_IOS_WEB": "ಐಒಎಸ್",
      "operations.store.form.hasPicking.label": "ಇಲ್ಲಿ ಆಯ್ಕೆಯನ್ನು ಮಾಡಲಾಗಿದೆಯೇ?",
      "operations.store.form.hasClickCollect.label": "ಇದು ಸಂಗ್ರಹ ಕೇಂದ್ರವೇ?",
      "operations.store.form.hasDeliveryHub.label": "ಇದು ವಿತರಣಾ ಕೇಂದ್ರವೇ?",
      "order.log.itemName": "ವಸ್ತುವಿನ ಹೆಸರು",
      "order.details.itemsTable.itemsInOrder.unavailabelText": "ಐಟಂ ಲಭ್ಯವಿಲ್ಲ",
      "tripPlanner.items": "ವಸ್ತುಗಳ ಎಣಿಕೆ",
      "order.details.itemsTable.title": "ವಸ್ತುಗಳು ಕ್ರಮದಲ್ಲಿ",
      "customer.table.header.joinDate": "ಸೇರುವ ದಿನಾಂಕ",
      "customer.table.header.joinDateLastPurchase":
        "ಸೇರುವ ದಿನಾಂಕ / ಕೊನೆಯ ಖರೀದಿ",
      "customer.details.header.joined": "ಸೇರಿಕೊಂಡರು",
      "customer.joinedOn": "ಸೇರಿಕೊಂಡರು",
      "key.label.text": "ಕೀ",
      "trip.details.km": "ಕಿಮೀ",
      "fileUpload.uploadedKML.heading":
        "KML ಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ!",
      "deliveryArea.kml.upload.text": "KML ಅಪ್‌ಲೋಡ್",
      "order.packing.labels": "ಲೇಬಲ್‌ಗಳು",
      "order.form.customer.landmark.heading": "ಹೆಗ್ಗುರುತು",
      "banners.languages": "ಭಾಷೆ",
      "order.packing.largeBags": "ದೊಡ್ಡ ಚೀಲಗಳು",
      "order.packing.largeBoxes": "ದೊಡ್ಡ ಪೆಟ್ಟಿಗೆಗಳು",
      "campaign.analytics.table.heading": "ಕೊನೆಯ 10 ತೆರೆಯಲಾಗಿದೆ",
      "customer.details.header.lastPurchase": "ಕೊನೆಯ ಖರೀದಿ",
      "customer.table.header.lastPurchased": "ಕೊನೆಯದಾಗಿ ಖರೀದಿಸಲಾಗಿದೆ",
      "extensions.lastUpdated": "ಕೊನೆಯದಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
      "campaign.analytics.smsTab.table.header.lastVisit": "ಕೊನೆಯ ಭೇಟಿ",
      "pickers.today.late": "ತಡವಾಗಿ",
      "customer.details.call.option.lateDelivery": "ತಡವಾಗಿ ವಿತರಣೆ",
      "tripPlanner.order.lat": "ಅಕ್ಷಾಂಶ",
      "themes.layout.timeline.heading": "ಲೇಔಟ್ ಟೈಮ್‌ಲೈನ್",
      "themes.layout.save.success": "ಲೇಔಟ್‌ಗಳನ್ನು ಉಳಿಸಲಾಗಿದೆ!",
      "extensions.seo.read.more": "ಇನ್ನಷ್ಟು ತಿಳಿಯಿರಿ",
      "menu.item.leave-management": "ನಿರ್ವಹಣೆಯನ್ನು ಬಿಡಿ",
      "menu.item.leave-summary": "ಸಾರಾಂಶವನ್ನು ಬಿಡಿ",
      "leaves.team.approveable": "ತಂಡದ ಸದಸ್ಯರು ಅರ್ಜಿ ಹಾಕಿದ ಎಲೆಗಳು",
      "coupon.header.limit": "ಮಿತಿ",
      "coupon.form.limits.heading": "ಮಿತಿಗಳು",
      "dualImageBanner.link": "ಲಿಂಕ್",
      "settings.online.payment.link.bank.account":
        "ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಲಿಂಕ್ ಮಾಡಿ",
      "online.payments.link.bank.account": "ಬ್ಯಾಂಕ್ ಖಾತೆ ಲಿಂಕ್ ಮಾಡಿ",
      "dualImageBanner.linkText": "ಲಿಂಕ್ ಪಠ್ಯ",
      "tag.action.linkToProduct": "ಉತ್ಪನ್ನಗಳಿಗೆ ಲಿಂಕ್",
      "banners.editForm.link.label": "ಲಿಂಕ್ URL",
      "campaign.form.vairableList.heading": "ಲಭ್ಯವಿರುವ ಅಸ್ಥಿರಗಳ ಪಟ್ಟಿ",
      "themes.loadMoreType": "ಬಳಸಿ ಹೆಚ್ಚಿನ ಉತ್ಪನ್ನಗಳನ್ನು ಲೋಡ್ ಮಾಡಿ",
      "mobileApps.theme.loadingText": "ವಿಷಯಗಳನ್ನು ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
      "product.form.variant.location.header": "ಸ್ಥಳ",
      "login.text": "ಲಾಗಿನ್",
      "menu.item.logistics": "ಲಾಜಿಸ್ಟಿಕ್ಸ್",
      "logistics.config.heading": "ಲಾಜಿಸ್ಟಿಕ್ಸ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
      "brand.header.logo": "ಲೋಗೋ",
      "menu.item.logout": "ಲಾಗ್ ಔಟ್",
      "mobileApps.longDescription.heading": "ದೀರ್ಘ ವಿವರಣೆ",
      "tripPlanner.order.lng": "ರೇಖಾಂಶ",
      "order.paymentmode.LOYALTY": "ನಿಷ್ಠೆ",
      "banners.header": "ಬ್ಯಾನರ್‌ಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
      "menu.item.pickers": "ಪಿಕ್ಕರ್‌ಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
      "menu.item.manage-runners": "ರನ್ನರ್ ಕಾರ್ಯನಿರ್ವಾಹಕರನ್ನು ನಿರ್ವಹಿಸಿ",
      "vehicles.heading": "ವಾಹನಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
      "designations.header.manager": "ಮ್ಯಾನೇಜರ್",
      "designations.form.roles": "ಪಾತ್ರಗಳಿಗೆ ಮ್ಯಾಪ್ ಮಾಡಲಾಗಿದೆ",
      "polygon.table.description": "ಗುರುತಿಸಲಾದ ಸ್ಥಳ",
      "menu.item.marketing": "ಮಾರ್ಕೆಟಿಂಗ್",
      "user.leave-summary.status.Maternity": "ಹೆರಿಗೆ",
      "offer.maxdiscountvalue": "ಗರಿಷ್ಠ ರಿಯಾಯಿತಿ",
      "store.max.purchaseable.qty": "ಗರಿಷ್ಠ ಖರೀದಿಸಬಹುದಾದ ಪ್ರಮಾಣ",
      "store.max.purchaseable.quantity": "ಗರಿಷ್ಠ ಕ್ಯೂಟಿ",
      "campaign.form.maxcharacters.description": "ಗರಿಷ್ಠ 150 ಅಕ್ಷರಗಳು",
      "coupon.form.maxDiscountAmount.heading": "ಗರಿಷ್ಠ ರಿಯಾಯಿತಿ ಮೊತ್ತ",
      "coupon.form.maxRedemption.heading": "ಗರಿಷ್ಠ ವಿಮೋಚನೆ",
      "coupon.form.maxSingleRedemption.heading":
        "ಗರಿಷ್ಠ ಏಕ ಬಳಕೆದಾರರಿಂದ ವಿಮೋಚನೆ",
      "order.packing.mediumBoxes": "ಮಧ್ಯಮ ಪೆಟ್ಟಿಗೆಗಳು",
      "slot.meridian": "ಮೆರಿಡಿಯನ್",
      "customer.details.sms.message": "ಸಂದೇಶ",
      "offer.min.amount.label": "ಕನಿಷ್ಠ ಮೊತ್ತ",
      "offer.min.quantity": "ಕನಿಷ್ಠ ಪ್ರಮಾಣ",
      "coupon.form.minOrderAmount.heading": "ಕನಿಷ್ಠ ಆದೇಶದ ಮೊತ್ತ",
      "offer.min.amount": "ಕನಿಷ್ಠ ಮೊತ್ತ",
      "orderConfig.form.minimumOrderValue.heading": "ಕನಿಷ್ಠ ಆರ್ಡರ್ ಮೌಲ್ಯ",
      asapDurationEndText: "ನಿಮಿಷಗಳು",
      "calls.filters.status.option.missed": "ತಪ್ಪಿಹೋಯಿತು",
      "customer.callWidget.form.reason.missedCallback": "ಮಿಸ್ಡ್ ಕಾಲ್ ವಾಪಸ್",
      "customer.details.call.option.missedCallback": "ತಪ್ಪಿದ ಕಾಲ್‌ಬ್ಯಾಕ್",
      "menu.item.mobile-apps": "ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು",
      "notification.phone.error": "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
      "order.details.payment.mode": "ಮೋಡ್",
      Monday: "ಸೋಮ",
      "extensions.monthlyBillingEstimate": "ಮಾಸಿಕ ಬಿಲ್ಲಿಂಗ್ ಅಂದಾಜು",
      "extensions.monthlyEstimate": "ಮಾಸಿಕ ಅಂದಾಜು",
      "return.move.order": "ಆದೇಶವನ್ನು ಇದಕ್ಕೆ ಸರಿಸಿ",
      "order.action.moveToPending": "ಬಾಕಿಗೆ ಸರಿಸಿ",
      "search-configuration.header.mrp": "MRP",
      "order.paymentmode.MSWIPE": "ಎಂ ಸ್ವೈಪ್",
      "menu.item.users": "ಬಹು ಬಳಕೆದಾರರ ಬೆಂಬಲ",
      "extensions.multiLingual.heading": "ಬಹುಭಾಷಾ ಬೆಂಬಲ",
      "entityMetaData.checkboxMultivaluedLabel": "ಬಹು ಮೌಲ್ಯಯುತ",
      "menu.item.my-account": "ನನ್ನ ಖಾತೆ",
      "trips.details.orderList.transactionId.na": "ಎನ್ / ಎ",
      "name.heading.text": "ಹೆಸರು",
      "mobileApps.name.heading": "ಆಪ್ ಹೆಸರು",
      "substitutionGroup.form.name.placeholder": "ಈ ಬದಲಿ ಗುಂಪಿಗೆ ಹೆಸರಿಸಿ",
      "themes.customize.nav": "ಸಂಚರಣೆ",
      "navigation.sequencing.heading": "ನ್ಯಾವಿಗೇಷನ್ ಸೀಕ್ವೆನ್ಸಿಂಗ್",
      "order.details.summary.netAmount": "ನಿವ್ವಳ ಮೊತ್ತ",
      "coupon.form.newUsers": "ಹೊಸ",
      "campaign.form.add.heading": "ಹೊಸ ಅಭಿಯಾನ",
      "order.helpItems.nca.title": "ಹೊಸ ಗ್ರಾಹಕ ಸ್ವಾಧೀನ",
      "changePassword.newPassword.heading": "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್",
      "order.log.newQuantity": "ಹೊಸ ಪ್ರಮಾಣ",
      "logistics.config.no": "ಇಲ್ಲ",
      "vehiclePlanner.vehicleList.novehicle":
        "ಯಾವುದೇ {type} ವಾಹನ ಚೆಕ್ ಇನ್ ಆಗಿಲ್ಲ",
      "attendance.summary.empty.message": "ಹಾಜರಾತಿ ವಿವರ ಕಂಡುಬಂದಿಲ್ಲ",
      "calls.emptyState.text": "ಯಾವುದೇ ಕರೆಗಳು ಕಂಡುಬಂದಿಲ್ಲ",
      "customerSupport.search.noCustomer": "ಯಾವುದೇ ಗ್ರಾಹಕರು ಕಂಡುಬಂದಿಲ್ಲ",
      "slots.DELIVERY.empty": "ಕಾನ್ಫಿಗರ್ ಮಾಡಲು ಯಾವುದೇ ಡೆಲಿವರಿ ಸ್ಲಾಟ್‌ಗಳಿಲ್ಲ",
      "attendance.label.noDesignation": "ಯಾವುದೇ ಹುದ್ದೆ ಇಲ್ಲ",
      "return.no.items.to.return": "ಈ ಆರ್ಡರ್‌ಗೆ ಹಿಂತಿರುಗಲು ಯಾವುದೇ ಐಟಂಗಳಿಲ್ಲ",
      "leaves.team.empty.message": "ಎಲೆಗಳಿಲ್ಲ",
      "customerSupport.search.noOrder": "ಯಾವುದೇ ಆದೇಶ ಕಂಡುಬಂದಿಲ್ಲ",
      "order.logs.emptyMeassage": "ಈ ಆದೇಶಕ್ಕಾಗಿ ಯಾವುದೇ ಆದೇಶದ ದಾಖಲೆಗಳಿಲ್ಲ",
      "slots.PICKUP.empty": "ಕಾನ್ಫಿಗರ್ ಮಾಡಲು ಯಾವುದೇ ಪಿಕಪ್ ಸ್ಲಾಟ್‌ಗಳಿಲ್ಲ",
      "vehiclePlanner.emptyState.message": "ಯಾವುದೇ ಯೋಜಿತ ಶಿಫ್ಟ್ ಕಂಡುಬಂದಿಲ್ಲ",
      "navigation.nosequence.display": "ಪ್ರದರ್ಶಿಸಲು ಯಾವುದೇ ಅನುಕ್ರಮವಿಲ್ಲ",
      "customer-service-tags.noTags": "ಯಾವುದೇ ಟ್ಯಾಗ್‌ಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ",
      "settings.users.permissionsForm.copyPlaceholder.name": "ಬಳಕೆದಾರರಿಲ್ಲ",
      "customer.reset.password.cancelText": "ಇಲ್ಲ, ರದ್ದುಗೊಳಿಸಿ",
      "attendance.label.notCheckedIn": "ಚೆಕ್ ಇನ್ ಮಾಡಿಲ್ಲ",
      "orderprocessing.qualityChecking.notneeded": "ಅಗತ್ಯವಿಲ್ಲ",
      "email.not.verified.text": "ಪರಿಶೀಲಿಸಿಲ್ಲ",
      "offer.dialog.notification.label": "ಅಧಿಸೂಚನೆ",
      "campaign.form.pushText.heading": "ಅಧಿಸೂಚನೆ ಪಠ್ಯ",
      "campaign.form.pushSubject.heading": "ಅಧಿಸೂಚನೆ ಶೀರ್ಷಿಕೆ",
      "menu.item.notifications": "ಅಧಿಸೂಚನೆಗಳು",
      "vehicles.header.number": "ಸಂಖ್ಯೆ",
      "trip.details.odometerImage": "ಓಡೋಮೀಟರ್ ಚಿತ್ರ",
      "picker.offDays": "ಆಫ್ ದಿನಗಳು",
      "menu.item.offers": "ಕೊಡುಗೆಗಳು",
      "socialMedia.google.heading": "ಅಧಿಕೃತ Google+ ಪುಟ",
      "store.ios.heading": "ಅಧಿಕೃತ ಐಒಎಸ್ ಪುಟ",
      "store.android.heading": "ಅಧಿಕೃತ ಪ್ಲೇಸ್ಟೋರ್ ಪುಟ",
      "socialMedia.twitter.heading": "ಅಧಿಕೃತ ಟ್ವಿಟರ್ ಪುಟ",
      "offer.ordertype.options.offline": "ಆಫ್‌ಲೈನ್",
      "order.details.location.offline.pending.title": "ಆಫ್‌ಲೈನ್ ವಿವರಗಳು",
      "customer.helpItems.om.title": "ಆಫ್‌ಲೈನ್ ಮಾರ್ಕೆಟಿಂಗ್",
      "ok.text": "ಸರಿ",
      "okay.text": "ಸರಿ",
      "changePassword.oldPassword.heading": "ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್",
      "order.log.oldQuantity": "ಹಳೆಯ ಪ್ರಮಾಣ",
      "orderprocessing.pickingView.onebyone": "ಒಂದೊಂದಾಗಿ",
      "coupon.form.onlinePayments": "ಆನ್ಲೈನ್",
      "menu.item.payment-configuration": "ಪಾವತಿಗಳು",
      "dispute.only deliver": "ಮಾತ್ರ ತಲುಪಿಸಿ",
      "orderprocessing.orderLabelFormat.onlyordernumber": "ಆದೇಶ ಸಂಖ್ಯೆ ಮಾತ್ರ",
      "dispute.only refund": "ಮರುಪಾವತಿ ಮಾತ್ರ",
      "themes.layout.save.title.error": "ಅಯ್ಯೋ",
      "errorDialog.title": "ಅಯ್ಯೋ!",
      "trips.filter.byStatus.open": "ತೆರೆಯಿರಿ",
      "coupon.analytics.emailTab.opened": "ತೆರೆಯಲಾಗಿದೆ",
      "operations.store.form.businessHours.openingTime": "ತೆರೆಯುವ ಸಮಯ",
      "menu.item.operations": "ಕಾರ್ಯಾಚರಣೆ",
      "operations.dashboard.heading": "ಕಾರ್ಯಾಚರಣೆಗಳ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
      "productUpload.orText": "ಅಥವಾ",
      "login.or.text": "ಅಥವಾ",
      "onboarding.basicinfo.url.subtext": "ಅಥವಾ ಬಳಸಿ",
      "trip.details.orderList.singleOrder.heading": "ಆದೇಶ",
      "trips.payment.order.id": "ಆದೇಶ #",
      "order.details.invoice.referenceNumber": "ಆದೇಶ #{referenceNumber}",
      "order.details.summary.orderAmount": "ಆದೇಶದ ಮೊತ್ತ",
      "logistics.config.orderBreakingMethod": "ಆದೇಶ ಮುರಿಯುವ ವಿಧಾನ",
      "menu.item.order-config": "ಆದೇಶ ಸಂರಚನೆ",
      "customer.details.call.option.orderConfirmation": "ಆದೇಶದ ಧೃಡೀಕರಣ",
      "order.metaData.heading": "ಕಸ್ಟಮ್ ಫೀಲ್ಡ್‌ಗಳನ್ನು ಆರ್ಡರ್ ಮಾಡಿ",
      "customer.details.call.option.orderEdit": "ಆದೇಶ ಸಂಪಾದನೆ",
      "dispute.orderItem": "ಆರ್ಡರ್ ಐಟಂ",
      "order.log.orderItemId": "ಆರ್ಡರ್ ಐಟಂ ಐಡಿ",
      "orderprocessing.orderLabelFormat.heading": "ಆರ್ಡರ್ ಲೇಬಲ್ ಫಾರ್ಮ್ಯಾಟ್",
      "orderprocessing.orderLabelPrinting.heading": "ಆರ್ಡರ್ ಲೇಬಲ್ ಮುದ್ರಣ",
      "offer.max.redemption": "ಆದೇಶ ಮಟ್ಟದ ವಿಮೋಚನೆ",
      "order.action.order.logs.for": "ಇದಕ್ಕಾಗಿ ಲಾಗ್‌ಗಳನ್ನು ಆದೇಶಿಸಿ",
      "order.table.header.referenceNumber": "ಆದೇಶ ಸಂಖ್ಯೆ.",
      "notifications.order.title": "ಆದೇಶ ಅಧಿಸೂಚನೆಗಳು",
      "trips.filter.byOrderNumber.heading": "ಆದೇಶ ಸಂಖ್ಯೆ",
      "order.form.save.success.title": "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನೀಡಲಾಗಿದೆ!",
      "orderprocessing.config.heading": "ಆರ್ಡರ್ ಪ್ರಕ್ರಿಯೆ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
      "order.log.return": "ಆರ್ಡರ್ ರಿಟರ್ನ್",
      "order.table.header.expectedTime": "ಆರ್ಡರ್ ಸ್ಲಾಟ್",
      "order.gridItem.status.title": "ಆದೇಶದ ಸ್ಥಿತಿ",
      "trips.details.orderList.orderTime": "ಆರ್ಡರ್ ಸಮಯ",
      "offer.ordertype.label": "ಆದೇಶ ಪ್ರಕಾರ",
      "order.config.orderTypes": "ಆದೇಶ ವಿಧಗಳು",
      "order.log.leftAtDoorStep": "ಆದೇಶವನ್ನು ಮನೆ ಬಾಗಿಲಿಗೆ ಬಿಡಲಾಗಿದೆ",
      "menu.item.orders": "ಆದೇಶಗಳು",
      "menu.item.orders-analytics": "ಆದೇಶಗಳ ವಿಶ್ಲೇಷಣೆ",
      "tripPlanner.unassignedOrders.title": "ಪ್ರಯಾಣಕ್ಕೆ ಆದೇಶಗಳು ಸಿದ್ಧವಾಗಿಲ್ಲ",
      "operations.dashboard.orderSlot.heading": "ಇಂದು ಆದೇಶಗಳು",
      "login.organisation.heading": "ಸಂಸ್ಥೆ",
      "order.details.itemsTable.header.quantity": "ಮೂಲ ಪ್ರಮಾಣ",
      "customer.details.call.option.other": "ಇತರೆ",
      "product.form.other.details.heading": "ಇತರೆ ವಿವರಗಳು",
      "customer.callWidget.form.otherReason.heading": "ಇತರ ಕಾರಣ",
      "calls.filters.tag.option.others": "ಇತರೆ",
      "calls.filters.type.option.outbound": "ಹೊರಹೋಗುವ",
      "themes.searchable.label": "ಫಾರ್ ಲೇಔಟ್ ಅನ್ನು ಅತಿಕ್ರಮಿಸಿ",
      "order.action.packed": "ಪ್ಯಾಕ್ ಆದೇಶ",
      "order.details.packages.multi-text.label": "ಪ್ಯಾಕೇಜುಗಳು",
      "order.status.packed": "ಕಟ್ಟಿ ಇಡುವುದು",
      "order.details.edit-packed.heading": "ಪ್ಯಾಕ್ ಮಾಡಿದ ಆರ್ಡರ್ ಎಡಿಟಿಂಗ್",
      "order.status.packing": "ಪ್ಯಾಕಿಂಗ್",
      "order.details.packing.heading": "ಪ್ಯಾಕಿಂಗ್ ವಿವರಗಳು",
      "websitePage.form.title.heading": "ಪುಟ ಶೀರ್ಷಿಕೆ",
      "banners.select.page.type": "ಪುಟ ಪ್ರಕಾರ",
      "menu.item.pages": "ಪುಟಗಳು",
      "order.payment.paid": "ಪಾವತಿಸಲಾಗಿದೆ",
      "category.form.parentCategory.heading": "ಪೋಷಕ ವರ್ಗ",
      "order.status.partiallypicked": "ಭಾಗಶಃ ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ",
      "login.password.heading": "ಗುಪ್ತಪದ",
      "shifts.reset.success.information":
        "ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರುಹೊಂದಿಸಲಾಗಿದೆ",
      "changePassword.list.heading": "ಪಾಸ್ವರ್ಡ್ ಹೊಂದಿರಬೇಕು",
      "extensions.password-policy.heading": "ಪಾಸ್ವರ್ಡ್ ನೀತಿ",
      "changePassword.save.success.title": "ಪಾಸ್ವರ್ಡ್ ಯಶಸ್ವಿಯಾಗಿ ಬದಲಾಗಿದೆ",
      "customer.save.success.title": "ಪಾಸ್ವರ್ಡ್ ಯಶಸ್ವಿಯಾಗಿ ಬದಲಾಗಿದೆ!",
      "shifts.passwords.dont.match": "ಗುಪ್ತಪದಗಳುತಾಳೆಯಾಗುತ್ತಿಲ್ಲ",
      "user.leave-summary.status.Paternity": "ಪಿತೃತ್ವ",
      "orderDetails.uploadPrescription.patientName": "ರೋಗಿಯ ಹೆಸರು",
      "user.transactions.header.payment": "ಪಾವತಿ",
      "order.log.payment.cancelled": "ಪಾವತಿಯನ್ನು ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
      "order.log.payment.changed": "ಪಾವತಿ ಬದಲಾಗಿದೆ",
      "order.log.payment.completed": "ಪಾವತಿ ಪೂರ್ಣಗೊಂಡಿದೆ",
      "order.action.viewPayments": "ಪಾವತಿ ವಿವರಗಳು",
      "coupon.analytics.paymentDistribution.heading": "ಪಾವತಿ ವಿತರಣೆ",
      "user.payment.fail.title": "ಪಾವತಿ ವಿಫಲವಾಗಿದೆ",
      "settings.online.payment.gateway": "ಪಾವತಿ ಗೇಟ್ವೇ",
      "settings.online.payment.gateway.saved":
        "ಪಾವತಿ ಗೇಟ್‌ವೇ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
      "order.log.payment.initiated": "ಪಾವತಿಯನ್ನು ಆರಂಭಿಸಲಾಗಿದೆ",
      "order.payment.paymentmode": "ಪಾವತಿ ವಿಧಾನ",
      "coupon.form.validOnPayment.heading": "ಪಾವತಿ ಮೋಡ್",
      "order.form.paymentMode.heading": "ಪಾವತಿ ಆಯ್ಕೆ",
      "customer.details.call.option.paymentReminder": "ಪಾವತಿ ಜ್ಞಾಪನೆ",
      "order.filters.paymentStatus": "ಪಾವತಿ ಸ್ಥಿತಿ",
      "user.payment.success.title": "ಪಾವತಿ ಯಶಸ್ವಿಯಾಗಿದೆ",
      "offer.payment.type": "ಪಾವತಿ ವಿಧಾನ",
      "order.status.pending": "ಬಾಕಿ ಇದೆ",
      "order.details.summary.pendingAmount": "ಬಾಕಿ ಮೊತ್ತ",
      "trips.payment.order": "ಆದೇಶಕ್ಕಾಗಿ ಬಾಕಿ ಪಾವತಿಗಳು",
      "menu.item.returns": "ಬಾಕಿ ರಿಟರ್ನ್ಸ್",
      "offer.percentoff": "ಶೇಕಡಾ ಆಫ್",
      "substitutionGroup.form.percentageDeviation.heading": "ಶೇಕಡಾವಾರು ವಿಚಲನ",
      "settings.users.permissionsForm.headingWithoutName": "ಅನುಮತಿಗಳು",
      "settings.users.permissionsForm.heading": "{Name} ಗಾಗಿ ಅನುಮತಿಗಳು",
      "phone.label.text": "ದೂರವಾಣಿ",
      "customer.details.pages.phoneemail": "ಫೋನ್ / ಇಮೇಲ್",
      "notification.save.success.phone": "ಫೋನ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
      "customer.details.phone.added": "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
      "customer.details.phone.deleted":
        "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ",
      "calls.filters.status.option.picked": "ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ",
      "tripPlanner.picker.name": "ಪಿಕ್ಕರ್",
      "pickers.today.title": "ಇಂದು ಪಿಕರ್ಸ್",
      "order.status.picking": "ಪಡೆದ",
      "picking.config.heading": "ಆಯ್ಕೆ ಸಂರಚನೆ",
      "tripPlanner.picker.endTime": "ಕೊಯ್ಲು ಅಂತ್ಯ",
      "menu.item.picking-queue": "ಕ್ಯೂ ಆಯ್ಕೆ",
      "tripPlanner.picker.startTime": "ಆಯ್ಕೆ ಆರಂಭ",
      "offer.ordertype.options.pickup": "ಪಿಕಪ್",
      "dispute.pickup and refund": "ಪಿಕಪ್ ಮತ್ತು ಮರುಪಾವತಿ",
      "dispute.pickup and replace": "ಪಿಕಪ್ ಮತ್ತು ಬದಲಿಸಿ",
      "order.details.location.pickup.pending.title": "ಪಿಕಪ್ ವಿವರಗಳು",
      "order.form.pickupLocation.heading": "ಪಿಕಪ್ ಸ್ಥಳ",
      "trips.pickuppending": "ಬಾಕಿ ಇರುವ ವಸ್ತುಗಳನ್ನು ಪಿಕಪ್ ಮಾಡಿ",
      "order.form.customer.pincode.heading": "ಪಿನ್‌ಕೋಡ್",
      "category.helpItems.poc.title": "ಮಿತಿಮೀರಿದ ವರ್ಗೀಕರಣದ ಅಪಾಯಗಳು",
      "order.form.add.heading": "ಹೊಸ ಆದೇಶವನ್ನು ಇರಿಸಿ",
      "order.form.submit.text": "ಸ್ಥಳ ಆದೇಶ",
      "tripPlanner.orderPlacedTime": "ನಲ್ಲಿ ಇರಿಸಲಾಗಿದೆ",
      "order.log.placedfrom": "ನಿಂದ ಇರಿಸಲಾಗಿದೆ",
      "order.filters.placedOn": "ಮೇಲೆ",
      "order.table.header.placedTime": "ಇರಿಸಿದ ಸಮಯ",
      "input.invalidFormatMessage": "ದಯವಿಟ್ಟು ಫಾರ್ಮ್ಯಾಟ್ ಪರಿಶೀಲಿಸಿ",
      "input.rangeUnderflow": "ದಯವಿಟ್ಟು ದೊಡ್ಡ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
      "input.rangeOverflow": "ದಯವಿಟ್ಟು ಒಂದು ಸಣ್ಣ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
      "input.invalidEmailFormat": "ದಯವಿಟ್ಟು ಸರಿಯಾದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
      "input.invalidPhoneFormat": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "input.invalidUsernameFormat":
        "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಬಳಕೆದಾರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "dualImageBanner.InputLinkPlaceholder":
        "ದಯವಿಟ್ಟು ಲಿಂಕ್ ಅನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "dualImageBanner.InputSubtitlePlaceholder":
        "ದಯವಿಟ್ಟು ಉಪ ಶೀರ್ಷಿಕೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "dualImageGrid.enterSubtitle": "ದಯವಿಟ್ಟು ಇಲ್ಲಿ ಉಪಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
      "websitePage.form.title.placeholder":
        "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪುಟದ ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
      "dualImageGrid.enterTitle": "ದಯವಿಟ್ಟು ಶೀರ್ಷಿಕೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      "customer.callWidget.form.reason.placeholder":
        "ದಯವಿಟ್ಟು ಒಂದು ಆಯ್ಕೆಯನ್ನು ಆರಿಸಿ",
      "operations.store.form.location.requiredMessage":
        "ದಯವಿಟ್ಟು ಅಂಗಡಿಯ ಸ್ಥಳವನ್ನು ಹೊಂದಿಸಿ",
      "input.isUploading": "ಸರ್ವರ್‌ಗೆ ಫೈಲ್ ಕಳುಹಿಸುವಾಗ ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ",
      "campaign.form.time.pm": "PM",
      "deliveryArea.filters.configType.label.polygon": "ಪಾಲಿಗಾನ್",
      "position.text.label": "ಸ್ಥಾನ",
      "marketing.blog.form.title.heading": "ಪೋಸ್ಟ್ ಶೀರ್ಷಿಕೆ",
      "order.details.preorder": "ಮುಂಚಿತವಾಗಿ ಆದೇಶಿಸಲಾಗಿದೆ",
      "order.details.preferredDate": "ಆದ್ಯತೆಯ ದಿನಾಂಕ",
      "trips.details.orderList.preferredSlot": "ಆದ್ಯತೆಯ ಸ್ಲಾಟ್",
      "order.filters.preorder": "ಮುಂಗಡ ಆದೇಶ",
      "product.form.handlingDays.label": "ನಿರ್ವಹಿಸುವ ದಿನಗಳನ್ನು ಪೂರ್ವ-ಆದೇಶಿಸಿ",
      "order.paymentmode.PREPAID": "ಪ್ರಿಪೇಯ್ಡ್",
      "orderDetails.uploadPrescription.prescription": "ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್",
      "attendance.summary.table.PRESENT": "ಪ್ರಸ್ತುತ",
      "order.details.removeItem.message":
        "ಈ ಐಟಂ ಅನ್ನು ಆದೇಶದಿಂದ ತೆಗೆದುಹಾಕಲು ದೃ confirmೀಕರಿಸಿ ಒತ್ತಿರಿ",
      "campaign.form.preview.heading": "ಮುನ್ನೋಟ",
      "product.form.variant.price.heading": "ಬೆಲೆ",
      "product.form.variant.price.header": "ಬೆಲೆ ನಿಗದಿ",
      "product.header.primaryCategory": "ಪ್ರಾಥಮಿಕ ವರ್ಗ",
      "category.helpItems.ppo.title": "ಉತ್ಪನ್ನ ವರ್ಗೀಕರಣದ ತತ್ವಗಳು",
      "order.action.printInvoice": "ಮುದ್ರಿತ ಸರಕುಪಟ್ಟಿ",
      "analytics.report.processing": "ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗುತ್ತಿದೆ",
      "user.accountBalance.rechargeButton.processing":
        "ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗುತ್ತಿದೆ ...",
      "tag.linkToProduct.product.heading": "ಉತ್ಪನ್ನ",
      "productConfig.heading": "ಉತ್ಪನ್ನ ಸಂರಚನೆ",
      "category.header.productCount": "ಉತ್ಪನ್ನ ಎಣಿಕೆ",
      "product.form.details.header": "ಉತ್ಪನ್ನ ವಿವರಗಳು",
      "dispute.name": "ಉತ್ಪನ್ನದ ಹೆಸರು",
      "banners.pageType.productTag": "ಉತ್ಪನ್ನ_ಟ್ಯಾಗ್",
      "menu.item.products": "ಉತ್ಪನ್ನಗಳು",
      "themes.product": "ಉತ್ಪನ್ನಗಳು ಹೊಂದಿರಬೇಕು",
      "coupon.promocode.label": "ಪ್ರೋಮೊ ಕೋಡ್",
      "offer.dialog.notification.message":
        "ಪ್ರೋಮೋಕೋಡ್‌ಗಳನ್ನು ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸಕ್ಕೆ ಕಳುಹಿಸಲಾಗಿದೆ",
      "cashback.reason.option.promotional": "ಪ್ರಚಾರದ",
      "campaign.analytics.emailTab.table.header.promotional": "ಪ್ರಚಾರದ",
      "onboarding.basicInfo.heading": "ಅಂಗಡಿ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಿ",
      "marketing.blog.form.submitText": "ಪ್ರಕಟಿಸಿ",
      "marketing.table.heading.publishTime": "ನಲ್ಲಿ ಪ್ರಕಟಿಸಲಾಗಿದೆ",
      "customer.details.order.purchase.history": "ಖರೀದಿ ಇತಿಹಾಸ",
      "customer.details.pages.basicinfo.purchasesworth": "ಮೌಲ್ಯದ ಖರೀದಿ",
      "order.details.itemsTable.header.quantity.abbreviated": "ಕ್ಯೂಟಿ",
      "orderprocessing.qualityChecking.heading": "ಗುಣಮಟ್ಟ ಪರಿಶೀಲನೆ",
      "product.form.variant.stock.quantity.heading": "ಪ್ರಮಾಣ",
      "product.form.variant.rack.heading": "ಚರಣಿಗೆ",
      "rackmanagement.heading": "ರ್ಯಾಕ್ ನಿರ್ವಹಣೆ",
      "menu.item.rack-management": "ರ್ಯಾಕ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್",
      "rackmanagement.empty.message": "ಚರಣಿಗೆಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ",
      "deliveryArea.filters.configType.label.radial": "ರೇಡಿಯಲ್",
      "settings.users.permissionsForm.header.read": "ಓದಿ",
      "calls.table.heading.reason": "ಕಾರಣ",
      "analytics.report.recent": "ಇತ್ತೀಚಿನ ವರದಿಗಳು",
      "user.accountBalance.rechargeButton.text": "ರೀಚಾರ್ಜ್",
      "calls.table.heading.recording": "ರೆಕಾರ್ಡಿಂಗ್",
      "order.logs.orderRef": "ಉಲ್ಲೇಖ",
      "trips.details.orderList.referenceNumber": "ಉಲ್ಲೇಖ ಸಂಖ್ಯೆ",
      "dispute.referenceNumber": "ಉಲ್ಲೇಖ ಸಂಖ್ಯೆ",
      "order.payment.refund": "ಮರುಪಾವತಿ",
      "order.details.summary.refundAmount": "ಮರುಪಾವತಿ ಮೊತ್ತ",
      "order.log.refund.initiated": "ಮರುಪಾವತಿಯನ್ನು ಆರಂಭಿಸಲಾಗಿದೆ",
      "order.details.payment.ref": "ಮರುಪಾವತಿ ಉಲ್ಲೇಖ",
      "onboarding.basicInfo.register.text": "ನೋಂದಣಿ",
      "order.register.payments": "ಪಾವತಿಗಳನ್ನು ನೋಂದಾಯಿಸಿ",
      "order.register.payments.for": "ಪಾವತಿಗಳನ್ನು ನೋಂದಾಯಿಸಿ",
      "vehiclePlanner.addShift.form.regular.label": "ನಿಯಮಿತ ವಾಹನದ ಎಣಿಕೆ",
      "dispute.reject": "ತಿರಸ್ಕರಿಸಿ",
      "dispute.rejected": "ತಿರಸ್ಕರಿಸಿದ",
      "login.remember": "ನನ್ನನ್ನು ನೆನಪಿನಲ್ಲಿ ಇಡು",
      "tag.linkToProduct.product.action.remove": "ತೆಗೆಯಿರಿ",
      "settings.online.payment.removebankaccount":
        "ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ತೆಗೆದುಹಾಕಿ",
      "customer.address.delete.prompt.title": "ಈ ವಿಳಾಸವನ್ನು ತೆಗೆದುಹಾಕುವುದೇ?",
      "order.details.removeItem.title": "ಈ ಐಟಂ ತೆಗೆದುಹಾಕುವುದೇ?",
      "customer.details.call.option.retention": "ಧಾರಣ",
      "order.helpItems.rcm.title": "ಗ್ರಾಹಕ ಮಾರ್ಕೆಟಿಂಗ್ ಅನ್ನು ಪುನರಾವರ್ತಿಸಿ",
      "vehiclePlanner.replaceVehicle.popup.heading": "ವಾಹನವನ್ನು ಬದಲಾಯಿಸಿ",
      "menu.item.reports": "ವರದಿಗಳು",
      "customer.details.call.option.rescheduling": "ಮರು ವೇಳಾಪಟ್ಟಿ",
      "resend.verification.link": "ಪರಿಶೀಲನೆ ಲಿಂಕ್ ಅನ್ನು ಮತ್ತೆ ಕಳುಹಿಸಿ",
      "shifts.resetpassword": "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ",
      "shifts.reset.title": "ಗೆ ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ",
      "orderprocessing.config.form.resetToDefault": "ಡೀಫಾಲ್ಟ್ಗೆ ಮರುಹೊಂದಿಸಿ",
      "dispute.resolution": "ನಿರ್ಣಯ",
      "customer.callWidget.form.reason.retention": "ಧಾರಣ",
      "tag.linkToProduct.product.action.retry": "ಮರುಪ್ರಯತ್ನಿಸಿ",
      "order.action.return": "ಹಿಂತಿರುಗಿಸುವ ಆದೇಶ",
      "return.file.heading": "ವಾಪಸಾತಿ ಆದೇಶ",
      "return.retunquantity": "ರಿಟರ್ನ್ ಪ್ರಮಾಣ",
      "return.reason.header": "ಹಿಂತಿರುಗುವ ಕಾರಣ",
      "order.status.returned": "ಹಿಂತಿರುಗಿಸಲಾಗಿದೆ",
      "return.heading": "ರಿಟರ್ನ್ಸ್",
      "return.heading.for": "ಗೆ ಹಿಂತಿರುಗುತ್ತದೆ",
      "return.view.heading": "ಆದೇಶಕ್ಕಾಗಿ ಹಿಂತಿರುಗಿಸುತ್ತದೆ",
      "settings.users.action.revoke": "ಆಹ್ವಾನವನ್ನು ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ",
      "menu.item.roles": "ಪಾತ್ರಗಳು",
      "rule.for": "ಇದಕ್ಕಾಗಿ ನಿಯಮ",
      "customer.helpItems.rpo.title": "ಪ್ರಚಾರದ ಕೊಡುಗೆಯನ್ನು ರನ್ ಮಾಡಿ",
      "campaign.table.heading.runDate": "ರನ್ ದಿನಾಂಕ",
      "trips.filter.byRunnerExecutive.heading": "ರನ್ನರ್ ಕಾರ್ಯನಿರ್ವಾಹಕ",
      "tripPlanner.runner.executive": "ರನ್ನರ್ ಕಾರ್ಯನಿರ್ವಾಹಕರು",
      "trips.details.orderList.serialNumber": "ಎಸ್.ಎನ್",
      "operations.dashboard.sales": "ಮಾರಾಟ",
      "operations.dashboard.lastWeek": "ಕಳೆದ ವಾರ ಅದೇ ದಿನ",
      Saturday: "ಶನಿ",
      "save.text": "ಉಳಿಸಿ",
      "banners.success.dialog.message": "ಉಳಿಸಲಾಗಿದೆ",
      "banners.editForm.radio.label": "ವ್ಯಾಪ್ತಿ",
      "themes.scroller": "ಸ್ಕ್ರೋಲರ್",
      "search.label.text": "ಹುಡುಕಿ Kannada",
      "order.details.productSearch.placeholder": "ಸೇರಿಸಲು ಉತ್ಪನ್ನವನ್ನು ಹುಡುಕಿ",
      "product.filters.brand.placeholder": "ಬ್ರಾಂಡ್ ಮೂಲಕ ಹುಡುಕಿ",
      "product.filters.category.placeholder": "ವರ್ಗದ ಪ್ರಕಾರ ಹುಡುಕಿ",
      "offer.filter.clientId.placeholder": "ಕ್ಲೈಂಟ್ ಐಡಿ ಮೂಲಕ ಹುಡುಕಿ",
      "coupon.filters.couponCode.placeholder": "ಕೂಪನ್ ಕೋಡ್ ಮೂಲಕ ಹುಡುಕಿ",
      "trips.filter.byDeliveryAgent.placeholder": "ವಿತರಣಾ ಏಜೆಂಟ್ ಮೂಲಕ ಹುಡುಕಿ",
      "customer.searchByEmail": "ಇಮೇಲ್ ಮೂಲಕ ಹುಡುಕಿ",
      "product.filters.clientId.placeholder": "ಐಡಿ ಮೂಲಕ ಹುಡುಕಿ",
      "search.by.name.text": "ಹೆಸರಿನಿಂದ ಹುಡುಕಿ",
      "vehicles.filters.byNumber.placeholder": "ಸಂಖ್ಯೆಯ ಮೂಲಕ ಹುಡುಕಿ",
      "trips.filter.byOrderNumber.placeholder": "ಆದೇಶ ಸಂಖ್ಯೆಯ ಮೂಲಕ ಹುಡುಕಿ",
      "customer.searchByPhone": "ಫೋನ್ ಮೂಲಕ ಹುಡುಕಿ",
      "trips.filter.byRunnerExecutive.placeholder":
        "ರನ್ನರ್ ಕಾರ್ಯನಿರ್ವಾಹಕರಿಂದ ಹುಡುಕಿ",
      "product.filters.tag.placeholder": "ಟ್ಯಾಗ್ ಮೂಲಕ ಹುಡುಕಿ",
      "trips.filter.byVehicleNumber.placeholder": "ವಾಹನದ ಸಂಖ್ಯೆಯ ಮೂಲಕ ಹುಡುಕಿ",
      "vehicles.filters.byVendor.placeholder": "ಮಾರಾಟಗಾರರಿಂದ ಹುಡುಕಿ",
      "order.helpItems.seo.title": "ಸರ್ಚ್ ಎಂಜಿನ್ ಆಪ್ಟಿಮೈಸೇಶನ್",
      "operations.store.form.location.placeholder": "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಹುಡುಕಿ",
      "search-configuration.search.header.name": "ಕೀವರ್ಡ್‌ಗಳನ್ನು ಹುಡುಕಿ",
      "radial.form.searchLocation.placeholder": "ನಕ್ಷೆಯಲ್ಲಿ ಸ್ಥಳವನ್ನು ಹುಡುಕಿ",
      "menu.item.search-configuration": "ಹುಡುಕಾಟ ಅತಿಕ್ರಮಿಸುತ್ತದೆ",
      "order.form.productSearch.heading":
        "ಉತ್ಪನ್ನವನ್ನು ಹುಡುಕಿ/ಬಾರ್‌ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
      "customer-service-tags.form.otherdetails.placeholder":
        "ಸೇರಿಸಲು ಟ್ಯಾಗ್‌ಗಳನ್ನು ಹುಡುಕಿ",
      "banners.select.search.term": "ಹುಡುಕಾಟ ಪದ",
      "product.form.secondarycategory.heading": "ದ್ವಿತೀಯ ವರ್ಗಗಳು",
      "themes.seeAll": "ಎಲ್ಲಾ ಬಟನ್ ನೋಡಿ",
      "category.action.seeProducts": "ಉತ್ಪನ್ನಗಳನ್ನು ನೋಡಿ",
      "product.form.select": "ಆಯ್ಕೆ ಮಾಡಿ",
      "radial.selectLocation.message": "ಸ್ಥಳವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "order.form.slots.error.message": "ಸ್ಲಾಟ್ ಆಯ್ಕೆಮಾಡಿ",
      "onboarding.industry.sencondaryHeading": "ಕೆಳಗಿನ ಉದ್ಯಮವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "store.accounts.placeholder": "ಆಪ್ ಸ್ಟೋರ್ ಆಯ್ಕೆಮಾಡಿ",
      "offer.brand.placeholder": "ಬ್ರಾಂಡ್ ಆಯ್ಕೆಮಾಡಿ",
      "offer.brands.placeholder": "ಬ್ರಾಂಡ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "offer.categories.placeholder": "ವರ್ಗಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "offer.category.placeholder": "ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "entityMetaData.country.placeholder": "ದೇಶಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "cutoff.time.placeholder": "ಕಟ್ಆಫ್ ಸಮಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "order.form.date.heading": "ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "analytics.report.dateRange": "ದಿನಾಂಕ ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "coupon.analytics.selectDates.heading": "ದಿನಾಂಕಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "day.placeholder": "ದಿನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "order.form.deliveryArea.heading": "ವಿತರಣಾ ವಿಳಾಸವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "shifts.select.designation": "ಪದನಾಮವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "choose.theme.device.placeholder": "ಸಾಧನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "offer.discounttype.placeholder": "ರಿಯಾಯಿತಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "themes.layout.timeline.endTime.placeholder": "ಅಂತಿಮ ಸಮಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "menu.selectLanguage.heading": "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "extensions.multiLingual.placeholder": "ಭಾಷೆ (ಗಳನ್ನು) ಆಯ್ಕೆ ಮಾಡಿ",
      "offer.type.placeholder": "ಕೊಡುಗೆ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "logistics.config.resees.placeholder": "ಒಂದು ಆಯ್ಕೆಯನ್ನು ಆರಿಸಿ",
      "order.filters.slot.placeholder": "ಆರ್ಡರ್ ಸ್ಲಾಟ್ ಆಯ್ಕೆಮಾಡಿ",
      "offer.ordertype.placeholder": "ಆದೇಶದ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "banners.select.page.type.placeholder": "ಪುಟ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "category.form.parentCategory.placeholder": "ಪೋಷಕ ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "order.filters.payment.placeholder": "ಪಾವತಿ ಮೋಡ್ ಆಯ್ಕೆಮಾಡಿ",
      "order.filters.paymentStatus.placeholder": "ಪಾವತಿ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "offer.product.placeholder": "ಉತ್ಪನ್ನಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "communication.service.provider.placeholder": "ಒದಗಿಸುವವರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "dispute.reason.placeholder": "ಕಾರಣವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "dispute.resolution.placeholder": "ರೆಸಲ್ಯೂಶನ್ ಆಯ್ಕೆ ಮಾಡಿ",
      "order.form.slot.heading": "ಸ್ಲಾಟ್ ಆಯ್ಕೆಮಾಡಿ",
      "socialMedia.accounts.placeholder": "ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "themes.sortinglogic": "ವಿಂಗಡಿಸುವ ತರ್ಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "themes.layout.timeline.startTime.placeholder":
        "ಆರಂಭದ ಸಮಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "coupon.form.stores.placeholder": "ಅಂಗಡಿಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "banners.languages.placeholder": "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "logistics.config.orderBreakingMethod.placeholder":
        "ಆರ್ಡರ್ ಬ್ರೇಕಿಂಗ್ ವಿಧಾನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "banners.page.type.placeholder": "ಪುಟದ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "return.reasons": "ಕಾರಣವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "return.move.placeholder": "ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "banners.stores.placeholder": "ಅಂಗಡಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "logistics.config.tripGeneration.placeholder":
        "ಟ್ರಿಪ್ ಜನರೇಷನ್ ವಿಧಾನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "vehiclePlanner.replaceVehicle.form.replaced.placeHolder":
        "ವಾಹನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "choose.theme.placeholder": "ಥೀಮ್ ಆಯ್ಕೆ ಮಾಡಿ",
      "offer.time.placeholder": "ಸಮಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "product.selectType": "ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "vehiclePlanner.checkin.form.select.plceholder": "ವಾಹನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "return.wastagereason.placeholder": "ವ್ಯರ್ಥ ಕಾರಣವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "shifts.form.weekly.off.placeholder": "ಸಾಪ್ತಾಹಿಕ ಆಫ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "zones.placeholder": "ವಲಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      "offer.selected.stores": "ಆಯ್ದ ಮಳಿಗೆಗಳು",
      "operations.store.form.hasSelfCheckout.label": "ಸ್ವಯಂ ಚೆಕ್ಔಟ್",
      "customer.details.mail.submitText.send": "ಕಳುಹಿಸು",
      "customer.details.sendEmail": "ಇಮೇಲ್ ಕಳುಹಿಸಿ",
      "customer.emailWidget.form.title": "ಗೆ ಇಮೇಲ್ ಕಳುಹಿಸಿ",
      "customer.details.sendMessage": "ಸಂದೇಶ ಕಳುಹಿಸಿ",
      "customer.smsWidget.form.title": "ಗೆ sms ಕಳುಹಿಸಿ",
      "menu.item.seo": "ಎಸ್‌ಇಒ",
      "category.helpItems.seo.title": "ಉತ್ಪನ್ನ ವರ್ಗೀಕರಣದ ಎಸ್‌ಇಒ ಪರಿಣಾಮ",
      "tripPlanner.orderSeq": "ಅನುಕ್ರಮ",
      "served.from.store.text": "ಅಂಗಡಿಯಿಂದ ಬಡಿಸಲಾಗುತ್ತದೆ",
      "communication.service.provider": "ಸೇವೆ ಒದಗಿಸುವವರು",
      "customer.contact.details.set.default": "ಪೂರ್ವನಿಯೋಜಿತವಾಗಿಡು",
      "rackManagement.save.success.title":
        "ಸೆಟ್ಟಿಂಗ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
      "menu.item.settings": "ಸಂಯೋಜನೆಗಳು",
      "basicInfo.save.success.title": "ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
      "settings.online.payment.succesfully.saved":
        "ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
      "return.settledQuantity": "ನಿಗದಿತ ಪ್ರಮಾಣ",
      "product.form.variant.shelf.heading": "ಶೆಲ್ಫ್",
      "shifts.form.endTime": "ಶಿಫ್ಟ್ ಅಂತ್ಯ ಸಮಯ",
      "shifts.form.startTime": "ಶಿಫ್ಟ್ ಆರಂಭ ಸಮಯ",
      "order.details.summary.shippingCharge": "ಶಿಪ್ಪಿಂಗ್ ಶುಲ್ಕ",
      "order.log.shipping.charges": "ಶಿಪ್ಪಿಂಗ್ ಶುಲ್ಕಗಳು",
      "order.details.summary.shippingDiscount": "ಶಿಪ್ಪಿಂಗ್ ರಿಯಾಯಿತಿ",
      "mobileApps.shortDescription.heading": "ಸಣ್ಣ ವಿವರಣೆ",
      "logistics.config.shouldCaptureOdometer":
        "RE ಓಡೋಮೀಟರ್ ಚಿತ್ರವನ್ನು ಸೆರೆಹಿಡಿಯಬೇಕೇ?",
      "themes.cc.config.image": "ಕವರ್ ಇಮೇಜ್ ಆಗಿ ತೋರಿಸಿ",
      "trips.action.vehicleOdometer": "ಓಡೋಮೀಟರ್ ಚಿತ್ರವನ್ನು ತೋರಿಸಿ",
      "themes.cc.pdtCount.inline": "ಉತ್ಪನ್ನಗಳ ಸಂಖ್ಯೆಯನ್ನು ತೋರಿಸಿ",
      "themes.cc.subCategories": "ಉಪವರ್ಗಗಳ ಸಂಗ್ರಹವನ್ನು ತೋರಿಸಿ",
      "themes.cc.showSubCategory.inline": "ಉಪವರ್ಗದ ಲಿಂಕ್‌ಗಳನ್ನು ತೋರಿಸಿ",
      "pagination.text": "ತೋರಿಸಲಾಗುತ್ತಿದೆ",
      "user.leave-summary.status.Sick": "ಅನಾರೋಗ್ಯ",
      "signup.oldUser.signinText": "ಸೈನ್ ಇನ್ ಮಾಡಿ",
      "signup.buttonText": "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
      "product.add.single.text": "ಏಕ ಉತ್ಪನ್ನ",
      "product.search.and.add.text": "ಹುಡುಕಿ ಮತ್ತು ಸೇರಿಸಿ",
      "extensions.tawk.site.id": "ಸೈಟ್ ಐಡಿ",
      "operations.dashboard.slot": "ಸ್ಲಾಟ್",
      "order.log.slot.changed": "ಸ್ಲಾಟ್ ಬದಲಾಗಿದೆ",
      "order.details.summary.slotCharge": "ಸ್ಲಾಟ್ ಚಾರ್ಜ್",
      "menu.item.slot-charges": "ಸ್ಲಾಟ್ ಶುಲ್ಕಗಳು",
      "order.details.summary.slotDiscount": "ಸ್ಲಾಟ್ ರಿಯಾಯಿತಿ",
      "tripPlanner.slot.endTime": "ಸ್ಲಾಟ್ ಅಂತ್ಯ",
      "tripPlanner.slots.startTime": "ಸ್ಲಾಟ್ ಆರಂಭ",
      "menu.item.slots": "ಸ್ಲಾಟ್‌ಗಳು",
      "extensions.slot.heading": "ಸ್ಲಾಟ್‌ಗಳ ಸಂರಚನೆ",
      "order.packing.smallBags": "ಸಣ್ಣ ಚೀಲಗಳು",
      "order.packing.smallBoxes": "ಸಣ್ಣ ಪೆಟ್ಟಿಗೆಗಳು",
      "campaign.form.sms.heading": "SMS",
      "socialMedia.heading": "ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ",
      "unexpected.error.description": "ಏನೋ ಅನಿರೀಕ್ಷಿತ ಸಂಭವಿಸಿದೆ",
      "error.server":
        "ಏನೋ ಅನಿರೀಕ್ಷಿತ ಸಂಭವಿಸಿದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
      "order.details.edit-packed.error": "ಏನೋ ತಪ್ಪಾಗಿದೆ",
      "error.generic": "ಏನೋ ತಪ್ಪಾಗಿದೆ!",
      "unexpected.error.heading": "ಕ್ಷಮಿಸಿ!",
      "offer.field.stackable.label": "ಪೇರಿಸಬಹುದಾದ",
      "tripPlanner.start": "ಆರಂಭ",
      "coupon.form.startDate.placeholder": "ಪ್ರಾರಂಭ ದಿನಾಂಕ",
      "calls.table.heading.startTime": "ಆರಂಭವಾಗುವ",
      "slots.start.end.mismatch": "ಆರಂಭದ ಸಮಯವು ಕೊನೆಯ ಸಮಯಕ್ಕಿಂತ ಕಡಿಮೆ ಇರಬೇಕು",
      "trip.details.startedAt": "ನಲ್ಲಿ ಆರಂಭವಾಯಿತು",
      "slots.startsAt": "ನಲ್ಲಿ ಆರಂಭವಾಗುತ್ತದೆ",
      "leaves.team.startson": "ಪ್ರಾರಂಭವಾಗುತ್ತದೆ",
      "settings.online.payment.state": "ರಾಜ್ಯ",
      "websitePage.table.heading": "ಸ್ಥಿರ ಪುಟಗಳು",
      "category.header.status": "ಸ್ಥಿತಿ",
      "order.log.status.changed": "ಸ್ಥಿತಿ ಬದಲಾಗಿದೆ",
      "product.form.variant.stock.header": "ಸ್ಟಾಕ್",
      "menu.item.stock-overrides": "ಸ್ಟಾಕ್ ಅತಿಕ್ರಮಿಸುತ್ತದೆ",
      "calls.filters.tag.option.store": "ಅಂಗಡಿ",
      "tripPlanner.trip.storeLocation.error":
        "ಅಂಗಡಿಯು ಸರಿಯಾದ ಅಕ್ಷಾಂಶ-ರೇಖಾಂಶವನ್ನು ಹೊಂದಿರಬೇಕು.",
      "basicInfo.storename.heading": "ಅಂಗಡಿ ಹೆಸರು",
      "orderprocessing.qrCodeFormat.heading":
        "ಮುದ್ರಿತ ಸ್ಥಳೀಯ ಬಾರ್‌ಕೋಡ್ ಸ್ವರೂಪವನ್ನು ಸಂಗ್ರಹಿಸಿ",
      "return.toStore": "ಸ್ಟೋರ್ ರಿಟರ್ನ್",
      "slots.store.page.title": "ಅಂಗಡಿ ಸ್ಲಾಟ್ ಸಂರಚನೆ",
      "banners.editForm.radio.option.storeSpecific": "ನಿರ್ದಿಷ್ಟ ಅಂಗಡಿ",
      "basicInfo.storeurl.heading": "ಅಂಗಡಿ URL",
      "menu.item.stores": "ಅಂಗಡಿಗಳು",
      "dualImageBanner.subTitle": "ಉಪ ಶೀರ್ಷಿಕೆ",
      "campaign.form.emailSubject.heading": "ವಿಷಯ",
      "submit.text": "ಸಲ್ಲಿಸು",
      "menu.item.substitution-groups": "ಬದಲಿ ಗುಂಪುಗಳು",
      "success.text.title": "ಯಶಸ್ಸು",
      "calls.form.summary.label": "ಸಾರಾಂಶ",
      Sunday: "ಸೂರ್ಯ",
      "basicInfo.storename.support.email": "ಬೆಂಬಲ ಇಮೇಲ್",
      "basicInfo.storename.support.phone": "ಬೆಂಬಲ ಫೋನ್",
      "basicInfo.supportWWW": "ಬೆಂಬಲ WWW",
      "customer.details.call.option.surveyOnceShopped":
        "ಸಮೀಕ್ಷೆ - ಒಮ್ಮೆ ಶಾಪಿಂಗ್",
      "category.icons.table-title": "ಟೇಬಲ್ ವೀಕ್ಷಣೆ",
      "product.filters.tag.heading": "ಟ್ಯಾಗ್",
      "category.form.tagline.heading": "ಟ್ಯಾಗ್ ಲೈನ್",
      "customer-service-tags.form.tag.name.label": "ಟ್ಯಾಗ್ ಹೆಸರು",
      "brand.form.tagline.heading": "ಟ್ಯಾಗ್‌ಲೈನ್",
      "menu.item.tags": "ಟ್ಯಾಗ್‌ಗಳು",
      "themes.tag.show": "ತೋರಿಸಲು ಟ್ಯಾಗ್‌ಗಳು",
      "menu.item.tawk": "Tawk.to ಲೈವ್ ಚಾಟ್",
      "product.form.variant.tax.header": "ತೆರಿಗೆ",
      "order.details.summary.taxAmount": "ತೆರಿಗೆ ಮೊತ್ತ",
      "order.details.summary.taxTitle": "ತೆರಿಗೆ ಸಾರಾಂಶ",
      "leaves.team.title": "ತಂಡ ಹೊರಡುತ್ತದೆ",
      "videoCarousel.textColor.label": "ಪಠ್ಯ ಬಣ್ಣ",
      "videoCarousel.textColor.placeholder": "ವಿವರಣೆಗಾಗಿ ಪಠ್ಯ ಬಣ್ಣ",
      "Thank you for shopping at ":
        "{Name} ನಲ್ಲಿ ಶಾಪಿಂಗ್ ಮಾಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು",
      "settings.online.payment.account.delete": "ಖಾತೆಯನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ",
      "category.delete.confirm": "ವರ್ಗವನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ",
      "leaves.team.approve.leave": "ರಜೆಯನ್ನು ಅನುಮೋದಿಸಲಾಗುವುದು",
      "themes.heading": "ಥೀಮ್‌ಗಳು",
      "operations.pickingQueue.emptyList.message":
        "ಆಯ್ಕೆ ಮಾಡಲು ಪ್ರಸ್ತುತ ಯಾವುದೇ ಆದೇಶಗಳಿಲ್ಲ",
      "operations.store.empty.message":
        "ಪ್ರಸ್ತುತ ಯಾವುದೇ ಮಳಿಗೆಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ",
      "shifts.empty.message": "ಪ್ರಸ್ತುತ ಯಾವುದೇ ಬಳಕೆದಾರರಿಲ್ಲ",
      "marketing.blog.helpItems.message":
        "ಇಲ್ಲಿ ಯಾವುದೇ ಬ್ಲಾಗ್‌ಗಳನ್ನು ತೋರಿಸಲಾಗಿಲ್ಲ",
      "campaign.helpItems.message": "ಯಾವುದೇ ಪ್ರಚಾರಗಳಿಲ್ಲ",
      "stores.empty.stock": "ಯಾವುದೇ ಸಂರಚನೆಗಳು ಇಲ್ಲ",
      "cp.empty": "ಯಾವುದೇ ಸಂರಚನೆಗಳನ್ನು ಹೊಂದಿಸಲಾಗಿಲ್ಲ",
      "coupon.helpItems.message": "ಇಲ್ಲಿ ಯಾವುದೇ ಕೂಪನ್‌ಗಳನ್ನು ತೋರಿಸಲಾಗಿಲ್ಲ",
      "deliveryArea.emptyList.message": "ಯಾವುದೇ ವಿತರಣಾ ಪ್ರದೇಶಗಳಿಲ್ಲ",
      "designations.empty.message": "ಯಾವುದೇ ಪದನಾಮಗಳಿಲ್ಲ",
      "order.details.itemsTable.emptyMessage": "ಈ ಕ್ರಮದಲ್ಲಿ ಯಾವುದೇ ಐಟಂಗಳಿಲ್ಲ.",
      "leaves.emptyMessage": "ಯಾವುದೇ ಎಲೆಗಳು ಲಭ್ಯವಿಲ್ಲ",
      "menu.notificationToday.empty": "ಇಲ್ಲಿ ಯಾವುದೇ ಅಧಿಸೂಚನೆಯನ್ನು ತೋರಿಸಲಾಗಿಲ್ಲ",
      "offer.emptyMessage": "ಯಾವುದೇ ಕೊಡುಗೆಗಳಿಲ್ಲ",
      "tripPlanner.emptyState.helperText":
        "ತಲುಪಿಸಲು ಯಾವುದೇ ಆದೇಶಗಳಿಲ್ಲ {ಎಣಿಕೆ, ಬಹುವಚನ, ಒಂದು {ಈ ಅಂಗಡಿಯಿಂದ} ಇನ್ನೊಂದು {}}",
      "returns.emptyMessage": "ಯಾವುದೇ ರಿಟರ್ನ್ಸ್ ಇಲ್ಲ",
      "extension.details.notfound": "ಈ ವಿಸ್ತರಣೆಗೆ ಯಾವುದೇ ಸೆಟ್ಟಿಂಗ್‌ಗಳಿಲ್ಲ",
      "slots.store.emptyMessage":
        "ಈ ಅಂಗಡಿಗೆ ಯಾವುದೇ ಸ್ಲಾಟ್ ನಿಯಮಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ",
      "slots.emptymessage": "ಯಾವುದೇ ಸ್ಲಾಟ್‌ಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ",
      "slots.blocking.empty.message":
        "ನಿರ್ಬಂಧಿಸಲು ಯಾವುದೇ ಸ್ಲಾಟ್‌ಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ",
      "websitePage.emptyList.message":
        "ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಯಾವುದೇ ಸ್ಥಿರ ಪುಟಗಳಿಲ್ಲ",
      "trips.emptyState.helperText":
        "ಯಾವುದೇ ಪ್ರವಾಸಗಳಿಲ್ಲ {ಎಣಿಕೆ, ಬಹುವಚನ, ಒಂದು {ಈ ಅಂಗಡಿಯಿಂದ} ಇತರೆ {}}",
      "order.details.packed.item.return.message":
        "ಆದೇಶದಲ್ಲಿ ಹಿಂತಿರುಗಿಸಿದ ವಸ್ತುಗಳು ಇವೆ",
      "deleteDialog.information": "ಈ ಕ್ರಿಯೆಯನ್ನು ಹಿಂತಿರುಗಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
      "order.details.cancellation.message":
        "ಈ ಕ್ರಮವು ಈ ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸುತ್ತದೆ",
      "order.details.completion.message":
        "ಈ ಕ್ರಮವು ಈ ಆದೇಶವನ್ನು ಪೂರ್ಣಗೊಳಿಸುತ್ತದೆ",
      "substitutionGroup.dialogs.disable.message":
        "ಈ ಕ್ರಿಯೆಯು ಬದಲಿ ಗುಂಪನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುತ್ತದೆ",
      "websitePage.dialogs.disable.message":
        "ಈ ಕ್ರಿಯೆಯು ಈ ಪುಟವನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುತ್ತದೆ",
      "substitutionGroup.dialogs.enable.message":
        "ಈ ಕ್ರಿಯೆಯು ಬದಲಿ ಗುಂಪನ್ನು ಸಕ್ರಿಯಗೊಳಿಸುತ್ತದೆ",
      "websitePage.dialogs.enable.message":
        "ಈ ಕ್ರಿಯೆಯು ಈ ಪುಟವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸುತ್ತದೆ",
      "order.details.pending.message":
        "ಈ ಕ್ರಿಯೆಯು ಆದೇಶವನ್ನು ಪೆಂಡಿಂಗ್ ಸ್ಥಿತಿಗೆ ಸರಿಸುತ್ತದೆ",
      "customer.email.delete.prompt.message":
        "ಈ ಕ್ರಿಯೆಯು ಈ ಇಮೇಲ್ ಐಡಿಯನ್ನು ತೆಗೆದುಹಾಕುತ್ತದೆ",
      "customer.phone.delete.prompt.message":
        "ಈ ಕ್ರಿಯೆಯು ಈ ಸಂಖ್ಯೆಯನ್ನು ತೆಗೆದುಹಾಕುತ್ತದೆ",
      "order.details.return.message": "ಈ ಕ್ರಮವು ಆದೇಶವನ್ನು ಹಿಂದಿರುಗಿಸುತ್ತದೆ",
      "input.requiredMessage": "ಇದನ್ನು ಖಾಲಿ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
      "notification.email.delete.message": "ಈ ಇಮೇಲ್ ಅನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ",
      "product.form.category.requiredMessage": "ಇದು ಮಾನ್ಯ ವರ್ಗವಲ್ಲ",
      "entityMetaData.this.key.will.be.deleted": "ಈ ಕೀಲಿಯನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ",
      "websitePage.dialog.information": "ಈ ವಿನ್ಯಾಸವನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ",
      "leaves.team.cancel.leave": "ಈ ರಜೆಯನ್ನು ರದ್ದುಗೊಳಿಸಲಾಗುತ್ತದೆ",
      "leaves.team.reject.leave": "ಈ ರಜೆಯನ್ನು ತಿರಸ್ಕರಿಸಲಾಗುತ್ತದೆ",
      "offer.dialog.clone.message":
        "ಈ ಕೊಡುಗೆಯನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಉಳಿದ ವಿಮೋಚನೆಯೊಂದಿಗೆ ಹೊಸ ಕೊಡುಗೆಯನ್ನು ರಚಿಸಲಾಗುತ್ತದೆ",
      "notification.phone.delete.message": "ಈ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ",
      "coupon.analytics.thisWeek.heading": "ಈ ವಾರ",
      "marketing.blog.dialogs.publish.message":
        "ಇದು ಬ್ಲಾಗ್ ಅನ್ನು ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಪ್ರಕಟಿಸುತ್ತದೆ",
      "marketing.blog.dialogs.unpublish.message":
        "ಇದು ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಬ್ಲಾಗ್ ಅನ್ನು ತೆಗೆದುಹಾಕುತ್ತದೆ ಮತ್ತು ಅದನ್ನು ಡ್ರಾಫ್ಟ್‌ಗಳಿಗೆ ಸೇರಿಸುತ್ತದೆ",
      Thursday: "ಥು",
      "campaign.form.time.heading": "ಸಮಯ",
      "designations.header.timing": "ಸಮಯ",
      "designations.header.timingType": "ಸಮಯ ಪ್ರಕಾರ",
      "shifts.header.timings": "ಸಮಯಗಳು",
      "shifts.header.timingsAndAttendance": "ಸಮಯ / ಹಾಜರಾತಿ",
      "title.heading.text": "ಶೀರ್ಷಿಕೆ",
      "calls.table.heading.to": "ಗೆ",
      "offer.excludeText": "ಹೊರಗಿಡಲು",
      "offer.includeText": "ಸೇರಿಸಲು",
      "onboarding.basicInfo.useIt.text": "ಅದನ್ನು ಬಳಸಲು ಅಥವಾ",
      "coupon.analytics.today.heading": "ಇಂದು",
      "order.details.tomorrow": "ನಾಳೆ",
      "operations.dashboard.topProducts.heading": "ಉನ್ನತ ಉತ್ಪನ್ನಗಳು",
      "operations.dashboard.total": "ಒಟ್ಟು",
      "order.details.summary.totalAmount": "ಒಟ್ಟು ಮೊತ್ತ",
      "coupon.analytics.totalDiscount.heading": "ಒಟ್ಟು ಕೂಪನ್ ರಿಯಾಯಿತಿ",
      "customer.details.pages.basicinfo.totalorders": "ಒಟ್ಟು ಆದೇಶಗಳು",
      "pickers.today.total.pickers": "ಒಟ್ಟು ಪಿಕರ್ಸ್",
      "return.totalquantity": "ಒಟ್ಟು ಪರಿಮಾಣ",
      "offer.total.redemption": "ಒಟ್ಟು ವಿಮೋಚನೆ",
      "coupon.analytics.totalPurchases.heading": "ಒಟ್ಟು ಮಾರಾಟ",
      "order.details.summary.totalTax": "ಒಟ್ಟು ತೆರಿಗೆ",
      "trips.details.orderList.transactionId": "ವಹಿವಾಟು ಐಡಿ",
      "category.icons.tree-title": "ಮರದ ನೋಟ",
      "trips.details.heading": "ಪ್ರವಾಸ #{id}",
      "tripPlanner.tripEnd": "ಪ್ರವಾಸ ಅಂತ್ಯ",
      "trip.endOdometer": "ಟ್ರಿಪ್ ಎಂಡ್ ಓಡೋಮೀಟರ್ ಚಿತ್ರ",
      "customer.details.call.option.tripEnquiry": "ಪ್ರವಾಸ ವಿಚಾರಣೆ",
      "customer.callWidget.form.reason.tripEnquiry": "ಪ್ರವಾಸ ವಿಚಾರಣೆಯ ಕರೆ",
      "logistics.config.tripGeneration": "ಪ್ರವಾಸ ಉತ್ಪಾದನೆಯ ವಿಧಾನ",
      "trip.details.tripIsActive": "ಪ್ರವಾಸವು ಸಕ್ರಿಯವಾಗಿದೆ",
      "tripPlanner.tripNo": "ಪ್ರವಾಸ ಸಂಖ್ಯೆ",
      "menu.item.trip-planner": "ಟ್ರಿಪ್ ಪ್ಲಾನರ್",
      "tripPlanner.tripStart": "ಪ್ರವಾಸ ಆರಂಭ",
      "trips.startOdometer": "ಟ್ರಿಪ್ ಸ್ಟಾರ್ಟ್ ಓಡೋಮೀಟರ್ ಚಿತ್ರ",
      "tripPlanner.trip.started": "ಪ್ರವಾಸ ಯಶಸ್ವಿಯಾಗಿ ಆರಂಭವಾಯಿತು",
      "tripPlanner.table.heading": "ಪ್ರವಾಸದ ಸಾರಾಂಶ",
      "menu.item.trips": "ಪ್ರವಾಸಗಳು",
      Tuesday: "ಮಂಗಳವಾರ",
      "media.select.twitter": "ಟ್ವಿಟರ್",
      "media.select.iosAppLink": "ಐಒಎಸ್",
      "media.select.androidAppLink": "ಆಂಡ್ರಾಯ್ಡ್",
      "type.text.label": "ಮಾದರಿ",
      "category.form.description.placeholder": "ವರ್ಗ ವಿವರಣೆಯನ್ನು ಟೈಪ್ ಮಾಡಿ",
      "order.log.type.changed": "ಟೈಪ್ ಬದಲಾಗಿದೆ",
      "marketing.blog.form.content.placeholder": "ಇಲ್ಲಿ ಬರೆಯಿರಿ",
      "offer.main.discount.type": "ರಿಯಾಯಿತಿ ಪ್ರಕಾರ",
      "pincode.form.pincodes.placeholder":
        "ZIP ಕೋಡ್‌ಗಳನ್ನು ಟೈಪ್ ಮಾಡಿ ಮತ್ತು Enter ಒತ್ತಿರಿ",
      "error.Unauthorized": "ಅನಧಿಕೃತ",
      "extensions.uninstall": "ಅಸ್ಥಾಪಿಸು",
      "extensions.uninstallExtension": "ವಿಸ್ತರಣೆಯನ್ನು ಅಸ್ಥಾಪಿಸಿ",
      "extensions.uninstalledSuccessfully": "ಯಶಸ್ವಿಯಾಗಿ ಅಸ್ಥಾಪಿಸಲಾಗಿದೆ",
      "coupon.analytics.uniqueUsers.heading": "ಅನನ್ಯ ಬಳಕೆದಾರರು",
      "product.form.variant.unlimitedAvailable.label": "ಅನಿಯಮಿತ",
      "extensions.deliveryArea.polygon.unnamed": "ಹೆಸರಿಸದ ಪ್ರದೇಶ",
      "extensions.deliveryArea.zone.unnamed": "ಹೆಸರಿಲ್ಲದ ಗುಂಪು",
      "marketing.blog.table.action.unpublish": "ಪ್ರಕಟಿಸದಿರುವುದು",
      "order.log.editedBy": "ನವೀಕರಿಸಲಾಗಿದೆ",
      "customer.updatedAt": "ನವೀಕರಿಸಲಾಗಿದೆ",
      "orderDetails.uploadPrescription.upload": "ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      "category.form.uploadImage.heading": "ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      "fileUpload.importedCsv.heading": "ಈಗ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      "product.form.variant.image.heading": "ಉತ್ಪನ್ನ ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      "basicInfo.uploadFavicon.heading": "ನಿಮ್ಮ ಫೆವಿಕಾನ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      "basicInfo.uploadImage.heading": "ನಿಮ್ಮ ಲೋಗೋ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      "orderDetails.uploadFile": "ಅಪ್ಲೋಡ್ ಮಾಡಿದ ಫೈಲ್",
      "orderDetails.uploadPrescription.uploadedPrescription":
        "ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
      "product.form.imageUpload.uploading": "ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ ...",
      "websitePage.table.url": "URL",
      "websitePage.form.url.placeholder": "ಪುಟದ URL",
      "banners.editForm.link.placeholder":
        "ಕ್ಲಿಕ್ ಮಾಡಿದ ಮೇಲೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಲು URL",
      "coupon.action.usageAnalytic": "ಉಪಯೋಗಗಳ ವಿಶ್ಲೇಷಣೆ",
      "settings.online.payment.use.zoppay": "ನಮ್ಮ ಡೀಫಾಲ್ಟ್ ಗೇಟ್ವೇ ಬಳಸಿ",
      "themes.selectTheme.text": "ಈ ಥೀಮ್ ಬಳಸಿ",
      "campaign.analytics.subHeading": "ಬಳಕೆದಾರರ ನಿಶ್ಚಿತಾರ್ಥ",
      "menu.item.user-hover": "ಬಳಕೆದಾರ ಸುಳಿದಾಡು",
      "customer-service-tags.heading": "ಬಳಕೆದಾರ ಟ್ಯಾಗ್‌ಗಳು",
      "login.username.heading": "ಬಳಕೆದಾರ ಹೆಸರು",
      "coupon.form.validOnUser.heading": "ಬಳಕೆದಾರರು",
      "shifts.empty.submessage": "ಒಮ್ಮೆ ಸೇರಿಸಿದಾಗ ಬಳಕೆದಾರರನ್ನು ನೋಡಬಹುದು",
      "offer.validFrom": "ನಿಂದ ಮಾನ್ಯ",
      "offer.time": "ಸಮಯದಿಂದ ಮಾನ್ಯವಾಗಿದೆ",
      "offer.validTill": "ಇಲ್ಲಿಯವರೆಗೆ ಮಾನ್ಯ",
      "offer.validto.time": "ಸಮಯದವರೆಗೆ ಮಾನ್ಯ",
      "coupon.header.validity": "ಸಿಂಧುತ್ವ",
      "banners.editForm.validity.from": "ನಿಂದ ಮಾನ್ಯತೆ",
      "banners.editForm.validity.to": "ಗೆ ಮಾನ್ಯತೆ",
      "enitityMetaData.values": "ಮೌಲ್ಯಗಳನ್ನು",
      "product.form.variant.name.placeholder": "ಭಿನ್ನ",
      "product.form.variant.name.heading": "ವೈವಿಧ್ಯಮಯ ಹೆಸರು",
      "product.form.variant.heading": "ರೂಪಾಂತರಗಳು",
      "trips.header.vehicle": "ವಾಹನ",
      "trips.filter.byVehicleNumber.heading": "ವಾಹನ ಸಂಖ್ಯೆ",
      "vehiclePlanner.heading": "ವಾಹನ ಯೋಜಕ",
      "menu.item.vehicle-planning": "ವಾಹನ ಯೋಜನೆ",
      "tripPlanner.vehicle.shiftEnd": "ವಾಹನ ಶಿಫ್ಟ್ ಅಂತ್ಯ",
      "tripPlanner.vehicle.shiftStart": "ವಾಹನ ಶಿಫ್ಟ್ ಆರಂಭ",
      "vehiclePlanner.replaceVehicle.form.replaced.label": "ಬದಲಿಸಬೇಕಾದ ವಾಹನ",
      "vehiclePlanner.replaceVehicle.form.replacedBy.label": "ಬದಲಿಸಬೇಕಾದ ವಾಹನ",
      "vehicles.vendor.heading": "ವಾಹನ ಮಾರಾಟಗಾರರು",
      "menu.item.vehicles": "ವಾಹನಗಳು",
      "vehicles.header.vendor": "ಮಾರಾಟಗಾರ",
      "vehicles.vendor.heading.name": "ಮಾರಾಟಗಾರರ ಹೆಸರು",
      "email.verified.text": "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
      "user.payment.loader.text":
        "ಪಾವತಿಯನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ, ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ ...",
      "extensions.version": "ಆವೃತ್ತಿ",
      "videoCarousel.videoUrl.title": "ವೀಡಿಯೊ URL",
      "product.action.view": "ನೋಟ",
      "campaign.action.viewAnalytics": "ವಿಶ್ಲೇಷಣೆಯನ್ನು ವೀಕ್ಷಿಸಿ",
      "category.view": "ವರ್ಗಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
      "trips.action.viewDetails": "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
      "designations.view.employees": "ಉದ್ಯೋಗಿಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
      "order.action.viewInvoice": "ಸರಕುಪಟ್ಟಿ ವೀಕ್ಷಿಸಿ",
      "order.actions.logs": "ದಾಖಲೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
      "trips.details.orderList.view.on.map": "ನಕ್ಷೆಯಲ್ಲಿ ವೀಕ್ಷಿಸಿ",
      "banners.table.view.on.web.url": "ವೆಬ್‌ನಲ್ಲಿ ವೀಕ್ಷಿಸಿ",
      "tag.action.viewOnWebsite": "ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ವೀಕ್ಷಿಸಿ",
      "order.actions.trips": "ಪ್ರವಾಸಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
      "Visit us at": "{Domain} ನಲ್ಲಿ ನಮ್ಮನ್ನು ಭೇಟಿ ಮಾಡಿ",
      "tripPlanner.waiting": "ನಿರೀಕ್ಷಿಸಿ",
      "order.filters.slot.fetchTitle":
        "ನಿರೀಕ್ಷಿಸಿ! ಸ್ಲಾಟ್‌ಗಳನ್ನು ಪಡೆಯಲಾಗುತ್ತಿದೆ",
      "wallet.config.heading": "ವಾಲೆಟ್ ಕಾನ್ಫಿಗರೇಶನ್",
      "offer.dialog.clone.label": "ಎಚ್ಚರಿಕೆ",
      "return.markasWastage": "ವ್ಯರ್ಥ",
      "return.wastagereason": "ವ್ಯರ್ಥ ಕಾರಣ",
      "menu.item.themes": "ಜಾಲತಾಣ",
      "category.helpItems.ppo.description":
        "ಉಪಯುಕ್ತ ವಿವರಣೆಯೊಂದಿಗೆ ವೆಬ್‌ಸೈಟ್‌ಗಳು Google ನಂತಹ ಸರ್ಚ್ ಇಂಜಿನ್‌ಗಳಿಂದ ಉಚಿತ ಸಂದರ್ಶಕರನ್ನು ಪಡೆಯುತ್ತವೆ.",
      Wednesday: "ಬುಧ",
      "operations.dashboard.weeklyAvg": "ವಾರದ ಸರಾಸರಿ",
      "attendance.summary.table.WEEKLY_OFF": "ಸಾಪ್ತಾಹಿಕ ಆಫ್",
      "shifts.header.offdaysAndStores": "ಸಾಪ್ತಾಹಿಕ ಆಫ್ / ಅಂಗಡಿಗಳು",
      "onboarding.industry.heading": "ನಿಮ್ಮ ಉದ್ಯಮ ಯಾವುದು",
      "extensions.whatwillchange": "ಏನು ಬದಲಾಗುತ್ತದೆ?",
      "websitePage.form.content.placeholder":
        "ನೀವು ಇಲ್ಲಿ ಟೈಪ್ ಮಾಡುವದನ್ನು ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಈ ಪುಟದ ವಿಷಯವಾಗಿ ತೋರಿಸಲಾಗುತ್ತದೆ",
      "socialMedia.whatsappSupportPhone": "WhatsApp ಬೆಂಬಲ ಫೋನ್",
      "notifications.customer.add.money":
        "ಗ್ರಾಹಕರು ವಾಲೆಟ್‌ಗೆ ಹಣವನ್ನು ಸೇರಿಸಿದಾಗ",
      "notifications.customer.add.address": "ಗ್ರಾಹಕರು ಹೊಸ ವಿಳಾಸವನ್ನು ಸೇರಿಸಿದಾಗ",
      "notifications.customer.complain": "ಗ್ರಾಹಕರು ದೂರು ನೀಡಿದಾಗ",
      "notification.new.customer": "ಹೊಸ ಗ್ರಾಹಕರು ಸೇರಿದಾಗ",
      "notifications.new.order": "ಹೊಸ ಆದೇಶ ಬಂದಾಗ",
      "notifications.order.cancel": "ಆರ್ಡರ್ ರದ್ದಾದಾಗ",
      "notifications.order.refund": "ಆದೇಶವನ್ನು ಮರುಪಾವತಿಸಿದಾಗ",
      "catalogue.dashboard.helpItems.uI.title":
        "ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವುದು ಏಕೆ ಮುಖ್ಯ?",
      "extensions.tawk.widget.id": "ವಿಜೆಟ್ ಐಡಿ",
      "trip.details.orderList.withthistrip": "ಈ ಪ್ರವಾಸದೊಂದಿಗೆ",
      asapDurationStartText: "ಒಳಗೆ",
      "404.heading": "ಕೆಲಸ ಪ್ರಗತಿಯಲ್ಲಿದೆ",
      "settings.users.permissionsForm.header.write": "ಬರೆಯಿರಿ",
      "marketing.blog.add.text": "ಹೊಸ ಪೋಸ್ಟ್ ಬರೆಯಿರಿ",
      "marketing.table.heading.author": "ಇವರಿಂದ ಬರೆಯಲ್ಪಟ್ಟಿದೆ",
      "logistics.config.yes": "ಹೌದು",
      "rackManagement.rack.delete.confirmText": "ಹೌದು, ಅಳಿಸಿ!",
      "customer.reset.password.confirmText": "ಹೌದು, ಮಾಡಿ!",
      "operations.dashboard.yesterday": "ನಿನ್ನೆ",
      "themes.customize.subheading":
        "ನೀವು {name} ಥೀಮ್ ಅನ್ನು ಕಸ್ಟಮೈಸ್ ಮಾಡುತ್ತಿದ್ದೀರಿ",
      "themes.subheading": "ನೀವು {name} ಥೀಮ್ ಅನ್ನು ಬಳಸುತ್ತಿರುವಿರಿ",
      "settings.online.payment.our.gateway":
        "ನೀವು ನಮ್ಮ ಪಾವತಿ ಗೇಟ್‌ವೇ ಬಳಸುತ್ತಿದ್ದೀರಿ",
      "onboarding.changeLater.text": "ನೀವು ನಂತರ ಇಲ್ಲಿ ಬದಲಾಯಿಸಬಹುದು",
      "order.helpItems.rcm.description":
        "ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ರಿಯಾಯಿತಿ ಕೂಪನ್ ಅನ್ನು ನೀವು ರಚಿಸಬಹುದು ಮತ್ತು ಆನ್‌ಲೈನ್ ಖರೀದಿಯನ್ನು ಉತ್ತೇಜಿಸಬಹುದು.",
      "order.helpItems.nca.description":
        "ನಿಮ್ಮ ಭೌತಿಕ ಅಂಗಡಿಯಲ್ಲಿ ಒಂದು ಪ್ರಮುಖ ಸ್ಥಳದಲ್ಲಿ ನೀವು ಬ್ಯಾನರ್ ಹಾಕಬಹುದು. ಬzz್ ರಚಿಸಲು ಪ್ರಚಾರದ ಕೊಡುಗೆಯನ್ನು ಸೇರಿಸಿ.",
      "error.server.403":
        "ಈ ಪುಟವನ್ನು ವೀಕ್ಷಿಸಲು ನೀವು ಸಾಕಷ್ಟು ಅನುಮತಿಗಳನ್ನು ಹೊಂದಿಲ್ಲ.",
      "user.transactions.emptyList.text":
        "ನೀವು ಇನ್ನೂ ಯಾವುದೇ ವಹಿವಾಟುಗಳನ್ನು ಹೊಂದಿಲ್ಲ",
      "product.helpItems.message":
        "ಗ್ರಾಹಕರು ಖರೀದಿಸಲು ನೀವು ಉತ್ಪನ್ನಗಳ ಕ್ಯಾಟಲಾಗ್ ಅನ್ನು ರಚಿಸಬೇಕು.",
      "rackManagement.rack.delete.message":
        "ನೀವು ಈ ರ್ಯಾಕ್ ಅನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಿ",
      "coupon.disableDialog.information":
        "ನೀವು ಈ ಕೂಪನ್ ಅನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲು ಬಯಸುತ್ತೀರಿ",
      "customer.table.actions.disable.information":
        "ಈ ಗ್ರಾಹಕರನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲು ನೀವು ಬಯಸುವಿರಾ?",
      "coupon.enableDialog.information":
        "ನೀವು ಈ ಕೂಪನ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ಬಯಸುತ್ತೀರಿ",
      "customer.table.actions.enable.information":
        "ಈ ಗ್ರಾಹಕರನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ನೀವು ಬಯಸುವಿರಾ?",
      "order.helpItems.seo.description":
        "ಆಮದು ಮಾಡಿದ ನಂತರ ಇಮೇಲ್ ಮತ್ತು ಎಸ್‌ಎಂಎಸ್ ಬಳಸಿ ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ಗುರಿಯಾಗಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ, ಇದು ನಿಮ್ಮ ಆದಾಯವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ.",
      "product.downloadSuccessMessage":
        "ನಿಮ್ಮ {ಮೌಲ್ಯ} ಡೇಟಾವನ್ನು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ನಿಮಗೆ ಇಮೇಲ್ ಮೂಲಕ ಕಳುಹಿಸಲಾಗುತ್ತದೆ",
      "password.sent.to.mobile":
        "ನಿಮ್ಮ ಖಾತೆಗೆ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಿಮ್ಮ ಮೊಬೈಲ್‌ಗೆ ಕಳುಹಿಸಲಾಗಿದೆ.",
      "marketing.blog.form.title.requiredMessage":
        "ನಿಮ್ಮ ಬ್ಲಾಗ್ ಒಂದು ಶೀರ್ಷಿಕೆಯನ್ನು ಹೊಂದಿರಬೇಕು",
      "brand.helpItems.message": "ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್‌ಗಳನ್ನು ಇಲ್ಲಿ ತೋರಿಸಲಾಗುತ್ತದೆ.",
      "category.helpItems.message": "ನಿಮ್ಮ ವರ್ಗಗಳನ್ನು ಇಲ್ಲಿ ತೋರಿಸಲಾಗುತ್ತದೆ.",
      "email.verified": "ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿದೆ!",
      "user.not.verified.email":
        "ನಿಮ್ಮ ಇಮೇಲ್ ಐಡಿ ಪರಿಶೀಲಿಸಲಾಗಿಲ್ಲ, ನೀವು ಆರ್ಡರ್ ಅಧಿಸೂಚನೆಗಳನ್ನು ಸ್ವೀಕರಿಸದಿರಬಹುದು.",
      "leaves.dialog.information": "ನಿಮ್ಮ ರಜೆ ರದ್ದಾಗುತ್ತದೆ",
      "menu.item.website-link": "ನಿಮ್ಮ ಆನ್ಲೈನ್ ​​ಸ್ಟೋರ್",
      "order.emptyList.message":
        "ನಿಮ್ಮ ಆದೇಶಗಳನ್ನು ಇಲ್ಲಿ ತೋರಿಸಲಾಗುತ್ತದೆ. ಈಗ ಮಾರಾಟ ಆರಂಭಿಸಿ.",
      "websitePage.form.title.requiredMessage":
        "ನಿಮ್ಮ ಪುಟವು ಶೀರ್ಷಿಕೆಯನ್ನು ಹೊಂದಿರಬೇಕು",
      "resetPassword.successMessage":
        "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರುಹೊಂದಿಸಲಾಗಿದೆ",
      "tag.helpItems.message":
        "ನಿಮ್ಮ ಉತ್ಪನ್ನ ಟ್ಯಾಗ್‌ಗಳು ಇಲ್ಲಿ ಕಾಣಿಸಿಕೊಳ್ಳುತ್ತವೆ",
      "customer.entityMetaData.successInformation":
        "ನಿಮ್ಮ ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
      "basicInfo.storename.placeholder": "ನಿಮ್ಮ ಅಂಗಡಿ",
      "pincode.form.pincodes.heading": "ZIP ಸಂಕೇತಗಳು",
      "deliveryArea.filters.configType.label.pincode": "ZIPCODE",
      "extensions.deliveryArea.pincodes.heading": "ಜಿಪ್‌ಕೋಡ್‌ಗಳು",
      "rackManagement.rack.zone.name": "ವಲಯದ ಹೆಸರು",
      "rackManagement.rack.zones": "ವಲಯಗಳು",
      "logistics.config.orderBreakingMethod.zoning": "ವಲಯ",
      "campaign.analytics.zopLinkActivity.heading": "ZopLink ಚಟುವಟಿಕೆ",
      "menu.item.api-integration": "API ಏಕೀಕರಣ",
      "view-website-text": "ಅಂಗಡಿಯನ್ನು ವೀಕ್ಷಿಸಿ",
      "input.invalidVehicleNumber":
        "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ವಾಹನ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      "input.invalidVendorName": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಮಾರಾಟಗಾರರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      "order.courier.select.heading": "ಮೂಲಕ ರವಾನೆ",
      "order.dispatch.label.courier.name": "ಕೊರಿಯರ್ ಹೆಸರು",
      "order.dispatch.label.courier.charges": "ಶುಲ್ಕಗಳು",
      "order.dispatch.label.courier.tracking.id": "ಟ್ರ್ಯಾಕಿಂಗ್ ಐಡಿ",
      "order.dispatch.placeholder.courier.name": "ಉದಾ: ಭಾರತ ಅಂಚೆ",
    },
  },
};

export default lang;
