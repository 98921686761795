import React, { useEffect, useState } from "react";
import { getMessage } from "../../../../lib/translator";
import { Link } from "react-router-dom";
import API from "../../../../lib/api";
import AlertIcon from "./AlertIcon.svg";
import ArrowRight from "./ArrowRight";

import "./style.css";

const ALERT_MESSAGE_LIST = [
  {
    txt: "Products with no image",
    isActive: true,
    name: "noImage",
    linkPath: "withoutImage=true",
  },
  {
    txt: "Products that are out of stock",
    isActive: false,
    name: "outOfStock",
    linkPath: "",
  },
  {
    txt: "Products with no description",
    isActive: true,
    name: "noDescription",
    linkPath: "withoutDescription=true",
  },
];
const ProductAlert = () => {
  const [alertData, setAlertData] = useState({
    noImage: 0,
    outOfStock: 0,
    noDescription: 0,
  });
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const statsApi = new API({ url: "/catalogue-service/stats" });
        const res = await statsApi.get();
        setAlertData({
          noImage: res?.data?.products?.withoutImagesCount || "N/A",
          outOfStock: "N/A",
          noDescription: res?.data?.products?.withoutDescriptionCount || "N/A",
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    }

    fetchData();
  }, []);

  const isAlert = alertData?.noImage > 0 || alertData?.noDescription > 0;
  return (
    <>
      {isAlert && (
        <div className="product-alert-wrp">
          <div className="header-container">
            <div className="left">
              <div className="heading">
                <img src={AlertIcon} alt="alert" />
                {getMessage("product.alert.heading")}
              </div>
            </div>
            <div className="right"></div>
          </div>
          <div className="alerts-list">
            {ALERT_MESSAGE_LIST.map((item) => {
              const { txt, isActive, name, linkPath } = item;
              return (
                <React.Fragment key={name}>
                  {isActive && alertData?.[name] > 0 && (
                    <div className="alert-item">
                      <div className="left">{txt}</div>
                      <div className="right">
                        {loader ? (
                          "..."
                        ) : (
                          <span className="count">
                            {alertData?.[name] || ""}
                          </span>
                        )}
                        <Link to={`/catalogue/products?${linkPath || ""}`}>
                          <ArrowRight className="right-arrow" />
                        </Link>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductAlert;
