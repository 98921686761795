import React from "react";

const ViewStoreIcon = (props) => (
<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title></title>
    <g id="Admin-Web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Zopping-Admin-Home-Final" transform="translate(-861.000000, -16.000000)">
            <g id="My-Store" transform="translate(861.000000, 16.000000)">
                <g id="Group-33-Copy-4" opacity="0.049999997">
                    <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                </g>
                <g id="Group-34" transform="translate(4.266667, 4.266667)" stroke="#b8b8b5" strokeLinecap="round">
                    <rect id="Rectangle" x="0" y="0" width="23.4666667" height="18.9718953" rx="2"></rect>
                    <line x1="0.439922124" y1="15.7626549" x2="23.0500093" y2="15.7626549" id="Path-13"></line>
                    <line x1="3.92274347" y1="23.5746118" x2="19.5671879" y2="23.5746118" id="Path-13-Copy-2"></line>
                    <line x1="0.570362514" y1="13.5306672" x2="2.80528315" y2="13.5306672" id="Path-13-Copy"></line>
                    <polyline id="Path-14" points="9.10077327 18.9718953 6.7047619 23.3736287 17.0238347 23.3736287 14.8494928 18.9718953"></polyline>
                    <path d="M9.23855568,5.96996945 L10.1978411,9.8575431 C10.227375,9.97723164 10.3484713,10.0503478 10.4683171,10.0208526 C10.5007466,10.0128714 10.5309726,9.9977326 10.5567726,9.9765495 L11.4443958,9.24776542 C11.5325778,9.17536339 11.6612225,9.18096884 11.7427554,9.26076589 L13.0008394,10.4920653 C13.0869708,10.5763629 13.2245359,10.5772139 13.3117063,10.4939884 L14.230318,9.61694852 C14.3195379,9.53176631 14.3227203,9.3904804 14.2374261,9.30137762 L13.0319689,8.16183341 C12.9456347,8.08024752 12.9383455,7.94544843 13.0153798,7.85505162 L13.7512856,6.99149388 C13.8312836,6.89761932 13.8199344,6.75675294 13.7259365,6.67685998 C13.698398,6.6534538 13.6655837,6.63707069 13.6303101,6.62911679 L9.50477837,5.69884553 C9.38437797,5.67169631 9.26473644,5.74716322 9.23755152,5.86740561 C9.22990937,5.90120786 9.23025316,5.93632291 9.23855568,5.96996945 Z" id="Path-15"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ViewStoreIcon;