import React from "react";

const OrderReturnIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="support-ticket---web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Support-Tickets" transform="translate(-21, -330)">
        <g
          id="return-Group-33"
          transform="translate(21, 330)"
          style={{ stroke: "#000" }}
        >
          <g
            id="Returns"
            transform="translate(1, 2.9348)"
            stroke="#B8B8B5"
            strokeWidth="0.8"
          >
            <g id="Group-32">
              <path
                d="M0,3.11222725 L0,16.8445502 L17.4740915,16.8445502 L17.4740915,11.4972088"
                id="Path-3"
              />
              <path
                d="M17.4740915,8.09579219 L17.4740915,3.11222725 L10.0473264,3.11222725"
                id="Path-5"
              />
              <path
                d="M10.0351172,0.403898896 L12.1292506,0.447907443 L9.90694153,3.18251265 L9.90694153,5.8292928 L7.52125929,5.8292928 L7.52125929,3.35329567 L10.0351172,0.403898896 Z"
                id="Path-6"
              />
              <line
                x1="20.3454484"
                y1="0.24580864"
                x2="17.4740915"
                y2="3.11222725"
                id="Path-7"
              />
              <path
                d="M0,3.14723996 L3.04792159,0.0651871286 L20.3454484,0.0651871286"
                id="Path-8"
              />
              <line
                x1="20.3702275"
                y1="0.466568266"
                x2="20.3702275"
                y2="8.35681679"
                id="Path-9"
              />
              <line
                x1="0"
                y1="3.17215364"
                x2="7.68104926"
                y2="3.17215364"
                id="Path-10"
              />
              <path
                d="M9.26257286,8.90797582 L14.1414569,5.27984956 L14.1414569,7.35782899 C17.3004986,7.64867029 19.3684958,8.68961994 20.3454484,10.480678 C21.8108773,13.167265 20.8447137,18.1672162 17.1535229,19.0651871"
                id="Path-11"
              />
              <path
                d="M17.1535229,19.0651871 C19.3998291,15.9597527 19.5066853,13.4370838 17.4740915,11.4971805"
                id="Path-15"
              />
              <path
                d="M17.4740915,11.4972088 C16.2119736,10.596796 15.1381139,10.2653423 14.2525124,10.5028477"
                id="Path-16"
              />
              <path
                d="M14.2525124,10.5028477 L14.2525124,12.4854009 L9.26257286,8.90797582"
                id="Path-17"
              />
              <line
                x1="1.42104547"
                y1="14.0618108"
                x2="5.26282989"
                y2="14.0618108"
                id="Path-18"
              />
              <line
                x1="1.42104547"
                y1="15.5909446"
                x2="5.26282989"
                y2="15.5909446"
                id="Path-19"
              />
            </g>
          </g>
          <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        </g>
      </g>
    </g>
  </svg>
);

export default OrderReturnIcon;
