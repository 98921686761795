import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { getMessage } from "../../lib/translator";
import AuthenticatedPage from "../../containers/AuthenticatedPage";
import TicketDetails from "./TicketDetails";
import CustomerDetails from "./CustomerDetails";
import API from "../../lib/api";
import TicketForm from "./TicketForm";
import Loader from "../../components/Loader";
import moment from "moment";

const API_HEADER = {
  realmID: 1,
};
const HistoryInfoCard = (props) => {
  const {
    createdBy,
    imgUrl = null,
    createdAt = "",
    title,
    comment,
    index,
  } = props;
  return (
    <div className="history-info-wrp" key={index}>
      <div className="user-img">
        <img src={imgUrl} alt={""} />
      </div>
      <div className="history-info">
        <div className="user-info">
          <span className="created-by">{createdBy}</span>
          <span className="created-date">
            {moment(createdAt).format("llll")}
          </span>
        </div>
        <div className="ticket-info">
          {title && <div className="ticket-title">{title}</div>}
          <pre
            className="ticket-description"
            dangerouslySetInnerHTML={{
              __html: comment,
            }}
          />
        </div>
      </div>
    </div>
  );
};

class IndividualSupportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ticketData: {},
      ticketHistory: [],
      description: "",
      address: {},
    };
  }

  async componentDidMount() {
    this.setState({
      showLoader: true,
    });
    let ticketDetailsApi = new API({
      url: `/ticket-service/ticket/${this.props.match.params.id}`,
    });
    await ticketDetailsApi
      .get("", API_HEADER)
      .then((response) => {
        this.setState({
          ticketData: response?.data,
        });
      })
      .catch((error) => console.error(error));

    let getDeliveryAddressApi = new API({
      url: `/order-service/order/${this.state.ticketData.metaData.OrderDetails.id}`,
    });
    getDeliveryAddressApi
      .get()
      .then((response) => {
        this.setState({
          address: response?.data,
        });
      })
      .catch((error) => console.error(error));

    this.fetchTicketHistory();
  }

  fetchTicketHistory = () => {
    const ticketHistoryApi = new API({
      url: `/ticket-service/ticket-history?ticketID=${this.props.match.params.id}`,
    });
    ticketHistoryApi
      .get("", { realmID: 1, ticketID: this.props.match.params.id })
      .then((response) => {
        this.setState({
          ticketHistory: response?.data?.tickethistory,
          showLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const ticketID = this?.props?.match?.params?.id;
    const ticketHistory = this?.state?.ticketHistory;

    const mainContent = () => (
      <div className="ticket-details">
        <h1 className="title heading">
          {getMessage("ticket.details.ticketList.singleOrder.heading")}{" "}
          <strong className="reference-number">{ticketID || ""}</strong>
        </h1>
        <div className="ticket-summary">
          <div className="details">
            {/* TICKET DETAILS COMPONENT */}
            <TicketDetails
              ticketDetail={this?.state?.ticketData}
              address={this?.state?.address}
            />
            {/* CUSTOMER DETAILS COMPONENT */}
            <CustomerDetails
              ticketData={this?.state?.ticketData}
              customerName={this.state.ticketData?.complainant?.name ?? ""}
              address={this?.state?.address?.order?.address}
              ticketHistory={this?.state?.ticketHistory}
            />
          </div>
        </div>
        <div className="ticket-history">
          <div className="ticket-details-heading">
            {getMessage("ticket.details.heading")}
          </div>
          {ticketHistory?.map((ticketItem, i) => {
            if (ticketItem.action === "TICKET_CREATED") {
              const ticketDetails = this.state.ticketData;
              const name = ticketItem?.details?.ticketCreationDetail?.createdBy;
              const url = `https://ui-avatars.com/api/name=${name}`;
              return (
                <HistoryInfoCard
                  key={i}
                  createdBy={ticketDetails?.complainant?.name}
                  createdAt={ticketDetails?.createdAt}
                  comment={ticketDetails?.description}
                  title={ticketDetails?.title}
                  index={i}
                  imgUrl={url}
                />
              );
            }
            const name = ticketItem?.details?.commentDetail?.addedBy;
            const url = `https://ui-avatars.com/api/name=${name}`;
            return (
              <HistoryInfoCard
                key={i}
                createdBy={ticketItem?.details?.commentDetail?.addedBy}
                createdAt={ticketItem?.createdAt}
                comment={ticketItem?.details?.commentDetail?.comment}
                imgUrl={url}
              />
            );
          })}
          <div className="ticket-form">
            <TicketForm
              ticketID={ticketID}
              fetchTicketHistory={this.fetchTicketHistory}
            />
          </div>
        </div>
      </div>
    );

    return (
      <AuthenticatedPage menu={this.props.menu}>
        <Helmet title="Zopping - Ticket Support" />
        {this.state.showLoader ? <Loader size={"lg"} /> : mainContent()}
      </AuthenticatedPage>
    );
  }
}

export default IndividualSupportComponent;
