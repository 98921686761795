import React from "react";

const CustomersIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g id="Page-5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-46">
        <g
          id="Group-45"
          transform="translate(0.950286, 4.000000)"
          stroke="#B8B8B5"
          strokeWidth="0.75"
        >
          <path
            d="M1.04971412,16 L0.999252664,15.7198708 C0.537021886,13.1536637 2.24259793,10.6986061 4.80879874,10.23634 C5.19163093,10.167378 5.58151844,10.1459926 5.96959646,10.1726702 C5.96959646,10.1726702 5.96959646,10.1726702 5.96959646,10.1726702 C6.51721667,10.210315 7.35134303,10.4044438 8.47197553,10.7550563"
            id="Path-12-Copy"
            strokeLinecap="round"
          ></path>
          <rect
            id="Rectangle-Copy-7"
            x="3.06851285"
            y="4.06671099"
            width="5.40346268"
            height="6.10595917"
            rx="2.70173134"
          ></rect>
          <path
            d="M7.31554403,16 L7.51410683,13.8139936 C7.80391652,10.623437 10.4789373,8.18038521 13.6826291,8.18038521 C16.8863209,8.18038521 19.5613416,10.623437 19.8511513,13.8139936 L20.0497141,16 L20.0497141,16"
            id="Path-12"
            strokeLinecap="round"
          ></path>
          <rect
            id="Rectangle"
            x="10.0223233"
            y="0"
            width="7.24489307"
            height="8.1867913"
            rx="3.57"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default CustomersIcon;
