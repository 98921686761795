const roleMapping = [
  {
    name: "PICKER",
    redirect: "/operations/picking-queue",
  },
  {
    name: "CHECKER",
    redirect: "/operations/picking-queue",
  },
  {
    name: "PACKER",
    redirect: "/operations/picking-queue",
  },
  {
    name: "DELIVERY AGENT",
    redirect: "/delivery/vehicles",
  },
  {
    name: "CS AGENT",
    redirect: "/customers/customers",
  },
  {
    name: "CS MANAGER",
    redirect: "/customers/customers",
  },
  {
    name: "Transport Coordinator",
    redirect: "/logistics/vehicles",
  },
  {
    name: "OPS Manager",
    redirect: "/operations/dashboard",
  },
  {
    name: "CONTENT MANAGER",
    redirect: "/catalogue/dashboard",
  },
  {
    name: "CONTENT WRITER",
    redirect: "/marketing/blog",
  },
  {
    name: "HR MANAGER",
    redirect: "/hr/employees",
  },
  {
    name: "MARKETING MANAGER",
    redirect: "/marketing/pages",
  },
  {
    name: "MARKETING EXECUTIVE",
    redirect: "/marketing/pages",
  },
  {
    name: "LOGISTICS MANAGER",
    redirect: "/logistics/vehicles",
  },
  {
    name: "STORE MANAGER",
    redirect: "/catalogue/dashboard",
  },
  {
    name: "PICKING MANAGER",
    redirect: "/operations/picking-queue",
  },
  {
    name: "Transport Coordinator",
    redirect: "/logistics/vehicles",
  },
  {
    name: "CASHIER",
    redirect: "/operations/orders",
  },
  {
    name: "ORDER MANAGER",
    redirect: "/orders/orders",
  },
];

export { roleMapping };
