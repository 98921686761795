import React, { Component } from "react";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import calenderIcon from "./calendar.svg";
import "./style.css"
export default class SingleDatePickerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: false,
    };
  }

  render() {
    const { focusedInput } = this.state;
    let {
      date,
      displayFormat,
      transForm,
      required,
      onDateChange,
      placeholder,
      numberOfMonths,
      isOutsideRange,
      enableToday,
      allowAllDates,
      openDirection,
      readOnly,
      disabled,
      key,
      name,
      customIsOutsideRange,
    } = this.props;
    const smallDevice = window.matchMedia("(max-width: 600px)").matches;
    const orientation = smallDevice ? "vertical" : "horizontal";

    date = (date && moment(date, transForm)) || null;

    return (
      <div className="date-picker">
        <SingleDatePicker
          id={key || name}
          date={date}
          focused={focusedInput && focusedInput.focused}
          onDateChange={(date) =>
            onDateChange(date && moment(date).format(transForm))
          }
          readOnly={readOnly}
          isOutsideRange={
            customIsOutsideRange
              ? isOutsideRange
              : isOutsideRange
              ? (day) => !isInclusivelyBeforeDay(day, moment())
              : allowAllDates
              ? () => false
              : enableToday
              ? undefined
              : (day) => isInclusivelyBeforeDay(day, moment())
          }
          onFocusChange={(focusedInput) => {
            this.setState({ focusedInput });
          }}
          displayFormat={displayFormat}
          required={required}
          placeholder={placeholder}
          customInputIcon={<img src={calenderIcon} alt="" />}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          openDirection={openDirection}
          disabled={disabled}
        />
      </div>
    );
  }
}
SingleDatePickerWrapper.defaultProps = {
  displayFormat: "YYYY-MM-DD",
  className: "",
  transForm: "YYYY-MM-DD",
  numberOfMonths: 1,
  isOutsideRange: false,
  readOnly: false,
  disabled: false,
};
