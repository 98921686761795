import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import NavigationDrawer from "./NavigationDrawer";
import UserInfo from "./UserActions/UserInfo";
import { GIT_HASH, GIT_TAG } from "../../config/app";
import { get } from "./../../lib/storage";
import { getMessage } from "../../lib/translator";
import StoreSelector from "../../containers/StoreSelector";
import getDefaultStore from "../../containers/StoreSelector/getDefaultStore";
import LanguageDropDown from "../../containers/LanguageDropDown";
import AccountDeletion from "./UserActions/AccountDeletion";
import MenuIcon from "./menuIcon";
import ArrowLeft from "./icons/ArrowLeft";
import "./sidemenu.css";
import Android from "./images/google-play-badge.png";
import IosLogo from "./images/ios-app.png";
import { isEnterprise } from "../../lib/auth";
function createSlugFromName(name) {
  return name.replace(/\s+/g, "-").toLowerCase();
}

const getParentRoute = (item, parent) => {
  switch (item) {
    case "picking-queue":
    case "rack-management":
    case "store-slot-config":
    case "vehicles":
    case "vehicle-planning":
    case "trips":
      return "delivery";
    case "trip-planner":
      return "delivery";
    case "manage-runners":
      return "delivery";
    case "pages":
    case "blog":
      return "marketing";
    default:
      return parent;
  }
};

const MenuItem = ({ item, parent, submenu = false }) => {
  const activeItemName = (item, parent) => {
    // to add active class if item not exist in items of router.js
    if (
      parent === "settings" &&
      item === "employees" &&
      window.location.pathname.includes("designations")
    ) {
      return true;
    } else if (
      parent === "catalogue" &&
      item === "products" &&
      window.location.pathname.includes("Categories")
    ) {
      return true;
    } else if (
      parent === "operations" &&
      item === "vehicles" &&
      window.location.pathname.includes("vehicle-vendor")
    ) {
      return true;
    } else {
      return window.location.pathname.includes(item);
    }
  };
  return (
    <>
      {item === "delete-account" ? (
        <AccountDeletion />
      ) : (
        <Link
          to={
            "/" + getParentRoute(item, parent) + "/" + createSlugFromName(item)
          }
        >
          <li
            className={[
              "menu-item",
              submenu ? "submenu" : "",
              activeItemName(item, parent) ? "active" : "",
            ].join(" ")}
          >
            <MenuIcon iconName={createSlugFromName(item)} />
            <span>{getMessage(`menu.item.${item}`)}</span>
          </li>
        </Link>
      )}
    </>
  );
};

const ParentMenu = ({ item, menuKey, activeParent, setActiveParent }) => {
  const handleParentMenuClick = (e) => {
    e.stopPropagation();
    if (activeParent === menuKey) {
      setActiveParent("");
    } else {
      setActiveParent(menuKey);
    }
  };

  return (
    <div className="parent-menu-wrp">
      <div
        className={[
          "menu-item parent-item-icon",
          activeParent === menuKey ? "active" : "",
          (menuKey === "user" && !window.location.pathname.includes("user")) ||
          (menuKey === "operations" && activeParent === "settings") ||
          (menuKey === "settings" && activeParent === "user") ||
          (menuKey === "operations" && activeParent === "user") ||
          (menuKey === "settings" && activeParent === "operations") ||
          (menuKey === "seller-support" && activeParent === "operations") ||
          (menuKey === "seller-support" && activeParent === "settings") ||
          (menuKey === "settings" && activeParent === "seller-support") ||
          (menuKey === "operations" && activeParent === "seller-support") ||
          (menuKey === "seller-support" && activeParent === "user")
            ? "hide"
            : "",
        ].join(" ")}
        onClick={!(menuKey === "user") ? handleParentMenuClick : null}
      >
        <MenuIcon iconName={createSlugFromName(menuKey)} />
        <span>{getMessage(`menu.item.${menuKey}`)}</span>
      </div>
      {activeParent === menuKey && (
        <div className="submenu-wrp">
          {item.map((sub) => (
            <MenuItem
              item={sub}
              parent={menuKey}
              key={sub}
              activeParent={activeParent}
              submenu
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Menu = (props) => {
  const { items, showLanguageDropDown, stores, onChange } = props;
  let endpoint = window.location.pathname.split("/");

  const additionalSubmenu = (parent, item) => {
    // to make submenu visible for item, which not exist in items router.js
    if (parent === "settings") {
      switch (item) {
        case "designations":
          return "employees";
        case "fb-analytics-configuration":
        case "wallet-configuration":
        case "customer-tags":
        case "store-configuration":
        case "abandoned-cart":
        case "search-configuration":
        case "custom-fields":
        case "shiprocket-configuration":
        case "picking-configuration":
        case "logistics-configuration":
        case "slots":
        case "delivery-area":
        case "web-banners":
        case "offers":
        case "seo":
        case "campaigns":
        case "substitution-groups":
        case "tags":
        case "brands":
        case "slot-charges":
        case "capacity-planning":
        case "google-merchant-center":
        case "referral-management":
        case "what-more":
        case "product-variant-support":
        case "ondc-integration":
        case "order-returns-support":
          return "extensions";
        default:
          return item;
      }
    } else if (parent === "delivery") {
      switch (item) {
        case "vehicle-vendor":
          return "vehicles";
        default:
          return item;
      }
    }

    return item;
  };

  const endpointName = (name) => {
    // get itemName from url, to check if exist in items router.js
    if (name === "operations") {
      name = "delivery";
    }
    if (
      endpoint.includes("marketing") &&
      (endpoint.includes("pages") || endpoint.includes("blog"))
    ) {
      // hardcoding for 'marketing pages, as for to come inside settings url should be 'settings/pages'
      const index = endpoint.findIndex((val) => val === "marketing");
      return additionalSubmenu(name, endpoint[index + 1]);
    }
    const index = endpoint.findIndex((val) => val === name);
    return additionalSubmenu(name, endpoint[index + 1]);
  };

  let getCurrentActive =
    Object.keys(items).filter((item) => {
      return (
        items[item].includes(endpointName(item)) &&
        (item === "settings" ||
          item === "operations" ||
          item === "seller-support")
      );
    })?.[0] || "";
  if (window.location.pathname.includes("user")) {
    getCurrentActive = "user";
  }
  const [activeParent, setActiveParent] = useState(getCurrentActive);
  return (
    <>
      <div className="menu-wrp">
        <div id="store-selector">
          {stores && Object.keys(stores).length > 1 && (
            <StoreSelector
              stores={stores}
              value={get("store") || getDefaultStore(stores).storeId}
              onChange={(e) => onChange(e)}
            />
          )}
        </div>
        {showLanguageDropDown && <LanguageDropDown />}
        <ul className="menu-items">
          {!!activeParent && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (activeParent === "user") {
                  props.history.push("/home/dashboard");
                } else setActiveParent("");
              }}
              className="menu-item main-menu"
            >
              <ArrowLeft />
              <span>{getMessage("main.menu")}</span>
              <hr />
            </div>
          )}
          {window.location.pathname.includes("user") && <UserInfo />}
          {Object.keys(items).map((item) => {
            return items[item].length > 1 || item === "operations" ? (
              <ParentMenu
                item={items[item]}
                menuKey={item}
                key={item}
                activeParent={activeParent}
                setActiveParent={(parentKey) => setActiveParent(parentKey)}
              />
            ) : (
              !activeParent && activeParent !== item && (
                <MenuItem
                  item={items[item][0]}
                  key={items[item][0]}
                  parent={item}
                  activeParent={activeParent}
                />
              )
            );
          })}
        </ul>
        <div className="build-version">{GIT_TAG || GIT_HASH}</div>
      </div>
      {!isEnterprise() && (
        <div className="download-app-container">
          <p>{getMessage("download.zooping.app")}</p>
          <div className="download-app-icon">
            <a
              href="https://play.google.com/store/apps/details?id=com.zopping.admin"
              target="_blank"
              className="android-wrp"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="play-store-logo" />
            </a>
            <a
              href="https://apps.apple.com/in/app/zopping/id1586937529"
              target="_blank"
              className="ios-wrp"
              rel="noopener noreferrer"
            >
              <img src={IosLogo} alt="play-store-logo" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const MenuWithRouter = withRouter(Menu); // Using Higher-order component to add router properties like location.

export default MenuWithRouter;

export { NavigationDrawer };
