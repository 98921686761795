import React from "react";
import OrderIcon from "./icons/OrderIcon";
import DeliveryIcon from "./icons/DeliveryIcon";
import CatalogueIcon from "./icons/CatalogueIcon";
import MarketingIcon from "./icons/MarketingIcon";
import CustomersIcon from "./icons/CustomersIcon";
import SettingsIcon from "./icons/SettingsIcon";
import ProfileImageIcon from "./UserActions/ProfileImageIcon";
import AnalyticsIcon from "./icons/AnalyticsIcon";
import OrderReturnIcon from "./icons/OrderReturnIcon";
import Review from "./UserActions/Review";
import SupportIcon from "./icons/SupportIcon";
import SellersIcon from "./icons/SellersIcon";

const MenuIcon = (props) => {
  const { iconName } = props;
  switch (iconName) {
    case "orders":
      return <OrderIcon />;
    case "products":
      return <CatalogueIcon />;
    case "coupons":
      return <MarketingIcon />;
    case "customers":
      return <CustomersIcon />;
    case "reports":
      return <AnalyticsIcon />;
    case "settings":
      return <SettingsIcon />;
    case "operations":
      return <DeliveryIcon />;
    case "user":
      return <ProfileImageIcon />;
    case "reviews":
      return <Review />;
    case "support":
      return <SupportIcon />;
    case "allreturns":
      return <OrderReturnIcon />;
    case "seller-support":
      return <SellersIcon />;
    default:
      return null;
  }
};

export default MenuIcon;
