import React from "react";
import { Provider } from "../Context";

import vehicle from "./vehicle.svg";
import ShortLogo from "./images/short_logo.webp";
import "./style.css";

export default function PublicPage(props) {
  let enterprise = true;
  let subDomain = window.location.hostname.split(".")[0];
  const pathname = window.location.pathname;
  if (
    subDomain === "dashboard" ||
    subDomain === "localhost" ||
    pathname.includes("admin")
  ) {
    enterprise = false;
  }
  return (
    <Provider value={enterprise}>
      <div className={"fullWidthPage eazy-page " + props.className}>
        {props.showHeader && (
          <header className="header-logo">
              <img  src={ShortLogo} alt="zopping logo" />
          </header>
        )}
        <main>{props.children}</main>
        <footer className="page-footer">
          <img className="vehicle" src={vehicle} alt="zopsmart-vehicle" />
        </footer>
      </div>
    </Provider>
  );
}
PublicPage.defaultProps = {
  showHeader: true,
};
