import React, { Suspense, lazy } from "react";
import { withRouter } from "react-router";
import FallbackLoader from "../../components/FallbackLoader";

export const LazyComponent = (dynamicImport) => {
  const Component = lazy(dynamicImport);
  Component.preLoad = dynamicImport;
  return Component;
};

const LazyComponentLoader = ({ Component, ...props }) => {
  return (
    <Suspense fallback={<FallbackLoader menu={props?.menu} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default withRouter(LazyComponentLoader);
