import React from "react";

const ProfileImageIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <path id="a" d="M0 0h30v30H0z"/>
        <circle id="b" cx="11" cy="11" r="11"/>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <mask id="c" fill="#fff">
            <use xlinkHref="#b"/>
        </mask>
        <use fill="none" stroke="#b8b8b5" xlinkHref="#b"/>
        <g fillRule="nonzero" mask="url(#c)">
            <path stroke="#b8b8b5" d="M11 12.575a3.606 3.606 0 0 0 0-7.21 3.606 3.606 0 0 0 0 7.21z"/>
            <path stroke="#b8b8b5" d="M19.286 17.98a8.07 8.07 0 0 0-.494-.994c-.989-1.578-2.539-2.645-4.268-2.912a.838.838 0 0 0-.63.17 5.015 5.015 0 0 1-3.1 1.092 5.015 5.015 0 0 1-3.1-1.092.838.838 0 0 0-.628-.17c-1.73.267-3.28 1.31-4.268 2.912-.202.315-.36.655-.494.994a.494.494 0 0 0 .022.437c.18.316.382.655.584.946.292.437.63.85.989 1.214.314.34.651.63 1.01.922 1.708 1.383 3.752 2.11 5.886 2.11 2.134 0 4.178-.727 5.885-2.11.36-.291.696-.607 1.01-.922.36-.364.697-.777.99-1.214.201-.29.404-.606.583-.946a.59.59 0 0 0 .023-.437z"/>
        </g>
    </g>
</svg>

);

export default ProfileImageIcon;