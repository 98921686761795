import React from "react";
import { getPrintableTime } from "../../lib/datetime";
import "./style.css";
import Image from "../../components/Image";
import { getMessage } from "../../lib/translator";
import pickupImage from "../delivery/AllCourierOrder/Table/Views/pickup.svg";
import deliveyImage from "../delivery/AllCourierOrder/Table/Views/delivery.svg";
import { Link } from "react-router-dom";
export default function TicketDetails(props) {
  const { ticketDetail, address } = props;

  const { metaData, status } = ticketDetail;

  const orderTypeImages = {
    PICKUP: pickupImage,
    DELIVERY: deliveyImage,
  };
  const getTicketStatus = () => {
    let orderStatusText = null;
    switch (status) {
      case "RESOLVED" || "CLOSED" || "PENDING":
        orderStatusText = (
          <span className={`ticket-status-${status.toLowerCase()}`}>
            {getMessage(`ticket.status.${status.toLowerCase()}`)}
          </span>
        );
        break;
      default:
        if (status) {
          orderStatusText = (
            <span>
              {status.slice(0, 1).toUpperCase() + status.slice(1).toLowerCase()}
            </span>
          );
        }
        break;
    }
    return orderStatusText;
  };

  return (
    <div className="ticket-details">
      <div className="section-pre-order">
        <h3 className="section-title">
          {getMessage("ticket.details.status")}
          <span>{getTicketStatus()}</span>
        </h3>
      </div>
      <p className="ticket-created">
        <div className="order-placement-info-icon icon-clock" />
        <div className="created-time">
          {getMessage("ticket.order.details.heading.createdAt")}
          <span className="date">
            {getPrintableTime(ticketDetail.createdAt)}
          </span>
        </div>
      </p>
      <p className="buyer-app-wrp">
        <span className="order-placement-info-icon icon-mobile" />
        <div className="app-name">
          {getMessage("ticket.order.details.heading.buyerApp")}
          <span className="buyer-app">{metaData?.BuyerApp ?? ""}</span>
        </div>
      </p>

      <p className="order-details">
        <div className="order-type-icons">
          <Image
            src={orderTypeImages[address?.order?.type?.name ?? "DELIVERY"]}
          />
        </div>
        <div className="order">
          {getMessage("ticket.order.details.heading.order.id")}
          <Link
            className="order-number"
            to={`/orders/orders/${metaData?.OrderDetails?.id}`}
          >
            {`ORD#${metaData?.OrderDetails?.id}`}
          </Link>
        </div>
      </p>
    </div>
  );
}
