/*
  This file exists in src/ folder due to limitations set by create-react-app
*/
let GO_HOST = `${window.location.origin}/api`;
if (window.location.host.includes("localhost")) {
  GO_HOST = process.env.REACT_APP_API_HOST || "https://staging.zopping.com/api";
}

let IS_STAGING = GO_HOST.includes("staging");
const ZOPPING_URL = IS_STAGING
  ? "https://staging.zopping.com"
  : "https://zopping.com";
const GOOGLE_MAP_DEFAULT_KEY = "AIzaSyA6YlEVc6ic84ZWAh2RtlOVsZh8S3fA2pY";
let GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,drawing&key=`;
const WEBSOCKET_URL =
  process.env.REACT_APP_EVENTS_URL || "wss://events.zopsmart.com/ws/store";
const BUGSNAG_KEY = process.env.REACT_APP_BUGSNAG_KEY || "";
const ZOPSMART_MAPS_URL =
  process.env.REACT_APP_MAPS_URL || "https://maps.zopping.com";
const GIT_HASH = process.env.REACT_APP_GIT_HASH;
const GIT_TAG = process.env.REACT_APP_GIT_TAG;
const GOOGLE_CLIENTID = IS_STAGING
  ? "1004747005329-iqk2gihlueu3pjoat6vqis7u58jrp9ta.apps.googleusercontent.com"
  : "1004747005329-rnnlg53ch76f57e485p1gr3hqlu2fqq0.apps.googleusercontent.com";
const DEVELOPER_TOKEN = IS_STAGING
  ? "8cd4413b-aff8-4d14-b286-17f5a1e40974"
  : "dd82ba4b-9501-4916-a088-47e6b3995044";
const GOOGLE_RECAPTCHA_KEY = window?.location.host.includes("localhost")
  ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //PUBLIC KEY
  : "6LfvRb4UAAAAAM6vbv9m4JE9jRcsmngJeF0wbiAo"; //ZOPPING KEY

const FARO_APP_NAME = IS_STAGING
  ? process.env.REACT_APP_FARO_APP_NAME || "zopping-admin-ui-stage"
  : process.env.REACT_APP_FARO_APP_NAME || "zopping-admin-ui";

const FARO_APP_URL =
  process.env.REACT_APP_FARO_APP_URL ||
  "https://grafana-agent.observability-prod.gcp.zopsmart.com/collect";

export {
  GO_HOST,
  ZOPPING_URL,
  GOOGLE_MAPS_URL,
  BUGSNAG_KEY,
  WEBSOCKET_URL,
  ZOPSMART_MAPS_URL,
  GIT_HASH,
  GIT_TAG,
  IS_STAGING,
  GOOGLE_MAP_DEFAULT_KEY,
  GOOGLE_CLIENTID,
  DEVELOPER_TOKEN,
  GOOGLE_RECAPTCHA_KEY,
  FARO_APP_NAME,
  FARO_APP_URL,
};
