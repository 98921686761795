import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import {
  getPermissions,
  saveSession,
  isExtensionEnabled,
} from "../../lib/auth";
import API, { isInternal } from "../../lib/api";
import { set } from "../../lib/storage";
import { GOOGLE_MAP_DEFAULT_KEY } from "../../config/app";
import MissingPage from "../../pages/others/MissingPage";
import RedirectFromAppLoader from "./RedirectFromApp.gif";
import LazyComponentLoader from "../LazyComponentLoader";

export const submenuRedirect = (mainMenuItem, submenu, menu) => {
  let url = mainMenuItem;
  let sub = menu[mainMenuItem]
    ? menu[mainMenuItem].indexOf(submenu) > -1
      ? submenu
      : menu[mainMenuItem][0]
    : submenu;
  return `/${url}/${sub}`;
};

const getUserDetails = () => {
  let userAPI = new API({ url: "/account-service/user" });
  return userAPI
    .get()
    .then((response) => {
      return response.data.user[0];
    })
    .then(({ id }) => {
      let user = new API({ url: `/account-service/user/${id}` });
      return user.get();
    });
};
const getBackOfficeAPIDetails = () => {
  let backOfficeApi = new API({ url: "/config-service/config/backOffice" });
  return backOfficeApi.get();
};

const websiteConfig = () => {
  const websiteConfigApi = new API({
    url: `/config-service/config/website`,
  });
  return websiteConfigApi.get();
};

const getOrganizationDetails = (organizationId) => {
  const organizationAPI = new API({
    url: `/account-service/organization/${organizationId}`,
  });
  return organizationAPI.get();
};
const getPagePermissions = (url, requiredPermissions) => {
  let path = url.split("/").filter(Boolean);
  let permissions = requiredPermissions[path[0]].find(
    (obj) => obj.slug === path[1]
  );
  return (({ endpoints, extensions }) => ({ endpoints, extensions }))(
    permissions
  );
};

const hasAccess = ({ endpoints, extensions = [] }) => {
  let hasExtensionsEnabled = extensions
    .map(isExtensionEnabled)
    .reduce((acc, value) => acc && value, true);
  let hasApiPermissions =
    !endpoints || !endpoints.length
      ? true
      : Object.values(getPermissions(endpoints)).reduce((acc, value) => {
          if (value instanceof Object) {
            return (
              acc &&
              Object.values(value).reduce((acc, value) => acc || value, false)
            );
          } else {
            return acc && value;
          }
        }, true);
  return hasApiPermissions && hasExtensionsEnabled;
};
const PrivateRoute = ({
  component: Component,
  isLazy,
  showMenu = true,
  setRefresh,
  rerender,
  isLoggedIn,
  requiredPermissions,
  menu,
  refresh,
  hasIndustry,
  ...rest
}) => {
  let endpoints = [];
  let extensions = [];
  let hasPageAccess = true;
  let location = new URL(JSON.parse(JSON.stringify(window.location)).href);
  let redirectedFrom = location.searchParams.get("redirectedFrom");
  let organizationId = location.searchParams.get("organizationId");
  redirectedFrom && set("redirectedFrom", redirectedFrom);
  const redirectedFromApp = redirectedFrom && redirectedFrom === "app";
  if (redirectedFromApp) {
    set("redirectedFromApp", redirectedFromApp);
  }
  const [isLoading, setIsLoading] = useState(false);
  useEffect(
    () => {
      if (location.searchParams.get("guid") && refresh) {
        if (isInternal()) {
          Promise.all([
            getUserDetails(),
            getOrganizationDetails(organizationId),
            getBackOfficeAPIDetails(),
            websiteConfig(),
          ]).then(
            ([
              userResponse,
              organizaitonResponse,
              backOfficeResponse,
              websiteConfigResponse,
            ]) => {
              let user = userResponse.data.user;
              let key =
                websiteConfigResponse.data &&
                websiteConfigResponse.data.website &&
                websiteConfigResponse.data.website.googleApisKey;
              set("googleMapAPIKey", key || GOOGLE_MAP_DEFAULT_KEY);
              saveSession({
                user: user,
                organization: organizaitonResponse.data.organization,
              });
              setRefresh(false);
            }
          );
        } else if (redirectedFromApp) {
          const api = new API({ url: "/account-service/me" });
          setIsLoading(true);
          Promise.all([
            api.get(),
            getOrganizationDetails(organizationId),
            getBackOfficeAPIDetails(),
            websiteConfig(),
          ]).then(
            ([
              userResponse,
              organizationResponse,
              backOfficeResponse,
              websiteConfigResponse,
            ]) => {
              let user = userResponse.data.user;
              let key =
                websiteConfigResponse.data &&
                websiteConfigResponse.data.website &&
                websiteConfigResponse.data.website.googleApisKey;
              set("googleMapAPIKey", key || GOOGLE_MAP_DEFAULT_KEY);
              saveSession({
                user,
                organization: organizationResponse.data.organization,
              });
              setRefresh(false);
              setIsLoading(false);
            },
            (error) => {
              setIsLoading(false);
              if (error.code === 401)
                window.location.href = "/admin/user/logout";
              throw error;
            }
          );
        } else {
          const api = new API({ url: "/account-service/me" });
          const extnApi = new API({ url: "/account-service/extension" });
          Promise.all([api.get(), extnApi.get()]).then(
            ([response, extnResponse]) => {
              saveSession({
                user: response.data.user,
                organization: {
                  extension: extnResponse.data.extension,
                  currency: { symbol: "" },
                },
              });
              setRefresh(false);
            },
            (error) => {
              throw error;
            }
          );
        }
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  let hasPermissionsInfo = Boolean(getPermissions());
  let redirectToLogin = !isLoggedIn || !hasPermissionsInfo;
  if (location.searchParams.get("guid") && hasPermissionsInfo) {
    redirectToLogin = false;
  }
  if (endpoints.length || extensions.length) {
    let temp = getPagePermissions(rest.path, requiredPermissions);
    endpoints = temp.endpoints;
    extensions = temp.extensions;
    hasPageAccess = hasAccess({ endpoints, extensions });
  }

  const [remountKey, setKey] = useState(0);
  if (isLoading)
    return (
      <div className="gif-loader-wrp">
        <img src={RedirectFromAppLoader} alt="loader" />
      </div>
    );
  return (
    !refresh && (
      <Route
        {...rest}
        render={(props) => {
          if (redirectToLogin)
            return (
              <Redirect
                to={{
                  pathname: isLoggedIn ? "/user/logout" : "/login",
                  state: { from: props.location },
                }}
              />
            );
          if (!hasPageAccess) return <MissingPage />;
          if (isLazy)
            return (
              <LazyComponentLoader
                menu={menu}
                Component={Component}
                isLoggedIn={isLoggedIn}
                // TODO: Provide a better way to figure out how to re-render when permissions change,
                // perhaps by maintaining a state which contains permissions
                rerender={() => {
                  rerender();
                  setKey(remountKey + 1); // Remounting component after permissions change
                }}
                key={remountKey}
                hasIndustry={hasIndustry}
                {...props}
              />
            );
          return (
            <Component
              {...props}
              menu={menu}
              isLoggedIn={isLoggedIn}
              // TODO: Provide a better way to figure out how to re-render when permissions change,
              // perhaps by maintaining a state which contains permissions
              rerender={() => {
                rerender();
                setKey(remountKey + 1); // Remounting component after permissions change
              }}
              key={remountKey}
              hasIndustry={hasIndustry}
            />
          );
        }}
      />
    )
  );
};

export default PrivateRoute;
export { hasAccess };
