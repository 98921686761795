import React, { useState } from "react";
import { getMessage } from "../../../lib/translator";
import { getSession } from "../../../lib/auth";
import API from "../../../lib/api";
import { BaseForm, Input } from "../../Form";
import { Popup, Dialog } from "../../Popup";
import { clearSession } from "../../../lib/auth";
import checkHttpResponseCode from "../../../utils/isApiCallSuccessfull";
import "./style.css";

class VerifyDeletion extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      formLoading: false,
      formError: "",
    };
    this.user = getSession().user;
  }

  onSubmit() {
    const { user } = this;
    const confirmDeleteApi = new API({
      url: `/account-service/account/${user.id}`,
    });
    const deleteParams = {
      code: this.state.values["verification-code"].trim(),
    };
    this.setState({ formLoading: true });
    confirmDeleteApi
      .delete(deleteParams)
      .then((response) => {
        this.setState({ formLoading: false });
        clearSession();
        window.location.href = "/admin/login";
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          formLoading: false,
          formError: err?.message ?? getMessage("error.generic"),
        });
      });
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { formLoading, formError } = this.state;
    return (
      <div>
        <Form>
          <p className="deletion-verification-message">
            {`${getMessage(
              "user.deletion.verification.code.messgae"
            )} ${getMessage(
              this.user?.phones?.length
                ? "customer.details.phone.phoneNumber"
                : "login.email.heading"
            ).toLowerCase()}`}
          </p>
          <Input
            label={getMessage("user.verification.code.deletion")}
            placeholder={getMessage(
              "user.verification.code.deletion.placeholder"
            )}
            name="verification-code"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["verification-code"],
              loseEmphasisOnFill: true,
            })}
          />
          {formError && (
            <div className="input-error-message-delete">{formError}</div>
          )}
          <SubmitButton disabled={formLoading}>
            {formLoading
              ? "..."
              : getMessage("product.form.delete.confirmText")}
          </SubmitButton>
        </Form>
      </div>
    );
  }
}

const AccountDeletion = () => {
  const [showConfirmDeletion, setConfirmDeletion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVerificationPopup, setVerificationPopup] = useState(false);
  const handleConfirmDeletion = () => {
    const deleteApi = new API({ url: "/auth-service/verification-code" });
    const deleteParams = {
      reason: "ACCOUNT_DELETION",
    };
    setLoading(true);
    deleteApi
      .post(deleteParams)
      .then((response) => {
        if (checkHttpResponseCode(response?.code)) {
          setLoading(false);
          setConfirmDeletion(false);
          setVerificationPopup(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setConfirmDeletion(false);
        console.error(err);
      });
  };

  return (
    <>
      <button
        className="delete-account-text"
        onClick={() => setConfirmDeletion(true)}
      >
        {getMessage("menu.item.delete-account")}
      </button>
      <Dialog
        show={showConfirmDeletion}
        title={""}
        information={getMessage("user.account.deletion.confirmation")}
        onOk={handleConfirmDeletion}
        close={() => setConfirmDeletion(false)}
        closeText={getMessage("cancel.text")}
        okText={loading ? "..." : getMessage("product.form.delete.confirmText")}
      />
      <Popup
        show={showVerificationPopup}
        close={() => setVerificationPopup(false)}
        heading={getMessage("user.deletion.confirm")}
      >
        <VerifyDeletion />
      </Popup>
    </>
  );
};

export default AccountDeletion;
