const data = {
  hi: {
    language: "Hindi",
    strings: {
      "order.details.invoice.taxDisclaimer": "*सभी कीमतों में टैक्स शामिल है",
      "slots.asap.placeholder": "0 मिनट",
      "mobileApps.appIcon.placeholder":
        "2048 x 2048 पीएक्स पारदर्शी पृष्ठभूमि पीएनजी",
      "customer.details.call.call": "शीघ्र ही ग्राहक को कॉल किया जाएगा",
      "onboarding.basicInfo.newOne.text": "एक नया विकल्प",
      "substitutionGroup.helpItems.message":
        "एक प्रतिस्थापन समूह में ऐसे उत्पाद शामिल होते हैं जो एक दूसरे के समान होते हैं और आवश्यकता पड़ने पर प्रतिस्थापन के रूप में कार्य कर सकते हैं।",
      "substitutionGroup.form.product.requiredMessage":
        "एक प्रतिस्थापन समूह में कम से कम एक उत्पाद होना चाहिए",
      "attendance.summary.table.ABSENT": "अनुपस्थित",
      "offer.absoluteoff": "बिल्कुल बंद",
      "menu.item.account-balance": "खाते में शेष",
      "settings.online.payment.accountholdername": "खाता धारक का नाम",
      "pickers.active.header": "सक्रिय पिकर",
      "add.label.text": "जोड़ें",
      "rackManagement.rack.zones.type.new": "एक नया क्षेत्र जोड़ें",
      "operations.store.form.add.heading": "एक स्टोर जोड़ें",
      "order.form.addAddress.heading": "पता जोड़ें",
      "campaign.form.pushImage.placeholder": "एक छवि जोड़ें",
      "store.accounts.label": "ऐप लिंक जोड़ें",
      "banners.view.add.button": "बैनर जोड़ें",
      "product.form.variant.barcodes.placeholder": "बारकोड जोड़ें",
      "marketing.form.add.heading": "ब्लॉग पोस्ट जोड़ें",
      "brand.add.text": "ब्रांड जोड़ें",
      "campaign.add.text": "अभियान जोड़ें",
      "category.form.add.heading": "कैटेगरी जोड़े",
      "store.configuraion.add.text": "कॉन्फ़िगरेशन जोड़ें",
      "coupon.add.text": "कूपन जोड़ें",
      "designations.add": "पदनाम जोड़ें",
      "notifications.add.email": "ईमेल जोड़ें",
      "notifications.email.mail": "ईमेल पता जोड़ें",
      "customer.details.pages.phonemail.addEmailId": "ईमेल आईडी जोड़ें",
      "employee.add": "कर्मचारी जोड़ें",
      "substitutionGroup.add.text": "समूह जोड़ें",
      "entityMetadata.add": "कुंजी जोड़ें",
      "websitepage.form.addLayout": "लेआउट जोड़ें",
      "navigation.dropdown.item.addLink": "लिंक जोड़ें",
      "product.form.brand.addButton": "नया ब्रांड जोड़ें",
      "product.form.tag.addButton": "नया टैग जोड़ें",
      "product.form.category.addButton": "नई श्रेणी जोड़ें",
      "vehicles.form.vendor.addButton": "नया विक्रेता जोड़ें",
      "rackManagement.rack.add.zone": "नया क्षेत्र जोड़ें",
      "campaign.form.pushImage.heading": "अधिसूचना छवि जोड़ें",
      "customer.details.pages.phonemail.addPhoneNumber": "संख्या जोड़ें",
      "offer.add.text": "ऑफ़र जोड़ें",
      "websitePage.add.text": "पृष्ठ जोड़ें",
      "notifications.add.phone": "फोन नंबर डालें",
      "rackManagement.popup.heading": "पिकिंग अनुक्रम जोड़ें",
      "orderDetails.uploadPrescription.addPrescription":
        "प्रिस्क्रिप्शन जोड़ें",
      "productUpload.addProduct": "उत्पाद जोड़ें",
      "order.form.addProduct.heading": "उत्पाद जोड़ें",
      "onboarding.products.heading": "अपनी वेबसाइट पर उत्पाद जोड़ें",
      "calls.action.form.heading": "कारण और सारांश जोड़ें",
      "role.add": "भूमिका जोड़ें",
      "cp.add": "नियम जोड़ें",
      "search-config.searchurls": "खोज कीवर्ड जोड़ें",
      "vehiclePlanner.addShift.heading": "शिफ्ट जोड़ें",
      "imageSlideShow.addSlide": "स्लाइड जोड़ें",
      "slots.addHeading": "स्लॉट जोड़ें",
      "slots.store.addHeading": "स्लॉट नियम जोड़ें",
      "socialMedia.accounts.label": "सोशल मीडिया जोड़ें",
      "websitePage.form.add.heading": "स्थिर पृष्ठ जोड़ें",
      "operations.store.add.text": "स्टोर जोड़ें",
      "navigation.dropdown.item.addTag": "टैग जोड़ो",
      "rackManagement.rack.zones.type.existing": "मौजूदा क्षेत्र में जोड़ें",
      "product.form.variant.addButtonText": "प्रकार जोड़ें",
      "vehicles.header.addText": "वाहन जोड़ें",
      "vehicles.vendor.addButton": "विक्रेता जोड़ें",
      "extensions.addedOn": "पर जोड़ा",
      "calls.action.success.info": "सफलतापूर्वक जोड़ा गया",
      "order.form.customer.address.heading": "पता",
      "customer.details.address.added": "पता सफलतापूर्वक जोड़ा गया",
      "order.log.address.changed": "पता बदल गया",
      "customer.callWidget.form.reason.addressClarifiation": "पता स्पष्टीकरण",
      "customer.details.call.option.addressClarification": "पता स्पष्टीकरण",
      "customer.details.address.deleted": "पता सफलतापूर्वक हटा दिया गया है",
      "customer.details.page.address.notServiceable.error":
        "पता सेवा योग्य नहीं है",
      "googleMaps.zeroResults.error": "पता नहीं पाया गया",
      "order.form.addressnotserved":
        "पता नहीं दिया गया। कृपया कोई अन्य पता या स्टोर चुनें",
      "customer.address.saved": "पता अपडेट किया गया",
      "vehiclePlanner.addShift.form.adhoc.label": "एडहॉक व्हीकल काउंट",
      "product.form.variant.aisle.heading": "गलियारा",
      "offer.alert.message": "चेतावनी संदेश",
      "all.label.text": "सभी",
      "offer.all.except.selected.stores": "चयनित दुकानों को छोड़कर सभी",
      "Friday.all": "सभी शुक्रवार",
      "order.details.itemsTable.clearingMessage":
        "सभी आइटम हटा दिए जाते हैं। आदेश रद्द कर दिया जाएगा।",
      "logistics.config.allOrders": "एक बार में सभी ऑर्डर",
      "Saturday.all": "सभी शनिवार",
      "offer.all.stores": "सभी स्टोर",
      "Sunday.all": "सभी रविवार",
      "Thursday.all": "सभी गुरुवार",
      "Tuesday.all": "सभी मंगलवार",
      "Wednesday.all": "सभी बुधवार",
      "shifts.form.toolAccess": "कर्मचारी को डैशबोर्ड पोर्टल तक पहुंचने दें",
      "socialMedia.facebook.allowlogin": "फेसबुक लॉगिन की अनुमति दें",
      "socialMedia.google.allowlogin": "Google लॉगिन की अनुमति दें",
      "picking.config.multiplePickingAllowed": "एकाधिक चुनने की अनुमति दें",
      "picking.config.partialPickingAllowed": "आंशिक चयन की अनुमति दें",
      "socialMedia.twitter.allowlogin": "ट्विटर लॉगिन की अनुमति दें",
      "resetPassword.oldUserText": "क्या आपके पास पहले से एक खाता मौजूद है?",
      "operations.store.form.businessHours.alwaysOpen": "सदैव खुला",
      "campaign.form.time.am": "पूर्वाह्न",
      "trips.details.orderList.amount": "राशि",
      "customer.details.page.amount": "राशि",
      "order.log.amount.changed": "राशि परिवर्तित",
      "trips.paymentsForm.amount.placeholder":
        "ग्राहक द्वारा भुगतान की गई राशि",
      "customer.details.email.send": "शीघ्र ही ग्राहक को एक ईमेल भेजा जाएगा।",
      "customer.details.sms.send": "जल्द ही ग्राहक को एक एसएमएस भेजा जाएगा।",
      "menu.item.analytics": "एनालिटिक्स",
      "extensions.analytics.heading": "एनालिटिक्स एक्सटेंशन सेटिंग्स",
      "coupon.analytics.analyticsFor": "विश्लेषिकी के लिए",
      "coupon.analytics.pieChart.MOBILE_ANDROID_WEB": "एंड्रॉयड",
      "mobileApps.appIcon.heading": "ऐप आइकन",
      "offer.applicable.to.stores": "स्टोर करने के लिए लागू",
      "leaves.team.appliedon": "पर लागू",
      "coupon.form.stores.heading": "पर लागू होता है",
      "leaves.apply": "लागू करना",
      "leaves.apply.new": "छुट्टी के लिए आवेदन करें",
      "dispute.approve": "मंजूर",
      "dispute.approveReject": "स्वीकृत अस्वीकृत",
      "dispute.approved": "स्वीकृत",
      "leaves.team.cancel.title": "स्वीकृत पत्ते जिन्हें रद्द किया जा सकता है",
      "notification.email.delete.title": "क्या आपको यकीन है?",
      "coupon.dialog.title": "क्या आपको यकीन है",
      "customer.reset.password.title": "क्या आपको यकीन है ?",
      "product.form.delete.message":
        "क्या आप वाकई इस उत्पाद को हटाना चाहते हैं?",
      "product.form.all.variants.delete.message":
        "क्या आप वाकई सभी प्रकार निकालना चाहते हैं?",
      "product.form.variant.delete.message":
        "क्या आप वाकई इस प्रकार को हटाना चाहते हैं?",
      "are.you.sure": "क्या आपको यकीन है?",
      "cp.area": "क्षेत्र",
      "polygon.table.subdescription": "मानचित्र में क्षेत्र",
      "mobileApps.assets.heading": "संपत्तियां",
      "tripPlanner.assign": "को आवंटित",
      "changePassword.list.lowercase": "कम से कम 1 लोअर केस लेटर (a..z)",
      "changePassword.list.number": "कम से कम 1 नंबर (0..9)",
      "changePassword.list.uppercase": "कम से कम 1 अपर केस लेटर (A..Z)",
      "changePassword.list.characterLimit": "कम से कम 6 वर्ण",
      "orderprocessing.orderLabelPrinting.startofpicking":
        "चुनने की शुरुआत में",
      "shifts.header.attendance": "उपस्थिति",
      "menu.item.attendance-summary": "उपस्थिति सारांश",
      "logistics.config.tripGeneration.automated": "स्वचालित",
      "product.form.variant.availability.heading": "उपलब्धता",
      "product.form.variant.availability.label": "उपलब्ध",
      "user.accountBalance.availableBalance.label": "उपलब्ध शेष राशि",
      "order.details.location.b2b.pending.title": "बी2बी विवरण",
      "back.text": "वापस",
      "order.form.balanceAmount": "संतुलन",
      "settings.online.payment.bank.account.details": "बैंक के खाते का विवरण",
      "settings.online.payment.accountnumber": "बैंक खाता संख्या",
      "settings.online.payment.bank.name": "बैंक का नाम",
      "menu.item.web-banners": "बैनर",
      "product.form.variant.barcodes.heading": "बारकोड",
      "order.details.summary.price": "आधार मूल्य",
      "coupon.analytics.smsTab.basedOnDelivered": "डिलीवर के आधार पर",
      "coupon.analytics.emailTab.basedOnEmailsDelivered":
        "वितरित ईमेल के आधार पर",
      "coupon.analytics.emailTab.basedOnEmailsOpened":
        "खोले गए ईमेल के आधार पर",
      "logistics.config.tripGeneration.basic": "बुनियादी",
      "customer.details.pages.basicinfo": "बुनियादी जानकारी",
      "menu.item.basic-information": "मूलभूत जानकारी",
      "settings.online.payment.beneficiaryname": "लाभार्थी का नाम",
      "user.transactions.header.billingInfo": "विज्ञापन कार्य की जानकारी",
      "menu.item.blog": "ब्लॉग",
      "campaign.form.emailBody.heading": "शरीर",
      "settings.online.payment.branchifsccode": "शाखा IFSC कोड",
      "brand.header.brand": "ब्रांड",
      "product.form.brand.placeholder": "ब्रांड का नाम",
      "menu.item.brands": "ब्रांड्स",
      "themes.brand.show": "दिखाने के लिए ब्रांड",
      "offer.ordertype.options.bulk": "थोक",
      "menu.item.bulk-coupons": "थोक कूपन",
      "product.form.bulkThreshold.label": "थोक आदेश सीमा",
      "product.add.bulk.text": "सामूहिक अपलोड",
      "operations.store.form.businessHours.title": "काम करने के घंटे",
      "offer.buy": "खरीदना",
      "order.log.user": "द्वारा",
      "customer.details.call": "बुलाना",
      "customer.details.call.option.callToInternalEmployees":
        "आंतरिक कर्मचारियों को कॉल करें",
      "menu.item.calls": "कॉल",
      "campaign.header.campaign": "अभियान",
      "campaign.analytics.heading": "अभियान विश्लेषिकी",
      "menu.item.campaigns": "अभियान",
      "errorDialog.delete.error.title": "नहीं मिटा सकते",
      "logistics.config.leaveAtDoorstep":
        "क्या आरई दरवाजे पर ऑर्डर छोड़ सकता है?",
      "cancel.text": "रद्द करें",
      "order.action.cancel": "आदेश रद्द",
      "leaves.filter.status.approvedcancelleble": "रद्द करने योग्य",
      "order.status.cancelled": "रद्द",
      "menu.item.capacity-planning": "क्षमता की योजना",
      "cp.today": "क्षमता योजना आज",
      "order.details.payment.card": "कार्ड",
      "offer.cartprice": "गाड़ी की कीमत",
      "order.form.paymentMode.cash": "नकद",
      "order.form.cash.tendered": "नकद निविदा",
      "coupon.form.cashback": "नकदी वापस",
      "wallet.config.cashbackPercentage": "कैशबैक प्रतिशत",
      "menu.item.catalogue": "सूची",
      "menu.item.categories": "श्रेणियाँ",
      "themes.category.show": "दिखाने के लिए श्रेणियाँ",
      "product.filters.category.heading": "श्रेणी",
      "category.form.name.placeholder": "श्रेणी नाम",
      "category.tree.title": "श्रेणी वृक्ष",
      "themes.change.text": "परिवर्तन",
      "menu.item.change-password": "पासवर्ड बदलें",
      "themes.change.heading": "विषय को परिवर्तित करें",
      "notification.success.save": "परिवर्तन सफलतापूर्वक सहेजे गए",
      "shifts.checkin": "चेक इन",
      "vehiclePlanner.checkout.button": "चेक आउट",
      "shifts.checkout": "चेक आउट",
      "order.status.checked": "चेक किए गए",
      "employee.checkedInAt": "जॉचो",
      "order.status.checking": "चेकिंग",
      "dispute.choose": "चुनना",
      "themes.selection.heading": "एक लेआउट चुनें",
      "banners.popup.banner.selection.heading": "बैनर चुनें",
      "order.filters.status.placeholder": "ऑर्डर की स्थिति चुनें",
      "leaves.filter.status.placeholder": "स्थिति चुनें",
      "leaves.form.type.placeholder": "प्रकार चुनें",
      "onboarding.theme.heading": "अपनी थीम चुनें",
      "order.form.customer.city.heading": "शहर",
      "vehiclePlanner.filters.clearFiltersText": "स्पष्ट",
      "category.filters.clearFiltersText": "सभी साफ करें",
      "rackManagement.clear.sequence": "स्पष्ट अनुक्रम",
      "themes.add.text": "लेआउट जोड़ने के लिए यहां क्लिक करें",
      "orderDetails.uploadPrescription.uploadImagePlaceholderText":
        "छवियों को अपलोड या खींचने के लिए यहां क्लिक करें",
      "basicInfo.uploadImage.placeholder":
        "अपना लोगो अपलोड करने या खींचने के लिए यहां क्लिक करें",
      "productUpload.uploadFileText": "फाइल अपलोड करने के लिए यहां क्लिक करें",
      "brand.form.image.placeholder":
        "अपलोड करने के लिए यहां क्लिक करें, या अपनी छवि खींचें",
      "user.payment.redirect.link":
        "अकाउंट बैलेंस देखने के लिए यहां क्लिक करें",
      "polygon.form.map.description": "हटाने के लिए क्षेत्र पर क्लिक करें",
      "polygon.delete.text": "हटाने के लिए क्लिक करें",
      "product.form.variant.image.placeholder":
        "अपलोड करने के लिए क्लिक करें, या छवियों को यहां खींचें",
      "coupon.analytics.smsTab.clicked": "क्लिक किए गए",
      "coupon.analytics.emailTab.clickedThrough": "के माध्यम से क्लिक किया गया",
      "campaign.analytics.smsTab.table.header.clicks": "क्लिक (अद्वितीय)",
      "product.filters.clientId.heading": "ग्राहक ID",
      "product.form.clientItemId": "क्लाइंट आइटम आईडी",
      "offer.dialog.notification.close": "बंद करे",
      "trips.filter.byStatus.close": "बंद किया हुआ",
      "extensions.slot.businessClosingTime": "पर बंद हो जाता है",
      "operations.store.form.businessHours.closingTime": "बंद करने का समय",
      "logistics.config.orderBreakingMethod.clustering": "क्लस्टरिंग",
      "coupon.form.codPayments": "सीओडी",
      "order.details.summary.collectionCharge": "संग्रह शुल्क",
      "order.details.summary.collectionDiscount": "संग्रह छूट",
      "themes.cc.name": "संग्रह का नाम",
      "offer.combo.discount": "कॉम्बो",
      "coupon.form.comment.heading": "टिप्पणी",
      "menu.item.communication-configuration": "संचार विन्यास",
      "order.action.complete": "आदेश पूरा",
      "order.status.completed": "पूरा हुआ",
      "trips.details.orderList.completedTime": "पूरा समय",
      "tripPlanner.complete": "समापन",
      "order.table.header.completionTime": "समापन समय",
      "coupon.form.conditions.heading": "शर्तेँ",
      "themes.cc.config": "कॉन्फ़िगरेशन",
      "extensions.configure": "कॉन्फ़िगर",
      "deliveryArea.slotConfigure": "स्लॉट कॉन्फ़िगर करें",
      "product.form.delete.confirmText": "पुष्टि करना",
      "order.details.completion.confirmReturnItem": "आइटम की पुष्टि करें",
      "changePassword.confirmPassword.placeholder": "नए पासवर्ड की पुष्टि करें",
      "changePassword.different.confirmPassword":
        "पासवर्ड की पुष्टि करें नया पासवर्ड के समान नहीं है",
      "customer.table.header.contact": "संपर्क",
      "menu.item.contact-details": "सम्पर्क करने का विवरण",
      "category.helpItems.seo.description":
        "सामग्री विपणन विपणन का एक रूप है जो लक्षित दर्शकों के लिए ऑनलाइन सामग्री बनाने, प्रकाशित करने और वितरित करने पर केंद्रित है।",
      "offer.dialog.clone.proceed": "जारी रखना",
      "analytics.conversionPixel.heading": "रूपांतरण पिक्सेल",
      "coupon.download.form.error":
        "दिए गए उपसर्ग और प्रत्यय के लिए कूपन नहीं मिल सका",
      "operations.dashboard.counts": "गिनती",
      "entityMetaData.country": "देशों",
      "coupon.header.coupon": "कूपन",
      "coupon.analytics.heading": "कूपन विश्लेषिकी",
      "coupon.form.name.heading": "कूपन कोड",
      "order.details.summary.coupondiscount": "कूपन छूट",
      "coupon.form.discount.heading": "कूपन देता है",
      "menu.item.coupons": "कूपन",
      "order.packing.crates": "क्रेट",
      "login.newUser.signupText": "खाता बनाएं",
      "order.add.text": "नया आदेश बनाएं",
      "offer.clone.copy": "समान बनाएं",
      "signup.heading": "अपने खाते बनाएँ",
      "onboarding.createWebsite.text": "अभी अपनी वेबसाइट बनाएं",
      "tripPlanner.orderPlacedDate": "पर बनाया गया",
      "calls.filters.tag.option.cs": "सीएस",
      "customer.import.successText":
        "CSV फ़ाइल सफलतापूर्वक अपलोड की गई! ग्राहक जोड़े जाएंगे और 15 मिनट के बाद दिखाई देंगे",
      "fileUpload.uploadedCsv.heading":
        "CSV फ़ाइल सफलतापूर्वक अपलोड की गई! उत्पाद 15 मिनट के बाद दिखाई देंगे",
      "customer.tags.import.successText":
        "CSV फ़ाइल सफलतापूर्वक अपलोड की गई! टैग जोड़े जाएंगे और 15 मिनट के बाद दिखाई देंगे",
      "picker.status": "वर्तमान स्थिति",
      "dispute.emptyMessage": "वर्तमान में कोई विवाद नहीं है",
      "banners.empty.banner": "वर्तमान में कोई होम पेज बैनर उपलब्ध नहीं है",
      "customer.details.purchase.emptyText":
        "वर्तमान में इस ग्राहक के लिए कोई आदेश नहीं है",
      "pickers.emptyMessage": "वर्तमान में कोई पिकर नहीं है",
      "pickers.empty": "वर्तमान में कोई पिकर उपलब्ध नहीं है",
      "menu.item.metadata": "कस्टम फील्ड्स",
      "calls.filters.customer.label": "ग्राहक",
      "order.form.customer.email.heading": "ग्राहक ईमेल पता",
      "order.form.customer.name.heading": "ग्राहक का नाम",
      "order.log.customerNA": "ग्राहक उपलब्ध नहीं है",
      "notifications.customer.title": "ग्राहक सूचनाएं",
      "order.form.customer.phone.heading": "ग्राहक फोन नंबर",
      "offer.customer.redemption": "ग्राहक मोचन",
      "trips.details.customerImage": "ग्राहक के हस्ताक्षर",
      "menu.item.customer-management": "",
      "menu.item.customer-tags": "ग्राहक टैग",
      "menu.item.customers": "ग्राहकों",
      "operations.dashboard.customerAcquired": "ग्राहकों का अधिग्रहण",
      "operations.dashboard.customersTransacted": "ग्राहकों ने लेन-देन किया",
      "themes.customize.text": "अनुकूलित करें",
      "slots.cutoff.mins": "कटऑफ मिनटों में",
      "cutoff.time": "खत्म होने का समय",
      "cp.today.cutofftime": "कटऑफ समय 1",
      "coupon.analytics.dailyDistribution.heading": "दैनिक वितरण",
      "menu.item.dashboard": "डैशबोर्ड",
      "trips.filter.byDates.heading": "दिनांक",
      "campaign.analytics.emailTab.table.header.dateTime": "दिनांक समय",
      "order.log.date.changed": "तिथि परिवर्तित",
      "campaign.form.invalidDateTime":
        "दिनांक समय वर्तमान दिनांक समय से अधिक होना चाहिए",
      "operations.store.form.businessHours.day": "दिन",
      "coupon.form.days.heading": "दिन",
      "customer.contact.details.default": "चूक जाना",
      "orderprocessing.config.allStores": "डिफ़ॉल्ट सेटिंग्स",
      "rackManagement.rack.add.zone.default": "डिफ़ॉल्ट क्षेत्र",
      "catalogue.dashboard.helpItems.cm.title":
        "सामग्री विपणन स्थिति को परिभाषित करना",
      "delete.text": "हटाएं",
      "order.details.location.delivery.completed.title": "बितरण किया",
      "order.details.location.delivery.pending.title": "को वितरित करना",
      "extensions.deliveryArea.zones.heading": "वितरण क्षेत्र",
      "menu.item.delivery": "वितरण",
      "trips.header.deliveryAgent": "डिलीवरी कारक",
      "menu.item.delivery-area": "वितरण क्षेत्र",
      "deliveryArea.editSlot.heading": "वितरण क्षेत्र स्लॉट नियम",
      "deliveryCharge.heading.text": "डिलीवरी चार्ज",
      "offer.heading.deliveyDate": "डिलीवरी की तारीख",
      "extensions.deliveryArea.deliveryFee": "अर्पण शुल्क",
      "logistics.config.deliveryFee": "वितरण शुल्क",
      "description.heading.text": "विवरण",
      "shifts.designation": "पद",
      "menu.item.designations": "पदनाम",
      "coupon.analytics.pieChart.DESKTOP_NA_WEB": "डेस्कटॉप",
      "order.details.payment.details": "विवरण",
      "coupon.analytics.deviceDistribution.heading": "उपकरण वितरण",
      "substitutionGroup.action.disable": "अक्षम करना",
      "offer.clone.disableNClone": "अक्षम करें और नया बनाएं",
      "disabled.text": "विकलांग",
      "product.form.variant.discount.heading": "छूट",
      "product.form.discount.error": "छूट कीमत से अधिक नहीं हो सकती",
      "offer.discountvalue": "डिस्काउंट मूल्य",
      "order.status.dispatched": "भेजा गया",
      "product.config.checkoutAllowed":
        "वेबसाइट पर कार्ट में जोड़ें बटन प्रदर्शित करें",
      "product.config.showPriceToggle": "वेबसाइट पर मूल्य प्रदर्शित करें",
      "order.dispute.heading": "आदेश के लिए विवाद",
      "dispute.heading": "विवादित आदेश",
      "menu.item.disputes": "विवादों",
      "dispute.view.heading": "आदेश के लिए विवाद",
      "trips.header.distance": "दूरी",
      "extensions.deliveryArea.radial.title": "दुकान से दूरी",
      "tripPlanner.order.distance": "दूरी (किमी)",
      "orderDetails.uploadPrescription.doctorName": "डॉक्टर का नाम",
      "login.newUser.message": "खाता नहीं है?",
      "order.details.itemsTable.actions.confirmText": "किया हुआ",
      "orderprocessing.qualityChecking.donebychecker": "चेकर द्वारा किया गया",
      "orderprocessing.packingDetails.donebypacker": "पैकर द्वारा किया गया",
      "orderprocessing.packingDetails.donebypicker": "पिकर द्वारा किया गया",
      "product.downloadOptionsTitle": "डाउनलोड",
      "product.downloadSuccessTitle": "डाउनलोड करें {मान}",
      "tripPlanner.download.summary.text": "सीएसवी डाउनलोड करें",
      "analytics.report.modal.title": "रिपोर्ट डाउनलोड करें",
      "productUpload.csvDownloadText": "नमूना सीएसवी डाउनलोड करें",
      "orderDetails.uploadPrescription.dr": "डॉ।",
      "marketing.blog.table.status.draft": "प्रारूप",
      "radial.form.map.description":
        "स्थान चिह्नित करने के लिए खींचें और क्लिक करें",
      "banners.view.drag.text": "क्रम बदलने के लिए खींचें",
      "polygon.selectLocation.message": "मानचित्र पर क्षेत्र बनाएं",
      "extensions.deliveryArea.polygon.title": "मानचित्र पर ड्रा करें",
      "tripPlanner.unassignedOrders.placeholder":
        "यहां ऑर्डर ड्रॉप करें जो यात्रा के लिए तैयार नहीं हैं",
      "leaves.form.duration": "अवधि",
      "user.leave-summary.status.Earned": "अर्जित",
      "action.edit.text": "संपादित करें",
      "banners.editForm.header": "बैनर संपादित करें",
      "marketing.form.edit.heading": "ब्लॉग पोस्ट संपादित करें",
      "brand.edit.text": "ब्रांड संपादित करें",
      "campaign.form.edit.heading": "अभियान संपादित करें",
      "category.form.edit.heading": "श्रेणी संपादित करें",
      "sc.edit.heading": "कॉन्फ़िगरेशन संपादित करें",
      "coupon.form.edit.heading": "कूपन संपादित करें",
      "designations.editheader": "पदनाम संपादित करें",
      "radial.form.edit.heading": "दुकान से दूरी संपादित करें",
      "websitePage.popup.heading.image": "संपादित छवि",
      "websitePage.popup.imagewithtext.heading":
        "टेक्स्ट के साथ छवि संपादित करें",
      "offer.edit.heading": "ऑफ़र संपादित करें",
      "order.action.editPackedOrder": "पैक्ड ऑर्डर संपादित करें",
      "settings.users.action.editPermissions": "अनुमतियाँ संपादित करें",
      "picker.editHeading": "पिकर संपादित करें",
      "product.form.edit.heading": "उत्पाद संपादित करें",
      "role.edit.heading": "भूमिका संपादित करें",
      "cp.edit": "नियम संपादित करें",
      "vehiclePlanner.editShift.heading": "शिफ्ट संपादित करें",
      "slots.store.editHeading": "स्लॉट नियम संपादित करें",
      "websitePage.form.edit.heading": "स्थिर पृष्ठ संपादित करें",
      "operations.store.form.edit.heading": "स्टोर संपादित करें",
      "tag.edit.text": "टैग संपादित करें",
      "vehicles.header.editText": "वाहन संपादित करें",
      "pincode.form.edit.heading": "ज़िप कोड / पोस्टल कोड संपादित करें",
      "email.title.text": "ईमेल",
      "customer.details.email.added": "ईमेल सफलतापूर्वक जोड़ा गया",
      "notification.email.error": "ईमेल पहले से ही मौजूद है",
      "customer.details.pages.phonemail.mailId": "ईमेल आईडी",
      "customer.details.email.deleted":
        "ईमेल आईडी को सफलतापूर्वक हटा दिया गया है",
      "calls.filters.employee.label": "कर्मचारी",
      "attendance.summary.table.employee.name": "कर्मचारी का नाम",
      "menu.item.employees": "कर्मचारियों",
      "category.form.enableText": "सक्षम",
      "socialMedia.whatsappSupportEnabled": "व्हाट्सएप सपोर्ट सक्षम करें",
      "status.label.enabled": "सक्रिय",
      "offer.delivery-date.end": "अंतिम तिथि",
      "banners.message.invalidDate":
        "खत्म होने की तारीख, शुरू होने की तारीख के बाद की होनी चाहिए",
      "trips.header.endTime": "अंतिम समय",
      "trips.action.close": "यात्रा समाप्त करें",
      "trips.endTrip.label": "यात्रा समाप्त करें KM",
      "trip.details.endedAt": "पर समाप्त हुआ",
      "slots.endsAt": "खतम होता है",
      "leaves.team.endson": "पर समाप्त होता है",
      "product.form.enter": "प्रवेश करना",
      "input.invalidNumber": "एक सकारात्मक मान दर्ज करें",
      "tag.linkToProduct.product.placeholder": "उत्पाद का नाम दर्ज करें",
      "basicInfo.invalidDomain": "एक मान्य डोमेन नाम दर्ज करें",
      "analytics.invalidTrackingId": "एक मान्य ट्रैकिंग आईडी दर्ज करें",
      "substitutionGroup.form.percentageDeviation.placeholder":
        "0 और 100 . के बीच कोई मान दर्ज करें",
      "order.form.customer.address.placeholder": "पता दर्ज करें",
      "offer.alert.message.placeholder": "अलर्ट संदेश दर्ज करें",
      "attendance.summary.filter.form.employee.placeholder":
        "एक कर्मचारी का नाम दर्ज करें",
      "order.filters.referenceNumber.placeholder": "यहां ऑर्डर नंबर दर्ज करें",
      "brand.form.name.placeholder": "ब्रांड नाम दर्ज करें",
      "themes.bc.categories.placeholder": "दिखाने के लिए ब्रांड दर्ज करें",
      "product.form.bulkThreshold.placeholder": "थोक आदेश सीमा दर्ज करें",
      "calls.form.reason.placeholder": "कॉल कारण दर्ज करें",
      "calls.form.summary.placeholder": "कॉल सारांश दर्ज करें",
      "capacity.placeholder": "क्षमता दर्ज करें",
      "offer.cartprice.placeholder": "कार्ट मूल्य दर्ज करें",
      "themes.cc.categories.placeholder": "दिखाने के लिए श्रेणियां दर्ज करें",
      "sc.form.cat.placeholder": "श्रेणी दर्ज करें",
      "product.form.category.placeholder": "श्रेणी का नाम दर्ज करें",
      "order.form.customer.city.placeholder": "शहर दर्ज करें",
      "order.filters.clientId.placeholder": "क्लाइंट आईडी दर्ज करें",
      "product.form.clientItemId.placeholder": "क्लाइंट आइटम आईडी दर्ज करें",
      "themes.cc.name.placeholder": "संग्रह का नाम दर्ज करें",
      "order.form.customerDetails.heading": "ग्राहक विवरण दर्ज करें",
      "order.form.customer.email.placeholder": "ग्राहक ईमेल दर्ज करें",
      "order.form.customer.name.placeholder": "ग्राहक का नाम दर्ज करें",
      "offer.customer.redemption.placeholder": "ग्राहक मोचन दर्ज करें",
      "slots.cutoff.placeholder": "कटऑफ समय मिनटों में दर्ज करें",
      "logistics.config.deliveryFee.placeholder": "वितरण शुल्क दर्ज करें",
      "offer.description.placeholder": "विवरण दर्ज करें",
      "shifts.designation.placeholder": "पदनाम दर्ज करें",
      "orderDetails.uploadPrescription.enterDoctorName":
        "डॉक्टर का नाम दर्ज करें",
      "shifts.form.email.placeholder": "ईमेल आईडी दर्ज करें",
      "videoCarousel.videoUrl.placeholder": "एम्बेड वीडियो URL दर्ज करें",
      "banners.form.endTime.placeholder": "समाप्ति समय दर्ज करें",
      "offer.entityid.placeholder": "इकाई आईडी दर्ज करें",
      "extensions.enterExtensionName": "एक्सटेंशन नाम दर्ज करें",
      "entityMetaData.text.placeholder": "कुंजी दर्ज",
      "order.form.customer.landmark.placeholder": "मील का पत्थर दर्ज करें",
      "designations.form.manager.placeholder": "प्रबंधक पद दर्ज करें",
      "offer.min.amount.placeholder": "न्यूनतम राशि दर्ज करें",
      "offer.min.quantity.placeholder": "न्यूनतम मात्रा दर्ज करें",
      "substitutionGroup.form.product.placeholder": "उत्पाद का नाम दर्ज करें",
      "entityMetaData.number.placeholder": "नंबर डालें",
      "changePassword.oldPassword.placeholder": "पुराना पासवर्ड दर्ज करें",
      "offer.max.redemption.placeholder": "आदेश स्तर मोचन दर्ज करें",
      "return.filter.referenceNumber.placeholder":
        "आदेश संदर्भ संख्या दर्ज करें",
      "order.details.packages.multi-text.placeholder": "पैकेज लेबल दर्ज करें",
      "orderDetails.uploadPrescription.enterPatientName":
        "रोगी का नाम दर्ज करें",
      "offer.payment.type.placeholder": "भुगतान प्रकार दर्ज करें",
      "order.form.customer.pincode.placeholder": "पिनकोड दर्ज करें",
      "product.form.handlingDays.placeholder":
        "प्री-ऑर्डर हैंडलिंग दिन दर्ज करें",
      "product.form.description.placeholder": "उत्पाद विवरण दर्ज करें",
      "product.form.name.placeholder": "उत्पाद का नाम दर्ज करें",
      "coupon.promocode.placeholder": "प्रचार कि नियमावली दर्ज करो",
      "offer.quantity.placeholder": "मात्रा दर्ज करें",
      "leaves.form.reason.placeholder": "कारण दर्ज करें",
      "designations.form.roles.placeholder": "भूमिकाएं दर्ज करें",
      "search-config.searchurls.placeholder": "ढूंढने के लिए कीवर्ड भरें",
      "banners.select.search.term.placeholder": "खोज शब्द दर्ज करें",
      "product.form.secondarycategory.placeholder":
        "माध्यमिक श्रेणियां दर्ज करें",
      "banners.form.startTime.placeholder": "प्रारंभ समय दर्ज करें",
      "settings.online.payment.enterstate": "राज्य दर्ज करें",
      "customer-service-tags.table.name.placeholder": "तालिका का नाम दर्ज करें",
      "category.form.tagline.placeholder": "टैग लाइन दर्ज करें",
      "product.form.tag.placeholder": "टैग नाम दर्ज करें",
      "campaign.form.pushText.placeholder": "पाठ यहां लिखें",
      "videoCarousel.description.placeholder": "यहां विवरण दर्ज करें",
      "trips.endTrip.placeholder": "ओडोमीटर पर प्रदर्शित KM दर्ज करें",
      "shifts.confirmpassword.placeholder": "पासवर्ड फिर से दर्ज करें",
      "banners.form.search.term.placeholder": "खोज शब्द दर्ज करें",
      "banners.form.sequence.placeholder": "अनुक्रम दर्ज करें",
      "imagewithtext.placeholder": "शीर्षक दर्ज करें",
      "campaign.form.pushSubject.placeholder": "यहां शीर्षक दर्ज करें",
      "offer.total.redemption.placeholder": "कुल मोचन दर्ज करें",
      "mobileApps.shortDescription.placeholder": "30 वर्ण तक दर्ज करें",
      "campaign.form.pushUrl.placeholder": "यहां यूआरएल दर्ज करें",
      "settings.users.inviteForm.email.placeholder":
        "उपयोगकर्ता का ईमेल दर्ज करें",
      "settings.users.inviteForm.name.placeholder":
        "उपयोगकर्ता का नाम दर्ज करें",
      "settings.users.inviteForm.phone.placeholder":
        "उपयोगकर्ता का फ़ोन नंबर दर्ज करें",
      "enitityMetaData.values.placeholder": "मान दर्ज",
      "vehiclePlanner.addShift.form.regular.count": "वाहन संख्या दर्ज करें",
      "vehicles.form.number.placeholder": "वाहन संख्या दर्ज करें",
      "vehicles.form.vendor.placeholder": "विक्रेता का नाम दर्ज करें",
      "user.accountBalance.input.placeholder": "अपनी राशि दर्ज करें",
      "mobileApps.name.placeholder": "अपने ऐप का नाम दर्ज करें",
      "login.subheading": "अपना विवरण नीचे दर्ज करें",
      "login.email.placeholder": "अपना ईमेल पता दर्ज करें",
      "integrationSettings.gtm.placeholder":
        "अपना Google टैग प्रबंधक आईडी दर्ज करें",
      "signup.name.placeholder": "अपना नाम दर्ज करें",
      "label.enter.phone.number": "अपना फोन नंबर डालें",
      "extensions.tawk.site.id.placeholder": "अपनी साइट आईडी दर्ज करें",
      "basicInfo.storeurl.placeholder": "अपने स्टोर का नाम दर्ज करें",
      "operations.store.form.address.placeholder":
        "अपने स्टोर का पूरा पता दर्ज करें",
      "marketing.blog.form.title.placeholder": "अपना शीर्षक यहां दर्ज करें",
      "extensions.tawk.widget.id.placeholder": "अपना विजेट आईडी दर्ज करें",
      "rackManagement.rack.add.zone.placeholder": "क्षेत्र दर्ज करें",
      "offer.entity.id": "इकाई आईडी",
      "offer.entity": "इकाई प्रकार",
      "error.text": "त्रुटि",
      "product.form.imageUploade.error": "अपलोड करने में त्रुटि",
      "offer.dialog.disable.errorText":
        "ऑफ़र को अक्षम करते समय त्रुटि हुई. कृपया एगिन का प्रयास करें।",
      "banners.radio.option.expired": "समय सीमा समाप्त बैनर",
      "coupon.form.endDate.placeholder": "समाप्ति तिथि",
      "menu.item.extensions": "एक्सटेंशन",
      "media.select.facebook": "फेसबुक",
      "order.status.failed": "अनुत्तीर्ण होना",
      "product.form.stores.fetchError":
        "कुछ स्टोर लोड करने में विफल. पुनः प्रयास करने के लिए यहां पर क्लिक करें।",
      "user.accountBalance.loader.text":
        "आपका संतुलन लाया जा रहा है। कृपया प्रतीक्षा करें...",
      "input.fileSizeExeeded": "फ़ाइल का आकार समाप्त हो गया",
      "input.invalidFileType": "फ़ाइल प्रकार अमान्य है.",
      "dispute.filingTime": "फाइलिंग समय",
      "entityMetaData.checkboxLabel": "फ़िल्टर",
      "themes.tag.filter.label": "फ़िल्टर दिखना चाहिए",
      "order.details.itemsTable.header.picked": "अंतिम मात्रा",
      "trips.filter.byStatus.completed": "आर्थिक रूप से बंद",
      "designations.form.timingtype.fixed": "फिक्स्ड",
      "designations.form.timingtype.flexible": "लचीला",
      "videoCarousel.fontSize.label": "फ़ॉन्ट आकार (पीएक्स में)",
      "videoCarousel.fontSize.placeholder": "px . में वर्णन के लिए फ़ॉन्ट आकार",
      "login.forgotPassword": "पासवर्ड भूल गए",
      "resetPassword.heading": "पासवर्ड भूल गए?",
      Friday: "शुक्र",
      "calls.table.heading.from": "से",
      "category.helpItems.title": "ब्लॉग से",
      "radial.table.description": "दुकान के स्थान से",
      "order.packing.frozenBoxes": "जमे हुए बक्से",
      "extensions.analytics.gaAccount.heading": "जीए खाता",
      "offer.get": "पाना",
      "banners.editForm.radio.option.global": "वैश्विक",
      "resetPassword.loginLinkText": "लॉगिन पर जाएं",
      "catalogue.dashboard.helpItems.gp.title":
        "अच्छा उत्पाद विवरण पैसे बचाता है",
      "analytics.tracking.heading": "GOOGLE ANALYTICS ट्रैकिंग आईडी",
      "googleMaps.addressMap.error":
        "Google मानचित्र API स्थिति के साथ पता मानचित्र पर विफल रहा",
      "integrationSettings.googleMapsKey.label": "गूगल मैप्स एपीआई कुंजी",
      "integrationSettings.gtm.label": "Google टैग प्रबंधक आईडी",
      "themes.grid": "ग्रिड",
      "attendance.summary.table.HALFDAY": "आधा दिन",
      "handle.text": "हैंडल",
      "operations.store.form.hasDeliveryHub": "डिलीवरी हब है",
      "operations.store.table.header.deliveyHub": "डेलीवी हब है",
      "operations.store.table.header.picking": "उठा रहा है",
      "offer.field.hasUniquePromocode.label": "अद्वितीय प्रोमोकोड है",
      "product.helpItems.submessage":
        "यहां आप मूल्य निर्धारण सहित उत्पादों के बारे में सभी जानकारी प्रदान कर सकते हैं और कुछ अच्छी छवियां अपलोड कर सकते हैं।",
      "vehicles.emptyState.helperText":
        "यहां आपको आपके लिए उपलब्ध डिलीवरी वाहनों की एक सूची दिखाई देगी।",
      "brand.status.hidden": "छिपा हुआ",
      "category.form.hideText": "छिपाना",
      "banners.default.page": "घर",
      "logistics.config.reseesOrders": "आरई आदेशों को कैसे देखता है?",
      "extensions.slot.numOfDays": "ग्राहक को कितने दिन दिखाना है?",
      "customer.helpItems.title": "ग्राहक कैसे प्राप्त करें?",
      "order.helpItems.title": "ऑर्डर कैसे प्राप्त करें?",
      "menu.item.hr": "मानव संसाधन",
      "settings.online.payment.ihaveagateway": "मेरे पास पेमेंट गेटवे है",
      "extensions.policy": "मैंने गोपनीयता नीति पढ़ ली है और उससे सहमत हूं",
      "extensions.icon": "आइकन",
      "trips.header.id": "पहचान",
      "pickers.today.idle.pickers": "बेकार",
      "pickers.idle.header": "निष्क्रिय बीनने वाले",
      "category.header.image": "छवि",
      "dualImageBanner.image1": "छवि 1",
      "dualImageBanner.image2": "छवि 2",
      "themes.images": "इमेजिस",
      "category.helpItems.poc.description":
        "छवियां आपके लैंडिंग पृष्ठ की अपील को बेहतर बनाती हैं जो सीधे बेहतर रूपांतरण से संबंधित होती है।",
      "fileUpload.importCsv.heading": "CSV फ़ाइल आयात करें",
      "customer.import.text": "आयात ग्राहक",
      "fileUpload.importKML.heading": "KML फ़ाइल आयात करें",
      "tag.import": "आयात टैग",
      "customer.helpItems.iec.title": "अपने मौजूदा ग्राहकों को आयात करें",
      "calls.filters.type.option.inbound": "भीतर का",
      "offer.includes": "शामिल",
      "product.form.soldByWeight.pattern": "गलत नाम पैटर्न",
      "offer.individual.discount": "व्यक्ति",
      "themes.infiniteScroll": "अनंत स्क्रॉलर",
      "extensions.install": "इंस्टॉल",
      "extensions.installExtension": "एक्सटेंशन स्थापित करें",
      "extensions.installedSuccessfully": "सफलतापूर्वक स्थापित",
      "menu.item.instore": "इंस्टाल प्रोसेसिंग",
      "menu.item.integration-settings": "एकीकरण सेटिंग्स",
      "marketing.table.heading.interaction": "परस्पर क्रिया",
      "trips.details.invalid-id": "अमान्य ट्रिप आईडी",
      "product.type.inventory": "इन्वेंटरी शीट",
      "settings.users.inviteForm.invite.buttonText": "आमंत्रण",
      "settings.users.invite.text": "उपयोगकर्ता को आमंत्रित करें",
      "order.details.invoice.title": "बीजक",
      "coupon.analytics.pieChart.MOBILE_IOS_WEB": "आईओएस",
      "operations.store.form.hasPicking.label": "चयन यहाँ किया जाता है?",
      "operations.store.form.hasClickCollect.label":
        "क्या यह एक संग्रह केंद्र है?",
      "operations.store.form.hasDeliveryHub.label": "क्या यह डिलीवरी हब है?",
      "order.log.itemName": "आइटम नाम",
      "order.details.itemsTable.itemsInOrder.unavailabelText": "आइटम अनुपलब्ध",
      "tripPlanner.items": "आइटम गिनती",
      "order.details.itemsTable.title": "क्रम में आइटम",
      "customer.table.header.joinDate": "संलग्न तिथि",
      "customer.table.header.joinDateLastPurchase":
        "ज्वाइन करने की तिथि / अंतिम खरीदारी",
      "customer.details.header.joined": "में शामिल हो गए",
      "customer.joinedOn": "पर शामिल हुए",
      "key.label.text": "चाभी",
      "trip.details.km": "किमी",
      "fileUpload.uploadedKML.heading": "KML फ़ाइल सफलतापूर्वक अपलोड की गई!",
      "deliveryArea.kml.upload.text": "केएमएल अपलोड",
      "order.packing.labels": "लेबल",
      "order.form.customer.landmark.heading": "सीमाचिह्न",
      "banners.languages": "भाषा",
      "order.packing.largeBags": "बड़े बैग",
      "order.packing.largeBoxes": "बड़े बक्से",
      "campaign.analytics.table.heading": "पिछले 10 खुले",
      "customer.details.header.lastPurchase": "पिछली खरीद",
      "customer.table.header.lastPurchased": "पिछली बार ख़रीदा गया",
      "extensions.lastUpdated": "आखरी अपडेट",
      "campaign.analytics.smsTab.table.header.lastVisit": "पिछला आगमन",
      "pickers.today.late": "देर",
      "customer.details.call.option.lateDelivery": "विलम्ब से वितरण",
      "tripPlanner.order.lat": "अक्षांश",
      "themes.layout.timeline.heading": "लेआउट समयरेखा",
      "themes.layout.save.success": "लेआउट सहेजे गए!",
      "extensions.seo.read.more": "और अधिक जानें",
      "menu.item.leave-management": "प्रबंधन छोड़ दो",
      "menu.item.leave-summary": "सारांश छोड़ें",
      "leaves.team.approveable": "टीम के सदस्यों द्वारा लगाए गए पत्ते",
      "coupon.header.limit": "सीमा",
      "coupon.form.limits.heading": "सीमाएं",
      "dualImageBanner.link": "संपर्क",
      "settings.online.payment.link.bank.account": "बैंक खाता लिंक करें",
      "online.payments.link.bank.account": "लिंक बैंक खाता",
      "dualImageBanner.linkText": "लिंक पाठ",
      "tag.action.linkToProduct": "उत्पादों के लिए लिंक",
      "banners.editForm.link.label": "लिंक यूआरएल",
      "campaign.form.vairableList.heading": "उपलब्ध चरों की सूची",
      "themes.loadMoreType": "अधिक उत्पादों का उपयोग करके लोड करें",
      "mobileApps.theme.loadingText": "विषय लोड हो रहा है",
      "product.form.variant.location.header": "स्थान",
      "login.text": "लॉग इन करें",
      "menu.item.logistics": "रसद",
      "logistics.config.heading": "रसद सेटिंग्स",
      "brand.header.logo": "प्रतीक चिन्ह",
      "menu.item.logout": "लॉग आउट",
      "mobileApps.longDescription.heading": "लंबा विवरण",
      "tripPlanner.order.lng": "देशान्तर",
      "order.paymentmode.LOYALTY": "निष्ठा",
      "banners.header": "बैनर प्रबंधित करें",
      "menu.item.pickers": "पिकर प्रबंधित करें",
      "menu.item.manage-runners": "रनर एक्जीक्यूटिव प्रबंधित करें",
      "vehicles.heading": "वाहनों का प्रबंधन करें",
      "designations.header.manager": "प्रबंधक",
      "designations.form.roles": "भूमिकाओं के लिए मैप किया गया",
      "polygon.table.description": "चिह्नित स्थान",
      "menu.item.marketing": "विपणन",
      "user.leave-summary.status.Maternity": "मातृत्व",
      "offer.maxdiscountvalue": "अधिकतम छूट",
      "store.max.purchaseable.qty": "अधिकतम क्रय योग्य मात्रा",
      "store.max.purchaseable.quantity": "अधिकतम मात्रा",
      "campaign.form.maxcharacters.description": "मैक्स। 150 वर्ण",
      "coupon.form.maxDiscountAmount.heading": "मैक्स। छूट राशि",
      "coupon.form.maxRedemption.heading": "मैक्स। मोचन",
      "coupon.form.maxSingleRedemption.heading":
        "मैक्स। एकल उपयोगकर्ता द्वारा मोचन",
      "order.packing.mediumBoxes": "मध्यम बक्से",
      "slot.meridian": "मध्याह्न",
      "customer.details.sms.message": "संदेश",
      "offer.min.amount.label": "न्यूनतम राशि",
      "offer.min.quantity": "न्यूनतम मात्रा",
      "coupon.form.minOrderAmount.heading": "न्यूनतम। ऑर्डर करने की राशि",
      "offer.min.amount": "न्यूनतम राशि",
      "orderConfig.form.minimumOrderValue.heading": "न्यूनतम आदेश मूल्य",
      asapDurationEndText: "मिनट",
      "calls.filters.status.option.missed": "चुक गया",
      "customer.callWidget.form.reason.missedCallback": "मिस्ड कॉल बैक",
      "customer.details.call.option.missedCallback": "मिस्ड कॉलबैक",
      "menu.item.mobile-apps": "मोबाईल ऐप्स",
      "notification.phone.error": "मोबाइल नंबर पहले से मौजूद है",
      "order.details.payment.mode": "तरीका",
      Monday: "सोमवार",
      "extensions.monthlyBillingEstimate": "मासिक बिलिंग अनुमान",
      "extensions.monthlyEstimate": "मासिक अनुमान",
      "return.move.order": "आदेश को यहां ले जाएं",
      "order.action.moveToPending": "लंबित में ले जाएं",
      "search-configuration.header.mrp": "एम आर पी",
      "order.paymentmode.MSWIPE": "एमस्वाइप",
      "menu.item.users": "मल्टी यूजर सपोर्ट",
      "extensions.multiLingual.heading": "बहुभाषी समर्थन",
      "entityMetaData.checkboxMultivaluedLabel": "बहुमान",
      "menu.item.my-account": "मेरा खाता",
      "trips.details.orderList.transactionId.na": "ना",
      "name.heading.text": "नाम",
      "mobileApps.name.heading": "ऐप का नाम",
      "substitutionGroup.form.name.placeholder":
        "इस प्रतिस्थापन समूह का नाम बताइए",
      "themes.customize.nav": "मार्गदर्शन",
      "navigation.sequencing.heading": "नेविगेशन अनुक्रमण",
      "order.details.summary.netAmount": "शुद्ध राशि",
      "coupon.form.newUsers": "नया",
      "campaign.form.add.heading": "नया अभियान",
      "order.helpItems.nca.title": "नया ग्राहक अधिग्रहण",
      "changePassword.newPassword.heading": "नया पासवर्ड",
      "order.log.newQuantity": "नई मात्रा",
      "logistics.config.no": "नहीं",
      "vehiclePlanner.vehicleList.novehicle":
        "कोई {type} वाहन चेक इन नहीं किया गया",
      "attendance.summary.empty.message": "कोई उपस्थिति विवरण नहीं मिला",
      "calls.emptyState.text": "कोई कॉल नहीं मिला",
      "customerSupport.search.noCustomer": "कोई ग्राहक नहीं मिला",
      "slots.DELIVERY.empty": "कॉन्फ़िगर करने के लिए कोई डिलीवरी स्लॉट नहीं",
      "attendance.label.noDesignation": "कोई पदनाम नहीं",
      "return.no.items.to.return":
        "इस आदेश के लिए वापस जाने के लिए कोई आइटम नहीं है",
      "leaves.team.empty.message": "कोई पत्ता नहीं",
      "customerSupport.search.noOrder": "कोई आदेश नहीं मिला",
      "order.logs.emptyMeassage": "इस आदेश के लिए कोई आदेश लॉग नहीं",
      "slots.PICKUP.empty": "कॉन्फ़िगर करने के लिए कोई पिकअप स्लॉट नहीं",
      "vehiclePlanner.emptyState.message": "कोई नियोजित शिफ्ट नहीं मिली",
      "navigation.nosequence.display": "प्रदर्शित करने के लिए कोई अनुक्रम नहीं",
      "customer-service-tags.noTags": "कोई टैग कॉन्फ़िगर नहीं किया गया",
      "settings.users.permissionsForm.copyPlaceholder.name":
        "कोई उपयोगकर्ता नहीं",
      "customer.reset.password.cancelText": "नहीं, रद्द करें",
      "attendance.label.notCheckedIn": "दर्ज नहीं",
      "orderprocessing.qualityChecking.notneeded": "जरूरत नहीं",
      "email.not.verified.text": "प्रमाणित नहीं है",
      "offer.dialog.notification.label": "अधिसूचना",
      "campaign.form.pushText.heading": "अधिसूचना पाठ",
      "campaign.form.pushSubject.heading": "अधिसूचना शीर्षक",
      "menu.item.notifications": "सूचनाएं",
      "vehicles.header.number": "संख्या",
      "trip.details.odometerImage": "ओडोमीटर छवि",
      "picker.offDays": "छुट्टी वाले दिनों",
      "menu.item.offers": "ऑफर",
      "socialMedia.google.heading": "आधिकारिक Google+ पृष्ठ",
      "store.ios.heading": "आधिकारिक आईओएस पेज",
      "store.android.heading": "आधिकारिक प्लेस्टोर पेज",
      "socialMedia.twitter.heading": "आधिकारिक ट्विटर पेज",
      "offer.ordertype.options.offline": "ऑफलाइन",
      "order.details.location.offline.pending.title": "ऑफलाइन विवरण",
      "customer.helpItems.om.title": "ऑफलाइन मार्केटिंग",
      "ok.text": "ठीक",
      "okay.text": "ठीक",
      "changePassword.oldPassword.heading": "पुराना पासवर्ड",
      "order.log.oldQuantity": "पुरानी मात्रा",
      "orderprocessing.pickingView.onebyone": "एक क",
      "coupon.form.onlinePayments": "ऑनलाइन",
      "menu.item.payment-configuration": "भुगतान",
      "dispute.only deliver": "केवल उद्धार",
      "orderprocessing.orderLabelFormat.onlyordernumber": "केवल आदेश संख्या",
      "dispute.only refund": "केवल धनवापसी",
      "themes.layout.save.title.error": "उफ़",
      "errorDialog.title": "उफ़!",
      "trips.filter.byStatus.open": "खोलना",
      "coupon.analytics.emailTab.opened": "खुल गया",
      "operations.store.form.businessHours.openingTime": "खुलने का समय",
      "menu.item.operations": "संचालन",
      "operations.dashboard.heading": "संचालन डैशबोर्ड",
      "productUpload.orText": "या",
      "login.or.text": "या",
      "onboarding.basicinfo.url.subtext": "या उपयोग करें",
      "trip.details.orderList.singleOrder.heading": "आदेश",
      "trips.payment.order.id": "आदेश #",
      "order.details.invoice.referenceNumber": "आदेश #{referenceNumber}",
      "order.details.summary.orderAmount": "ऑर्डर करने की राशि",
      "logistics.config.orderBreakingMethod": "आदेश तोड़ने की विधि",
      "menu.item.order-config": "आदेश विन्यास",
      "customer.details.call.option.orderConfirmation": "आदेश की पुष्टि",
      "order.metaData.heading": "कस्टम फ़ील्ड ऑर्डर करें",
      "customer.details.call.option.orderEdit": "आदेश संपादित करें",
      "dispute.orderItem": "चीजों की फरमाइश करें",
      "order.log.orderItemId": "ऑर्डर आइटम आईडी",
      "orderprocessing.orderLabelFormat.heading": "ऑर्डर लेबल प्रारूप",
      "orderprocessing.orderLabelPrinting.heading": "ऑर्डर लेबल प्रिंटिंग",
      "offer.max.redemption": "आदेश स्तर मोचन",
      "order.action.order.logs.for": "आदेश लॉग के लिए",
      "order.table.header.referenceNumber": "आदेश संख्या।",
      "notifications.order.title": "आदेश सूचनाएं",
      "trips.filter.byOrderNumber.heading": "क्रम संख्या",
      "order.form.save.success.title": "आदेश सफलतापूर्वक दिया गया!",
      "orderprocessing.config.heading": "आदेश प्रसंस्करण सेटिंग्स",
      "order.log.return": "आदेश वापसी",
      "order.table.header.expectedTime": "आदेश स्लॉट",
      "order.gridItem.status.title": "आदेश की स्थिति",
      "trips.details.orderList.orderTime": "आदेश का समय",
      "offer.ordertype.label": "आदेश प्रकार",
      "order.config.orderTypes": "आदेश प्रकार",
      "order.log.leftAtDoorStep": "आदेश दरवाजे पर छोड़ दिया गया था",
      "menu.item.orders": "आदेश",
      "menu.item.orders-analytics": "आदेश विश्लेषिकी",
      "tripPlanner.unassignedOrders.title": "यात्रा के लिए तैयार नहीं आदेश",
      "operations.dashboard.orderSlot.heading": "आज के आदेश",
      "login.organisation.heading": "संगठन",
      "order.details.itemsTable.header.quantity": "मूल मात्रा",
      "customer.details.call.option.other": "अन्य",
      "product.form.other.details.heading": "अन्य जानकारी",
      "customer.callWidget.form.otherReason.heading": "दूसरी वजह",
      "calls.filters.tag.option.others": "अन्य",
      "calls.filters.type.option.outbound": "आउटबाउंड",
      "themes.searchable.label": "इसके लिए लेआउट ओवरराइड करें",
      "order.action.packed": "पैक ऑर्डर",
      "order.details.packages.multi-text.label": "संकुल",
      "order.status.packed": "पैक्ड",
      "order.details.edit-packed.heading": "पैक्ड ऑर्डर एडिटिंग",
      "order.status.packing": "पैकिंग",
      "order.details.packing.heading": "पैकिंग विवरण",
      "websitePage.form.title.heading": "पृष्ठ का शीर्षक",
      "banners.select.page.type": "पृष्ठ प्रकार",
      "menu.item.pages": "पृष्ठों",
      "order.payment.paid": "भुगतान किया गया",
      "category.form.parentCategory.heading": "अभिभावक श्रेणी",
      "order.status.partiallypicked": "आंशिक रूप से चुना गया",
      "login.password.heading": "पासवर्ड",
      "shifts.reset.success.information":
        "पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है",
      "changePassword.list.heading": "पासवर्ड में अवश्य होना चाहिए",
      "extensions.password-policy.heading": "पासवर्ड नीति",
      "changePassword.save.success.title": "पासवर्ड सफलतापूर्वक बदल दिया है",
      "customer.save.success.title": "पासवर्ड सफलतापूर्वक बदल दिया है!",
      "shifts.passwords.dont.match": "पासवर्ड मेल नहीं खाते",
      "user.leave-summary.status.Paternity": "पितृत्व",
      "orderDetails.uploadPrescription.patientName": "मरीज का नाम",
      "user.transactions.header.payment": "भुगतान",
      "order.log.payment.cancelled": "भुगतान रद्द",
      "order.log.payment.changed": "भुगतान बदल गया",
      "order.log.payment.completed": "भुगतान पूरा हुआ",
      "order.action.viewPayments": "भुगतान विवरण",
      "coupon.analytics.paymentDistribution.heading": "भुगतान वितरण",
      "user.payment.fail.title": "भुगतान असफल हुआ",
      "settings.online.payment.gateway": "अदायगी रास्ता",
      "settings.online.payment.gateway.saved":
        "पेमेंट गेटवे सफलतापूर्वक सहेजा गया",
      "order.log.payment.initiated": "भुगतान शुरू किया गया",
      "order.payment.paymentmode": "भुगतान विधि",
      "coupon.form.validOnPayment.heading": "भुगतान का प्रकार",
      "order.form.paymentMode.heading": "भुगतान विकल्प",
      "customer.details.call.option.paymentReminder": "भुगतान रिमाईन्डर",
      "order.filters.paymentStatus": "भुगतान की स्थिति",
      "user.payment.success.title": "भुगतान सफल",
      "offer.payment.type": "भुगतान के प्रकार",
      "order.status.pending": "लंबित",
      "order.details.summary.pendingAmount": "बकाया राशि",
      "trips.payment.order": "आदेश के लिए लंबित भुगतान",
      "menu.item.returns": "लंबित रिटर्न",
      "offer.percentoff": "प्रतिशत छूट",
      "substitutionGroup.form.percentageDeviation.heading": "प्रतिशत विचलन",
      "settings.users.permissionsForm.headingWithoutName": "अनुमतियां",
      "settings.users.permissionsForm.heading": "{नाम} के लिए अनुमतियां",
      "phone.label.text": "फ़ोन",
      "customer.details.pages.phoneemail": "फोन / ईमेल",
      "notification.save.success.phone": "फ़ोन सफलतापूर्वक जोड़ा गया",
      "customer.details.phone.added": "फ़ोन नंबर सफलतापूर्वक जोड़ा गया",
      "customer.details.phone.deleted": "फ़ोन नंबर सफलतापूर्वक हटा दिया गया है",
      "calls.filters.status.option.picked": "उठाया",
      "tripPlanner.picker.name": "कुदाल",
      "pickers.today.title": "पिकर्स टुडे",
      "order.status.picking": "उठा",
      "picking.config.heading": "कॉन्फ़िगरेशन चुनना",
      "tripPlanner.picker.endTime": "अंत चुनना",
      "menu.item.picking-queue": "कतार चुनना",
      "tripPlanner.picker.startTime": "शुरुआत चुनना",
      "offer.ordertype.options.pickup": "पिक अप",
      "dispute.pickup and refund": "पिकअप और रिफंड",
      "dispute.pickup and replace": "पिकअप और बदलें",
      "order.details.location.pickup.pending.title": "पिकअप विवरण",
      "order.form.pickupLocation.heading": "उठाने की जगह",
      "trips.pickuppending": "पिकअप लंबित आइटम",
      "order.form.customer.pincode.heading": "पिन कोड",
      "category.helpItems.poc.title": "अति-वर्गीकरण के नुकसान",
      "order.form.add.heading": "नया आदेश दें",
      "order.form.submit.text": "आदेश देना",
      "tripPlanner.orderPlacedTime": "पर रखा गया",
      "order.log.placedfrom": "से रखा गया",
      "order.filters.placedOn": "पर रखा",
      "order.table.header.placedTime": "निर्धारित समय",
      "input.invalidFormatMessage": "कृपया प्रारूप की जांच करें",
      "input.rangeUnderflow": "कृपया एक बड़ा मान दर्ज करें",
      "input.rangeOverflow": "कृपया एक छोटा मान दर्ज करें",
      "input.invalidEmailFormat": "कृपया एक वैध ई - मेल एड्रेस डालें",
      "input.invalidPhoneFormat": "कृपया एक मान्य फोन नंबर दर्ज करें",
      "input.invalidUsernameFormat": "एक मान्य उपयोगकर्ता का नाम दर्ज करें",
      "dualImageBanner.InputLinkPlaceholder": "कृपया यहां लिंक दर्ज करें",
      "dualImageBanner.InputSubtitlePlaceholder":
        "कृपया उपशीर्षक यहां दर्ज करें",
      "dualImageGrid.enterSubtitle": "कृपया यहां उपशीर्षक दर्ज करें",
      "websitePage.form.title.placeholder":
        "कृपया अपने पृष्ठ का शीर्षक दर्ज करें",
      "dualImageGrid.enterTitle": "कृपया यहां शीर्षक दर्ज करें",
      "customer.callWidget.form.reason.placeholder": "कृपया एक विकल्प चुनें",
      "operations.store.form.location.requiredMessage":
        "कृपया स्टोर का स्थान निर्धारित करें",
      "input.isUploading":
        "फ़ाइल सर्वर पर भेजी जा रही है, कृपया प्रतीक्षा करें",
      "campaign.form.time.pm": "बजे",
      "deliveryArea.filters.configType.label.polygon": "बहुभुज",
      "position.text.label": "पद",
      "marketing.blog.form.title.heading": "शीर्षक पोस्ट करें",
      "order.details.preorder": "पूर्व के आदेश",
      "order.details.preferredDate": "वरीय तिथि",
      "trips.details.orderList.preferredSlot": "पसंदीदा स्लॉट",
      "order.filters.preorder": "पूर्व आदेश",
      "product.form.handlingDays.label": "प्री-ऑर्डर हैंडलिंग दिन",
      "order.paymentmode.PREPAID": "प्रीपेड",
      "orderDetails.uploadPrescription.prescription": "नुस्खा",
      "attendance.summary.table.PRESENT": "वर्तमान",
      "order.details.removeItem.message":
        "इस आइटम को ऑर्डर से हटाने के लिए कन्फर्म दबाएं",
      "campaign.form.preview.heading": "पूर्वावलोकन",
      "product.form.variant.price.heading": "कीमत",
      "product.form.variant.price.header": "मूल्य निर्धारण",
      "product.header.primaryCategory": "प्राथमिक श्रेणी",
      "category.helpItems.ppo.title": "उत्पाद वर्गीकरण के सिद्धांत",
      "order.action.printInvoice": "चालान छापें",
      "analytics.report.processing": "प्रसंस्करण",
      "user.accountBalance.rechargeButton.processing":
        "संसाधित किया जा रहा है...",
      "tag.linkToProduct.product.heading": "उत्पाद",
      "productConfig.heading": "उत्पाद विन्यास",
      "category.header.productCount": "उत्पाद गणना",
      "product.form.details.header": "उत्पाद विवरण",
      "dispute.name": "प्रोडक्ट का नाम",
      "banners.pageType.productTag": "उत्पाद_टैग",
      "menu.item.products": "उत्पादों",
      "themes.product": "उत्पादों में होना चाहिए",
      "coupon.promocode.label": "प्रचार कोड",
      "offer.dialog.notification.message":
        "प्रोमोकोड आपके ईमेल पते पर भेज दिए गए हैं",
      "cashback.reason.option.promotional": "प्रोमोशनल",
      "campaign.analytics.emailTab.table.header.promotional": "प्रोमोशनल",
      "onboarding.basicInfo.heading": "स्टोर की जानकारी प्रदान करें",
      "marketing.blog.form.submitText": "प्रकाशित करना",
      "marketing.table.heading.publishTime": "पर प्रकाशित",
      "customer.details.order.purchase.history": "खरीद इतिहास",
      "customer.details.pages.basicinfo.purchasesworth": "खरीद मूल्य",
      "order.details.itemsTable.header.quantity.abbreviated": "मात्रा",
      "orderprocessing.qualityChecking.heading": "गुणवत्ता जांच",
      "product.form.variant.stock.quantity.heading": "मात्रा",
      "product.form.variant.rack.heading": "रैक",
      "rackmanagement.heading": "रैक प्रबंधन",
      "menu.item.rack-management": "रैक प्रबंधन",
      "rackmanagement.empty.message": "रैक कॉन्फ़िगर नहीं किए गए हैं",
      "deliveryArea.filters.configType.label.radial": "रेडियल",
      "settings.users.permissionsForm.header.read": "पढ़ना",
      "calls.table.heading.reason": "कारण",
      "analytics.report.recent": "हाल की रिपोर्ट",
      "user.accountBalance.rechargeButton.text": "फिर से दाम लगाना",
      "calls.table.heading.recording": "रिकॉर्डिंग",
      "order.logs.orderRef": "संदर्भ।",
      "trips.details.orderList.referenceNumber": "संदर्भ संक्या",
      "dispute.referenceNumber": "संदर्भ संख्या",
      "order.payment.refund": "धनवापसी",
      "order.details.summary.refundAmount": "वापसी राशि",
      "order.log.refund.initiated": "धनवापसी शुरू",
      "order.details.payment.ref": "धनवापसी संदर्भ",
      "onboarding.basicInfo.register.text": "रजिस्टर करें",
      "order.register.payments": "भुगतान रजिस्टर करें",
      "order.register.payments.for": "के लिए भुगतान पंजीकृत करें",
      "vehiclePlanner.addShift.form.regular.label": "नियमित वाहन गणना",
      "dispute.reject": "अस्वीकार",
      "dispute.rejected": "अस्वीकृत",
      "login.remember": "पहचाना की नहीं",
      "tag.linkToProduct.product.action.remove": "हटाना",
      "settings.online.payment.removebankaccount": "बैंक खाता हटाएं",
      "customer.address.delete.prompt.title": "यह पता हटाएं?",
      "order.details.removeItem.title": "इस सामान को हटाओ?",
      "customer.details.call.option.retention": "अवधारण",
      "order.helpItems.rcm.title": "ग्राहक विपणन दोहराएं",
      "vehiclePlanner.replaceVehicle.popup.heading": "वाहन बदलें",
      "menu.item.reports": "रिपोर्टों",
      "customer.details.call.option.rescheduling": "शेड्यूलिंग",
      "resend.verification.link": "सत्यापन लिंक दोबारा भेजें",
      "shifts.resetpassword": "पासवर्ड रीसेट",
      "shifts.reset.title": "के लिए पासवर्ड रीसेट करें",
      "orderprocessing.config.form.resetToDefault": "वितथ पर ले जाएं",
      "dispute.resolution": "संकल्प",
      "customer.callWidget.form.reason.retention": "अवधारण",
      "tag.linkToProduct.product.action.retry": "पुन: प्रयास करें",
      "order.action.return": "वापसी आदेश",
      "return.file.heading": "के लिए वापसी आदेश",
      "return.retunquantity": "वापसी मात्रा",
      "return.reason.header": "रिटर्न वजह",
      "order.status.returned": "लौटा हुआ",
      "return.heading": "रिटर्न",
      "return.heading.for": "के लिए रिटर्न",
      "return.view.heading": "आदेश के लिए वापसी",
      "settings.users.action.revoke": "आमंत्रण निरस्त करें",
      "menu.item.roles": "भूमिकाएँ",
      "rule.for": "के लिए नियम",
      "customer.helpItems.rpo.title": "प्रचार ऑफ़र चलाएं",
      "campaign.table.heading.runDate": "भागो तिथि",
      "trips.filter.byRunnerExecutive.heading": "रनर एक्जीक्यूटिव",
      "tripPlanner.runner.executive": "रनर एक्जीक्यूटिव",
      "trips.details.orderList.serialNumber": "क्रमांक",
      "operations.dashboard.sales": "बिक्री",
      "operations.dashboard.lastWeek": "पिछले सप्ताह उसी दिन",
      Saturday: "बैठ गया",
      "save.text": "सहेजें",
      "banners.success.dialog.message": "बचाया",
      "banners.editForm.radio.label": "दायरा",
      "themes.scroller": "स्क्रोलर",
      "search.label.text": "खोज",
      "order.details.productSearch.placeholder": "जोड़ने के लिए उत्पाद खोजें",
      "product.filters.brand.placeholder": "ब्रांड द्वारा खोजें",
      "product.filters.category.placeholder": "श्रेणी के आधार पर खोजें",
      "offer.filter.clientId.placeholder": "क्लाइंट आईडी के आधार पर खोजें",
      "coupon.filters.couponCode.placeholder": "कूपन कोड द्वारा खोजें",
      "trips.filter.byDeliveryAgent.placeholder": "डिलीवरी एजेंट द्वारा खोजें",
      "customer.searchByEmail": "ईमेल द्वारा खोजें",
      "product.filters.clientId.placeholder": "आईडी . द्वारा खोजें",
      "search.by.name.text": "नाम से खोजें",
      "vehicles.filters.byNumber.placeholder": "नंबर के आधार पर खोजें",
      "trips.filter.byOrderNumber.placeholder": "आदेश संख्या द्वारा खोजें",
      "customer.searchByPhone": "फोन द्वारा खोजें",
      "trips.filter.byRunnerExecutive.placeholder":
        "रनर एक्जीक्यूटिव द्वारा खोजें",
      "product.filters.tag.placeholder": "टैग द्वारा खोजें",
      "trips.filter.byVehicleNumber.placeholder": "वाहन संख्या द्वारा खोजें",
      "vehicles.filters.byVendor.placeholder": "विक्रेता द्वारा खोजें",
      "order.helpItems.seo.title": "सर्च इंजन अनुकूलन",
      "operations.store.form.location.placeholder": "अपना स्थान खोजें",
      "search-configuration.search.header.name": "मुख्य शब्द खोजे",
      "radial.form.searchLocation.placeholder": "मानचित्र पर स्थान खोजें",
      "menu.item.search-configuration": "खोज ओवरराइड",
      "order.form.productSearch.heading": "उत्पाद खोजें/बारकोड स्कैन करें",
      "customer-service-tags.form.otherdetails.placeholder":
        "जोड़ने के लिए टैग खोजें",
      "banners.select.search.term": "जाँच अवधि",
      "product.form.secondarycategory.heading": "माध्यमिक श्रेणियां",
      "themes.seeAll": "सभी देखें बटन",
      "category.action.seeProducts": "उत्पाद देखें",
      "product.form.select": "चुनते हैं",
      "radial.selectLocation.message": "एक स्थान चुनें",
      "order.form.slots.error.message": "एक स्लॉट चुनें",
      "onboarding.industry.sencondaryHeading": "नीचे एक उद्योग चुनें",
      "store.accounts.placeholder": "ऐप स्टोर चुनें",
      "offer.brand.placeholder": "ब्रांड चुनें",
      "offer.brands.placeholder": "ब्रांड चुनें",
      "offer.categories.placeholder": "श्रेणियाँ चुनें",
      "offer.category.placeholder": "श्रेणी का चयन करें",
      "entityMetaData.country.placeholder": "देशों का चयन करें",
      "cutoff.time.placeholder": "कटऑफ समय चुनें",
      "order.form.date.heading": "तारीख़ चुनें",
      "analytics.report.dateRange": "दिनांक सीमा चुनें",
      "coupon.analytics.selectDates.heading": "तिथियों का चयन करें",
      "day.placeholder": "दिन चुनें",
      "order.form.deliveryArea.heading": "वितरण पता चुनें",
      "shifts.select.designation": "पद का चयन करें",
      "choose.theme.device.placeholder": "डिवाइस का चयन करें",
      "offer.discounttype.placeholder": "छूट का प्रकार चुनें",
      "themes.layout.timeline.endTime.placeholder": "समाप्ति समय चुनें",
      "menu.selectLanguage.heading": "भाषा का चयन करें",
      "extensions.multiLingual.placeholder": "भाषा चुनें",
      "offer.type.placeholder": "ऑफ़र का प्रकार चुनें",
      "logistics.config.resees.placeholder": "एक विकल्प चुनें",
      "order.filters.slot.placeholder": "ऑर्डर स्लॉट चुनें",
      "offer.ordertype.placeholder": "आदेश प्रकार चुनें",
      "banners.select.page.type.placeholder": "पृष्ठ प्रकार चुनें",
      "category.form.parentCategory.placeholder": "अभिभावक श्रेणी का चयन करें",
      "order.filters.payment.placeholder": "भुगतान मोड चुनें",
      "order.filters.paymentStatus.placeholder": "भुगतान की स्थिति चुनें",
      "offer.product.placeholder": "उत्पादों का चयन करें",
      "communication.service.provider.placeholder": "प्रदाता चुनें",
      "dispute.reason.placeholder": "कारण चुनें",
      "dispute.resolution.placeholder": "संकल्प चुनें",
      "order.form.slot.heading": "स्लॉट का चयन करें",
      "socialMedia.accounts.placeholder": "सोशल मीडिया का चयन करें",
      "themes.sortinglogic": "छँटाई तर्क चुनें",
      "themes.layout.timeline.startTime.placeholder": "प्रारंभ समय चुनें",
      "coupon.form.stores.placeholder": "स्टोर चुनें",
      "banners.languages.placeholder": "भाषा चुनें",
      "logistics.config.orderBreakingMethod.placeholder":
        "आदेश तोड़ने की विधि का चयन करें",
      "banners.page.type.placeholder": "पृष्ठ प्रकार का चयन करें",
      "return.reasons": "कारण चुनें",
      "return.move.placeholder": "स्थिति का चयन करें",
      "banners.stores.placeholder": "दुकान का चयन करें",
      "logistics.config.tripGeneration.placeholder": "ट्रिप जनरेशन विधि चुनें",
      "vehiclePlanner.replaceVehicle.form.replaced.placeHolder":
        "वाहन का चयन करें",
      "choose.theme.placeholder": "चुनिंदा विषय",
      "offer.time.placeholder": "समय चुनें",
      "product.selectType": "प्रकार चुनें",
      "vehiclePlanner.checkin.form.select.plceholder": "वाहन चुनें",
      "return.wastagereason.placeholder": "अपव्यय कारण चुनें",
      "shifts.form.weekly.off.placeholder": "साप्ताहिक अवकाश चुनें",
      "zones.placeholder": "जोन चुनें",
      "offer.selected.stores": "चयनित स्टोर",
      "operations.store.form.hasSelfCheckout.label": "स्वयं नियंत्रण",
      "customer.details.mail.submitText.send": "भेजना",
      "customer.details.sendEmail": "ईमेल भेजें",
      "customer.emailWidget.form.title": "ईमेल भेजना",
      "customer.details.sendMessage": "मेसेज भेजें",
      "customer.smsWidget.form.title": "को एसएमएस भेजें",
      "menu.item.seo": "एसईओ",
      "category.helpItems.seo.title": "उत्पाद वर्गीकरण का एसईओ प्रभाव",
      "tripPlanner.orderSeq": "अनुक्रम",
      "served.from.store.text": "स्टोर से परोसा गया",
      "communication.service.provider": "सेवा प्रदाता",
      "customer.contact.details.set.default": "डिफाल्ट के रूप में सेट",
      "rackManagement.save.success.title": "सेटिंग सफलतापूर्वक सहेज ली गई हैं",
      "menu.item.settings": "समायोजन",
      "basicInfo.save.success.title": "सेटिंग सफलतापूर्वक सहेज ली गई हैं",
      "settings.online.payment.succesfully.saved":
        "सेटिंग सफलतापूर्वक सहेजी गईं",
      "return.settledQuantity": "बसे हुए मात्रा",
      "product.form.variant.shelf.heading": "शेल्फ",
      "shifts.form.endTime": "शिफ्ट समाप्ति समय",
      "shifts.form.startTime": "शिफ्ट प्रारंभ समय",
      "order.details.summary.shippingCharge": "भेजने की लागत",
      "order.log.shipping.charges": "शिपिंग शुल्क",
      "order.details.summary.shippingDiscount": "शिपिंग छूट",
      "mobileApps.shortDescription.heading": "संक्षिप्त वर्णन",
      "logistics.config.shouldCaptureOdometer":
        "क्या RE को ओडोमीटर इमेज कैप्चर करनी चाहिए?",
      "themes.cc.config.image": "कवर इमेज के रूप में दिखाएं",
      "trips.action.vehicleOdometer": "ओडोमीटर छवि दिखाएं",
      "themes.cc.pdtCount.inline": "उत्पाद संख्या दिखाएं",
      "themes.cc.subCategories": "उपश्रेणियों का संग्रह दिखाएं",
      "themes.cc.showSubCategory.inline": "उपश्रेणी लिंक दिखाएं",
      "pagination.text": "दिखा",
      "user.leave-summary.status.Sick": "बीमार",
      "signup.oldUser.signinText": "साइन इन करें",
      "signup.buttonText": "साइन अप करें",
      "product.add.single.text": "एकल उत्पाद",
      "product.search.and.add.text": "खोजें और जोड़ें",
      "extensions.tawk.site.id": "साइट आईडी",
      "operations.dashboard.slot": "स्लॉट",
      "order.log.slot.changed": "स्लॉट बदल गया",
      "order.details.summary.slotCharge": "स्लॉट चार्ज",
      "menu.item.slot-charges": "स्लॉट शुल्क",
      "order.details.summary.slotDiscount": "स्लॉट छूट",
      "tripPlanner.slot.endTime": "स्लॉट अंत",
      "tripPlanner.slots.startTime": "स्लॉट प्रारंभ",
      "menu.item.slots": "स्लॉट्स",
      "extensions.slot.heading": "स्लॉट विन्यास",
      "order.packing.smallBags": "छोटे बैग",
      "order.packing.smallBoxes": "छोटे बक्से",
      "campaign.form.sms.heading": "एसएमएस",
      "socialMedia.heading": "सामाजिक मीडिया",
      "unexpected.error.description": "कुछ अनपेक्षित हुआ है",
      "error.server": "कुछ अप्रत्याशित हुआ है। बाद में पुन: प्रयास करें।",
      "order.details.edit-packed.error": "कुछ गलत हो गया",
      "error.generic": "कुछ गलत हो गया!",
      "unexpected.error.heading": "माफ़ करना!",
      "offer.field.stackable.label": "stackable",
      "tripPlanner.start": "शुरू",
      "coupon.form.startDate.placeholder": "आरंभ करने की तिथि",
      "calls.table.heading.startTime": "समय शुरू",
      "slots.start.end.mismatch": "प्रारंभ समय समाप्ति समय से कम होना चाहिए",
      "trip.details.startedAt": "इस समय पर शुरू किया",
      "slots.startsAt": "पर आरंभ होती है",
      "leaves.team.startson": "पर शुरू होता है",
      "settings.online.payment.state": "राज्य",
      "websitePage.table.heading": "स्टेटिक पेज",
      "category.header.status": "स्थिति",
      "order.log.status.changed": "स्थिति बदली",
      "product.form.variant.stock.header": "भंडार",
      "menu.item.stock-overrides": "स्टॉक ओवरराइड",
      "calls.filters.tag.option.store": "दुकान",
      "tripPlanner.trip.storeLocation.error":
        "स्टोर में उचित अक्षांश-देशांतर होना चाहिए।",
      "basicInfo.storename.heading": "स्टोर नाम",
      "orderprocessing.qrCodeFormat.heading":
        "स्टोर मुद्रित स्थानीय बारकोड प्रारूप",
      "return.toStore": "स्टोर वापसी",
      "slots.store.page.title": "स्टोर स्लॉट विन्यास",
      "banners.editForm.radio.option.storeSpecific": "स्टोर विशिष्ट",
      "basicInfo.storeurl.heading": "स्टोर Url",
      "menu.item.stores": "स्टोर",
      "dualImageBanner.subTitle": "उप शीर्षक",
      "campaign.form.emailSubject.heading": "विषय",
      "submit.text": "प्रस्तुत करना",
      "menu.item.substitution-groups": "प्रतिस्थापन समूह",
      "success.text.title": "सफलता",
      "calls.form.summary.label": "सारांश",
      Sunday: "रवि",
      "basicInfo.storename.support.email": "समर्थन ईमेल",
      "basicInfo.storename.support.phone": "समर्थन फोन",
      "basicInfo.supportWWW": "WWW का समर्थन करें",
      "customer.details.call.option.surveyOnceShopped":
        "सर्वेक्षण - एक बार खरीदारी करने के बाद",
      "category.icons.table-title": "टेबल व्यू",
      "product.filters.tag.heading": "उपनाम",
      "category.form.tagline.heading": "टैग लाइन",
      "customer-service-tags.form.tag.name.label": "टैग नाम",
      "brand.form.tagline.heading": "TAGLINE",
      "menu.item.tags": "टैग",
      "themes.tag.show": "दिखाने के लिए टैग",
      "menu.item.tawk": "लाइव चैट करने के लिए",
      "product.form.variant.tax.header": "कर",
      "order.details.summary.taxAmount": "कर राशि",
      "order.details.summary.taxTitle": "कर सारांश",
      "leaves.team.title": "टीम चली जाती है",
      "videoCarousel.textColor.label": "लिखावट का रंग",
      "videoCarousel.textColor.placeholder": "विवरण के लिए पाठ का रंग",
      "Thank you for shopping at ": "{name} पर खरीदारी करने के लिए धन्यवाद",
      "settings.online.payment.account.delete": "खाता हटा दिया जाएगा",
      "category.delete.confirm": "श्रेणी हटा दी जाएगी",
      "leaves.team.approve.leave": "छुट्टी मंजूर की जाएगी",
      "themes.heading": "विषयों",
      "operations.pickingQueue.emptyList.message":
        "वर्तमान में चुनने के लिए कोई आदेश नहीं हैं",
      "operations.store.empty.message":
        "वर्तमान में कोई स्टोर कॉन्फ़िगर नहीं किया गया है",
      "shifts.empty.message": "वर्तमान में कोई उपयोगकर्ता नहीं है",
      "marketing.blog.helpItems.message": "यहां कोई ब्लॉग नहीं दिखाया गया है",
      "campaign.helpItems.message": "कोई अभियान नहीं है",
      "stores.empty.stock": "कोई कॉन्फ़िगरेशन नहीं है",
      "cp.empty": "कोई कॉन्फ़िगरेशन सेट नहीं है",
      "coupon.helpItems.message": "यहां कोई कूपन नहीं दिखाया गया है",
      "deliveryArea.emptyList.message": "कोई वितरण क्षेत्र नहीं हैं",
      "designations.empty.message": "कोई पदनाम नहीं हैं",
      "order.details.itemsTable.emptyMessage": "इस क्रम में कोई आइटम नहीं हैं।",
      "leaves.emptyMessage": "कोई पत्ते उपलब्ध नहीं हैं",
      "menu.notificationToday.empty": "यहां कोई सूचना नहीं दिखाई गई है",
      "offer.emptyMessage": "कोई ऑफ़र नहीं है",
      "tripPlanner.emptyState.helperText":
        "वितरित करने के लिए कोई आदेश नहीं हैं {गिनती, बहुवचन, एक{इस स्टोर से} अन्य{}}",
      "returns.emptyMessage": "कोई रिटर्न नहीं है",
      "extension.details.notfound": "इस एक्सटेंशन के लिए कोई सेटिंग नहीं है",
      "slots.store.emptyMessage":
        "इस स्टोर के लिए कोई स्लॉट नियम कॉन्फ़िगर नहीं किया गया है",
      "slots.emptymessage": "कोई स्लॉट कॉन्फ़िगर नहीं किया गया है",
      "slots.blocking.empty.message":
        "अवरुद्ध करने के लिए कोई स्लॉट कॉन्फ़िगर नहीं किया गया है",
      "websitePage.emptyList.message":
        "आपकी वेबसाइट के लिए कोई स्थिर पृष्ठ नहीं हैं",
      "trips.emptyState.helperText":
        "कोई यात्राएं नहीं हैं {गिनती, बहुवचन, एक{इस स्टोर से} अन्य{}}",
      "order.details.packed.item.return.message": "ऑर्डर में लौटाए गए आइटम हैं",
      "deleteDialog.information": "यह क्रिया पूर्ववत नहीं की जा सकती",
      "order.details.cancellation.message":
        "यह कार्रवाई इस आदेश को रद्द कर देगी",
      "order.details.completion.message": "यह क्रिया इस आदेश को पूरा करेगी",
      "substitutionGroup.dialogs.disable.message":
        "यह क्रिया प्रतिस्थापन समूह को अक्षम कर देगी",
      "websitePage.dialogs.disable.message":
        "यह क्रिया इस पृष्ठ को अक्षम कर देगी",
      "substitutionGroup.dialogs.enable.message":
        "यह क्रिया प्रतिस्थापन समूह को सक्षम करेगी",
      "websitePage.dialogs.enable.message": "यह क्रिया इस पृष्ठ को सक्षम करेगी",
      "order.details.pending.message":
        "यह क्रिया आदेश को लंबित स्थिति में ले जाएगी",
      "customer.email.delete.prompt.message":
        "यह क्रिया इस ईमेल आईडी को हटा देगी",
      "customer.phone.delete.prompt.message": "यह क्रिया इस नंबर को हटा देगी",
      "order.details.return.message": "यह क्रिया आदेश वापस कर देगी",
      "input.requiredMessage": "यह खाली नहीं हो सकता",
      "notification.email.delete.message": "यह ईमेल हटा दिया जाएगा",
      "product.form.category.requiredMessage": "यह एक मान्य श्रेणी नहीं है",
      "entityMetaData.this.key.will.be.deleted": "यह कुंजी हटा दी जाएगी",
      "websitePage.dialog.information": "यह लेआउट हटा दिया जाएगा",
      "leaves.team.cancel.leave": "यह छुट्टी रद्द हो जाएगी",
      "leaves.team.reject.leave": "यह छुट्टी अस्वीकार कर दी जाएगी",
      "offer.dialog.clone.message":
        "यह ऑफ़र अक्षम कर दिया जाएगा और शेष छुटकारे के साथ एक नया ऑफ़र बनाया जाएगा",
      "notification.phone.delete.message": "यह फ़ोन नंबर हटा दिया जाएगा",
      "coupon.analytics.thisWeek.heading": "इस सप्ताह",
      "marketing.blog.dialogs.publish.message":
        "यह ब्लॉग को वेबसाइट पर प्रकाशित करेगा",
      "marketing.blog.dialogs.unpublish.message":
        "यह वेबसाइट पर ब्लॉग को हटा देगा और उसे ड्राफ़्ट में जोड़ देगा",
      Thursday: "गुरु",
      "campaign.form.time.heading": "समय",
      "designations.header.timing": "समय",
      "designations.header.timingType": "समय का प्रकार",
      "shifts.header.timings": "समय",
      "shifts.header.timingsAndAttendance": "समय / उपस्थिति",
      "title.heading.text": "शीर्षक",
      "calls.table.heading.to": "प्रति",
      "offer.excludeText": "बाहर करने के लिए",
      "offer.includeText": "शामिल करना",
      "onboarding.basicInfo.useIt.text": "इसका उपयोग करने के लिए या",
      "coupon.analytics.today.heading": "आज",
      "order.details.tomorrow": "आने वाला कल",
      "operations.dashboard.topProducts.heading": "शीर्ष उत्पाद",
      "operations.dashboard.total": "कुल",
      "order.details.summary.totalAmount": "कुल रकम",
      "coupon.analytics.totalDiscount.heading": "कुल कूपन छूट",
      "customer.details.pages.basicinfo.totalorders": "कुल आदेश",
      "pickers.today.total.pickers": "टोटल पिकर्स",
      "return.totalquantity": "कुल मात्रा",
      "offer.total.redemption": "कुल मोचन",
      "coupon.analytics.totalPurchases.heading": "कुल बिक्री",
      "order.details.summary.totalTax": "कुल कर",
      "trips.details.orderList.transactionId": "लेन-देन आईडी",
      "category.icons.tree-title": "ट्री व्यू",
      "trips.details.heading": "यात्रा #{id}",
      "tripPlanner.tripEnd": "ट्रिप एंड",
      "trip.endOdometer": "ट्रिप एंड ओडोमीटर इमेज",
      "customer.details.call.option.tripEnquiry": "यात्रा पूछताछ",
      "customer.callWidget.form.reason.tripEnquiry": "यात्रा पूछताछ कॉल",
      "logistics.config.tripGeneration": "ट्रिप जनरेशन विधि",
      "trip.details.tripIsActive": "यात्रा सक्रिय है",
      "tripPlanner.tripNo": "यात्रा संख्या",
      "menu.item.trip-planner": "ट्रिप प्लानर",
      "tripPlanner.tripStart": "यात्रा प्रारंभ",
      "trips.startOdometer": "ट्रिप स्टार्ट ओडोमीटर इमेज",
      "tripPlanner.trip.started": "यात्रा सफलतापूर्वक शुरू हुई",
      "tripPlanner.table.heading": "यात्रा सारांश",
      "menu.item.trips": "ट्रिप्स",
      Tuesday: "मंगल",
      "media.select.twitter": "ट्विटर",
      "media.select.iosAppLink": "आईओएस",
      "media.select.androidAppLink": "एंड्रॉयड",
      "type.text.label": "प्रकार",
      "category.form.description.placeholder": "श्रेणी विवरण टाइप करें",
      "order.log.type.changed": "प्रकार परिवर्तित",
      "marketing.blog.form.content.placeholder": "यहा लिखो",
      "offer.main.discount.type": "छूट का प्रकार",
      "pincode.form.pincodes.placeholder": "ज़िप कोड टाइप करें और एंटर दबाएं",
      "error.Unauthorized": "अनधिकृत",
      "extensions.uninstall": "स्थापना रद्द करें",
      "extensions.uninstallExtension": "एक्सटेंशन अनइंस्टॉल करें",
      "extensions.uninstalledSuccessfully": "सफलतापूर्वक अनइंस्टॉल किया गया",
      "coupon.analytics.uniqueUsers.heading": "अद्वितीय उपयोगकर्ता",
      "product.form.variant.unlimitedAvailable.label": "असीमित",
      "extensions.deliveryArea.polygon.unnamed": "बेनाम क्षेत्र",
      "extensions.deliveryArea.zone.unnamed": "अनाम समूह",
      "marketing.blog.table.action.unpublish": "अप्रकाशित",
      "order.log.editedBy": "अद्यतन",
      "customer.updatedAt": "को अपडेट किया",
      "orderDetails.uploadPrescription.upload": "डालना",
      "category.form.uploadImage.heading": "तस्विर अपलोड करना",
      "fileUpload.importedCsv.heading": "अभी अपलोड करें",
      "product.form.variant.image.heading": "उत्पाद चित्र अपलोड करें",
      "basicInfo.uploadFavicon.heading": "अपना फ़ेविकॉन अपलोड करें",
      "basicInfo.uploadImage.heading": "अपना लोगो अपलोड करें",
      "orderDetails.uploadFile": "अपलोड की गई फ़ाइल",
      "orderDetails.uploadPrescription.uploadedPrescription":
        "अपलोड किया गया प्रिस्क्रिप्शन",
      "product.form.imageUpload.uploading": "अपलोड हो रहा है...",
      "websitePage.table.url": "यूआरएल",
      "websitePage.form.url.placeholder": "पेज का यूआरएल",
      "banners.editForm.link.placeholder":
        "क्लिक करने पर नेविगेट करने के लिए URL",
      "coupon.action.usageAnalytic": "उपयोग विश्लेषणात्मक",
      "settings.online.payment.use.zoppay":
        "हमारे डिफ़ॉल्ट गेटवे का उपयोग करें",
      "themes.selectTheme.text": "इस विषय का प्रयोग करें",
      "campaign.analytics.subHeading": "उपयोगकर्ता जुड़ाव",
      "menu.item.user-hover": "उपयोगकर्ता होवर",
      "customer-service-tags.heading": "उपयोगकर्ता टैग",
      "login.username.heading": "उपयोगकर्ता नाम",
      "coupon.form.validOnUser.heading": "उपयोगकर्ताओं",
      "shifts.empty.submessage":
        "एक बार जोड़े जाने पर उपयोगकर्ताओं को देखा जा सकता है",
      "offer.validFrom": "से मान्य",
      "offer.time": "समय से मान्य",
      "offer.validTill": "तक मान्य है",
      "offer.validto.time": "समय तक मान्य",
      "coupon.header.validity": "वैधता",
      "banners.editForm.validity.from": "से वैधता",
      "banners.editForm.validity.to": "की वैधता",
      "enitityMetaData.values": "मूल्यों",
      "product.form.variant.name.placeholder": "प्रकार",
      "product.form.variant.name.heading": "वेरिएंट का नाम",
      "product.form.variant.heading": "वेरिएंट",
      "trips.header.vehicle": "वाहन",
      "trips.filter.byVehicleNumber.heading": "गाडी नंबर",
      "vehiclePlanner.heading": "वाहन योजनाकार",
      "menu.item.vehicle-planning": "वाहन योजना",
      "tripPlanner.vehicle.shiftEnd": "वाहन शिफ्ट अंत",
      "tripPlanner.vehicle.shiftStart": "वाहन शिफ्ट प्रारंभ",
      "vehiclePlanner.replaceVehicle.form.replaced.label":
        "बदले जाने वाले वाहन",
      "vehiclePlanner.replaceVehicle.form.replacedBy.label":
        "द्वारा प्रतिस्थापित किया जाने वाला वाहन",
      "vehicles.vendor.heading": "वाहन विक्रेता",
      "menu.item.vehicles": "वाहनों",
      "vehicles.header.vendor": "विक्रेता",
      "vehicles.vendor.heading.name": "विक्रेता का नाम",
      "email.verified.text": "सत्यापित",
      "user.payment.loader.text":
        "भुगतान सत्यापित किया जा रहा है, कृपया प्रतीक्षा करें...",
      "extensions.version": "संस्करण",
      "videoCarousel.videoUrl.title": "वीडियो यूआरएल",
      "product.action.view": "राय",
      "campaign.action.viewAnalytics": "विश्लेषिकी देखें",
      "category.view": "श्रेणियां देखें",
      "trips.action.viewDetails": "विवरण देखें",
      "designations.view.employees": "कर्मचारियों को देखें",
      "order.action.viewInvoice": "चालान देखें",
      "order.actions.logs": "लॉग्स को देखें",
      "trips.details.orderList.view.on.map": "मानचित्र पर देखें",
      "banners.table.view.on.web.url": "वेब पर देखें",
      "tag.action.viewOnWebsite": "वेबसाइट पर देखें",
      "order.actions.trips": "यात्राएं देखें",
      "Visit us at": "{डोमेन} पर हमसे मिलें",
      "tripPlanner.waiting": "रुकना",
      "order.filters.slot.fetchTitle": "रुकना! स्लॉट ला रहे हैं",
      "wallet.config.heading": "वॉलेट कॉन्फ़िगरेशन",
      "offer.dialog.clone.label": "चेतावनी",
      "return.markasWastage": "क्षय",
      "return.wastagereason": "अपव्यय कारण",
      "menu.item.themes": "वेबसाइट",
      "category.helpItems.ppo.description":
        "उपयोगी विवरण वाली वेबसाइटें Google जैसे खोज इंजनों से निःशुल्क विज़िटर प्राप्त करती हैं।",
      Wednesday: "बुध",
      "operations.dashboard.weeklyAvg": "साप्ताहिक औसत",
      "attendance.summary.table.WEEKLY_OFF": "साप्ताहिक अवकाश",
      "shifts.header.offdaysAndStores": "साप्ताहिक अवकाश / स्टोर",
      "onboarding.industry.heading": "आपका उद्योग क्या है",
      "extensions.whatwillchange": "क्या बदलेगा?",
      "websitePage.form.content.placeholder":
        "आप यहां जो टाइप करेंगे वह आपकी वेबसाइट पर इस पेज की सामग्री के रूप में दिखाया जाएगा",
      "socialMedia.whatsappSupportPhone": "व्हाट्सएप सपोर्ट फोन",
      "notifications.customer.add.money": "जब ग्राहक वॉलेट में पैसे जोड़ता है",
      "notifications.customer.add.address": "जब ग्राहक नया पता जोड़ता है",
      "notifications.customer.complain": "जब ग्राहक शिकायत दर्ज करता है",
      "notification.new.customer": "जब नया ग्राहक जुड़ता है",
      "notifications.new.order": "नया आदेश आने पर",
      "notifications.order.cancel": "जब आदेश रद्द हो जाता है",
      "notifications.order.refund": "जब आदेश वापस हो जाता है",
      "catalogue.dashboard.helpItems.uI.title":
        "चित्र अपलोड करना क्यों महत्वपूर्ण है?",
      "extensions.tawk.widget.id": "विजेट आईडी",
      "trip.details.orderList.withthistrip": "इस यात्रा के साथ",
      asapDurationStartText: "अंदर",
      "404.heading": "कार्य प्रगति पर है",
      "settings.users.permissionsForm.header.write": "लिखना",
      "marketing.blog.add.text": "एक नई पोस्ट लिखें",
      "marketing.table.heading.author": "द्वारा लिखित",
      "logistics.config.yes": "हां",
      "rackManagement.rack.delete.confirmText": "हाँ, मिटा दो!",
      "customer.reset.password.confirmText": "हाँ करो इसे!",
      "operations.dashboard.yesterday": "बीता हुआ कल",
      "themes.customize.subheading":
        "आप {name} थीम को पसंद के मुताबिक बना रहे हैं",
      "themes.subheading": "आप {name} थीम का उपयोग कर रहे हैं",
      "settings.online.payment.our.gateway":
        "आप हमारे पेमेंट गेटवे का उपयोग कर रहे हैं",
      "onboarding.changeLater.text": "आप बाद में यहां बदल सकते हैं",
      "order.helpItems.rcm.description":
        "आप अपने ग्राहकों के लिए डिस्काउंट कूपन बना सकते हैं और ऑनलाइन खरीदारी को बढ़ावा दे सकते हैं।",
      "order.helpItems.nca.description":
        "आप अपने भौतिक स्टोर में एक प्रमुख स्थान पर एक बैनर लगा सकते हैं। buzz बनाने के लिए प्रचार ऑफ़र शामिल करें.",
      "error.server.403":
        "इस पृष्ठ को देखने के लिए आपके पास पर्याप्त अनुमतियाँ नहीं हैं।",
      "user.transactions.emptyList.text": "आपके पास अभी तक कोई लेनदेन नहीं है",
      "product.helpItems.message":
        "आपको ग्राहकों से खरीदने के लिए उत्पादों की एक सूची बनानी होगी।",
      "rackManagement.rack.delete.message": "आप इस रैक को हटाना चाहते हैं",
      "coupon.disableDialog.information":
        "आप इस कूपन को निष्क्रिय करना चाहते हैं",
      "customer.table.actions.disable.information":
        "आप इस ग्राहक को अक्षम करना चाहते हैं?",
      "coupon.enableDialog.information": "आप इस कूपन को सक्षम करना चाहते हैं",
      "customer.table.actions.enable.information":
        "आप इस ग्राहक को सक्षम करना चाहते हैं?",
      "order.helpItems.seo.description":
        "आप आयात के बाद ईमेल और एसएमएस का उपयोग करके अपने ग्राहकों को लक्षित करने में सक्षम होंगे, जिससे संभावित रूप से आपके राजस्व में वृद्धि होगी।",
      "product.downloadSuccessMessage":
        "आपका {value} डेटा कुछ समय में आपको ईमेल के माध्यम से भेजा जाएगा",
      "password.sent.to.mobile":
        "आपके खाते का पासवर्ड आपके मोबाइल पर भेज दिया गया है।",
      "marketing.blog.form.title.requiredMessage":
        "आपके ब्लॉग का शीर्षक होना चाहिए",
      "brand.helpItems.message": "आपके ब्रांड यहां दिखाए जाएंगे।",
      "category.helpItems.message": "आपकी श्रेणियां यहां दिखाई जाएंगी।",
      "email.verified": "आपका ईमेल सत्यापित किया गया है!",
      "user.not.verified.email":
        "आपकी ईमेल आईडी सत्यापित नहीं है, हो सकता है कि आपको आदेश सूचनाएं प्राप्त न हों।",
      "leaves.dialog.information": "आपकी छुट्टी रद्द कर दी जाएगी",
      "menu.item.website-link": "आपका ऑनलाइन स्टोर",
      "order.emptyList.message":
        "आपके आदेश यहां दिखाए जाएंगे। अभी से बेचना शुरू करें।",
      "websitePage.form.title.requiredMessage":
        "आपके पृष्ठ का एक शीर्षक होना चाहिए",
      "resetPassword.successMessage":
        "आपका पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है",
      "tag.helpItems.message": "आपके उत्पाद टैग यहां दिखाई देंगे",
      "customer.entityMetaData.successInformation":
        "आपकी सेटिंग सफलतापूर्वक सहेज ली गई हैं",
      "basicInfo.storename.placeholder": "आपकी दुकान",
      "pincode.form.pincodes.heading": "ज़िप कोड",
      "deliveryArea.filters.configType.label.pincode": "ज़िप कोड",
      "extensions.deliveryArea.pincodes.heading": "ज़िप कोड",
      "rackManagement.rack.zone.name": "क्षेत्र का नाम",
      "rackManagement.rack.zones": "क्षेत्र",
      "logistics.config.orderBreakingMethod.zoning": "जोनिंग",
      "campaign.analytics.zopLinkActivity.heading": "ज़ोपलिंक गतिविधि",
      "menu.item.api-integration": "एपीआई एकीकरण",
      "view-website-text": "दुकान देखे",
      "input.invalidVehicleNumber": "कृपया एक वैध वाहन संख्या दर्ज करें",
      "input.invalidVendorName": "कृपया एक मान्य विक्रेता नाम दर्ज करें",
      "order.courier.select.heading": "द्वारा प्रेषण",
      "order.dispatch.label.courier.name": "कूरियर का नाम",
      "order.dispatch.label.courier.charges": "प्रभार",
      "order.dispatch.label.courier.tracking.id": "ट्रैकिंग आईडी",
      "order.dispatch.placeholder.courier.name": "उदाहरण: इंडिया पोस्ट",
    },
  },
};

export default data;
