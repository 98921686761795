import React from "react";
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
  Checkbox,
  Phone,
} from "../../../../../components/Form";
import { isExtensionEnabled } from "../../../../../lib/auth";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import { getPrintableTime, getMinutes } from "../../../../../lib/datetime";
import {
  sortSlots,
  getSortedOrderStatus,
} from "../../../../../lib/commonlyused";
import toTitleCase from "../../../../../utils/toTitleCase";
import { VALIDATION_TYPES } from "../../../../../components/Form";
import { getAllOrderTabs } from "../index";

const paymentModes = [
  {
    text: getMessage("coupon.form.codPayments"),
    value: "COD",
  },
  {
    text: getMessage("coupon.form.onlinePayments"),
    value: "ONLINE",
  },
];

const paymentStatuses = [
  {
    text: getMessage("order.status.pending"),
    value: "PENDING",
  },
  {
    text: getMessage("order.payment.paid"),
    value: "PAID",
  },
  {
    text: getMessage("order.payment.refund"),
    value: "REFUND",
  },
];

const orderSortBySlots = [
  {
    text: getMessage("order.filters.slot.sortBy.asc"),
    value: "SLOT_ASC",
  },
  {
    text: getMessage("order.filters.slot.sortBy.desc"),
    value: "SLOT_DESC",
  },
];

const getOrderStatusOptions = () => {
  const orderTabs = getAllOrderTabs();
  const tab =
    orderTabs[Number(window.localStorage.getItem(`orderPage-tabIndex`))];
  const allAllowedStatus = getSortedOrderStatus();

  if (tab === "PENDING")
    return [
      {
        text: getMessage("order.status.pending"),
        value: "PENDING",
      },
    ];

  if (tab === "INPROGRESS") {
    const notAllowedArray = ["PENDING", "COMPLETED", "CANCELLED", "RETURNED"];
    const modifiedStatus = allAllowedStatus.filter(
      (item) => !notAllowedArray.includes(item)
    );
    return modifiedStatus.map((status) => {
      return {
        text: getMessage(`order.status.${status.toLowerCase()}`),
        value: status,
      };
    });
  }
  if (tab === "COMPLETED")
    return [
      {
        text: getMessage("order.status.completed"),
        value: "COMPLETED",
      },
    ];

  if (tab === "CANCELLED")
    return [
      {
        text: getMessage("order.status.cancelled"),
        value: "CANCELLED",
      },
    ];

  if (tab === "RETURNED")
    return [
      {
        text: getMessage("order.status.returned"),
        value: "RETURNED",
      },
    ];
  return allAllowedStatus.map((status) => {
    return {
      text: getMessage(`order.status.${status.toLowerCase()}`),
      value: status,
    };
  });
};

const SELF_CHECKOUT = "SELF_CHECKOUT";
export default class OrderFiltersForm extends BaseForm {
  constructor(props) {
    // lastOrderType is being used to track the change in selected orderType
    // As the state is getting mutated, we are not able to get correct prevState
    super(props);
    this.lastOrderType = undefined;
    this.getOrderSlots = this.getOrderSlots.bind(this);
  }

  getOrderSlots(orderType) {
    this.slotApi = new API({ url: `/order-service/slot` });
    let params = { paginated: "false" };
    if (orderType) {
      params = { ...params, orderType };
    }
    this.setState({ isFetchingSlots: true }, () => {
      this.slotApi.get(params).then((response) => {
        let slots = response.data?.slot || [];
        slots = sortSlots(slots);
        let slotOptions = [];
        slots.forEach((slot) => {
          if (slot.type === "STANDARD") {
            slotOptions.push({
              text: `${
                getPrintableTime(
                  `${new Date().toISOString().split("T")[0]} ${slot.startTime}`
                ).split(",")[1]
              } - ${
                getPrintableTime(
                  `${new Date().toISOString().split("T")[0]} ${slot.endTime}`
                ).split(",")[1]
              }`,
              value: JSON.stringify({
                startTime: slot.startTime,
                endTime: slot.endTime,
                type: slot.type,
              }),
            });
          } else if (slot.type === "ASAP") {
            slotOptions.push({
              text:
                getMessage("asapDurationStartText") +
                " " +
                getMinutes(slot.endTime) +
                " " +
                getMessage("asapDurationEndText"),
              value: JSON.stringify({ endTime: slot.endTime, type: slot.type }),
            });
          }
        });
        this.setState({
          slotOptions,
          isFetchingSlots: false,
          slotFetchError: undefined,
        });
      });
    });
  }

  componentDidMount() {
    this.configApi = new API({ url: "/account-service/config/order" });
    this.configApi.get().then((response) => {
      let orderTypes = response.data.config.order.orderTypes;
      orderTypes = (orderTypes || []).map((orderType) => {
        const orderTypeArray = orderType.split("_");
        const transformedOrderType = orderTypeArray.map(toTitleCase);
        return {
          text: transformedOrderType.join(" "),
          value: orderType,
        };
      });
      this.setState({ orderTypes });
    });
    if (isExtensionEnabled("DeliverySlots")) {
      this.getOrderSlots();
    }
  }

  // This componentDidUpdate method used to track the change in orderType
  // As inside BaseForm the state is getting mutated, we are not able to compare
  // prevState and current state. This is why lastOrderType variable is introduced
  // to store the last value of orderType. As it does not have any impact on the UI,
  // we are not storing it inside state, thus we can prevent unnecessary render.
  componentDidUpdate(prevProps, prevState) {
    const { values } = this.state;
    if (values && values.orderType !== this.lastOrderType) {
      this.lastOrderType = values.orderType;
      if (values.orderType !== SELF_CHECKOUT) {
        this.getOrderSlots(values.orderType);
      }
    }
  }

  componentWillUnmount() {
    this.slotApi && this.slotApi.cancel();
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    const { slotOptions, orderTypes, isFetchingSlots } = this.state;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("trips.filter.byOrderNumber.heading")}
            placeholder={getMessage(
              "order.filters.referenceNumber.placeholder"
            )}
            name="referenceNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["referenceNumber"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage("product.filters.clientId.heading")}
            placeholder={getMessage("order.filters.clientId.placeholder")}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage("category.header.status")}
            placeholder={getMessage("order.filters.status.placeholder")}
            name="status"
            options={getOrderStatusOptions()}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              loseEmphasisOnFill: true,
            })}
          />
          {isExtensionEnabled("OnlinePaymentSupport") && (
            <Select
              label={getMessage("coupon.form.validOnPayment.heading")}
              placeholder={getMessage("order.filters.payment.placeholder")}
              name="paymentMode"
              options={paymentModes}
              {...this.generateStateMappers({
                stateKeys: ["paymentMode"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Select
            label={getMessage("order.filters.paymentStatus")}
            placeholder={getMessage("order.filters.paymentStatus.placeholder")}
            name="paymentStatus"
            options={paymentStatuses}
            {...this.generateStateMappers({
              stateKeys: ["paymentStatus"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage("offer.ordertype.label")}
            placeholder={getMessage("offer.ordertype.placeholder")}
            name="orderType"
            options={orderTypes}
            {...this.generateStateMappers({
              stateKeys: ["orderType"],
              loseEmphasisOnFill: true,
            })}
          />
          {slotOptions && (
            <>
              <Select
                label={getMessage("order.table.header.expectedTime")}
                placeholder={getMessage("order.filters.slot.placeholder")}
                name="orderSlot"
                options={slotOptions}
                {...this.generateStateMappers({
                  stateKeys: ["slot"],
                  loseEmphasisOnFill: true,
                })}
                disabled={isFetchingSlots}
                title={
                  isFetchingSlots
                    ? getMessage("order.filters.slot.fetchTitle")
                    : undefined
                }
              />
              <Select
                label={getMessage("order.filters.slot.sortBy.text")}
                placeholder={getMessage(
                  "order.filters.slot.sortBy.placeholder"
                )}
                name="sortBy"
                options={orderSortBySlots}
                {...this.generateStateMappers({
                  stateKeys: ["sorting"],
                  loseEmphasisOnFill: true,
                })}
                disabled={isFetchingSlots}
                title={
                  isFetchingSlots
                    ? getMessage("order.filters.slot.fetchTitle")
                    : undefined
                }
              />
            </>
          )}
          <SingleDatePicker
            label={getMessage("order.filters.placedOn")}
            placeholder={getMessage("trips.filter.byDates.heading")}
            name="placed-on"
            isOutsideRange
            {...this.generateStateMappers({
              stateKeys: ["placedOn"],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage("order.details.preferredDate")}
            placeholder={getMessage("trips.filter.byDates.heading")}
            name="preferred-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["preferredDate"],
              loseEmphasisOnFill: true,
            })}
          />
          {isExtensionEnabled("PreOrderSupport") && (
            <Checkbox
              label={getMessage("order.filters.preorder")}
              name={`preorder`}
              className="pre-order"
              {...this.generateStateMappers({
                stateKeys: ["preorder"],
                loseEmphasisOnFill: true,
              })}
              controlled
            />
          )}
          <Input
            label={getMessage("order.filters.enterCustomerEmail")}
            placeholder={getMessage("customer.searchByEmail")}
            name="email"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["email"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Phone
            label={getMessage("order.filters.enterCustomerPhone")}
            placeholder={getMessage("customer.searchByPhone")}
            name="phone"
            {...this.generateStateMappers({
              stateKeys: ["phone"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage("search.label.text")}</SubmitButton>
        <ClearButton>
          {getMessage("category.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
