import React from "react";
import { Link } from "react-router-dom";
import { getDisplayAddress } from "../../lib/commonlyused";
import { getMessage } from "../../lib/translator";
import "./style.css";

export default function CustomerDetails(props) {
  const { customerName, address, ticketData } = props;
  return (
    <div className="customer-details">
      <h3 className="customer-heading">{getMessage("Customer")}</h3>
      {
        <Link
          className="customer-name"
          to={`/customers/customers/view/${ticketData?.complainantUserID}`}
        >
          {customerName}
        </Link>
      }
      <div className="address-wrapper">
        <h3>{getMessage("tickets.heading.delivery.address")}</h3>
        {address ? (
          <div className="delievery-address">
            {getDisplayAddress(address, null)}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
