import en from "./dataset/en";
import MessageFormat from "messageformat";

function getMessage(key, params = {}) {
  if (!Boolean(key)) {
    return "";
  }
  let lang = getLocale()["language"];
  let data;
  try {
    data = require(`./dataset/${lang}`).default;
  } catch (e) {
    data = en;
  }
  let msg = key;
  if (
    lang in data &&
    key in data[lang].strings &&
    data[lang]["strings"][key] !== ""
  ) {
    msg = data[lang]["strings"][key];
  } else if (key in en["en"].strings) {
    msg = en["en"]["strings"][key];
  }
  return new MessageFormat(lang).compile(msg)(params);
}

function setLocale({ language }) {
  if (language) {
    window.localStorage.setItem("language", language);
  }
}

function getLocale() {
  return {
    language: window.localStorage.getItem("language"),
  };
}

function getSupportedLanguages() {
  return [
    {
      isoCode: "en",
      language: "English",
    },
    {
      isoCode: "kn",
      language: "Kannada",
    },
    {
      isoCode: "hi",
      language: "Hindi",
    },
  ];
}

export { getMessage, setLocale, getLocale, getSupportedLanguages };
