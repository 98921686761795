const CountTrialDays = (evaluationPeriodDetails) => {
  const date1 = new Date();
  const date2 = new Date(evaluationPeriodDetails.endDate);
  const timeDifference = date2 - date1;
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const Days = Math.ceil(timeDifference / millisecondsInADay);
  return Days;
};

export default CountTrialDays;
