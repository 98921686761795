import React from "react";
import { BaseForm } from "../../components/Form";
import RichEditor, {
  exportText,
  importText,
} from "../../components/RichEditor";
import { getMessage } from "../../lib/translator";
import API from "../../lib/api";
import { Dialog } from "../../components/Popup";

const API_HEADER = {
  realmID: 1,
};
export default class TicketForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      errorMessage: false,
      values: {
        comment: "",
        ticketID: props.ticketID || "",
        showSuccessDialog: false,
      },
    };
  }

  onSubmit = (data) => {
    const payload = {
      isRespondentComment: true,
      ticketID: Number(this.state.values.ticketID),
      comment: exportText(this.state.values.comment),
    };

    if (payload.comment !== "") {
      this.setState({
        submitting: true,
      });
      const commentApi = new API({ url: "/ticket-service/comment" });
      commentApi
        .post(payload, API_HEADER)
        .then((res) => {
          this.props.fetchTicketHistory();
          this.setState({
            showSuccessDialog: true,
            values: {
              ...this.state.values,
              comment: "",
            },
          });
        })
        .catch((error) => {
          this.setState({
            errorMessage: true,
          });
          console.error(error);
        })
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    }
  };

  handleOnChange = (value) => {
    this.setState({
      values: {
        ...this.state.values,
        comment: value,
      },
    });
  };
  closeDialogs = () => {
    this.setState({
      showSuccessDialog: false,
      errorMessage: false,
    });
  };

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="form-wrapper">
        <Form>
          <Dialog
            show={this.state.showSuccessDialog}
            className="success"
            information={getMessage("support.ticket.comment.succes.title")}
            close={this.closeDialogs}
            closeText={getMessage("okay.text")}
          />
          <Dialog
            show={this.state.errorMessage}
            className="error"
            information={getMessage("basicInfo.save.error.title")}
            close={this.closeDialogs}
            closeText={getMessage("okay.text")}
          />
          <div className="ticket-rich-edit">
            <RichEditor
              key={`content`}
              placeholder={"Type"}
              value={importText(this.state.values.comment)}
              onChange={this.handleOnChange}
            />
          </div>

          <SubmitButton
            disabled={this.state.submitting}
            onClick={this.handleOnReply}
          >
            {this.state.submitting
              ? "..."
              : getMessage("customer.details.mail.submitText.send")}
          </SubmitButton>
        </Form>
      </div>
    );
  }
}
