import React from "react";
import DataFormater from "../../../../utils/dateFormater";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const DoubleLineChart = ({
  headerTag = null,
  data,
  yKey,
  toolTipContent,
  legendStyles,
  isComparator,
  yKey2,
  legendsName,
  isMobileView,
  xaxis,
  yaxis,
  cartesianGrid,
}) => (
  <section className="analytic-double-line-chart">
    {headerTag}
    <ResponsiveContainer width={"100%"} height={300}>
      <LineChart
        data={data}
        margin={{
          top: 30,
          right: 30,
          left: isMobileView ? 0 : 15,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey={xaxis?.xKey ?? "date"}
          padding={{ left: 30 }}
          ticks={xaxis?.xAxisTicks?.xAxisTicks}
          tickLine={xaxis?.tickLine}
          tickMargin={xaxis?.tickMargin}
          axisLine={{ stroke: xaxis?.axisLine?.stroke }}
          tick={{
            color: xaxis?.tick?.color,
            strokeWidth: xaxis?.tick?.strokeWidth,
          }}
        />
        <YAxis
          tickFormatter={DataFormater}
          tickLine={yaxis?.tickLine}
          tickMargin={yaxis?.tickMargin}
          tick={{
            color: yaxis?.tick?.color,
            strokeWidth: yaxis?.tick?.strokeWidth,
          }}
          axisLine={{ stroke: yaxis?.axisLine?.stroke }}
        />
        <CartesianGrid
          vertical={cartesianGrid?.vertical}
          stroke={cartesianGrid?.stroke}
        />

        <Line
          dataKey={yKey}
          stroke="#4ab819"
          fill="#4ab819"
          strokeWidth={1}
          activeDot={{ stroke: "#4ab819", r: 2 }}
          dot={{ r: 1 }}
          name={legendsName?.primary ?? ""}
        />
        {isComparator && (
          <Line
            dataKey={yKey2}
            stroke="#239CAE"
            fill="#239CAE"
            strokeDasharray="5 5"
            strokeWidth={1}
            activeDot={{ stroke: "#239CAE", r: 2 }}
            dot={{ r: 1 }}
            name={legendsName?.secondary ?? ""}
          />
        )}

        <Legend
          wrapperStyle={{ top: 8, left: 25 }}
          verticalAlign={legendStyles.verticalAlign || "top"}
          height={legendStyles.height || 36}
          layout="vertical"
          iconType="plainline"
        />
        <Tooltip
          cursor={false}
          wrapperStyle={{
            top: "-4.5rem",
            left: "-2.7rem",
          }}
          content={toolTipContent}
        />
      </LineChart>
    </ResponsiveContainer>
  </section>
);

export default DoubleLineChart;
