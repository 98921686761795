import "sanitize.css/sanitize.css";
import React, { Component } from "react";
import Router from "./router";
import { getLocale, setLocale } from "../../lib/translator";
import { getSession, isLoggedIn, saveSession } from "../../lib/auth";
import ErrorBoundary from "../../components/ErrorBoundary";
import "../../lib/polyfills";
import API from "../../lib/api";
import "./css.js";
import "./app.css";
import { messageHandler } from "../../lib/internalDashboard/internalDashboard";
import { IS_STAGING } from "../../config/app";
import TagManager from "react-gtm-module";
import { FaroErrorBoundary, withFaroProfiler } from "@grafana/faro-react";
let tagManagerId = "";
if (
  process.env.NODE_ENV === "production" &&
  !IS_STAGING &&
  process.env.NODE_ENV !== "development"
) {
  tagManagerId = "GTM-MZBV747";
}
if (IS_STAGING && process.env.NODE_ENV !== "development") {
  tagManagerId = "GTM-NQ48S5L";
}
const tagManagerArgs = {
  gtmId: tagManagerId,
};
TagManager.initialize(tagManagerArgs);
class App extends Component {
  constructor(props) {
    super(props);
    this.org = getSession().organization;
  }
  componentDidMount() {
    if (window) {
      // for handling communication with internal-dashboard to handle certain actions
      window.onload = function () {
        window.addEventListener("message", messageHandler);
      };
    }

    // fetch permissions wnenever app loads for logged in users, so that stale permission data from localstorage is not used when the user refreshes the page
    if (window.localStorage.getItem("permissions")) {
      this.meApi = new API({ url: "/account-service/me" });
      this.organizationApi = new API({
        url: `/account-service/organization/${this.org.id}?include=evaluationPeriodDetails`,
      });
      Promise.all([this.meApi.get(), this.organizationApi.get()])
        .then(([meApiResponse, organizationResponse]) => {
          saveSession({
            user: meApiResponse.data?.user,
            organization: organizationResponse?.data?.organization,
          });
          this.forceUpdate();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
    // Add GoogleTag Manager script in production or staging
    window.dataLayer.push({
      event: "pageview",
    });
    // Add GoogleTag and GoogleAds script only in Production
    if (
      process.env.NODE_ENV === "production" &&
      !IS_STAGING &&
      process.env.NODE_ENV !== "development"
    ) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", "G-F0HVPCNDPD");
      gtag("config", "AW-10877183916");

      const scriptGoogleTag = document.createElement("script");
      scriptGoogleTag.src =
        "https://www.googletagmanager.com/gtag/js?id=G-F0HVPCNDPD";
      scriptGoogleTag.async = true;

      const scriptGoogleAds = document.createElement("script");
      scriptGoogleAds.src =
        "https://www.googletagmanager.com/gtag/js?id=AW-10877183916";
      scriptGoogleAds.async = true;

      document.head.appendChild(scriptGoogleTag);
      document.head.appendChild(scriptGoogleAds);
    } else {
      if (IS_STAGING && process.env.NODE_ENV !== "development") {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag("js", new Date());
        gtag("config", "G-NM3GFN7ZG1");
        const scriptgtag = document.createElement("script");
        scriptgtag.src =
          "https://www.googletagmanager.com/gtag/js?id=G-NM3GFN7ZG1";
        scriptgtag.async = true;
        document.head.appendChild(scriptgtag);
      }
    }

    import("../../initialize").then(({ initializeFaro }) => {
      initializeFaro();
    });
  }
  componentWillUnmount() {
    if (window) {
      window.removeEventListener("message", messageHandler);
    }
    this.meApi && this.meApi.cancel();
    this.organizationApi && this.organizationApi.cancel();
  }
  render() {
    let locale = getLocale();
    if (!locale.language) {
      locale.language = "en";
    }
    setLocale(locale);
    return (
      <FaroErrorBoundary>
        <ErrorBoundary>
          <Router isLoggedIn={isLoggedIn()} />
        </ErrorBoundary>
      </FaroErrorBoundary>
    );
  }
}
export default withFaroProfiler(App);
