import React from "react";

const NotificationIcon = (props) => (
<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title></title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="mster-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="notification">
            <g>
                <g id="master">
                    <rect id="Rectangle" x="0" y="0" width="30" height="30"></rect>
                </g>
                <g transform="translate(5.000000, 3.000000)" id="Group-6" stroke="#b8b8b5">
                    <g>
                        <path d="M13.2937938,20.5706837 L13.2937938,20.6399557 C13.2937976,23.0109577 11.7295109,24.5659083 9.79732197,24.5706837 C7.86661397,24.5541414 6.30533123,23.0048215 6.29379376,20.6356262 L6.29379376,20.5706837" id="Path"></path>
                        <path d="M0.658317104,19.3043707 L1.47052792,17.5884739 C2.0476098,16.3640056 2.34885447,15.0201582 2.35161879,13.6579423 L2.35161879,8.31431936 C2.35161879,8.1777601 2.35161879,8.0421904 2.35161879,7.90761027 C2.55538898,3.81083263 5.90181232,0.598721463 9.98008635,0.598721463 L10.1608966,0.598721463 C14.2420406,0.598721463 17.5855939,3.81676998 17.7778841,7.92245367 C17.7778841,8.0570338 17.7778841,8.1926035 17.7778841,8.32916275 L17.7778841,13.6727858 C17.7855505,15.035151 18.0917034,16.3779918 18.673325,17.6003487 L19.4826658,19.3162454 C19.6135567,19.5925643 19.5972801,19.9191979 19.4396171,20.180132 C19.2819541,20.441066 19.0052424,20.5993313 18.707765,20.598715 L1.44182788,20.598715 C1.1389984,20.5999081 0.857827988,20.4364528 0.701367147,20.1682564 C0.543704025,19.9073228 0.527426901,19.5806896 0.658317104,19.3043707 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default NotificationIcon;