import React from "react";

export default function SupportIcon() {
  return (
    <svg
      width="23px"
      height="20px"
      viewBox="0 0 23 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 25</title>
      <g
        id="Support---tickets"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Support-Tickets"
          transform="translate(-21, -332)"
          stroke="#b8b8b5"
          stroke-width="0.75"
        >
          <g id="Group-25" transform="translate(22, 333)">
            <polygon id="Path-3" points="0 10 0 17 3 17 3 10"></polygon>
            <path
              d="M3,11.9753788 L5.27778675,11.9753788 C5.96686799,10.6584596 6.99933462,10 8.37518665,10 C9.75103869,10 10.7190641,10.6584596 11.279263,11.9753788 C13.1253979,11.4788443 14.3399676,12.153718 14.9229723,14 C15.1447609,14.7023689 16.3029837,11.3715423 18.5486628,10.3398291 C19.0531206,10.1080703 19.8702331,10.4049862 21,11.230577 L16.3300998,18 L6.9292873,18 C6.13461244,17.2868295 5.50960867,16.8442822 5.05427598,16.672358 C4.59894329,16.5004338 3.91418463,16.448442 3,16.5163825"
              id="Path-6"
            ></path>
            <path
              d="M10,8 L18,8 C17.7242575,6.71352079 17.3499274,5.83402177 16.8770098,5.36150297 C16.8770098,5.36150297 16.8770098,5.36150297 16.8770098,5.36150297 C15.7532234,4.23866435 14.075815,3.87842139 12.5901002,4.44083927 C11.8429528,4.72366291 11.3040455,5.03055351 10.9733754,5.36150297 C10.4625591,5.87275087 10.1381006,6.75224988 10,8 Z"
              id="Path-7"
            ></path>
            <circle id="Oval" cx="14" cy="2" r="2"></circle>
            <line x1="14" y1="15" x2="8" y2="15" id="Path-8"></line>
          </g>
        </g>
      </g>
    </svg>
  );
}
