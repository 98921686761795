import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {
  isExtensionEnabled,
  isEnterprise,
  modifyEnterprisePermissions,
  modifyMenuForEnterprise,
  getSession,
} from "../../lib/auth";

import { get } from "../../lib/storage";
import { roleMapping } from "../../lib/commonlyused/constants";

import PrivateRoute, { hasAccess } from "../PrivateRoute";
import LazyComponentLoader, { LazyComponent } from "../LazyComponentLoader";
import Home from "../../pages/home";
// Only list mandatory API endpoints/extensions here
import { SupportRoutes } from "../../pages/support";

let requiredPermissions = {
  user: [
    {
      slug: "personal-information",
    },
    {
      slug: "billing-information",
    },
    {
      slug: "notifications",
    },
    {
      slug: "account-balance",
      endpoints: ["billing-service/transaction", "billing-service/balance"],
    },
    {
      slug: "change-password",
      endpoints: ["account-service/change-password"],
    },
    {
      slug: "delete-account",
    },
    {
      slug: "my-leaves",
      endpoints: ["account-service/user-leave"],
      extensions: ["HrManagement"],
    },
    {
      slug: "leave-management",
      endpoints: ["account-service/user-leave"],
      extensions: ["HrManagement"],
    },
    {
      slug: "leave-summary",
      endpoints: ["account-service/user-leave"],
      extensions: ["HrManagement"],
    },
    {
      slug: "logout",
    },
  ],
  orders: [
    {
      slug: "orders",
      endpoints: ["order-service/order", "account-service/config"],
    },
  ],
  catalogue: [
    {
      slug: "products",
      endpoints: [
        "catalogue-service/category",
        "catalogue-service/product",
        "catalogue-service/product-upload",
        "account-service/extension",
      ],
    },
  ],
  marketing: [
    {
      slug: "coupons",
      endpoints: ["promo-service/coupon"],
      extensions: ["Marketing"],
    },
  ],
  customers: [
    {
      slug: "customers",
      endpoints: ["customer-service/customer"],
      extensions: ["CustomerSupport"],
    },
  ],
  reviews: [
    {
      slug: "reviews",
      extensions: ["ProductRatings"],
    },
  ],
  support: [
    {
      slug: "support",
      extensions: ["TicketManagement"],
    },
  ],
  allReturns: [
    {
      slug: "allReturns",
      extensions: ["OrderReturns"],
    },
  ],
  analytics: [
    {
      slug: "reports",
      extensions: ["ReportsAndAnalytics"],
    },
  ],
  operations: [
    {
      slug: "store-slot-config",
      endpoints: [
        // 'order-service/store-slot-rule',
        "account-service/extension",
      ],
      extensions: ["DeliverySlots"],
    },
    {
      slug: "vehicles",
      endpoints: ["logistics-service/vehicle"],
      extensions: ["LogisticsSupport"],
    },
    {
      slug: "vehicle-planning",
      endpoints: [
        "logistics-service/vehicle-planner",
        "logistics-service/vehicle-attendance",
        "logistics-service/vehicle",
      ],
      extensions: ["LogisticsSupport"],
    },
    {
      slug: "trips",
      endpoints: ["logistics-service/trip", "account-service/config"],
      extensions: ["LogisticsSupport"],
    },
    {
      slug: "trip-planner",
      endpoints: ["logistics-service/automation", "logistics-service/trip"],
      extensions: ["LogisticsSupport"],
    },
    {
      slug: "manage-runners",
      endpoints: ["account-service/employee"],
      extensions: ["LogisticsSupport"],
    },
    {
      slug: "picking-queue",
      endpoints: ["order-service/picking-queue"],
      extensions: ["InStoreProcessing"],
    },
    {
      slug: "rack-management",
      endpoints: ["order-service/picking-sequence"],
      extensions: ["InStoreProcessing"],
    },
  ],
  "seller-support": [
    {
      slug: "sellers",
      endpoints: ["account-service/seller"],
      extensions: ["MarketPlace"],
    },
    {
      slug: "employee",
      endpoints: ["account-service/employee"],
      extensions: ["MarketPlace"],
    },
  ],
  settings: [
    {
      slug: "basic-information",
      endpoints: ["account-service/config"],
    },
    {
      slug: "contact-details",
      endpoints: ["account-service/config"],
    },
    {
      slug: "multi-store",
      extensions: ["MultiStoreSupport"],
      endpoints: ["account-service/store"],
    },
    {
      slug: "themes",
      endpoints: ["account-service/page", "account-service/theme"],
    },
    {
      slug: "pages",
    },
    {
      slug: "blog",
    },
    {
      slug: "order-config",
    },

    {
      slug: "payment-configuration",
    },
    {
      slug: "courier-support",
    },
    {
      slug: "order-package-config",
      endpoints: ["order-service/package"],
      // extensions: ["ShipRocket"],
    },
    {
      slug: "communication-configuration",
      endpoints: ["config-service/config"],
    },
    {
      slug: "mobile-apps",
      endpoints: ["account-service/config"],
      //extensions: ["MobileApps"],
    },
    {
      slug: "employees",
      endpoints: ["account-service/employee", "account-service/designation"],
      extensions: ["HrManagement"],
    },
    {
      slug: "api-integration",
    },
    {
      slug: "extensions",
      endpoints: ["account-service/extension"],
    },
  ],
};

const Operations = LazyComponent(() =>
  import(/* webpackPrefetch: true */ "../../pages/operations")
);
const OnboardingContainer = LazyComponent(() =>
  import(/* webpackChunkName: "onboarding"*/ "../../pages/onboarding")
);

const CustomerSupport = LazyComponent(() =>
  import("../../pages/customer-support")
);
const Delivery = LazyComponent(() => import("../../pages/delivery"));
const Catalogue = LazyComponent(() => import("../../pages/catalogue"));
const Marketing = LazyComponent(() => import("../../pages/marketing"));
const OnlineStore = LazyComponent(() => import("../../pages/online-store"));
const Settings = LazyComponent(() => import("../../pages/settings"));
const Hr = LazyComponent(() => import("../../pages/hr"));
const Analytics = LazyComponent(() => import("../../pages/analytics"));
const AllReturns = LazyComponent(() => import("../../pages/allReturns"));
const SignUp = LazyComponent(() => import("../../pages/others/SignUp"));
const Login = LazyComponent(() => import("../../pages/others/Login"));
const ResetPassword = LazyComponent(() =>
  import("../../pages/others/ResetPassword")
);
const Logout = LazyComponent(() => import("../../pages/others/Logout"));
const MissingPage = LazyComponent(() =>
  import("../../pages/others/MissingPage")
);
const Verify = LazyComponent(() => import("../../pages/others/Verify"));
const AccountCreated = LazyComponent(() =>
  import("../../pages/others/AccountCreated")
);
const PaymentResponse = LazyComponent(() =>
  import("../../pages/others/PaymentResponse")
);
const Reviews = LazyComponent(() => import("../../pages/reviews/Reviews"));
const Seller = LazyComponent(() => import("../../pages/sellers"));

const User = LazyComponent(() => import("../../pages/others/user"));
export default class Router extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location);
    if (url.searchParams.get("guid")) {
      this.state = {
        refresh: true,
      };
    } else {
      this.state = {
        refresh: false,
      };
    }
  }
  setRefresh = (refreshValue) => {
    this.setState({ refresh: refreshValue });
  };
  rerender = () => {
    this.forceUpdate();
  };
  render() {
    const { props } = this;

    if (isEnterprise()) {
      requiredPermissions.settings = modifyEnterprisePermissions();
    }

    let menu = Object.assign(
      {},
      ...Object.keys(requiredPermissions).map((key) => {
        let permissions = requiredPermissions[key];
        return {
          [key]: permissions.filter(hasAccess).map(({ slug }) => slug),
        };
      })
    );

    // Prune menu sections which have no links in them
    for (let section in menu) {
      if (!menu[section].length) {
        delete menu[section];
      }
    }

    const isLoggedIn = props.isLoggedIn;

    if (isEnterprise()) {
      menu = modifyMenuForEnterprise(menu);
    }
    if (get("user") && JSON.parse(get("user"))) {
      let user = JSON.parse(get("user"));
      if (!user.isOwner) {
        delete menu.settings;
        delete menu.user[1];
        delete menu.user[2];
      }
      let roles =
        user.designation && user.designation.roles && user.designation.roles;
      if (roles && roles.length === 1) {
        let role = roles[0];
        if (role.name === "Transport Coordinator") {
          delete menu.hr;
        }
        if (role.name === "ORDER MANAGER") {
          delete menu.customers;
          delete menu.operations;
          delete menu.catalogue;
        }
        if (role.name === "STORE MANAGER") {
          delete menu.customers;
        }
      }
    }

    const user = JSON.parse(get("user"));
    const role =
      user &&
      user.designation &&
      user.designation.roles &&
      user.designation.roles[0]
        ? user.designation.roles[0]
        : {};
    let roleObject = roleMapping.find((mapping) => mapping.name === role.name);
    let roleRedirect = roleObject ? roleObject.redirect : null;
    const { organization } = getSession();
    roleRedirect =
      !organization?.industry && !isEnterprise()
        ? "/onboarding"
        : roleRedirect || "/home/dashboard";

    return (
      <Switch>
        {/* Default second level menu option for each top level menu */}
        <Redirect exact from="/" to={roleRedirect} />

        <Route path="/signUp">
          <LazyComponentLoader Component={SignUp} />
        </Route>
        <Route path="/login">
          <LazyComponentLoader Component={Login} />
        </Route>
        <Route path="/forgot-password">
          <LazyComponentLoader Component={ResetPassword} />
        </Route>
        <Route path="/user/logout" exact>
          <LazyComponentLoader Component={Logout} />
        </Route>
        <Route path="/verify">
          <LazyComponentLoader Component={Verify} />
        </Route>
        <Route path="/account-created">
          <LazyComponentLoader Component={AccountCreated} />
        </Route>
        {/* For each route, we should have exactly one component */}
        <PrivateRoute
          path="/home"
          component={Home}
          isLazy={true}
          isLoggedIn={isLoggedIn}
          refresh={this.state.refresh}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
          menu={menu}
        />
        <PrivateRoute
          path="/onboarding"
          component={OnboardingContainer}
          isLazy={true}
          isLoggedIn={isLoggedIn}
          refresh={this.state.refresh}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/orders"
          component={Operations}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
          hasIndustry={organization?.industry}
        />
        <PrivateRoute
          path="/reviews"
          menu={menu}
          component={Reviews}
          isLazy={true}
          {...props}
        />
        <PrivateRoute
          path="/delivery"
          component={Delivery}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
          hasIndustry={organization?.industry}
        />
        <PrivateRoute
          path="/customers"
          component={CustomerSupport}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/catalogue"
          component={Catalogue}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/online-store"
          component={OnlineStore}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/settings"
          component={Settings}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/seller-support"
          component={Seller}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/support"
          menu={menu}
          component={SupportRoutes}
          isLazy={true}
          {...props}
        />
        <PrivateRoute
          path="/payment-response"
          component={PaymentResponse}
          isLazy={true}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />

        <PrivateRoute
          path="/staff"
          component={Hr}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />

        {(isExtensionEnabled("Marketing") ||
          isExtensionEnabled("Seo") ||
          isExtensionEnabled("Campaigns")) && (
          <PrivateRoute
            path="/marketing"
            component={Marketing}
            isLoggedIn={isLoggedIn}
            requiredPermissions={requiredPermissions}
            menu={menu}
            refresh={this.state.refresh}
            isLazy={true}
            rerender={this.rerender}
            setRefresh={this.setRefresh}
          />
        )}
        <PrivateRoute
          path="/analytics"
          component={Analytics}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/user"
          component={User}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />
        <PrivateRoute
          path="/allReturns"
          component={AllReturns}
          isLoggedIn={isLoggedIn}
          requiredPermissions={requiredPermissions}
          menu={menu}
          refresh={this.state.refresh}
          isLazy={true}
          rerender={this.rerender}
          setRefresh={this.setRefresh}
        />

        <Route>
          <LazyComponentLoader Component={MissingPage} />
        </Route>
      </Switch>
    );
  }
}
